import * as React from "react";
import { ListItemButton, ListItemText } from "@mui/material";
import {
  ExtraSmallTypography,
  PrimaryPopUp,
  GrayDivider,
  StyledListItemButton,
  StyledProfilePopText,
  BlackTypography,
} from "assets/Mui/globalTheme";
import { Link } from "react-router-dom";
import LogoutModel from "./logout";
import { useSelector } from "react-redux";
import { ACM, CS, MODERATOR } from "common/constants/userModules";
export default function ProfilPopOver({ handleClose, anchorEl,viewModel }) {
  const user = useSelector(state => state?.Login);
  const open = Boolean(anchorEl);
  const role = JSON.parse(localStorage.getItem("authUser")).role;
  const id = open ? "profile-popover" : undefined;
  const [logout, setLogout] = React.useState(false);
  function handleLogout() {
    setLogout(!logout);
  }
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    style: {
      maxHeight: ITEM_HEIGHT * 5.7 + ITEM_PADDING_TOP,
      width: 250,
      background: "white",
      marginTop: "10px",
      fontSize: "14px",
      padding: "10px 0px",
      borderRadius: "10px",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
  };
  function SettingsUrl() {
    switch (user?.role) {
      case "ZA":
        return "/profile-settings?tab=2";
      case "CA":
        return "/profile-settings?tab=2";
      default:
        return "/profile-settings?tab=2";
    }
  }
  const isValid = [CS,MODERATOR,ACM].includes(role)
  return (
    <>
      <PrimaryPopUp
        id={id}
        open={open}
        PaperProps={MenuProps}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <StyledListItemButton>
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              {user?.first_name} {user?.last_name}
            </BlackTypography>
            <ExtraSmallTypography variant="h6" component="h6">
              {user?.email}
            </ExtraSmallTypography>
          </StyledProfilePopText>
        </StyledListItemButton>
        <GrayDivider />
        {!isValid ?<>
        <StyledListItemButton component={Link} to="/">
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              Home
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton>
        <StyledListItemButton component={Link} to="/profile-settings?tab=0">
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              Profile
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton>
        <StyledListItemButton component={Link} to={SettingsUrl()}>
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              Settings
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton>
        </> :
          <>
        <StyledListItemButton component={Link} to="/profile-settings?tab=0">
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              Profile
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton>
        {/* <StyledListItemButton onClick={viewModel}>
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              Profile
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton> */}
          
          </>
        }


        {!isValid && <GrayDivider />}

        <StyledListItemButton onClick={handleLogout}>
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              Logout
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton>
      </PrimaryPopUp>
      <LogoutModel logout={logout} handleLogout={handleLogout} />
    </>
  );
}

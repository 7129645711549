import {
  Avatar,
  Typography,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import {
  SearchPrimaryInput,
  PrimaryButton,
  PrimaryTypography,
  ExtraSmallTypography,
  PrimaryAvatar,
  PrimaryChip,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import SearchParamHook from "hooks/searchParams";
import DrawerFilter from "./drawerFilter";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DetailsDrawer from "components/JobDetails/DetailDrawer";
import { getSingleJobDetails } from "helpers/services/jobs/jobs";
import ErrorHandlingToaster from "helpers/Toaster/error";
import AddPeople from "./AddPeople";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { imageURL } from "common/constants/commonURLS";
import KanbanPopup from "./popup";
import JobDescDrawer from "./JobDescDrawer";

const useStyles = makeStyles({
  status:{
    width:'100px',
    textAlign:'center',
    "& p":{
      color:'#fff',
      fontSize:'14px',
      borderRadius:'4px 4px 0 0',
      padding:'2px'
    },
    "& span":{
      border:'1px solid #081753',
      display:'inline-block',
      padding:'4px',
      width:'100%',
      borderRadius:'0 0 4px 4px',
      borderTop:'none'
    }
  }
})

export default function KanbanHeader({ setUpdated, updated,jobDetails,showGrid,handleGrid,fetchKanban }) {
  const [filter, setFilter] = React.useState(null);
  const { role } = useSelector(state => state.Login);
  const [addPeopleModel, setAddPeopleModel] = useState(false);
  const [viewJobInfo,setViewJobInfo]= useState(false);
  const [jobPostInfo,setJobPostInfo] = useState(null);
  const {id} = useParams(); 

  const classes = useStyles()
  function handleAddPeople() {
    setAddPeopleModel(!addPeopleModel);
  }
  const open = Boolean(filter);
  const handleClick = event => {
    setFilter(event.currentTarget);
  };
  const handleClose = () => {
    setFilter(null);
  };



  useEffect(()=>{
    (async function(){
     const res =  await getSingleJobDetails(id)
     const {data} = res
    
     setJobPostInfo(data?.data_payload)
    })()
  },[])



  const [showDetails, setShowDetails] = useState(false);

  const [drawerStatus, setDrawerStatus] = React.useState(false);
  const [viewPopup,setViewPopup] = useState(false)
  const [anchorEl,setAnchorEl]  = useState(null)
  const toggleJobDrawer = React.useCallback(() => {
    setShowDetails(!showDetails);
    searchParams.delete("id");
    history.replace({
      search: searchParams.toString(),
    });
  }, [showDetails]);
  const toggleDrawer = React.useCallback(() => {
    setDrawerStatus(!drawerStatus);
  }, [drawerStatus]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const filters = [
    { name: "Latest", value: "latest" },
    { name: "Old", value: "Old" },
    { name: "View Details", value: "view" },
    { name: "Add Filter", value: "add_filter" },
  ];
  // const [jobDetails, setJobDetails] = useState({
  //   application_deadline: new Date(),
  //   createdAt: new Date(),
  // });
  function handleFilters(e) {
    if (e === "view") {
      searchParams.set("id", id);
      history.replace({ search: searchParams.toString() });
    }
    if (e === "add_filter") {
      searchParams.set("add_filter", true);
      history.replace({
        search: searchParams.toString(),
      });
      toggleDrawer();
    }
    handleClose();
  }
  // const searchParams = SearchParamHook();
  // const history = useHistory();
  // const { id } = useParams();
  // useEffect(() => {
  //   findJobDetails(id);
  // }, [id]);
  // async function findJobDetails(id) {
  //   const { status, data } = await getSingleJobDetails(id);
  //   ErrorHandlingToaster({ data, status });
  //   setJobDetails(data);
  // }
  // console.log(jobDetails);
  function handleSearchInKanban(e) {
    searchParams.set("search", e.target.value);
    history.replace({ search: searchParams.toString() });
  }
  // console.log(jobDetails,'veda');
  const isRecentUser = Boolean(jobDetails?.recently_applied_candidate_details?.length)
  let recentCandDetails;
  let newCandidate;
  if(isRecentUser){
    recentCandDetails = jobDetails?.recently_applied_candidate_details[0] || ""
    newCandidate = recentCandDetails ? recentCandDetails?.first_name +" "+ recentCandDetails?.last_name : ''
  }

  let companyName = JSON.parse(localStorage.getItem("authUser"))

  return (
    <div className="d-flex align-items-center justify-content-between px-3 mb-3 mt-3">
      <section className="d-flex align-items-center gap-2 justify-content-between ">
       <PrimaryAvatar src={imageURL + jobDetails?.company_details?.company_image}>
        {jobDetails?.company_details?.company_name?.charAt(0)}
       </PrimaryAvatar>
       <Stack>
        <Stack spacing={0.85} direction={'row'} justifyContent={"start"} alignItems={"start"}>
          <PrimaryTypography variant="h2" component="h2">
            {jobDetails?.job_title}
          </PrimaryTypography>
          <IconButton onClick={()=>setViewJobInfo(!viewJobInfo)}>
          <FeatherIcon style={{fill:"#fff"}} icon="info" size="20" />
        </IconButton>

        <JobDescDrawer setViewJobInfo={setViewJobInfo} viewJobInfo={viewJobInfo} jobPostInfo={jobPostInfo}/>
          {/* <PrimaryChip sx={{bgcolor:"#4caf50 !important",color:'#fff !important'}} label="Published" size="small" /> */}
          {isRecentUser && <>
            {/* <PrimaryChip sx={{bgcolor:"#024430 !important",color:'#ffe7bb !important'}}  label="New Candidates" size="small" /> */}
            <PrimaryChip  icon={<Avatar sx={{ width: "16px",bgcolor:"#024430 !important",color:'#ffe7bb !important', fontSize:'12px !important', height: "16px" }}>{recentCandDetails?.first_name[0]||''}</Avatar>} label={newCandidate} size="small" />
          </>}
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 32 32"
              id="building"
            >
              <path d="M20 4h2v2h-2zm4 0h2v2h-2zm-4 4h2v2h-2zm4 0h2v2h-2zm-4 4h2v2h-2zm4 0h2v2h-2zm-4 4h2v2h-2zm4 0h2v2h-2zm-4 4h2v2h-2zm4 0h2v2h-2zm4-20H18a2 2 0 0 0-2 2v6H6a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM12 30h-2v-4h2v4zm2 0v-6H8v6H6V10h10v20h-2zm10 0h-2v-4h2v4zm4 0h-2v-6h-6v6h-2V2h10v28zM8 12h2v2H8zm4 0h2v2h-2zm-4 4h2v2H8zm4 0h2v2h-2zm-4 4h2v2H8zm4 0h2v2h-2z"></path>
            </svg>
            <ExtraSmallTypography variant="h6">
              {jobDetails?.company_details?.company_name}
            </ExtraSmallTypography>
          </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <FeatherIcon icon='map-pin' size={14} />
            <ExtraSmallTypography variant="h6">
              {jobDetails?.location}
            </ExtraSmallTypography>
          </Stack>
       </Stack>
      </section>
      <aside className="d-flex flex-column align-items-center gap-2 justify-content-between ">
        {/* <PrimaryButton
          sx={{ width: "100px" }}
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<FeatherIcon icon="chevron-down" size="14" />}
        >
          Filters
        </PrimaryButton>
        <Primarymenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={filter}
          open={open}
          onClose={handleClose}
        >
          {filters?.map(item => {
            return (
              <MenuItem
                onClick={() => handleFilters(item?.value)}
                key={item?.value}
                value={item?.value}
              >
                {item?.name}
              </MenuItem>
            );
          })}
        </Primarymenu>

        <SearchPrimaryInput
          defaultValue=""
          id="bootstrap-input"
          placeholder="search"
          onChange={handleSearchInKanban}
        /> */}
        {/* {["CA","CS","ZA"].includes(role) && jobDetails?.is_active && <IconButton className="align-self-end" onClick={(e)=>{setAnchorEl(e.currentTarget)}}>
          <FeatherIcon icon="more-vertical" size="14" />
        </IconButton>} */}
        <div className="d-flex gap-3">
          {/* <ToggleButtonGroup exclusive sx={{alignSelf:'flex-end'}} onChange={handleGrid} value={showGrid}>
            <ToggleButton size="small" value={"grid"}>
              <FeatherIcon size={14} icon={"grid"} />
            </ToggleButton>
            <ToggleButton size="small" value={"list"}>
              <FeatherIcon size={14} icon={"align-right"} />
            </ToggleButton>
          </ToggleButtonGroup> */}
          <div className={classes.status}>
            <Typography bgcolor={"#4caf50 "} color={"#fff"}>Hired</Typography>
            <span style={{border:'1px solid #081753',display:'inline-block',padding:'4px',width:'100%',borderTop:'none'}}>{jobDetails?.hired_count}</span>
          </div>
          <div className={classes.status}>
            <Typography bgcolor={"#ffc107 "} color={"#fff"}>In Progress</Typography>
            <span>{jobDetails?.inprogress_count}</span>
          </div>
          <div className={classes.status}>
            <Typography bgcolor={"#9e9e9e "} color={"#fff"}>Rejected</Typography>
            <span>{jobDetails?.rejected_count}</span>
          </div>
        </div>
        {/* {(role === "CA" || role === "RC" || role === "BVC") && (
          <PrimaryButton
            onClick={handleAddPeople}
            sx={{ width: "150px" }}
            startIcon={<FeatherIcon size="14" icon="plus" />}
          >
            Add People
          </PrimaryButton>
        )} */}
      </aside>
      {/* <DrawerFilter toggleDrawer={toggleDrawer} open={true} /> */}
      <KanbanPopup handlePeople={handleAddPeople} handleClose={()=>setAnchorEl(null)} anchorEl={anchorEl} jobDetails={jobDetails}/>
      {/* <DetailsDrawer
        jobDetails={jobDetails}
        toggleDrawer={toggleJobDrawer}
        open={showDetails}
      /> */}
      <AddPeople
        handleClose={handleAddPeople}
        open={addPeopleModel}
        setUpdated={setUpdated}
        updated={updated}
        fetchKanban={fetchKanban}
        companyId={jobDetails?.company_details?.id}
      ></AddPeople>
    </div>
  );
}

import * as React from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { getAllReferalParterns } from "helpers/services/referalPartner";
import { imageURL } from "common/constants/commonURLS";
import {
  HoldChip,
  PrimaryButton,
  PrimaryButtonForTable,
  PrimaryChip,
  PrimaryDataGrid,
  PrimarySwitch,
  SmallPrimaryAvatar,
  SmallSizeChip,
} from "assets/Mui/globalTheme";
import { Pagination } from "@mui/material";
import TablePopper from "components/table/header/popper";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import { KeySkillOperator } from "components/table/operators/keySkillOperator";
import { getAllAdminStaffListWithStatus } from "helpers/services/adminStaff/adminStaff";
import { useHistory, useLocation } from "react-router-dom";
import { CA, CS, MODERATOR, ZA } from "common/constants/userModules";
import { IndustryOperators } from "components/table/operators/industryOperators";
import AdminStaffModel from "./addStaff";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import SkillViewModal from "helpers/Modals/SkillViewModal";

export default function AdminStaffTable() {
  const [accountManagers, setAccountManagers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const loginStore = JSON.parse(localStorage.getItem("authUser"));
  const role = loginStore?.role;
  const [viewAddModal, setViewAddModal] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openSkillModal, setOpenSkillModal] = React.useState(false);
  const [modalName, setModalName] = React.useState("");
  const [refetch, setRefetch] = React.useState(false);

  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState("");
  const rowsPerPage = 20;
  const offset = (page - 1) * rowsPerPage;
  const { search } = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    initialFetch(role, page, rowsPerPage);
  }, [update]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page);
    history.push({
      search: searchParams.toString(),
    });
    initialFetch(role, page, rowsPerPage);
  }, [page]);

  // const loginStore = JSON.parse(localStorage.getItem("authUser"));
  const locationCurrent = useLocation();

  async function initialFetch(role, page, rowsPerPage) {
    setLoading(true);
    const { data, status } = await getAllAdminStaffListWithStatus(
      role,
      page,
      rowsPerPage
    );
    setTotalCount(Math.ceil(data?.count / rowsPerPage));
    if (!["/account-manager-list"].includes(locationCurrent.pathname)) {
      // setTotalCount(Math.ceil(data?.count))
      const result =
        data?.data?.length > 0 &&
        data?.data
          ?.filter(e => e.usertype === "MODERATOR")
          .map((item, index) => ({
            id: item?.id,
            email: item?.email || "N/A",
            image: `${imageURL}${item?.image}`,
            phone_number: item?.phone_number || "N/A",
            location: item?.location || "N/A",
            experience: item?.experience || "N/A",
            active_jobs_assigned: item?.active_jobs_assigned || "N/A",
            total_jobs_assigned: item?.total_jobs_assigned || "N/A",
            highest_education: item?.highest_education || "N/A",
            resume: item?.resume,
            name: `${item?.first_name} ${item?.last_name}`,
            companies_mapped: "N/A",
            status: item?.user_approval,
            industry: `${
              item?.industry_details?.length > 0
                ? item?.industry_details?.map(item => item?.name)?.join(",")
                : "N/A"
            }`,
            categories: `${
              item?.functional_area_details?.length > 0
                ? item?.functional_area_details
                    ?.map(item => item?.name)
                    ?.join(",")
                : "N/A"
            }`,
            skills: `${
              item?.skill_details?.length > 0
                ? item?.skill_details?.map(item => item?.name)?.join(",")
                : "N/A"
            }`,
            companies_assigned:
              item?.assigned_company_details?.length > 0
                ? item?.assigned_company_details
                    ?.map(item => item?.company_name)
                    ?.join(",")
                : "N/A",

            moderated_candidates: `${
              item?.candidate_screened ? item.candidate_screened : "0"
            }`,
            candidate_onboarded: `${
              item?.candidate_onboarded ? item.candidate_onboarded : "0"
            }`,
          }));
      if (status === 200) {
        setLoading(false);
      }
      setAccountManagers(result);
      return;
    }
    const result =
      data?.data?.length > 0 &&
      data?.data
        ?.filter(e => e.usertype === "ACCOUNT_MANAGER")
        ?.map((item, index) => ({
          id: item?.id,
          email: item?.email || "N/A",
          image: `${imageURL}${item?.image}`,
          phone_number: item?.phone_number || "N/A",
          location: item?.location || "N/A",
          experience: item?.experience || "N/A",
          active_jobs_assigned: item?.active_jobs_assigned || "N/A",
          total_jobs_assigned: item?.total_jobs_assigned || "N/A",
          highest_education: item?.highest_education || "N/A",
          resume: item?.resume,
          name: `${item?.first_name} ${item?.last_name}`,
          companies_mapped: "N/A",
          status: item?.user_approval,
          industry: `${
            item?.industry_details?.length > 0
              ? item?.industry_details?.map(item => item?.name)?.join(",")
              : "N/A"
          }`,
          categories: `${
            item?.functional_area_details?.length > 0
              ? item?.functional_area_details
                  ?.map(item => item?.name)
                  ?.join(",")
              : "N/A"
          }`,
          skills: `${
            item?.skill_details?.length > 0
              ? item?.skill_details?.map(item => item?.name)?.join(",")
              : "N/A"
          }`,
          companies_assigned:
            item?.assigned_company_details?.length > 0
              ? item?.assigned_company_details
                  ?.map(item => item?.company_name)
                  ?.join(",")
              : "N/A",
        }));
    if (status === 200) {
      setLoading(false);
    }
    setAccountManagers(result);
  }
  const handleCloseModal = () => {
    setOpenSkillModal(!openSkillModal);
  };

  const handleCellClick = params => {
    const field = params.field;

    if (field === "skills") {
      if (params.row.skills !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.skills.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } else if (field === "Categories") {
      if (params.row.Categories !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.Categories.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } else if (field === "companies_assigned") {
      if (params.row.companies_assigned !== "N/A") {
        setModalName(params.field.replace("_", " "));
        setSelectedRowData(params.row.companies_assigned.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } else {
      if (
        !["skills", "Categories"].includes(field) &&
        ![CA, CS].includes(loginStore.role)
      )
        history.push(`/common-user/${params.row.id}`);

      // history.push(`/common-user/${params.row.id}`);
    }
  };
  return (
    <div
      style={{
        padding: "10px",
        height: "91vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {[ZA].includes(loginStore.role) ? (
        <PrimaryButton
          sx={{ width: "max-content !important", ml: "auto !important" }}
          onClick={() => {
            // dispatch({ type: "APPROVE_MODAL" });
            setViewAddModal(true);
          }}
          // startIcon={(<FeatherIcon icon={"add"} size={16} />)}
        >
          Add
        </PrimaryButton>
      ) : null}
      <PrimaryDataGrid
        onCellClick={handleCellClick}
        loading={loading}
        // checkboxSelection={true}
        columns={[
          {
            field: "image",
            headerName:"Image",
            filterable: false,
            disableColumnMenu:true,
            renderCell: params => {
              return (
                <SmallPrimaryAvatar
                  imgProps={{ loading: "lazy" }}
                  src={params?.formattedValue}
                >
                  {params?.row?.name?.charAt(0)?.toUpperCase()}
                </SmallPrimaryAvatar>
              );
            },
          },
          { field: "name",
          headerName:"Name",
          width: 200 },

          { field: "phone_number",
          headerName:"Phone Number",
          width: 300 },
          { field: "email",
          headerName:"Email",
           width: 300 },

          { field: "location",
          headerName:"Location",
          width: 400, filterOperators: LocationOperators },
          {
            field: "status",
            headerName: "Status",
            width: 200,
            renderCell: params => {
              if (params.formattedValue !== "Approve") {
                return <HoldChip label={params?.formattedValue} size="small" />;
              }
              return (
                <PrimaryChip label={params?.formattedValue} size="small" />
              );
            },
          },
          { field: "companies_mapped",
          headerName:"Companies Mapped",
          width: 200 },
          // { field: "active_jobs_assigned", width: 200 },
          // { field: "candidate_onboarded", width: 200 },
          // { field: "total_jobs_assigned", width: 200 },
          // { field: "available_earnings", width: 200 },
          {
            field: "industry",
            headerName: "Industry",
            filterOperators: IndustryOperators,
            width: 400,
          },
          {
            field: "categories",
            headerName: "Categories",
            width: 400,
            renderCell: paramss => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {paramss.value && paramss.value !== "N/A" ? (
                  <>
                    {/* <span>
                      {paramss.value.split(",").slice(0, 2).join(",")}
                    </span>
                    {paramss.value.split(",").length > 1 && (
                      <InfoOutlinedIcon
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          opacity: "50%",
                        }}
                      />
                    )} */}
                    {paramss.value
                      .split(",")
                      .slice(0, 2)
                      ?.map(each => (
                        <SmallSizeChip
                          label={each}
                          style={{ margin: "1px" }}
                          size="small"
                        />
                      ))}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },
          {
            field: "skills",
            headerName:"Skills",
            width: 400,
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                    {/* <span>{params.value.split(",").slice(0, 2).join(",")}</span>
                    {params.value.split(",").length > 1 && (
                      <InfoOutlinedIcon
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          opacity: "50%",
                        }}
                      />
                    )} */}
                    {params.value
                      .split(",")
                      .slice(0, 2)
                      ?.map(each => (
                        <SmallSizeChip
                          label={each}
                          style={{ margin: "1px" }}
                          size="small"
                        />
                      ))}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },
          ["ZA"].includes(role)
            ? {
                field: "companies_assigned",
                width: 400,
                headerName: "Companies Assigned",
                renderCell: params => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {params.value && params.value !== "N/A" ? (
                      <>
                        {/* <span>{params.value.split(",").slice(0, 2).join(",")}</span>
                    {params.value.split(",").length > 1 && (
                      <InfoOutlinedIcon
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          opacity: "50%",
                        }}
                      />
                    )} */}
                        {params.value
                          .split(",")
                          .slice(0, 2)
                          ?.map(each => (
                            <SmallSizeChip
                              label={each}
                              style={{ margin: "1px" }}
                              size="small"
                            />
                          ))}
                      </>
                    ) : (
                      <span>N/A</span>
                    )}
                  </div>
                ),
              }
            : "",
          !["/account-manager-list"].includes(locationCurrent.pathname)
            ? {
                field: "moderated_candidates",
                headerName: "Moderated Candidates",
                width: 200,
              }
            : "",

          !["/account-manager-list"].includes(locationCurrent.pathname)
            ? {
                field: "candidate_onboarded",
                headerName: "Onboarded Candidates",
                width: 200,
              }
            : "",
        ]}
        rows={accountManagers}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButtonForTable,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: props => (
            <Pagination
              {...props}
              // color="primary"
              // count={Math.ceil(rowsData.length / rowsPerPage)}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChange={(_, newPage) => setPage(newPage)}
              showFirstButton
              showLastButton
              sx={{
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#024430",
                    color: "#ffe7bb",
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                  },
                },
              }}
            />
          ),
        }}
      />
      {openSkillModal && (
        <SkillViewModal
          rowdata={selectedRowData}
          closeModalFunc={handleCloseModal}
          openModal={openSkillModal}
          headerName={modalName}
        />
      )}
      {viewAddModal && (
        <AdminStaffModel
          isOpen={viewAddModal}
          updated={() => setUpdate(pre => !pre)}
          toggle={() => setViewAddModal(false)}
        />
      )}
    </div>
  );
}

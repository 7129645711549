import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Typography, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { getAllNotificationsSettings,updateNotifyInAppSettings,updateIndividualNotifyInAppSettings } from "helpers/services/Notifications/NotificationApi";
import {
  NotificationDescTextTypography,
  NotificationDescTypography,
  NotificationTitleTypography,
  MiddleDivider,
  PrimarySwitch,
  SkillTypography,
  CommentTypography,
  PrimaryTypography,
  NotificationTypography,
} from "assets/Mui/globalTheme";
import Loading from "store/loading/reducer";
import {
  NotificationTags,
  GlobalStatusApp,
  LocalStatusApp,
  GlobalStatusEmail,
  LocalStatusEmail,
} from "store/Notifications/actions";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import { socket } from "Sockets/socket";

// const NotificationsList = [
//   {
//     id: 0,
//     name: "Account",
//     description: {
//       heading: "Account settings are updated",
//       statusDescription:
//         "You will receive a notification when you make a change in the administration such as enabling or disabling features",
//     },
//     applicationStatus: "true",
//     applicationStatus: "true",
//   },
//   {
//     id: 2,
//     name: "Users",
//     description: {
//       heading: "Account settings are updated",
//       statusDescription:
//         "You will receive a notification when you make a change in the administration such as enabling or disabling features",
//     },
//     applicationStatus: "true",
//     applicationStatus: "true",
//   },
//   {
//     id: 3,
//     name: "Organizations",
//     description: {
//       heading: "Account settings are updated",
//       statusDescription:
//         "You will receive a notification when you make a change in the administration such as enabling or disabling features",
//     },
//     applicationStatus: "true",
//     applicationStatus: "true",
//   },
//   {
//     id: 4,
//     name: "Jobs",
//     description: {
//       heading: "Account settings are updated",
//       statusDescription:
//         "You will receive a notification when you make a change in the administration such as enabling or disabling features",
//     },
//     applicationStatus: "true",
//     applicationStatus: "true",
//   },
//   {
//     id: 5,
//     name: "Jobs",
//     description: {
//       heading: "Account settings are updated",
//       statusDescription:
//         "You will receive a notification when you make a change in the administration such as enabling or disabling features",
//     },
//     applicationStatus: "true",
//     applicationStatus: "true",
//   },
//   {
//     id: 6,
//     name: "Jobs",
//     description: {
//       heading: "Account settings are updated",
//       statusDescription:
//         "You will receive a notification when you make a change in the administration such as enabling or disabling features",
//     },
//     applicationStatus: "true",
//     applicationStatus: "true",
//   },
//   {
//     id: 7,
//     name: "Jobs",
//     description: {
//       heading: "Account settings are updated",
//       statusDescription:
//         "You will receive a notification when you make a change in the administration such as enabling or disabling features",
//     },
//     applicationStatus: "true",
//     applicationStatus: "true",
//   },
// ];

const NotificationsMockData = [
  {
    id: "a4456a62-0dd2-4af5-9852-cc3be90fd804",
    title: "chats",
    short_desc: "chats",
    long_desc: "chats",
    is_app_notification: true,
    is_email_notification: false,
    createdAt: "2024-01-02T10:53:17.581Z",
    updatedAt: "2024-01-02T12:26:51.296Z",
    is_active: true,
    created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d",
  },
  {
    id: "1ad09bc1-45b7-4cfd-814f-ba88110df592",
    title: "Application Tracking",
    short_desc: "Application Tracking",
    long_desc: "Application Tracking",
    is_app_notification: true,
    is_email_notification: false,
    createdAt: "2024-01-02T10:53:17.581Z",
    updatedAt: "2024-01-02T12:26:51.297Z",
    is_active: true,
    created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d",
  },
  {
    id: "8eaaa574-a6cc-452e-b39d-79595f4f1a76",
    title: "Interview scheduling",
    short_desc: "Interview scheduling",
    long_desc: "Interview scheduling",
    is_app_notification: true,
    is_email_notification: false,
    createdAt: "2024-01-02T10:53:17.581Z",
    updatedAt: "2024-01-02T12:26:51.297Z",
    is_active: true,
    created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d",
  },
  {
    id: "8b962afd-627b-4821-bb1e-8bcf5945ac1e",
    title: "Job post expiry/deadline",
    short_desc: "Job post expiry/deadline",
    long_desc: "Job post expiry/deadline",
    is_app_notification: true,
    is_email_notification: false,
    createdAt: "2024-01-02T10:53:17.581Z",
    updatedAt: "2024-01-02T12:26:51.298Z",
    is_active: true,
    created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d",
  },
  {
    id: "a16018d3-29ae-4143-a04b-16035025fc19",
    title: "Interview Reminder",
    short_desc: "Interview Reminder",
    long_desc: "Interview Reminder",
    is_app_notification: true,
    is_email_notification: false,
    createdAt: "2024-01-02T10:53:17.581Z",
    updatedAt: "2024-01-02T12:26:51.298Z",
    is_active: true,
    created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d",
  },
  {
    id: "5d6d274a-9702-4ac4-8a79-9a6ea2f4f1e6",
    title: "Profile updation after few weeks/months",
    short_desc: "Profile updation after few weeks/months",
    long_desc: "Profile updation after few weeks/months",
    is_app_notification: true,
    is_email_notification: false,
    createdAt: "2024-01-02T10:53:17.581Z",
    updatedAt: "2024-01-02T12:26:51.299Z",
    is_active: true,
    created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d",
  },
  {
    id: "15a67e69-e9cd-415d-a4fe-f8e3f99f9283",
    title: "Interview feedback",
    short_desc: "Interview feedback",
    long_desc: "Interview feedback",
    is_app_notification: true,
    is_email_notification: false,
    createdAt: "2024-01-02T10:53:17.581Z",
    updatedAt: "2024-01-02T12:26:51.299Z",
    is_active: true,
    created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d",
  },
  {
    id: "3faa8857-c87f-4379-a5e4-bf5f98b7cdc9",
    title: "Matching Job Alerts",
    short_desc: "Matching Job Alerts",
    long_desc: "Matching Job Alerts",
    is_app_notification: true,
    is_email_notification: false,
    createdAt: "2024-01-02T10:53:17.581Z",
    updatedAt: "2024-01-02T12:33:59.675Z",
    is_active: true,
    created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d",
  },
];

function FormRow() {
  return (
    <React.Fragment>
      <Grid item xs={3}>
        {/* <Item>Item</Item> */}
      </Grid>
      <Grid item xs={5}>
        <NotificationTypography varient="h3">
          Notification Settings
        </NotificationTypography>
      </Grid>
      <Grid item xs={2}>
        <NotificationTypography>
          In Application
          <PrimarySwitch />
        </NotificationTypography>
      </Grid>
      <Grid item xs={2}>
        <NotificationTypography>
          {" "}
          Email
          <PrimarySwitch />
        </NotificationTypography>
      </Grid>
      <MiddleDivider varient="middle" style={{}} />
    </React.Fragment>
  );
}

function NotificationSettings() {
 

  const [showAll, setShowAll] = useState(true);
  const [showEmailAll, setShowEmailAll] = useState(true);
  const userId = JSON.parse(localStorage.getItem("authUser")).id;


  //    const [NotificationsList, setNotificationsList] = useState([
  //     {
  //         id: "a4456a62-0dd2-4af5-9852-cc3be90fd804",
  //         title: "chats",
  //         short_desc: "chats",
  //         long_desc: "chats",
  //         is_app_notification: true,
  //         is_email_notification: false,
  //         createdAt: "2024-01-02T10:53:17.581Z",
  //         updatedAt: "2024-01-02T12:26:51.296Z",
  //         is_active: true,
  //         created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d"
  //     },
  //     {
  //         id: "1ad09bc1-45b7-4cfd-814f-ba88110df592",
  //         title: "Application Tracking",
  //         short_desc: "Application Tracking",
  //         long_desc: "Application Tracking",
  //         is_app_notification: true,
  //         is_email_notification: false,
  //         createdAt: "2024-01-02T10:53:17.581Z",
  //         updatedAt: "2024-01-02T12:26:51.297Z",
  //         is_active: true,
  //         created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d"
  //     },
  //     {
  //         id: "8eaaa574-a6cc-452e-b39d-79595f4f1a76",
  //         title: "Interview scheduling",
  //         short_desc: "Interview scheduling",
  //         long_desc: "Interview scheduling",
  //         is_app_notification: true,
  //         is_email_notification: false,
  //         createdAt: "2024-01-02T10:53:17.581Z",
  //         updatedAt: "2024-01-02T12:26:51.297Z",
  //         is_active: true,
  //         created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d"
  //     },
  //     {
  //         id: "8b962afd-627b-4821-bb1e-8bcf5945ac1e",
  //         title: "Job post expiry/deadline",
  //         short_desc: "Job post expiry/deadline",
  //         long_desc: "Job post expiry/deadline",
  //         is_app_notification: true,
  //         is_email_notification: false,
  //         createdAt: "2024-01-02T10:53:17.581Z",
  //         updatedAt: "2024-01-02T12:26:51.298Z",
  //         is_active: true,
  //         created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d"
  //     },
  //     {
  //         id: "a16018d3-29ae-4143-a04b-16035025fc19",
  //         title: "Interview Reminder",
  //         short_desc: "Interview Reminder",
  //         long_desc: "Interview Reminder",
  //         is_app_notification: true,
  //         is_email_notification: false,
  //         createdAt: "2024-01-02T10:53:17.581Z",
  //         updatedAt: "2024-01-02T12:26:51.298Z",
  //         is_active: true,
  //         created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d"
  //     },
  //     {
  //         id: "5d6d274a-9702-4ac4-8a79-9a6ea2f4f1e6",
  //         title: "Profile updation after few weeks/months",
  //         short_desc: "Profile updation after few weeks/months",
  //         long_desc: "Profile updation after few weeks/months",
  //         is_app_notification: true,
  //         is_email_notification: false,
  //         createdAt: "2024-01-02T10:53:17.581Z",
  //         updatedAt: "2024-01-02T12:26:51.299Z",
  //         is_active: true,
  //         created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d"
  //     },
  //     {
  //         id: "15a67e69-e9cd-415d-a4fe-f8e3f99f9283",
  //         title: "Interview feedback",
  //         short_desc: "Interview feedback",
  //         long_desc: "Interview feedback",
  //         is_app_notification: true,
  //         is_email_notification: false,
  //         createdAt: "2024-01-02T10:53:17.581Z",
  //         updatedAt: "2024-01-02T12:26:51.299Z",
  //         is_active: true,
  //         created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d"
  //     },
  //     {
  //         id: "3faa8857-c87f-4379-a5e4-bf5f98b7cdc9",
  //         title: "Matching Job Alerts",
  //         short_desc: "Matching Job Alerts",
  //         long_desc: "Matching Job Alerts",
  //         is_app_notification: true,
  //         is_email_notification: false,
  //         createdAt: "2024-01-02T10:53:17.581Z",
  //         updatedAt: "2024-01-02T12:33:59.675Z",
  //         is_active: true,
  //         created_by_id: "bb1d9c56-7767-423f-bfcb-21ce8f683e1d"
  //     }
  // ]);

  const [NotificationsList, setNotificationsList] = useState([
    {
      id: 0,
      type: "email",
      name: "Matching Job Alerts",
      description: {
        heading:
          "Receive curated job recommendations based on your qualifications and interests.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 2,
      name: "Application Tracking",
      type: "email",
      description: {
        heading:
          "Easily track the progress of your job applications in real-time, all in one place.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 3,
      type: "email",
      name: "Interview Reminder",
      description: {
        heading:
          "Be the first to know about new job openings perfectly matching your skills with personalized email alerts.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 4,
      name: "Job post expiry/deadline",
      type: "email",
      description: {
        heading:
          "Stay ahead of your interview game with real-time notifications for upcoming scheduled interviews.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 5,
      name: "Profile updation after few weeks/months",
      type: "email",
      description: {
        heading:
          "Stay ahead of your interview game with real-time notifications for upcoming scheduled interviews.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 6,
      name: "Interview feedback",
      type: "email",
      description: {
        heading:
          "Stay ahead of your interview game with real-time notifications for upcoming scheduled interviews.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 7,
      name: "chats",
      type: "email",
      description: {
        heading:
          "Stay ahead of your interview game with real-time notifications for upcoming scheduled interviews.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 8,
      name: "Stage of the stage ",
      type: "email",
      description: {
        heading:
          "Stay ahead of your interview game with real-time notifications for upcoming scheduled interviews.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
  ]);

  const [notificationsData, setNotificationsData] = useState([])

  /*Global Check func start here for both normal & email */

  const updateNotifyInApp = async(status,type)=>{
    const payload = {
      // type:"in_app",
      type:type,
      is_app_notification: status
    }
    const response = await updateNotifyInAppSettings(payload,userId)
  
    if(response){
    
    }
  }

  const updateIndividualNotifyInApp = async(id)=>{

    const result = notificationsData?.filter(each => {
      if (each.id == id) {
        return {
          inApplication: !each.inApplication,
        };
      }
   
    });
    const inApplicationStatus = result[0]?.inApplication


    const payload = {
      is_app_notification: !inApplicationStatus,
      // is_email_notification: false
    }
    const updateInApp = await updateIndividualNotifyInAppSettings(payload,id)
  }

  const updateIndividualNotifyInEmail = async(id)=>{

    const result = notificationsData?.filter(each => {
      if (each.id == id) {
        return {
          inEmail: !each.inEmail,
          is_email_notification:!each?.is_email_notification
        };
      }
   
    });
    const inEmailStatus = result[0]?.is_email_notification


    const payload = {
      // is_app_notification: !inApplicationStatus,
      is_email_notification: !inEmailStatus
    }
    const updateInApp = await updateIndividualNotifyInAppSettings(payload,id)
  }


  const handleChangeSwitch = async(e) => {
    const type = "in_app"
    const result = notificationsData?.map(each => {
      return {
        ...each,
        inApplication: e.target.checked,
        isActive:e.target.checked,
        is_app_notification:e.target.checked
      };
    });
    // Do something with the updated result
    setNotificationsData(result);
    setShowAll(!showAll);
      const updateSelectAll = await updateNotifyInApp(e.target.checked,type)
  };

  const handleChangeEmailSwitch = async(e) => {
    const type = "email"
    const result = notificationsData?.map(each => {
      return {
        ...each,
        inEmail: e.target.checked,
        is_email_notification:e.target.checked
      };
    });
    // Do something with the updated result
    setNotificationsData(result);
    setShowEmailAll(!showEmailAll);
    const updateSelectAll = await updateNotifyInApp(e.target.checked,type)
  };

  /*Global Check func start here for both normal & email */

  /* Individual checks of both normal & email func start here */
  const handleSwitchIterate = async row => {
 
    const result = notificationsData?.map(each => {
      if (each.id == row.id) {
        return {
          ...each,
          inApplication: !each.inApplication,
          isActive:!each.isActive,
          is_app_notification:!each?.is_app_notification,
        };
      }
      return each;
    });
    setNotificationsData(result);

    const updateSelectAll = await updateIndividualNotifyInApp(row?.id)
  };

  const handleEmailNotify = async row => {
    const result = notificationsData?.map(each => {
      if (each.type == row.type && each.id == row.id) {
        return {
          ...each,
          inEmail: !each?.inEmail,
          is_email_notification:!each?.is_email_notification
        };
      }
      return each;
    });
    setNotificationsData(result);
    const updateSelectAll = await updateIndividualNotifyInEmail(row?.id)
  };

  /* Individual checks of both normal & email func End here */




   /* Notifications integration start */

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    fetchNotificationSettings()
  },[])

  const fetchNotificationSettings = async()=>{
    const response= await getAllNotificationsSettings()
    const formatData = response?.data_payload?.map((each)=>{
      return {
        
          id: each?.id,
          type: "email",
          name: each?.title,
          description: {
            heading:
              each?.short_desc,
            statusDescription:
              each?.long_desc,
          },
          inApplication: each?.is_app_notification,
          inEmail: each?.is_email_notification,
          isActive:each?.is_active,
          is_app_notification:each?.is_app_notification,
          is_email_notification:each?.is_email_notification
        }
      
    })
    
    if(formatData?.length > 0){
      setNotificationsData(formatData)
    }
   
    // if(status == 200){
    //   console.log("sdssd")
    //   // dispatch(NotificationTags(data))
    //   setNotificationsData(formatData)
    //   setLoading(false)
    // }
  }

  /* Notifications integration start */




  useEffect(() => {
    let emailCount = 0;
    let appCount = 0;
    if (notificationsData) {
      notificationsData.forEach(each => {
        if (each?.inEmail) {
          emailCount += 1;
        }
        if (each?.inApplication) {
          appCount += 1;
        }
      });
      if (emailCount === notificationsData.length) {
        setShowEmailAll(true);
      } else {
        setShowEmailAll(false);
      }
      if (appCount === notificationsData.length) {
        setShowAll(true);
      } else {
        setShowAll(false);
      }
    }
  }, [notificationsData]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="start" sx={{ fontFamily: "Inter,sans-serif", fontWeight: "700" }}>Title</TableCell>
              <TableCell align="start" sx={{ fontFamily: "Inter,sans-serif", fontWeight: "700" }}>Descriptions</TableCell>
              <TableCell align="start" sx={{ fontFamily: "Inter,sans-serif", fontWeight: "700", minWidth: "150px" }}>
                <Typography sx={{ fontWeight: "500" }}> In Application </Typography>
                <Tooltip title="Select All" placement="bottom" arrow>
                  <PrimarySwitch
                    onChange={e => handleChangeSwitch(e)}
                    checked={showAll ? showAll : false}
                  />
                </Tooltip>
              </TableCell>
              <TableCell align="start" sx={{ fontFamily: "Inter,sans-serif", minWidth: "150px" }}>
                <Typography sx={{ fontWeight: "500" }}> In Email </Typography>
                <Tooltip title="Select All" placement="bottom" arrow>
                  <PrimarySwitch
                    onChange={e => handleChangeEmailSwitch(e)}
                    checked={showEmailAll ? showEmailAll : false}
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationsData?.map(row => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <NotificationTitleTypography>
                    {row.name}
                  </NotificationTitleTypography>
                </TableCell>
                <TableCell align="start">
                  <Grid>
                    <NotificationDescTypography>
                      {row.description.heading}
                    </NotificationDescTypography>
                    <NotificationDescTextTypography>
                      {row.description.statusDescription}
                    </NotificationDescTextTypography>
                  </Grid>
                </TableCell>

                <TableCell align="start">
                  <PrimarySwitch
                    //  checked={
                    //   showAll
                    //     ? showAll
                    //     : row.inApplication
                    //     ? row.inApplication
                    //     : row.inApplication
                    // }

                    // checked={row.inApplication}
                    checked={row.is_app_notification}
                    onChange={e => handleSwitchIterate(row)}
                  />
                </TableCell>
                <TableCell align="start">
                  <PrimarySwitch
                    onChange={e => handleEmailNotify(row)}
                    // checked={
                    //   showEmailAll
                    //     ? showEmailAll
                    //     : row.inEmail
                    //     ? row.inEmail
                    //     : row.inEmail
                    // }
                    checked={row.inEmail}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


    </>
  );
}

export default NotificationSettings;

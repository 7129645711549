import callApi from "helpers/callApi";

async function companyId() {
  return await JSON.parse(localStorage.getItem("authUser"))?.company_id;
}

export const getAllBackgroundUsersList = async (role,page,rowsPerPage) => {
  if(["ZA"].includes(role)){
    // return await callApi(`referral_partner_authorise/bgvs_list?offset={offset}&&limit={page}`);
    return await callApi(`referral_partner_authorise/bgvs_list?pageNo=${page}&&pageSize=${rowsPerPage}`);
  }
    return await callApi(`referral_partner_authorise/bgvs_list?company_id=${await companyId()}&&pageNo=${page}&&pageSize=${rowsPerPage}`);
  };
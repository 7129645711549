import { POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";
async function companyUserID() {
  return await JSON.parse(localStorage.getItem("authUser"))?.company_id;
}
export const creaetBackgroundVerifications = async body => {
  const reqBody = {
    email: body.email,
    first_name: body.first_name,
    last_name: body.last_name,
    phone_number: body.phone_number,
    user_password: body.password,
    usertype: body.userType,
    company_name: body.company_name,
  };
  return await callApi("/bgv_authorise/register", reqBody, POST);
};
export const createBGV = async body => {
  const reqBody = {
    ...body,
    email: body.email,
    first_name: body.first_name,
    last_name: body.last_name,
    phone_number: body.phone_number,
    user_password: body.user_password,
    usertype: body.userType,
    company_name: body.company_name,
  };
  delete reqBody.userType
  return await callApi("/bgv_authorise/register", reqBody, POST);
};
export const createBackgroundVerificationCompanyStaff = async ({
  email,
  first_name,
  last_name,
  user_password,
  usertype,
}) => {
  return await callApi(
    "bgv_company_staff/register",
    {
      email,
      first_name,
      last_name,
      user_password,
      usertype: "BACKGROUND_VERIFICATION_STAFF",
      company_id: await companyUserID(),
      is_active: true,
    },
    POST
  );
};
export const getAllBackgroundVerificationCompanyStaffList = async () => {
  return await callApi(
    `bgv_authorise/get_bgv_staff_list?company_id=${await companyUserID()}`
  );
};

export const updateBackgroundVerificationCompanyStaff = async (data, id) => {
  return await callApi(
    `bgv_company_staff/${id}`,
    { ...data, company_id: await companyUserID() },
    PUT
  );
};

export const getSingleBackgroundVerificationStaffDetails = async id => {
  return await callApi(
    `bgv_company_staff/${id}?company_id=${await companyUserID()}`
  );
};

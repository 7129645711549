import { DELETE, POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";
import { toast } from "react-hot-toast";

export async function getAllFunctionalArea() {
  const data = await callApi("job_attributes/functional_area");
  const items = data.data.map(item => {
    return {
      id: item.id,
      name: item.name,
      default: true,
      country: item.country,
      is_active: item.is_active,
    };
  });
  return items;
}

export async function deleteFunctionalArea(id) {
  if (id === undefined) return;

  const data = await callApi(
    `job_attributes/functional_area/${id}`,
    "",
    DELETE
  );
  return;
}
export async function createAndEditFunctionalArea(
  incomingData,
  type,
  index,
  id = ""
) {
  if (type === "create") {
    const changeData = {
      name: incomingData?.name,
      is_active: incomingData?.active,
      country: "INDIA",
    };

    const data = await callApi(
      "job_attributes/functional_area",
      changeData,
      POST
    );
    this.setState({
      data: [...this.state?.data, incomingData],
    });
  } else {
    const changeData = {
      name: incomingData.name,
      is_active: incomingData?.active,
      country: "INDIA",
    };
  
    const data = await callApi(
      `job_attributes/functional_area/${id}`,
      changeData,
      PUT
    );
    let result = this.state.data;
    // result[index] = { ...changeData, id };

    const filterOne = result?.map((each)=>{
      if(each.id == incomingData.id){
        return {
          ...each,
          name:incomingData?.name,
          is_active:incomingData?.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

    this.setState({
      data: filterOne,
    });
  }
}
export async function getSingleFunctionalArea(id) {
  if (id === undefined) return;

  const data = await callApi(`job_attributes/functional_area/${id}`);
  return data.data;
}

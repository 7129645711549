const { Row, Container, Col, CardBody, Card } = require("reactstrap");
import { updateSingleAdminStaffDetails } from "helpers/services/adminStaff/adminStaff";
import {
  FormControlLabel,
  FormGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  createIndustries,
  deleteIndustries,
  getAllIndustries,
  updateIndustries,
} from "helpers/services/jobAttributes/industries";
import React,{useState,useEffect} from "react";
import { toast } from "react-hot-toast";
import CommonModel from "../../components/Common/models/createModel";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import {
  createActualJobLevels,
  getAllActualJobLevel,
  updateActualJobLevel,
} from "helpers/services/jobAttributes/actualJobLevel";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import {
  StageSearchInput,
  GreyTypography,
} from "assets/Mui/globalTheme";
import CustomPagination from "components/Pagination/globalPagination";

class JobLevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      name: "",
      id:"",
      data: [],
      isLoading: "true",
      openSearch:true,
      searchRole:""
    };
    this.toggle = this.toggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
    this.createActualJobLevels = createActualJobLevels.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handlRestore = this.handlRestore.bind(this);
  }
  async componentDidMount() {
    this.setState({ isLoading: true });
    const { data } = await getAllActualJobLevel();
    this.setState({ data, isLoading: false });
  }

  toggle(purpose = "", name = "", active = "", id = "", index) {
    this.setState({
      purpose: purpose,
      name: name,
      index,
      id,
      active: active,
      isOpen: !this.state.isOpen,
    });
  }
  handleDelete(index, id, name) {
    updateActualJobLevel({ is_active: false, name: name }, id);
    let result = this.state.data;
    // result[index].is_active = !result[index].is_active;

    const filterOne = result?.map((each)=>{
      if(each.id == id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

    this.setState({
      data: filterOne,
    });
    toast.error("Disabled");
  }
  handlRestore(item, index) {
    const sample = {
      name: item.name,
      is_active: !item.is_active,
    };
    updateActualJobLevel(sample, item.id);
    let result = this.state.data;
    // result[index].is_active = !result[index].is_active;

    const filterOne = result?.map((each)=>{
      if(each.id == item.id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

    this.setState({
      data: filterOne,
    });
    toast.success("Enabled");
  }
  handleAction(item, index) {
    item.is_active
      ? this.handleDelete(index, item.id, item.name)
      : this.handlRestore(item, index);
  }
  async updateFunc(sample, type, index,id) {
    let editedId;
    if (id) {
      editedId = id;
    } else {
      editedId = this.state.id;
    }

    if (type === "Create") {
      await this.createActualJobLevels(sample);
      this.setState({
        data: [...this.state?.data, sample],
      });
      toast.success("Created")
      const { data } = await getAllActualJobLevel();
      this.setState({ data });
    } else {
      // await updateActualJobLevel(sample, this.state.id);
      const changeData = {
        name: sample?.name,
        is_active: sample?.is_Active,
      }
      await updateActualJobLevel(changeData, editedId);
      let result = this.state.data;
      // result[index] = sample;

      const filterOne = result?.map((each)=>{
        if(each.id == editedId){
          return {
            ...each,
            name:sample.name,
            is_active:sample.is_active
          }
        }else {
          return {
            ...each
          }
        }
       })

      this.setState({
        data: filterOne,
      });
      const { data } = await getAllActualJobLevel();
      this.setState({ data });
    }
  }

   /* Search toggle effect */
   handleSearchOpen = () => {
    this.setState((prevState) => ({
      openSearch: !prevState.openSearch,
      searchRole:""
    }));
  }

  handleSearch=(e)=>{
    this.setState((prevState)=>({
      searchRole:e.target.value
    }))
  }

  searchFilterData = (data)=>{
    const searchWord = this.state?.searchRole?.toLowerCase()
    const searchPattern = data?.filter((item)=>{
      const roleName = item?.name?.toLowerCase()
    
      if(roleName?.includes(searchWord)){
        return item
      }
    })
    return searchPattern
  }
  
  /* Search toggle effect end */

  render() {
    const filteredData = this.searchFilterData(this.state?.data)
    return (
      <>
        <CommonModel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          headTag={"Job Levels"}
          purpose={this.state.purpose}
          index={this.state.index}
          title={this.state.name}
          active={this.state.active}
          update={this.updateFunc}
          id={this.state.id}
        />
        <div md={6} style={{ padding: "40px" }} className="header_jobs_sticky">
          <PrimaryButton
            sx={{ width: "200px" }}
            onClick={() => this.toggle("Create")}
          >
            Create Job Levels
          </PrimaryButton>
          <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
                  {this.state.openSearch ? (
                    <SearchIcon
                      onClick={this.handleSearchOpen}
                    
                    />
                  ) : (
                    <StageSearchInput
                      placeholder="search..."
                      onChange={(e)=>this.handleSearch(e)}
                      endAdornment={
                        <IconButton edge="end" onClick={this.handleSearchOpen}>
                          <ClearIcon />
                        </IconButton>
                      }
                    />
                  )}
                
                </Stack>
        </div>
        <Row style={{ marginLeft: "20px" }}>
          <div className="table_cont">
            <div className="table-responsive">
              <Card style={{ maxWidth: "80vw" }}>
                <CardBody>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Job Levels</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {this.state.isLoading ? (
                        <BubbleLoader />
                      ) : (
                        // this.state.data.map((item, index) => (
                          filteredData?.map((item, index) => (
                          <TableRow key={item.name + index}>
                            <TableCell>{item.name}</TableCell>

                            <TableCell className="active_td">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <PrimarySwitch
                                      sx={{ m: 1 }}
                                      checked={item.is_active}
                                      onChange={async e => {
                                        this.handleAction(item, index);
                                      }}
                                    />
                                  }
                                />
                              </FormGroup>
                            </TableCell>
                            <TableCell className="col_btn">
                              <PrimaryButton
                                sx={{ width: "100px" }}
                                onClick={() =>
                                  this.toggle(
                                    "Edit",
                                    item.name,
                                    item.active,
                                    item.id,
                                    index
                                  )
                                }
                              >
                                Edit
                              </PrimaryButton>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </>
    );
  }
}

export default JobLevel;


// import React, { useState, useEffect } from 'react';
// import {
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   FormGroup,
//   FormControlLabel,
//   Card,
//   CardBody,
//   Stack,
//   IconButton,
//   TextField,
// } from '@mui/material';
// import ClearIcon from '@mui/icons-material/Clear';
// import SearchIcon from '@mui/icons-material/Search';
// import { BubbleLoader, PrimaryButton, PrimarySwitch } from './YourComponentImports'; // Replace with your actual import paths
// import { getAllActualJobLevel, updateActualJobLevel, createActualJobLevels } from './YourApiFunctions'; // Replace with your actual API functions

// const JobLevel = () => {
//   const [state, setState] = useState({
//     isOpen: false,
//     active: false,
//     index: -1,
//     default: false,
//     name: '',
//     id: '',
//     data: [],
//     isLoading: true,
//     openSearch: true,
//     searchRole: '',
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       setState((prevState) => ({ ...prevState, isLoading: true }));
//       const { data } = await getAllActualJobLevel();
//       setState((prevState) => ({ ...prevState, data, isLoading: false }));
//     };

//     fetchData();
//   }, []);

//   const toggle = (purpose = '', name = '', active = '', id = '', index) => {
//     setState((prevState) => ({
//       ...prevState,
//       purpose,
//       name,
//       index,
//       id,
//       active,
//       isOpen: !prevState.isOpen,
//     }));
//   };

//   const handleDelete = (index, id, name) => {
//     updateActualJobLevel({ is_active: false, name }, id);
//     const result = state.data.map((each) =>
//       each.id === id ? { ...each, is_active: !each.is_active } : { ...each }
//     );

//     setState((prevState) => ({ ...prevState, data: result }));
//     // Other logic...
//   };

//   const handlRestore = (item, index) => {
//     const sample = {
//       name: item.name,
//       is_active: !item.is_active,
//     };
//     updateActualJobLevel(sample, item.id);

//     const result = state.data.map((each) =>
//       each.id === item.id ? { ...each, is_active: !each.is_active } : { ...each }
//     );

//     setState((prevState) => ({ ...prevState, data: result }));
//     // Other logic...
//   };

//   const handleAction = (item, index) => {
//     item.is_active ? handleDelete(index, item.id, item.name) : handlRestore(item, index);
//   };

//   const updateFunc = async (sample, type, index, id) => {
//     let editedId;
//     if (id) {
//       editedId = id;
//     } else {
//       editedId = state.id;
//     }

//     if (type === 'Create') {
//       await createActualJobLevels(sample);
//       setState((prevState) => ({ ...prevState, data: [...state?.data, sample] }));
//       toast.success('Created');
//       const { data } = await getAllActualJobLevel();
//       setState((prevState) => ({ ...prevState, data }));
//     } else {
//       const changeData = {
//         name: sample?.name,
//         is_active: sample?.is_Active,
//       };
//       await updateActualJobLevel(changeData, editedId);
//       let result = state.data;
//       const filterOne = result?.map((each) =>
//         each.id === editedId
//           ? { ...each, name: sample.name, is_active: sample.is_active }
//           : { ...each }
//       );

//       setState((prevState) => ({ ...prevState, data: filterOne }));
//       const { data } = await getAllActualJobLevel();
//       setState((prevState) => ({ ...prevState, data }));
//     }
//   };

//   const handleSearchOpen = () => {
//     setState((prevState) => ({
//       ...prevState,
//       openSearch: !prevState.openSearch,
//       searchRole: '',
//     }));
//   };

//   const handleSearch = (e) => {
//     setState((prevState) => ({ ...prevState, searchRole: e.target.value }));
//   };

//   const searchFilterData = (data) => {
//     const searchWord = state?.searchRole?.toLowerCase();
//     return data?.filter((item) => item?.name?.toLowerCase().includes(searchWord));
//   };

//   const filteredData = searchFilterData(state?.data);

//   return (
//     <>
//       <CommonModel
//         isOpen={state.isOpen}
//         toggle={toggle}
//         headTag={'Job Levels'}
//         purpose={state.purpose}
//         index={state.index}
//         title={state.name}
//         active={state.active}
//         update={updateFunc}
//         id={state.id}
//       />
//       <div md={6} style={{ padding: '40px' }} className="header_jobs_sticky">
//         <PrimaryButton sx={{ width: '200px' }} onClick={() => toggle('Create')}>
//           Create Job Levels
//         </PrimaryButton>
//         <Stack alignItems={'center'} direction={'row'} spacing={1.6}>
//           {state.openSearch ? (
//             <SearchIcon onClick={handleSearchOpen} />
//           ) : (
//             <TextField
//               placeholder="search..."
//               onChange={(e) => handleSearch(e)}
//               endAdornment={
//                 <IconButton edge="end" onClick={handleSearchOpen}>
//                   <ClearIcon />
//                 </IconButton>
//               }
//             />
//           )}
//         </Stack>
//       </div>
//       <Row style={{ marginLeft: '20px' }}>
//         <div className="table_cont">
//           <div className="table-responsive">
//             <Card style={{ maxWidth: '80vw' }}>
//               <CardBody style={{ height: 'calc(100vh - 220px)', overflowY: 'scroll' }}>
//                 <Table className="table mb-0">
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>Job Levels</TableCell>
//                       <TableCell>Status</TableCell>
//                       <TableCell>Action</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <tbody>
//                     {state.isLoading ? (
//                       <BubbleLoader />
//                     ) : (
//                       filteredData?.map((item, index) => (
//                         <TableRow key={item.name + index}>
//                           <TableCell>{item.name}</TableCell>

//                           <TableCell className="active_td">
//                             <FormGroup>
//                               <FormControlLabel
//                                 control={
//                                   <PrimarySwitch
//                                     sx={{ m: 1 }}
//                                     checked={item.is_active}
//                                     onChange={async (e) => {
//                                       handleAction(item, index);
//                                     }}
//                                   />
//                                 }
//                               />
//                             </FormGroup>
//                           </TableCell>
//                           <TableCell className="col_btn">
//                             <PrimaryButton
//                               sx={{ width: '100px' }}
//                               onClick={() => toggle('Edit', item.name, item.active, item.id, index)}
//                             >
//                               Edit
//                             </PrimaryButton>
//                           </TableCell>
//                         </TableRow>
//                       ))
//                     )}
//                   </tbody>
//                 </Table>
//               </CardBody>
//             </Card>
//           </div>
//         </div>
//         <CustomPagination />
//       </Row>
//     </>
//   );
// };

// export default JobLevel;


import {
    Avatar,
    Box,
    Button,
    IconButton,
    Modal,
    Stack,
    Typography,
  } from "@mui/material";
  import { useHistory } from "react-router-dom";
  import React, { useEffect } from "react";
  import { grey } from "@mui/material/colors";
  import SearchParamHook from "hooks/searchParams";
  import { PrimaryTypography, Styledh2Typography,DownloadMenuButton } from "assets/Mui/globalTheme";
  import { updateFromPipeline,updateFromPipelineModel } from "helpers/services/kanban/getKanbanDetails";
  import { toast } from "react-hot-toast";
  export default function MoveCandidateModel({ open,toggleModel,stagesInfo,userJobPostId,currentStage ,currentStageId}) {
    const style = {
      position: "absolute",
      top: "50%",
      borderRadius: "8px",
      pading: "20px",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
      p: 4,
    };
    const history = useHistory();
    const searchParams = SearchParamHook();
    const [stagesInformation,setStagesInformation] = React.useState([])
    useEffect(()=>{
        setStagesInformation(stagesInfo)
    },[])

    const moveStage = async()=>{

           const nextStageId =  stagesInformation[0].id

          const payloadData = {
            stage_id: nextStageId,
            old_stage_id:currentStageId
          }
          const { status, data } = await updateFromPipelineModel(
            userJobPostId,
            payloadData
          );
          if ([200, 201].includes(status)) {
            toast.success(data.message);
            // searchParams.set("stage_order", item?.stage_order);
            // history.replace({ search: searchParams.toString() });
            toggleModel()
          } else {
            toast.error("oops something went wrong!!");
            toggleModel()
          }
    }



    return (
      <Modal
        //   hideBackdrop={true}
        open={open}
        onClose={()=>toggleModel()}
        sx={{
          pading: "20px",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PrimaryTypography variant="h2">Move to next stage</PrimaryTypography>
          <Typography variant="body1" sx={{ my: 2, color: "#066a4c",}}>
            Are you sure you want to move the profile to next stage?
            <br />
          </Typography>
          <Stack
            alignItems={"center"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Button
              onClick={()=>toggleModel()}
              variant="text"
              sx={{
                backgroundColor: "#ffe7bb",
                textTransform: "capitalize",
              }}
            >
            No
            </Button>
            <Button
              variant="solid"
             purpose="delete"
              sx={{backgroundColor: "#024430",color:"#ffe7bb"}}
              onClick={()=>moveStage()}
            //   onClick={() => {
            //     history.push("/logout");
            //   }}
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    );
  }
  
import React, { useState } from "react";
import {
  CircularProgressbar,
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { toast } from "react-hot-toast";
import {
  ScoreCardBox,
  ScoreCardHeader,
  BlackTypography,
  DownloadMenuButton,
  StyledCard,
  StyledEvalCard,
  MiddleDivider,
  PrimaryTypography,
  RatingButton,
  GreyTypography,
  GreenTypography,
} from "assets/Mui/globalTheme";
import '../../../../assets/css/index.css';
import {
  Stack,
  Grid,
  InputLabel,
  Select,
  Box,
  CircularProgress,
} from "@mui/material";
import PdfScoreCard from "./pdfScoreCard";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { useRef } from "react";

const percentage = 66;

function Example(props) {
  return (
    <div style={{ marginBottom: 80, }}>
      <div style={{ marginTop: 10, display: "flex" }}>
        <div
          style={{
            width: "50%",
            height: "50%",
            paddingRight: 10,
            paddingLeft: "12px",
            wordWrap: "break-word",
          }}
        >
          {props.children}
        </div>
        <div style={{ width: "50%" }}>
          <GreenTypography
            varient="h3"
            sx={{
              color: "#066A4C",
              fontSize: "16px",
              fontWeight: "500",
              wordWrap: "break-word",
            }}
          >
            {props.label}
          </GreenTypography>
          <GreyTypography
            sx={{
              wordWrap: "break-word",
              color: "#000000",
              fontSize: "13px",
              fontWeight: "400",
              textOverflow: "ellipsis",
            }}
            varient="h6"
          >
            {/* This type of candidate is likely to be successful in the role, and
            requires less training and onboarding time than other candidates. */}
            {props.desc}
          </GreyTypography>
        </div>
      </div>
    </div>
  );
}

export default function ProfileRatingGraph({
  scoreData,
  profileRating,
  screeningSkills,
  evaluatorSkills,
  moderatorSkills,
  screeningQues,
  evaluatorQues,
  moderatorQues,
  jobPostInfo,
}) {
  const { candidate } = useSelector(state => state.Candidates);
  const jobTitle =
    candidate?.user_job_post_details?.job_post_in_user_job_post?.job_title ||
    "";
  let finalData = scoreData;
  const screeningPartnerDetails = finalData?.screening_partner_evalutation;
  const technicalEvalDetails = finalData?.technical_evalutation;
  const moderatorDetails = finalData?.moderator_evalutation;
  const btnRef = useRef(null);

  let finalScore =
    (screeningPartnerDetails?.average_rating || 0) +
    (technicalEvalDetails?.average_rating || 0) +
    (moderatorDetails?.average_rating || 0);
  // const avg = Math.round(overAllScore / 3);
  const avg = finalScore / 3;
  const roundScore = avg > 1 ? avg.toFixed(1) : avg === 0 ? 0 : avg.toFixed(1);

  // Calculate the percentage value
  // const percentage = (3.5 / 5) * 100;

  // Format the text to display "3.5/5"
  // const formattedText = `${3.5}/${5}`;

  const ratingDetails = profileRating?.map(each => {
    if (each) {
      return {
        percentage: (each?.rating / 10) * 100,
        formattedText: `${each?.rating}/10`,
        status: each?.status,
        comment: each?.comment,
        userType: each?.evaluator_type,
      };
    }
  });


  return (
    <>
      <ScoreCardBox>
        <ScoreCardHeader>
          <Grid container alignItems="center">
            <Grid xs={8} lg={10} sx={{ padding: "8px" }}>
              <BlackTypography varient="h4">
                Overall Profile Rating
              </BlackTypography>
            </Grid>

            <Grid xs={8} lg={2} sx={{ padding: "8px" }}>
              <Stack
                direction={"row"}
                justifyContent={"end"}
                alignItems={"end"}
              >
                {/* <DownloadMenuButton>Download</DownloadMenuButton> */}

                <PDFDownloadLink
                  // className={classes.btn}
                  document={
                    <PdfScoreCard
                      // jobTitle={jobTitle}
                      score={ratingDetails}
                      screening={screeningSkills}
                      moderator={moderatorSkills}
                      evaluator={evaluatorSkills}
                      screeningQues={screeningQues}
                      moderatorQues={moderatorQues}
                      evaluatorQues={evaluatorQues}
                      jobPostInfo={jobPostInfo}
                      candidate={candidate}
                      jobTitle={jobTitle}
                    />
                  }
                  fileName="scorecard.pdf"
                >
                  {({ blob, url, loading, error }) => {
                    // console.log(blob, url, loading, error, "v");
                    if (error) {
                      toast.error("wrong");
                    }
                    return loading ? (
                      <CircularProgress />
                    ) : (
                      <DownloadMenuButton
                        ref={btnRef} //convertToCanvas
                        // onClick={()=>openPrintView()}
                        // onClick={handleGeneratePDF}
                        sx={{ width: "auto" }}
                        // className={classes.print}
                        startIcon={<FeatherIcon icon="file-text" size="14" />}
                      >
                        Download
                      </DownloadMenuButton>
                    );
                  }}
                </PDFDownloadLink>
              </Stack>
            </Grid>
          </Grid>
        </ScoreCardHeader>

        <Box sx={{ padding: "16px" }}>
          <Grid container spacing={1} sx={{ paddingTop: "12px" }}>
            <Grid lg={4}>
              <StyledEvalCard>
                <Example
                  label="Screening Evaluator"
                  desc={`${
                    ratingDetails?.[0] ? ratingDetails[0].comment : "No comment"
                  }`}
                >
                  <CircularProgressbarWithChildren
                    // value={percentage}
                    // text={`${formattedText}`}

                    value={`${
                      ratingDetails?.[0] ? ratingDetails[0]?.percentage : "0/10"
                    }`}
                    text={`${
                      ratingDetails?.[0]
                        ? ratingDetails[0]?.formattedText
                        : "0/10"
                    }`}
                    counterClockwise
                    className="custom-svg-height"
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0.25,

                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "rounded",

                      // Text size
                      textSize: "14px",

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',

                      // Colors
                      // pathColor: `#066A4C, ${percentage / 100})`,
                      textColor: "#066A4C",
                      pathColor: "#066A4C",
                      height: "50px !important",
                      // trailColor: "gold"
                    })}
                  >
                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                    <div
                      style={{
                        fontSize: "13px",
                        color: "green",
                        marginTop: "40%",
                        wordWrap: "break-word",
                        textAlign: "center",
                      }}
                    >
                      <BlackTypography
                        sx={{
                          width: "100%",
                          wordWrap: "break-word",
                          fontSize: "13px",
                        }}
                      >
                        {" "}
                        {ratingDetails?.[0]
                          ? ratingDetails[0].status
                          : "No comment"}
                      </BlackTypography>
                    </div>
                  </CircularProgressbarWithChildren>
                </Example>
              </StyledEvalCard>
            </Grid>

            <Grid lg={4}>
              <StyledEvalCard>
                <Example
                  label="Moderate Evaluator"
                  desc={`${
                    ratingDetails?.[1] ? ratingDetails[1].comment : "No comment"
                  }`}
                >
                  <CircularProgressbarWithChildren
                    // value={percentage}
                    // text={`${percentage}%`}
                    value={`${
                      ratingDetails?.[1] ? ratingDetails[1]?.percentage : "0/10"
                    }`}
                    text={`${
                      ratingDetails?.[1]
                        ? ratingDetails[1]?.formattedText
                        : "0/10"
                    }`}
                    counterClockwise
                    className="custom-svg-height"
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0.25,

                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "rounded",

                      // Text size
                      textSize: "14px",

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',

                      // Colors
                      // pathColor: `#066A4C, ${percentage / 100})`,
                      textColor: "#066A4C",
                      pathColor: "#066A4C",
                      height: "100px",
                      // trailColor: "gold"
                    })}
                  >
                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                    <div
                      style={{
                        fontSize: "13px",
                        color: "green",
                        marginTop: "40%",
                        wordWrap: "break-word",
                        textAlign: "center",
                      }}
                    >
                      <BlackTypography
                        sx={{
                          width: "100%",
                          wordWrap: "break-word",
                          fontSize: "13px",
                        }}
                      >
                        {ratingDetails?.[1]
                          ? ratingDetails[1].status
                          : "No comment"}
                      </BlackTypography>
                    </div>
                  </CircularProgressbarWithChildren>
                </Example>
              </StyledEvalCard>
            </Grid>

            <Grid lg={4}>
              <StyledEvalCard>
                <Example
                  label="Technical Evaluator"
                  desc={`${
                    ratingDetails?.[2] ? ratingDetails[2].comment : "No comment"
                  }`}
                >
                  <CircularProgressbarWithChildren
                    // value={percentage}
                    // text={`${percentage}%`}
                    value={`${
                      ratingDetails?.[2] ? ratingDetails[2]?.percentage : "0/10"
                    }`}
                    text={`${
                      ratingDetails?.[2]
                        ? ratingDetails[2]?.formattedText
                        : "0/10"
                    }`}
                    counterClockwise
                    className="custom-svg-height"
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0.25,

                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "rounded",

                      // Text size
                      textSize: "14px",

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',

                      // Colors
                      // pathColor: `#066A4C, ${percentage / 100})`,
                      textColor: "#066A4C",
                      pathColor: "#066A4C",
                      height: "100px",
                      // trailColor: "gold"
                    })}
                  >
                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                    <div
                      style={{
                        fontSize: "13px",
                        color: "green",
                        marginTop: "40%",
                        wordWrap: "break-word",
                        textAlign: "center",
                      }}
                    >
                      <BlackTypography
                        sx={{
                          width: "100%",
                          wordWrap: "break-word",
                          fontSize: "13px",
                        }}
                      >
                        {ratingDetails?.[2]
                          ? ratingDetails[2].status
                          : "No comment"}
                      </BlackTypography>
                    </div>
                  </CircularProgressbarWithChildren>
                </Example>
              </StyledEvalCard>
            </Grid>
          </Grid>
        </Box>
      </ScoreCardBox>
    </>
  );
}

import { WS_URL, get_chat_active_user_list } from "helpers/services/sockets";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";
import SnackbarCom from "./snackBar";
import { getAllNotifications } from "helpers/services/Notifications/NotificationApi";


export default function SocketConnection() {
  const user = useSelector(state => state?.Login);
  const [status, setStatus] = useState(true);
  const [socketURL, setSocketURL] = useState(null);

  useEffect(() => {
    if (Boolean(user?.id))
      (async () => {
        const activeUsersList = await get_chat_active_user_list();
        const extractMatchId = activeUsersList?.data?.filter(
          each => each.userId === user?.id
        );
       
        if (extractMatchId?.length > 0) {
          const connectionId = extractMatchId[0]?.connectionId; // Assuming connectionId is present in the first element of extractMatchId
          setSocketURL(WS_URL + user?.id + `&connectionId=${connectionId}`);
        } else {
          setSocketURL(WS_URL + user?.id);
        }
      })();

    else {
      setStatus(false);
      setSocketURL(null);
    }
  }, [user?.id]);

  
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    socketURL || WS_URL,
    {
      share: true,
      shouldReconnect: () => true,
    },
    status
  );


   // Listen for socket events
   useEffect(() => {
   
    if (lastMessage) {
      const messageData = JSON.parse(lastMessage.data);

      // Example: Check the type of socket event and trigger actions
      if (messageData.type === 'header-action') {
        // Handle header action, maybe make an API call
        console.log('Received header action:', messageData.data);
      } else if (messageData.type === 'company-action') {
        // Handle company page action, maybe make an API call
        getAllNotifications()
        console.log('Received company action:sehsu', messageData.data);
      }
    }
  }, [lastMessage]);


    // if(lastMessage){
    //   // window.alert("message")
    //   return <SnackbarCom  openSnackBar={true} />
    // }



  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];
  // console.log("WebSocket Connection Status:", connectionStatus);
  return {
    connectionStatus,
    sendMessage,
    lastMessage,
    readyState,
    getWebSocket,
  };
}

import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryOpacitedButton,
  PrimaryTypography,
  GrayDivider,
  BlackTypography,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import InputButtonField from "./inputButtonField";
import { useEffect, useState } from "react";


export default function AdditionalInformation({ candidateDetails,readOnly }) {
  const [candidate, setCandidate] = useState({});
  // const [salaryModel,setSalaryModel] = useState(true)
  useEffect(() => {
    setCandidate(candidateDetails);
  }, [candidateDetails]);

 
// const formattedData = currentSalaryData?.map(item => {
//   const formattedSalary = `${item.currency}-${item.current_salary}`;
//   const formattedPay = item.pay.charAt(0).toUpperCase() + item.pay.slice(1);

//   return `${formattedSalary}/${formattedPay}`;
// });
  

const   formatSalary = (data) => {
  if (!data || data.length === 0) {
    return ''; // Return an empty string if data is not provided or is empty
  }

  const formattedData = data?.map(item => {
    const formattedSalary = `${item?.currency}-${item?.current_salary}`;
    // const formattedPay = item.pay.charAt(0).toUpperCase() + item.pay.slice(1);
    const formattedPay = item?.pay
  
    return `${formattedSalary}/${formattedPay}`;
  });

  const formattedString = formattedData?.join(','); 

  return formattedString; 
}

    const formatExpSalary = (data)=>{
      if (!data || data.length === 0) {
        return ''; // Return an empty string if data is not provided or is empty
      }
    
      const formattedData = data?.map(item => {
        const formattedSalary = `${item?.currency}-${item?.expected_salary}`;
        const formattedPay = item?.pay
      
        return `${formattedSalary}/${formattedPay}`;
      });
    
      const formattedString = formattedData?.join(','); 
    
      return formattedString; 
    }
  return (
    <Stack spacing={2} className="mt-3">
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
        <PrimaryTypography component="h4" variant="h4">
          Additional Information
        </PrimaryTypography>
      </Stack>
      <BorderCard>
        <CardContent>
          <Stack spacing={1}>
            {/* <InputButtonField
              text_field={"expected salary"}
              // input_value={candidate?.expected_salary}
              type="input"
              input_type="number"
              update_type={"expected_salary"}
            />
            <InputButtonField
              text_field={"current salary"}
              // input_value={candidate?.current_salary}
              input_type="number"
              type="input"
              update_type={"current_salary"}
            /> */}
            <InputButtonField
              text_field={"current salary"}
              currency_field={"currency"}
              work_field={"worktype"}
              pay_field = {"pay"}
              input_value={formatSalary(candidate?.current_salary)}
              input_type="number"
              type="salary"
              update_type={"current_salary"}
              update_type2={"symbol"}
              update_type3={"pay"}
              update_type4={"work"}
              preDefineValue={candidate?.current_salary?.length ? candidate?.current_salary[0]:null}
              readOnly={readOnly}
              // setSalaryModel={setSalaryModel}
              // salaryModel={salaryModel}
            />
            <InputButtonField
              text_field={"expected salary"}
              currency_field={"currency"}
              work_field={"worktype"}
              pay_field = {"pay"}
              input_value={formatExpSalary(candidate?.expected_salary)}
              input_type="number"
              type="salary"
              update_type={"expected_salary"}
              update_type2={"symbol"}
              update_type3={"pay"}
              update_type4={"work"}
              preDefineValue={candidate?.expected_salary?.length ? candidate?.expected_salary[0]:null}
              readOnly={readOnly}
              // setSalaryModel={setSalaryModel}
              // salaryModel={salaryModel}
            />
            <InputButtonField
              text_field={"Notice Period"}
              input_value={candidate?.notice_period}
              input_type="number"
              type="noticeperiod"
              update_type={"notice_period"}
              readOnly={readOnly}
            />
            <InputButtonField
              text_field={"Experience"}
              input_value={candidate?.experience}
              input_type="number"
              type="experience"
              update_type={"experience"}
              readOnly={readOnly}
            />
            <InputButtonField
              text_field={"Nationality"}
              input_value={candidate?.nationality}
              input_type="number"
              type="nationality"
              update_type={"nationality"}
              readOnly={readOnly}
            />
             <InputButtonField
              text_field={"Languages"}
              input_value={candidate?.languages}
              input_type="number"
              type="languages"
              update_type={"languages"}
              readOnly={readOnly}
            />
          </Stack>
        </CardContent>
      </BorderCard>
    </Stack>
  );
}

import "../../../assets/css/dashboard.css";
import React, { useEffect, useReducer, useState } from "react";

import { CardBody, Container, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import JobList from "../../../components/Jobs/list";
import ClosedJobs from "../../../components/Jobs/closedJobs";
import Pending from "../../../components/Jobs/pending";
import { ACM, CA, CS, ZA } from "common/constants/userModules";
import JobsBasedOnCompanies from "./jobsBasedOnCompanies";
import {
  getAllJobs,
  getAllJobsBasedOnCompany,
  getSingleJobDetails,
} from "helpers/services/jobs/jobs";
import ErrorHandlingToaster from "helpers/Toaster/error";
import SearchParamHook from "hooks/searchParams";
import DetailsDrawer from "components/JobDetails/DetailDrawer";
import { CircularProgress, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  PrimaryButton,
  PrimaryTextButton,
  SecondaryButton,
  SolidTab,
  StyledLoginTabsObject,
  StageSearchInput,
  PrimaryPopUp,
  PrimaryAvatar,
  GreyTypography,
} from "assets/Mui/globalTheme";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { Tabs } from "@mui/material";
import searchFilter from "components/Common/filters/searchFilter";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { makeStyles } from "@mui/styles";
import { getAllCompaniesWithStatus } from "helpers/services/companies/company";
import { toast } from "react-hot-toast";
import SocketConnection from "hooks/sockets";
import CustomPagination from "components/Pagination/globalPagination";

const useStyles = makeStyles({
  label: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "16px",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
    // "& >svg":{
    //     marginLeft:'12px',
    // }
  },
  activeLabel: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "16px",
    cursor: "pointer",
    background: "#cde2e7",
    color: "#024430",
  },
});
const MenuProps = {
  style: {
    maxHeight: 400,
    // width: 350,
    background: "white",
    marginRight: "20px",
    marginTop: "20px",
    fontSize: "14px",
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
  },
};
function WholeJobs({ history, match }) {
  const {
    lastMessage,
    sendMessage: sendSocketMessage,
    connectionStatus,
  } = SocketConnection();
  const userData = JSON.parse(localStorage.getItem("authUser"));
  const userId = userData?.id;

  const searchParams = SearchParamHook();
  const [openSearch, setOpenSearch] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [jobDetails, setJobDetails] = useState({
    application_deadline: new Date(),
    createdAt: new Date(),
  });
  const [filterValue, setFilterValue] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const login = {
    ...JSON.parse(localStorage.getItem("authUser")),
  };
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const id = open ? "transition-popper" : undefined;
  // const [path, setPath] = useState("/joblist/pending");
  const [path, setPath] = useState("/joblist/approved");
  const [jobs, setJobs] = useState([]);
  // const [filteredJobs,setFilteredJobs] = useState([])
  const [load, setLoad] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");
  const [update, setUpdate] = useState(false);
  const [listLoad, setListLoad] = useState(false);

  const [fetchCompanyJobs, setFetchCompanyJobs] = useState()
  const [filterCompanyJobs, setFilterCompanyJobs] = useState(false)

  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(10);
  const rowsPerPage = 20;

  /*For pagination search Params */
  const { search } = useLocation();
  useEffect(() => {
    dataCaller();
  }, [searchParams.get("page")]);

  /*For pagination search Params */

  // useEffect(() => {
  //   setPath(location.pathname);
  // }, [location]);
  useEffect(() => {
    const data = searchParams.get("id");
    if (data) {
      setShowDetails(true);
      findJobDetails(data);
    }
  }, [searchParams]);
  useEffect(() => {
    history.push("/joblist/approved");
    (async () => {
      setListLoad(true);
      try {
        if (![CA, CS].includes(login.role)) {
          // const [zee,dummy] = await Promise.all([getAllCompaniesWithStatus(),getAllCompaniesWithStatus()])
          const { data } = await getAllCompaniesWithStatus();
          // const { data } = zee
          const companies = data.filter(item => {
            return item.company_user_details.usertype === "COMPANY";
          });
          const sorted = companies.filter(item => {
            if (item.related_to_admin === false) return item;
          });
          const alphaSort = sorted.sort((a, b) =>
            a.company_name
              .toLowerCase()
              .localeCompare(b.company_name.toLowerCase())
          );
          // console.log(dummy,zee,"veda");
          setCompanyList(alphaSort);
          setListLoad(false);
        }
      } catch (error) {
        // console.log(error);
        toast.error(error.message, "Something went wrong");
        setListLoad(false);
        // setListLoad(false)
      }
    })();
    // (async function () {
    //     const [mainData,dummy]=await Promise.all()
    //     await getAllCompaniesWithStatus()
    // })()
  }, []);
  async function findJobDetails(id) {
    const { status, data } = await getSingleJobDetails(id);
    ErrorHandlingToaster({ data, status });
    setJobDetails(data?.data_payload);
  }
  const toggleDrawer = React.useCallback(() => {
    setShowDetails(!showDetails);
    searchParams.delete("id");
    history.replace({
      search: searchParams.toString(),
    });
  }, [showDetails]);
  // function toggleDrawer() {
  //   setShowDetails(!showDetails);
  //   searchParams.delete("id");
  //   history.replace({
  //     search: searchParams.toString(),
  //   });
  // }

  async function dataCaller() {
    setLoad(true);
    try {
      const { data, status } = [CS].includes(login.role)
        ? await getAllJobsBasedOnCompany(login.company_id, page, 20)
        : await getAllJobs(page, 10);

      if (status === 200) {
        const sortedJobsList = data?.job_post_details?.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        setJobs(data?.job_post_details);
        setLoad(false);

        // setTotalCount(Math.ceil((data?.overall_jobs_count)/10))

        // setFilteredJobs(data)
        // status ? setLoad(true) : "";
      }
      ErrorHandlingToaster({ data, status });
    } catch (error) {
      setLoad(false);
    }
  }

  useEffect(() => {
    dataCaller();
    // setUpdate((pre)=>!pre);
  }, [update]);
  useEffect(() => {
    if (!Boolean(selectedCompany)) dataCaller();
    // setUpdate((pre)=>!pre);
  }, [selectedCompany]);

  /*Testing Search functionality code start here*/
  const handleSearch = event => {
    const value = event.target.value;
    setFilterSearch(value.trim());
  };
  /*Testing Search functionality code End here*/
  const fiteredCompanies = companyList?.filter(each =>
    each.company_name.toLowerCase().includes(filterValue.toLocaleLowerCase())
  );
  const filteredJobs = jobs?.filter(item =>
    item?.job_title?.toLowerCase().includes(filterSearch?.toLowerCase())
  );





  /*socket listening start here */
  useEffect(() => {}, [connectionStatus]);

  // useEffect(async()=>{
  //   if (lastMessage) {
  //     // Use optional chaining to safely access properties
  //     const messageType = lastMessage?.message?.type;
  //     const messageData = lastMessage?.message?.data;
  //     const companyId = lastMessage?.reciever_connectionId;
  //   const getAssignedAcmList = await getAssignedCompanies()
  //   const filterAcmList = getAssignedAcmList?.filter((each)=>{
  //     if(each?.assigned_companies?.includes(companyId)){
  //       return {
  //         ...each
  //       }
  //     }
  //   })
  //   if(filterAcmList?.includes(userId)){
  //     dataCaller()
  //   }

  //   }
  // },[lastMessage])

  /*socket listening start here */

  function NavSelection(data) {
    switch (data) {
      case "/joblist/approved":
        return (
          <JobList
            setUpdates={setUpdate}
            jobsData={jobs}
            loader={load}
            count={totalCount}
            pages={page}
            setPages={setPage}
            fetchCompanyJobs={fetchCompanyJobs}
            filterCompanyJobs={filterCompanyJobs}
          />
        );
      case "/joblist/pending":
        return (
          <Pending
            update={update}
            setUpdate={setUpdate}
            jobs={jobs}
            load={load}
            fetchCompanyJobs={fetchCompanyJobs}
            filterCompanyJobs={filterCompanyJobs}
          />
        );
      case "/joblist/closed":
        return (
          <ClosedJobs setUpdate={setUpdate} jobs={jobs} load={load}
          fetchCompanyJobs={fetchCompanyJobs}
          filterCompanyJobs={filterCompanyJobs}
          />
        );
      case "/joblist/companies":
        return <JobsBasedOnCompanies />;
    }
  }
  let navBar = [
    {
      name: "Active",
      to: "/joblist/approved",
    },
    {
      name: "Pending",
      to: "/joblist/pending",
    },
    {
      name: "Closed",
      to: "/joblist/closed",
    },
  ];
  if (login.role === ACM) {
    navBar = [
      {
        name: "Active",
        to: "/joblist/approved",
      },
      {
        name: "Pending",
        to: "/joblist/pending",
      },

      {
        name: "Closed",
        to: "/joblist/closed",
      },
    ];
  }
  if (login.role === ZA) {
    navBar = [
      {
        name: "Active",
        to: "/joblist/approved",
      },
      {
        name: "Pending",
        to: "/joblist/pending",
      },

      {
        name: "Closed",
        to: "/joblist/closed",
      },
      // {
      //   name: "Company",
      //   to: "/joblist/companies",
      // },
    ];
  }

  /* Search toggle effect */
  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
    setFilterSearch("");
  };
  /* Search toggle effect end */

  


  async function handleCompanyJobs(company_id) {
    const currentPath = window.location.pathname;
   

    let pathStatus 
    if(currentPath === "/joblist/approved"){
      pathStatus = "active"
    }else if (currentPath === "/joblist/pending"){
      pathStatus = "pending"
    }else if (currentPath === "/joblist/closed"){
      pathStatus = "closed"
    }
    setSelectedCompany(pre => (pre === company_id ? null : company_id));
    setAnchorEl(null);
    setLoad(true);

    setFilterCompanyJobs(!filterCompanyJobs)
/* in progerss */
setFetchCompanyJobs({companyId:company_id,activePath:pathStatus})
/* in progress */


    // try {
    //   const { data } = await getAllJobsBasedOnCompany(company_id,pathStatus,page,10);
    //   setJobs(data?.job_post_details);
    //   setLoad(false);
    // } catch (error) {
    //   toast.error(error.message, "Something went wrong");
    //   setLoad(false);
    // }

  }



  return (
    // <div className="dashboard_wrapper">
    <React.Fragment>
      <div className="page-content position-sticky top-0">
        <Container fluid>
          {login.role !== "ZM" && login.role !== "SP" ? (
            <Row className="header_jobs_sticky">
              <div className="tab_bar">
                <Tabs
                  sx={{
                    height: "20px",
                    borderRadius: "8px",
                    // ...(login.role === ZA && {
                    //   width: "25% !important",
                    // }),
                    // border: "0.5px solid rgba(0,0,0,0.5)",
                  }}
                  value={path}
                  TabIndicatorProps={StyledLoginTabsObject}
                  onChange={(_, to) => {
                    setPath(to);
                    setFetchCompanyJobs({})
                    history.push(to);
                  }}
                  centered
                >
                  {navBar.map(({ name, to }, index) => {
                    return (
                      <SolidTab
                        value={to}
                        label={name}
                        key={index.toString()}
                      />
                    );
                  })}
                </Tabs>
                {/* {navBar.map(({ name, to }, index) => {
                    return (
                      <React.Fragment key={index.toString()}>
                        {path === to ? (
                          <PrimaryButton
                            sx={{ width: "100px" }}
                            onClick={() => {
                              history.push(to);
                            }}
                            key={index.toString()}
                          >
                            {name}
                          </PrimaryButton>
                        ) : (
                          <PrimaryTextButton
                            sx={{ width: "100px" }}
                            onClick={() => {
                              history.push(to);
                            }}
                            key={index.toString()}
                          >
                            {name}
                          </PrimaryTextButton>
                        )}
                      </React.Fragment>
                    );
                  })} */}

{/* 
                <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
                  {openSearch ? (
                    <SearchIcon
                      onClick={handleSearchOpen}
                    />
                  ) : (
                    <StageSearchInput
                      placeholder="search..."
                      onChange={handleSearch}
                      endAdornment={
                        <IconButton edge="end" onClick={handleSearchOpen}>
                          <ClearIcon />
                        </IconButton>
                      }
                    />
                  )}
                  {![CA, CS].includes(login.role) ? (
                    <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                      <FeatherIcon icon={"filter"} size={15} />
                    </IconButton>
                  ) : null}
                </Stack> */}


              </div>
            </Row>
          ) : (
            <Stack
              alignItems={"center"}
              justifyContent={"flex-end"}
              direction={"row"}
              spacing={1.6}
            >
              {/* {openSearch ? (
                <SearchIcon
                  onClick={handleSearchOpen}
               
                />
              ) : (
                <StageSearchInput
                  placeholder="search..."
                  onChange={handleSearch}
                  endAdornment={
                    <IconButton edge="end" onClick={handleSearchOpen}>
                      <ClearIcon />
                    </IconButton>
                  }
                />
              )} */}
              {![CA, CS].includes(login.role) ? (
                <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                  {/* <FeatherIcon icon={"filter"} size={15} /> */}
                </IconButton>
              ) : null}
            </Stack>
          )}
          <Row
            style={{
              marginTop: "20px",
            }}
          >
            {NavSelection(path)}
          </Row>
        </Container>
      </div>
      <DetailsDrawer
        jobDetails={jobDetails}
        toggleDrawer={toggleDrawer}
        open={showDetails}
      />
      <PrimaryPopUp
        id={id}
        open={open}
        PaperProps={MenuProps}
        anchorEl={anchorEl}
        // sx={{left:"800px"}}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <StageSearchInput
          placeholder="search..."
          onChange={e => setFilterValue(e.target.value)}
          value={filterValue}
          sx={{ position: "sticky", top: 0, zIndex: 4 }}
          endAdornment={
            Boolean(filterValue) && (
              <IconButton
                style={{ marginLeft: 8 }}
                edge="end"
                onClick={() => setFilterValue("")}
              >
                <ClearIcon />
              </IconButton>
            )
          }
        />
        {!listLoad ? (
          fiteredCompanies.map(item => (
            <div
              key={item?.id}
              className={
                selectedCompany !== item?.id
                  ? classes.label
                  : classes.activeLabel
              }
              onClick={() => {
                // handleCompanyJobs(item?.company_user_details?.id)
                handleCompanyJobs(item?.id);
              }}
            >
              <PrimaryAvatar
                src={item?.company_image_details}
                sx={{ width: "24px !important", height: "24px !important" }}
              />
              <GreyTypography>{item?.company_name}</GreyTypography>
            </div>
          ))
        ) : (
          <CircularProgress sx={{ ml: "45%", mt: "16px" }} size={16} />
        )}
      </PrimaryPopUp>

      {/* <div style={{position:"absolute",bottom:0,right:0,marginBottom:"12px"}}> 
      <CustomPagination 
        count={totalCount}
        page={page}
        setPage={setPage}
      />
      </div> */}
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {
    login: state.Login,
  };
};
export default withRouter(connect(mapStateToProps, null)(WholeJobs));

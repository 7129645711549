import { Stack } from "@mui/material";
import {
  BlackTypography,
  PrimaryButton,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import JobPostingStepper from "components/Posting/stepper";
import {
  getAllEducationalSpecialization,
  getEducationalSpecializationWithQualification,
  updateEducationalSpecialization,
} from "helpers/services/jobAttributes/educationalQualifications";
import { CreateJobPost, getSingleJob, updateJobPost } from "helpers/services/jobs/jobs";
import { useState,useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory,withRouter,useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import SocketConnection from "hooks/sockets";
import { connect } from "react-redux";
import BasicInfo from "./basicInfo";
import JobInfo from "./jobInfo";
import HireIn from "./hireIn";
import htmlToDraft from "html-to-draftjs";
import { getAllActualJobLevel } from "helpers/services/jobAttributes/actualJobLevel";
import {  ContentState, EditorState} from "draft-js";
import { formatNumberWithCommas } from "helpers/functions/formatAmount";
import SearchParamHook from "hooks/searchParams";
function JobEditing({ updateJob, data, history }) {
  const [activeStep, setActiveStep] = useState(0);
  const searchParams = SearchParamHook()
  const userId = searchParams.get("user_id")
  const { id } = useParams();
  const methods = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
    job_title: "",
    job_type: "",
    location: "",
    work_mode: "",
    job_description: "",
    job_requirement: "",    
    functional_area: null,
    job_roles: [],
    no_of_vacancies: "",
    currency: "",
    application_deadline: "",
    time_zone: [],
    min_sal: "0",
    max_sal: "1",
    min_exp: "0",
    max_exp: "0",
    min_exp_months: "0",
    max_exp_months: "0",
    commission_rate_type: "",
    refer_percentage: "",
    educational_qualifications: [],
    attachment: "",
    screening_questions: [],
    hours_per_week: "",
    project_duration: "",
  },
  });
  const [editState,setEditState] = useState({
    job_title: "",
    job_type: "",
    location: "",
    work_mode: "",
    job_desc: "",
    functional_area: null,
    job_role: null,
    no_of_vacancies: "",
    currency: "",
    application_deadline: "",
    time_zone: [],
    min_sal: "0",
    max_sal: "1",
    min_exp: "0",
    max_exp: "0",
    min_exp_months: "0",
    max_exp_months: "0",
    commission_rate_type: "",
    refer_percentage: "",
    educational_qualifications: [],
    attachment: "",
    screening_questions: [],
    hours_per_week: "",
    project_duration: "",
    job_level_id:null,
  })
  function JobDescription(data) {
    const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        return editorState
      }
  }
  function JobRequirement(data) {
    const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        return editorState
      }
  }
 
  useEffect(() => {
    try {
      (async function() {
        // await deleteJob();
        const data = await getSingleJob(id,userId);
        const { data:jobLevels } = await getAllActualJobLevel();
        const JobLevelInfo = jobLevels.find(item=>item?.id === data.job_level_id)
        // setJobDetails(data);
        const questions = data.job_screening_questions_details.map(
          item =>({value:item.question})
        );
        // const deadline = date => {
        //   const value = new Date(date);
        //   const year = value.getFullYear();
        //   const month = value.getMonth() + 1;
        //   const day = value.getDate();
        //   return `${year}-${month}-${day < 10 ? `0${day}` : day}T00:00`;
        // };
        const job_roles_parse = item => {
          const [job_roles] = item.map(item => ({value:item?.id,name:item?.name,isActive:item?.is_active}));
          return job_roles;
        };
        const key_skills = item => {
          const skills = item.map(item => ({ value: item.id, name: item.name,isActive:item.is_active,id:item?.id }));
          return skills;
        };
        const findEducationalQualfificationAndSpec = item => {
          return item.educational_qualification_details.map(data => {
            return [
               data?.education_qualification_id?.id,
              
                data?.education_qualification_spec_id?.id,
              // education_qualification_spec_name:data?.education_qualification_spec_id?.name
            ];
          });
        };
        methods.reset({
          job_title: data.job_title,
          job_type: data.job_type,
          min_exp: data.min_exp,
          max_exp: data.max_exp,
          min_exp_months: data.min_exp_months,
          max_exp_months: data.max_exp_months,
          location: data.location,
          work_mode: data.work_mode,
          payout:data.payout,
          job_description: JobDescription(data.job_desc),
          job_requirement:JobRequirement(data.requirements),
          functional_area: {
            name: data.functional_area_details[0]?.name,
            value:data.functional_area_details[0]?.id,
            isActive: data.functional_area_details[0]?.is_active,
          },
          job_level_id: {
            name: JobLevelInfo.name,
            value: JobLevelInfo.id,
            isActive:JobLevelInfo.is_active
          },
          job_role: job_roles_parse(data.job_roles_details),
          no_of_vacancies: data.no_of_vacancies,
          currency: data.currency,
          application_deadline: data.application_deadline,
          time_zone: data.time_zone,
          min_sal: formatNumberWithCommas(data.min_sal),
          key_skills: key_skills(data.key_skills_details),
          max_sal: formatNumberWithCommas(data.max_sal),
          commission_rate_type: data.commission_rate_type,
          refer_percentage: data.commission_rate,
          educational_qualifications: findEducationalQualfificationAndSpec(data),
          attachement: "s3_url",
          question: [...questions],
          hours_per_week: data.hours_per_week,
          project_duration: data.project_duration === "0" ? "1_2_years" :data.project_duration ,
        });
        setEditState({
          job_title: data.job_title,
          job_type: data.job_type,
          min_exp: data.min_exp,
          max_exp: data.max_exp,
          min_exp_months: data.min_exp_months,
          max_exp_months: data.max_exp_months,
          location: data.location,
          work_mode: data.work_mode,
          job_description: data.job_desc,
          job_requirement:data.requirements,
          functional_area: {
            name: data.functional_area_details[0]?.name,
            value:data.functional_area_details[0]?.id,
            isActive: data.functional_area_details[0]?.is_active,
          },
          job_role: job_roles_parse(data.job_roles_details),
          no_of_vacancies: data.no_of_vacancies,
          currency: data.currency,
          application_deadline: data.application_deadline,
          time_zone: data.time_zone,
          min_sal: data.min_sal.toString(),
          key_skills: key_skills(data.key_skills_details),
          max_sal: data.max_sal.toString(),
          commission_rate_type: data.commission_rate_type,
          refer_percentage: data.commission_rate,
          educational_qualifications: findEducationalQualfificationAndSpec(data),
          attachement: "s3_url",
          screening_questions: [...questions],
          hours_per_week: data.hours_per_week,
          project_duration: data.project_duration === "0" ? "1_2_years" : data.project_duration,
          job_level_id: {
            name: JobLevelInfo.name,
            value: JobLevelInfo.id,
            isActive:JobLevelInfo.is_active
          },
        })
        
      })()
    } catch (error) {
      
    }


  }, []);

  function findstep(active) {
    switch (active) {
      case 0:
        return <BasicInfo  />;
      case 1:
        return <JobInfo  />;
      case 2:
        return <HireIn  />;
    }
  }
  const onSubmit = async data => {

    if (activeStep === 2) {
      if (Number(data.refer_percentage) < 8.3) {
        toast.error("Refer percentage should be greater than 8.3%");
      }
      data.commission_rate_type = "Percentage";
      data.job_level_id = data.job_level_id.value;
      data.commission_rate = data.refer_percentage;
      data.functional_areas = data.functional_area;

      // data.min_sal = Number(data.min_sal);
      // data.max_sal = Number(data.max_sal);
      data.min_sal = parseInt(data.min_sal.replace(",",""));
      data.max_sal = parseInt(data.max_sal.replace(",",""));
      data.screening_questions = data.question?.map(item => item?.value);
      data.application_deadline = new Date(data.application_deadline);
      data.hours_per_week = "less_than_30";
      // data.project_duration = "less_than_month";
      data.project_duration = data.project_duration?data.project_duration:"0";
      data.job_roles = [data.job_role?.value];
      data.payout = data.payout
      data.job_desc = Boolean(data.job_desc)? data.job_desc : editState.job_description
      data.requirements = Boolean(data.requirements) ? data.requirements : editState.job_requirement
      data.max_exp = data.min_exp === "10+" ? "0" : data.max_exp
      if (data?.location === "") {
        delete data.location;
      }
      delete data.job_description;
      // delete data.payout;  
      delete data.refer_amount;
      delete data.refer_percentage;
      delete data.question;
      delete data.job_role;
      delete data.functional_area;
      delete data.job_requirement;
      // const educational_qualifications = data?.educational_qualifications?.map(
      //   async item => {
      //     const [specialization_data] =
      //       await getEducationalSpecializationWithQualification(item?.value);

      //     return {
      //       education_qualification_id:
      //         specialization_data?.length > 0
      //           ? specialization_data[0]?.educational_qualification_id
      //           : item?.value,
      //       education_qualification_spec_id:
      //         specialization_data?.length > 0 ? specialization_data[0]?.id : "",
      //     };
      //   }
      // );
      const educational_qualifications = data?.educational_qualifications?.map(
        item => {
          const [education_qualification_id, education_qualification_spec_id] =
            item;
          return {
            education_qualification_id,
            education_qualification_spec_id,
          };
        }
      );
      // const ed_full = await Promise.all(educational_qualifications);
      const ed_full = (educational_qualifications);
      const functional_areas = [data?.functional_areas?.value];
      const key_skills = data?.key_skills?.map(item => item?.value);
      // console.log(key_skills,functional_areas,"v");
        // console.log({
        //   ...data,
        //   functional_areas,
        //   educational_qualifications: ed_full,
        //   key_skills,
        // },"v");
      const { status, data: errors } = await updateJobPost(
        id,
        {
          ...data,
          functional_areas,
          educational_qualifications: ed_full,
          key_skills,
        }
      );
      if ([200,201].includes(status)) {
        toast.success("Job post is updated Successfully")
        history.push("/joblist/pending");
      }
      return;
    }
    setActiveStep(activeStep + 1);
  };

  // const schema = yup
  //   .object({
  //     max_sal: yup
  //       .number()
  //       .required()
  //       .min(Number(methods.watch("min_sal"))),
  //   })
  //   .required();

  function handleBack() {
    if (activeStep > 0) setActiveStep(activeStep - 1);
    else history.goBack();
  }
  return (
    <Stack spacing={2}>
      <JobPostingStepper activeStep={activeStep} />
      <FormProvider {...methods}>
        <form
          style={{ padding: "0px 50px", overflow: "scroll", height: "72vh" }}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {findstep(activeStep)}
          <Stack
            sx={{
              // position: "absolute",
              marginBlock: "16px",
              background: "white",
            }}
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            <SecondaryFilledButton
              sx={{ width: "100px", color: "#D60D0D" }}
              type="button"
              onClick={handleBack}
            >
              {activeStep === 0 ? "cancel" : "back"}
            </SecondaryFilledButton>

            <PrimaryButton sx={{ width: "200px" }} type="submit">
              {activeStep === 2 ? "Publish this Job" : "Proceed to next step"}
            </PrimaryButton>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
}

const mapStateToProps = ({ Jobs }) => ({
  data: Jobs,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
  deleteJob: () => dispatch(deleteJob()),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobEditing)
);
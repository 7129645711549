const { Row, Container, Col, CardBody, Card } = require("reactstrap");
import {
  FormControlLabel,
  FormGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { toast } from "react-hot-toast";
import React,{useState,useEffect} from "react";
import CommonModel from "../../components/Common/models/createModel";
import {
  createAndEditFunctionalArea,
  deleteFunctionalArea,
  getAllFunctionalArea,
} from "../../helpers/services/jobAttributes/functionalArea";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import { StageSearchInput, GreyTypography } from "assets/Mui/globalTheme";
import CustomPagination from "components/Pagination/globalPagination";
import SearchParamHook from "hooks/searchParams";

class FunctionalArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      id:"",
      name: "",
      data: [],
      isLoading: "true",
      openSearch: true,
      searchRole: "",
    };
    this.toggle = this.toggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
    this.createAndEditFunctionalArea = createAndEditFunctionalArea.bind(this);
    this.getAllFunctionalArea = getAllFunctionalArea.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handlRestore = this.handlRestore.bind(this);
  }
  async componentDidMount() {
    this.setState({ isLoading: true });
    const items = await this.getAllFunctionalArea();
    this.setState({ data: items, isLoading: false });
  }

  toggle(
    purpose = "",
    id = "",
    name = "",
    defaultValue = "",
    active = "",
    index
  ) {
    // this.setState({
    //   purpose: purpose,
    //   id,
    //   name: name,
    //   index,
    //   default: defaultValue,
    //   active: active,
    //   isOpen: !this.state.isOpen,
    // });

    this.setState(prev => ({
      ...prev,
      purpose: purpose,
      name: name,
      index: index,
      id: id,
      default: defaultValue,
      active: active,
      isOpen: !this.state.isOpen,
    }));

  }
  handleDelete(index, id) {
    deleteFunctionalArea(id);
    let result = this.state.data;

    const filterOne = result?.map((each)=>{
      if(each.id == id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

    // result[index].is_active = !result[index].is_active;
    this.setState({
      data: filterOne,
    });
    toast.error("Disabled");
  }
  handlRestore(item, index) {
    const sample = {
      name: item.name,
      active: !item.is_active,
    };
    createAndEditFunctionalArea(sample, "Edit", index, item.id);
    let result = this.state.data;

 
    const filterOne = result?.map((each)=>{
      if(each.id == item.id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

    // result[index].is_active = !result[index].is_active;
    this.setState({
      data: filterOne,
    });
    toast.success("Enabled");
  }
  handleAction(item, index) {
    item.is_active
      ? this.handleDelete(index, item.id)
      : this.handlRestore(item, index);
  }
  updateFunc(sample, type, index,id) {

    let editedId;
    if (id) {
      editedId = id;
    } else {
      // editedId = this.state.id;
      editedId = sample?.id
    }
  

   
    this.createAndEditFunctionalArea(sample, type, index, editedId);

    // this.createAndEditFunctionalArea(sample, type, index, this.state.id);
  }

  /* Search toggle effect */
  handleSearchOpen = () => {
    this.setState(prevState => ({
      openSearch: !prevState.openSearch,
      searchRole:""
    }));
  };

  handleSearch = e => {
    this.setState(prevState => ({
      searchRole: "",
      searchRole: e.target.value,
    }));
  };

  searchFilterData = data => {
    const searchWord = this.state?.searchRole?.toLowerCase();
    const searchPattern = data?.filter(item => {
      const roleName = item?.name?.toLowerCase();

      if (roleName?.includes(searchWord)) {
        return item;
      }
    });
    return searchPattern;
  };

  /* Search toggle effect end */

  render() {
    const filteredData = this.searchFilterData(this.state?.data);
    return (
      <Container fluid>
        <CommonModel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          headTag={"Category"}
          purpose={this.state.purpose}
          index={this.state.index}
          title={this.state.name}
          defaultValue={this.state.default}
          active={this.state.active}
          update={this.updateFunc}
          id={this.state.id}
        />
        <div md={6} style={{ padding: "40px" }} className="header_jobs_sticky">
          <PrimaryButton
            // className="header_jobs_sticky"
            sx={{ width: "200px" }}
            onClick={() => this.toggle("Create")}
          >
            Create Category
          </PrimaryButton>
          <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
            {this.state.openSearch ? (
              <SearchIcon onClick={this.handleSearchOpen} />
            ) : (
              <StageSearchInput
                placeholder="search..."
                onChange={e => this.handleSearch(e)}
                endAdornment={
                  <IconButton edge="end" onClick={this.handleSearchOpen}>
                    <ClearIcon />
                  </IconButton>
                }
              />
            )}
          </Stack>
        </div>
        <Row style={{ marginTop: "", marginLeft: "20px" }}>
          <Row className="align-items-center "></Row>
          <div className="table_cont">
            <div className="table-responsive">
              <Card>
                <CardBody>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Categories</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {this.state.isLoading ? (
                        <BubbleLoader />
                      ) : (
                        // this.state.data.map((item, index) => (
                        filteredData?.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>

                            <TableCell className="active_td">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <PrimarySwitch
                                      sx={{ m: 1 }}
                                      checked={item.is_active}
                                      onChange={async e => {
                                        this.handleAction(item, index);
                                      }}
                                    />
                                  }
                                />
                              </FormGroup>
                            </TableCell>
                            <TableCell className="col_btn">
                              <PrimaryButton
                                style={{ width: "100px" }}
                                onClick={() =>
                                  this.toggle(
                                    "Edit",
                                    item.id,
                                    item.name,
                                    item.default,
                                    item.is_active,
                                    index
                                  )
                                }
                              >
                                Edit
                              </PrimaryButton>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}

export default FunctionalArea;




// const FunctionalArea = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [active, setActive] = useState(false);
//   const [index, setIndex] = useState(-1);
//   const [defaultVal, setDefaultVal] = useState(false);
//   const [id, setId] = useState('');
//   const [name, setName] = useState('');
//   const [purpose, setPurpose] = useState(''); // Initialize purpose state
//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [openSearch, setOpenSearch] = useState(true);
//   const [searchRole, setSearchRole] = useState('');
//   const searchParams = SearchParamHook()

//   useEffect(() => {
//     const fetchData = async () => {
//       setIsLoading(true);
//       const items = await getAllFunctionalArea();
//       setData(items);
//       setIsLoading(false);
//     };

//     fetchData();
//   }, []);

//   const toggle = (purpose = '', id = '', name = '', defaultValue = '', active = '', index) => {
//     setPurpose(purpose);
//     setName(name);
//     setIndex(index);
//     setId(id);
//     setDefaultVal(defaultValue);
//     setActive(active);
//     setIsOpen(!isOpen);
//   };

//   const handleDelete = (index, id) => {
//     deleteFunctionalArea(id);
//     const result = data.map((each) => (each.id === id ? { ...each, is_active: !each.is_active } : each));
//     setData(result);
//     toast.error('Disabled');
//   };

//   const handleRestore = (item, index) => {
//     const sample = {
//       name: item.name,
//       active: !item.is_active,
//     };
//     createAndEditFunctionalArea(sample, 'Edit', index, item.id);
//     const result = data.map((each) =>
//       each.id === item.id ? { ...each, is_active: !each.is_active } : each
//     );
//     setData(result);
//     toast.success('Enabled');
//   };

//   const handleAction = (item, index) => {
//     item.is_active ? handleDelete(index, item.id) : handleRestore(item, index);
//   };

//   const updateFunc = (sample, type, index, id) => {
//     const editedId = id || sample?.id;
//     createAndEditFunctionalArea(sample, type, index, editedId);

//     if (type === 'Create') {
//       setData([...data, sample]);
//     } else {
//       const result = data.map((each) =>
//         each.id === sample.id ? { ...each, name: sample.name, is_active: sample.is_active } : each
//       );
//       setData(result);
//     }
//   };

//   const handleSearchOpen = () => {
//     setOpenSearch(!openSearch);
//     setSearchRole('');
//   };

//   const handleSearch = (e) => {
//     setSearchRole(e.target.value);
//   };

//   const searchFilterData = (data) => {
//     const searchWord = searchRole?.toLowerCase();
//     const searchPattern = data?.filter((item) => {
//       const roleName = item?.name?.toLowerCase();

//       if (roleName?.includes(searchWord)) {
//         return item;
//       }
//     });
//     return searchPattern;
//   };

//     /* For pagination purpose in progress */

//     useEffect(()=>{
//       getAllFunctionalArea()
//     },[searchParams.get("page")])
  
//     /* For pagination purpose in progress */

//   const filteredData = searchFilterData(data);

//   return (
//     <Container fluid>
//       <CommonModel
//         isOpen={isOpen}
//         toggle={toggle}
//         headTag={'Category'}
//         purpose={purpose}
//         index={index}
//         title={name}
//         defaultValue={defaultVal}
//         active={active}
//         update={updateFunc}
//         id={id}
//       />
//       <div md={6} style={{ padding: '40px' }} className="header_jobs_sticky">
//         <PrimaryButton sx={{ width: '200px' }} onClick={() => toggle('Create')}>
//           Create Category
//         </PrimaryButton>
//         <Stack alignItems={'center'} direction={'row'} spacing={1.6}>
//           {openSearch ? (
//             <SearchIcon onClick={handleSearchOpen} />
//           ) : (
//             <StageSearchInput
//               placeholder="search..."
//               onChange={(e) => handleSearch(e)}
//               endAdornment={
//                 <IconButton edge="end" onClick={handleSearchOpen}>
//                   <ClearIcon />
//                 </IconButton>
//               }
//             />
//           )}
//         </Stack>
//       </div>
//       <Row style={{ marginTop: '', marginLeft: '20px' }}>
//         <Row className="align-items-center "></Row>
//         <div className="table_cont">
//           <div className="table-responsive">
//             <Card>
//               <CardBody style={{ height: 'calc(100vh - 220px)', overflowY: 'scroll' }}>
//                 <Table className="table mb-0">
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>Categories</TableCell>
//                       <TableCell>Status</TableCell>
//                       <TableCell>Action</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <tbody>
//                     {isLoading ? (
//                       <BubbleLoader />
//                     ) : (
//                       filteredData?.map((item, index) => (
//                         <TableRow key={item.id}>
//                           <TableCell>{item.name}</TableCell>

//                           <TableCell className="active_td">
//                             <FormGroup>
//                               <FormControlLabel
//                                 control={
//                                   <PrimarySwitch
//                                     sx={{ m: 1 }}
//                                     checked={item.is_active}
//                                     onChange={async (e) => {
//                                       handleAction(item, index);
//                                     }}
//                                   />
//                                 }
//                               />
//                             </FormGroup>
//                           </TableCell>
//                           <TableCell className="col_btn">
//                             <PrimaryButton
//                               style={{ width: '100px' }}
//                               onClick={() => toggle('edit', item.id, item.name, item.default, item.is_active, index)}
//                             >
//                               Edit
//                             </PrimaryButton>
//                           </TableCell>
//                         </TableRow>
//                       ))
//                     )}
//                   </tbody>
//                 </Table>
//               </CardBody>
//             </Card>
//           </div>
//         </div>

//         <CustomPagination />
//       </Row>
//     </Container>
//   );
// };

// export default FunctionalArea;



import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  handleCollapse,
  setDownSide,
  setSidebarContent,
  defaultSidebar,
} from "store/actions";
import "../../assets/css/layout/sidebar.css";
import Zepul from "../../assets/images/zepul/shiyftGreenIcon.png";
import ZepulLarge from "../../assets/images/zepul/shifyt1.png";
import joineeLogo from "../../assets/images/joineeLogo.png";
import joineeIcon from "../../assets/images/JoineeIcon.png";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import { StyledTootlTip } from "assets/Mui/globalTheme";

import { contentFinder } from "./items";
import { Tooltip } from "@mui/material";
import { blue, grey, indigo } from "@mui/material/colors";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import LogoutModel from "./logout";
import { imageURL } from "common/constants/commonURLS";

const popperProps = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [2, 7], // Adjust the values to reduce the distance
      },
    },
  ],
};
class Secondary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      hover: true,
      profilePoP: false,
      //   downSide: false,
      logout: false,
      location: "Sidebar",
      sidebar: [],
      windowWidth: 0,
    };
    this.onResize = this.onResize.bind(this);
    this.handleProfilePopup = this.handleProfilePopup.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }
  handleLogout() {
    this.setState({ logout: !this.state.logout });
  }
  onResize() {
    this.setState({ windowWidth: window.innerWidth });
  }
  handleProfilePopup() {
    this.setState({ profilePoP: !this.state.profilePoP });
  }
  componentDidMount() {
    this.props.defaultSidebar();
    this.mounted = true;
    const role = JSON.parse(localStorage.getItem("authUser")).role;
    if (this.mounted)
      this.setState({
        sidebar: contentFinder(role),
        location: this.props.history.location.pathname,
      });
    if (typeof Window !== undefined)
      this.setState({ windowWidth: window.innerWidth });
    window.addEventListener("resize", this.onResize, false);
  }
  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onResize, false);
  }
  componentDidUpdate(_, prevState) {
    if (prevState.sidebar.length !== this.state.sidebar.length) {
      this.state.sidebar.map(({ name, link }) => {
        if (this.state.location.includes(link)) {
          this.props.setSideItem(name);
          return;
        }
      });
      // }
    }
  }
  viewModal = () => {
    this.setState({
      logout: true,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className={`sidebar sidebar_ab ${
            this.props.collapse ? "width_f overXhide" : "width_n"
          } `}
          style={{
            transition: "0.5s ease-in-out",
          }}
        >
          <div
            style={{
              transition: "0.5s ease-in-out",
            }}
            className={`logo_sidebar  d-flex align-items-center justify-content-between ${
              this.props.collapse ? "w_img_f" : "w_img_m"
            }`}
          >
            <Link
              to={"/"}
              style={
                this.props.collapse
                  ? { paddingLeft: "45px" }
                  : { paddingLeft: "20px" }
              }
            >
              <img
                // src={ShifytLarge}
                src={this.props.collapse ? joineeLogo : joineeIcon}
                className={this.props.collapse ? "logo_full" : "logo_img"}
              />
            </Link>

            <div
              className={`sidebar_collapse_handler`}
              onClick={() => {
                this.props.collpaseHanlder(this.props.collapse);
                // this.props.collapseMain();
                // this.setState({ hover: !this.state.hover });
              }}
            >
              <FeatherIcon
                size="14"
                icon={`${
                  this.props.collapse ? "chevron-left" : "chevron-right"
                }`}
              />
            </div>
          </div>

          <ul className={`sidebar_list ${this.props.collapse ? "w_full" : ""}`}>
            {this.state.sidebar?.map((item, idx) => {
              return item.icon ? (
                !this.props.collapse ? (
                  <Tooltip
                    PopperProps={popperProps}
                    placement="right-start"
                    title={item.name}
                  >
                    {item.name !== "logout" ? (
                      <Link
                        to={item.link}
                        key={idx}
                        data-label={item.name}
                        className={`list_icons ${
                          this.props.clickedItem === item.name
                            ? "list_click"
                            : ""
                        }`}
                        onClick={() => {
                          this.props.setSideItem(item.name);
                        }}
                      >
                        {/* <i
                          className={item.icon}
                          style={
                            item.icon === "mdi mdi-lightbulb-on"
                              ? {
                                  fontSize: "13.5px",
                                  marginLeft: "3px",
                                  color: "#ffe7bb",
                                }
                              : {}
                          }
                        ></i> */}
                        {typeof item.icon === "string" ? (
                          <i
                            className={item.icon}
                            style={
                              item.icon === "mdi mdi-lightbulb-on"
                                ? { fontSize: "13.5px", marginLeft: "3px" }
                                : {}
                            }
                          ></i>
                        ) : (
                          item.icon
                        )}
                        {this.props.collapse ? (
                          <span className="text_sidebar">{item.name}</span>
                        ) : (
                          ""
                        )}
                      </Link>
                    ) : (
                      <li
                        key={idx}
                        data-label={item.name}
                        className={`list_icons ${
                          this.props.clickedItem === item.name
                            ? "list_click"
                            : ""
                        }`}
                        onClick={() => {
                          this.props.setSideItem(item.name);
                          this.viewModal();
                        }}
                      >
                        {/* <i
                          className={item.icon}
                          style={
                            item.icon === "mdi mdi-lightbulb-on"
                              ? {
                                  fontSize: "13.5px",
                                  marginLeft: "3px",
                                  color: "#ffe7bb",
                                }
                              : {}
                          }
                        ></i> */}
                        {typeof item.icon === "string" ? (
                          <i
                            className={item.icon}
                            style={
                              item.icon === "mdi mdi-lightbulb-on"
                                ? { fontSize: "13.5px", marginLeft: "3px" }
                                : {}
                            }
                          ></i>
                        ) : (
                          item.icon
                        )}
                        {this.props.collapse ? (
                          <span className="text_sidebar">{item.name}</span>
                        ) : (
                          ""
                        )}
                      </li>
                    )}
                  </Tooltip>
                ) : item.name !== "logout" ? (
                  <Link
                    to={item.link}
                    key={idx}
                    data-label={item.name}
                    className={`list_icons ${
                      this.props.clickedItem === item.name ? "list_click" : ""
                    }`}
                    onClick={() => {
                      this.props.setSideItem(item.name);
                    }}
                  >
                    {typeof item.icon === "string" ? (
                      <i
                        className={item.icon}
                        style={
                          item.icon === "mdi mdi-lightbulb-on"
                            ? { fontSize: "13.5px", marginLeft: "3px" }
                            : {}
                        }
                      ></i>
                    ) : (
                      item.icon
                    )}
                    {this.props.collapse ? (
                      <span className="text_sidebar">{item.name}</span>
                    ) : (
                      ""
                    )}
                  </Link>
                ) : (
                  <li
                    key={idx}
                    data-label={item.name}
                    className={`list_icons ${
                      this.props.clickedItem === item.name ? "list_click" : ""
                    }`}
                    onClick={() => {
                      this.props.setSideItem(item.name);
                      this.viewModal();
                    }}
                  >
                    {/* <i
                      className={item.icon}
                      style={
                        item.icon === "mdi mdi-lightbulb-on"
                          ? { fontSize: "13.5px", marginLeft: "3px" }
                          : {}
                      }
                    ></i> */}
                    {typeof item.icon === "string" ? (
                      <i
                        className={item.icon}
                        style={
                          item.icon === "mdi mdi-lightbulb-on"
                            ? { fontSize: "13.5px", marginLeft: "3px" }
                            : {}
                        }
                      ></i>
                    ) : (
                      item.icon
                    )}
                    {this.props.collapse ? (
                      <span className="text_sidebar">{item.name}</span>
                    ) : (
                      ""
                    )}
                  </li>
                )
              ) : (
                <React.Fragment key={idx}>
                  {!this.props.collapse ? (
                    <Tooltip
                      title={item.name}
                      PopperProps={popperProps}
                      placement="right-start"
                    >
                      <li
                        className="arrow_icon"
                        data-label={item.name}
                        onClick={() => {
                          if (this.props.downSide === idx) {
                            this.props.setDownSide(-1);
                            return;
                          }
                          this.props.setDownSide(idx);
                        }}
                      >
                        {item.logo}
                        {this.props.collapse ? (
                          <>
                            <span className="text_sidebar">{item.name}</span>
                            <span>
                              <FeatherIcon
                                icon="chevron-down"
                                className={`${
                                  this.props.downSide === idx ? "rot_full2" : ""
                                }`}
                                style={{
                                  transition: "0.5s ease-in-out",
                                  transformOrigin: "center center",
                                  height: "18px",
                                  width: "18px",
                                }}
                              ></FeatherIcon>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </li>
                    </Tooltip>
                  ) : (
                    <li
                      className="arrow_icon"
                      data-label={item.name}
                      onClick={() => {
                        if (this.props.downSide === idx) {
                          this.props.setDownSide(-1);
                          return;
                        }
                        this.props.setDownSide(idx);
                      }}
                    >
                      {item.logo}
                      {this.props.collapse ? (
                        <>
                          <span className="text_sidebar">{item.name}</span>
                          <span>
                            <FeatherIcon
                              icon="chevron-down"
                              className={`${
                                this.props.downSide === idx ? "rot_full2" : ""
                              }`}
                              style={{
                                transition: "0.5s ease-in-out",
                                transformOrigin: "center center",
                                height: "18px",
                                width: "18px",
                              }}
                            ></FeatherIcon>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </li>
                  )}
                  <div
                    className={`drop_cont ${
                      this.props.downSide === idx ? "down_s" : "up_s"
                    }`}
                    style={{
                      transition: "0.5s ease-in-out",
                    }}
                  >
                    {item?.items.map((data, id) =>
                      !this.props.collapse ? (
                        <Tooltip
                          key={id * 98}
                          PopperProps={popperProps}
                          title={data.name}
                          placement="right-start"
                        >
                          <li
                            key={id * 98}
                            data-label={data.name}
                            id="sidebar_dropdowns"
                            style={{ transition: "0.3s ease-out" }}
                            className={`list_icons only_items ${
                              this.props.clickedItem === data.name
                                ? "list_click"
                                : ""
                            } ${this.props.collapse ? "item_exp" : ""}`}
                            onClick={() => {
                              // this.props.collpaseHanlder();

                              // setTimeout(() => {
                              //   this.props.collpaseHanlder();
                              // }, 500);
                              this.props.setSideItem(data.name);

                              this.props.history.push(data.link);
                            }}
                          >
                            {/* {data?.icon ? (
                            <i className={data?.icon}></i>
                          ) : (
                            <span className={data.img}></span>
                          
                          )} */}
                            {data?.icon ? (
                              <i className={data?.icon}></i>
                            ) : data?.logo ? (
                              data.logo
                            ) : (
                              // <span className={data.img}></span>
                              data.img
                            )}
                            {this.props.collapse ? (
                              <span className="text_sidebar">{data.name}</span>
                            ) : (
                              ""
                            )}
                          </li>
                        </Tooltip>
                      ) : (
                        <StyledTootlTip
                              placement="right-start"
                              title={
                               data.name
                              }
                            >
                               
                        <li
                          key={id * 98}
                          data-label={data.name}
                          id="sidebar_dropdowns"
                          style={{ transition: "0.3s ease-out" }}
                          className={`list_icons only_items ${
                            this.props.clickedItem === data.name
                              ? "list_click"
                              : ""
                          } ${this.props.collapse ? "item_exp" : ""}`}
                          onClick={() => {
                         
                            this.props.setSideItem(data.name);

                            this.props.history.push(data.link);
                          }}
                        >
                          {data?.icon ? (
                            <i className={data?.icon}></i>
                          ) : data?.logo ? (
                            data.logo
                          ) : (
                            // <span className={data.img}></span>
                            data.img
                          )}
                          {this.props.collapse ? (
                              <span className="text_sidebar">{data.name}</span>
                          
                          ) : (
                            ""
                          )}
                        </li>
                        </StyledTootlTip>
                      )
                    )}
                  </div>
                </React.Fragment>
              );
            })}
          </ul>
          {/* {(!this.props.collapse && this.state.viewLabel) && <div style={{position:'fixed',top:this.state.labelHeight,left:'90px',color:'white',background:'#000',padding:2}}>{this.state.activeTabLabel}</div>} */}
          <div
            style={
              this.props.collapse
                ? {
                    width: "208px",
                  }
                : { width: "70px" }
            }
            className={`sidebar_user_details ${this.props.collapse ? "" : ""}`}
          >
            {/* <div className="sidebar_user_details_container">
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Avatar
                    sx={
                      this.props.collapse
                        ? {
                            height: 34,
                            width: 34,
                            bgcolor: indigo[100],
                          }
                        : {
                            height: 24,
                            width: 24,
                            bgcolor: indigo[100],
                          }
                    }
                    onClick={() => {
                      history.push("/profile-settings");
                    }}
                    alt="Company Logo"
                    src={`${imageURL}${this?.props?.user?.profile_image}`}
                  >
                    {this?.props?.user?.first_name?.charAt(0)?.toUpperCase()}
                  </Avatar>
                  {this.props?.collapse ? (
                    <Stack spacing={0}>
                      <div className="remove_text">
                        <Typography
                          sx={{
                            color: "white",
                            textTransform: "capitalize",
                          }}
                          variant="subtitle2"
                          fontWeight="700"
                        >
                          {this?.props?.user?.usertype
                            ?.replace("_", " ")
                            ?.toLowerCase()}
                        </Typography>
                      </div>

                      <div className="remove_text">
                        <Typography
                          sx={{ color: "white" }}
                          variant="subtitle2"
                          fontWeight="400"
                        >
                          {this?.props?.user?.user_name}
                        </Typography>
                      </div>
                    </Stack>
                  ) : (
                    ""
                  )}
                </Stack>
                {this.props?.collapse ? (
                  <IconButton
                    onClick={this.handleProfilePopup}
                    sx={{ color: "white" }}
                  >
                    <FeatherIcon icon="chevron-down" size="14" />
                  </IconButton>
                ) : (
                  ""
                )}
              </Stack>
              {this.state?.profilePoP ? (
                <>
                  {this.props.collapse ? (
                    <LogoutButton
                      onClick={() => {
                        this.handleLogout();
                      }}
                    >
                      Logout
                    </LogoutButton>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </div> */}
          </div>
        </div>
        {this.state.logout ? (
          <LogoutModel
            logout={this.state.logout}
            handleLogout={this.handleLogout}
          />
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  clickedItem: state.Layout.sidetab,
  collapse: state.Layout.collapse,
  downSide: state.Layout.downside,
  user: state.Login,
});
const mapDispatchToProps = dispatch => ({
  setSideItem: name => dispatch(setSidebarContent(name)),
  setDownSide: index => dispatch(setDownSide(index)),
  collpaseHanlder: () => dispatch(handleCollapse()),
  defaultSidebar: () => dispatch(defaultSidebar()),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Secondary)
);

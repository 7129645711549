import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  IconButton,
  InputBase,
  Modal,
  Stack,
} from "@mui/material";
import {
  BorderCard,
  BorderedCardActionArea,
  PrimaryButton,
  PrimaryIconButton,
  GreyTypography,
  PrimaryAvatar,
  BlackTypography,
  GrayDivider,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { resumeParser } from "helpers/resume";
import { applyForJobPost } from "helpers/services/jobs/jobs";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import React from "react";
import Dropzone from "react-dropzone";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ErrorHandlingToaster from "helpers/Toaster/error";
import toast from 'react-hot-toast';
import moment from "moment";

export default function AddPeople({ open, handleClose, setUpdated,fetchKanban,companyId }) {
  const { id: jobPostId } = useParams();
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  // const [resumeUrl,setResumeUrl] = React.useState("")
  const [loading, setLoading] = React.useState(false);
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  async function handleAcceptedFiles(files) {
    if (files.length === 1) {
    }
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        crop: false,
      })
    );
    setSelectedFiles(files);
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <Box style={{ ...style }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <BorderCard>
              <CardContent>
                <Stack
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  direction="row"
                >
                  <PrimaryTypography
                    className="mb-3"
                    component="h3"
                    variant="h3"
                  >
                    Upload Files
                  </PrimaryTypography>
                  <PrimaryIconButton>
                    <FeatherIcon icon="plus" size="14" />
                  </PrimaryIconButton>
                </Stack>

                <Dropzone
                  onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      <InputBase {...getInputProps()} />
                      <BorderedCardActionArea
                        {...getRootProps()}
                        sx={{ width: "100%" }}
                      >
                        <Stack spacing={2} alignItems={"center"}>
                          <FeatherIcon icon="upload-cloud" size="40" />
                          <BlackTypography variant="h6" component="h6">
                            Drop files here or
                          </BlackTypography>
                          <PrimaryButton>Select files to upload</PrimaryButton>
                          <GreyTypography variant="h6" component="h6">
                            Supported File types (20) MB
                          </GreyTypography>
                        </Stack>
                      </BorderedCardActionArea>
                    </>
                  )}
                </Dropzone>
              </CardContent>
            </BorderCard>
            {selectedFiles.map((f, i) => {
              return (
                <div
                  key={i + "-file"}
                  sx={{ padding: "10px 30px", marginRight: "10px" }}
                >
                  <Stack direction="row" justifyContent={"space-between"}>
                    <Stack spacing={2} direction={"row"} alignItems={"center"}>
                      <PrimaryAvatar alt={f.name} src={f.preview}>
                        {f.name?.slice(f.name?.lastIndexOf(".") + 1)}
                      </PrimaryAvatar>

                      <Stack alignItems="flex-start">
                        <p className="text-muted font-weight-bold">{f.name}</p>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Stack>
                    </Stack>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <PrimaryAvatar>H</PrimaryAvatar>
                      <GreyTypography variant="h6" component="h6">
                        2023-11-19
                      </GreyTypography>
                      <GreyTypography variant="h6" component="h6">
                        17:49
                      </GreyTypography>
                      <IconButton>
                        <FeatherIcon icon="more-vertical" size="14" />
                      </IconButton>
                    </Stack>
                  </Stack>
                  <GrayDivider sx={{ marginTop: "10px" }} />
                </div>
              );
            })}
        <PrimaryButton
          sx={{
            width: "100px",
            marginLeft: "auto",
            marginTop: "10px",
            marginRight: "10px",
          }}
          onClick={async () => {
            setLoading(true);
            const file = selectedFiles[0];
            const resumeUrl = await getPreSignedURL(selectedFiles)
            const { status, data: resumeData } = await resumeParser(
              file,
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ilh5Zyt4flUlPklQU1MtO2oiLCJpYXQiOjE2NzAyMzY4NzN9.f86Y2GG0a9eQENcKPpfB7tZHNsNFWvuME9WbqPBfonU"
            );
            const res = await applyForJobPost(
              {
                first_name:
                  resumeData?.ResumeParserData?.Name?.FirstName !== ""
                    ? resumeData?.ResumeParserData?.Name?.FirstName
                    : "N/A",
                last_name:
                  resumeData?.ResumeParserData?.Name?.LastName !== ""
                    ? resumeData?.ResumeParserData?.Name?.LastName
                    : "N/A",
                location: "N/A",
                email:
                  resumeData?.ResumeParserData?.Email[0].EmailAddress !== ""
                    ? resumeData?.ResumeParserData?.Email[0].EmailAddress
                    : "notfound@zepul.com",
                phone_number: "+918877666789",
                dob:
                  resumeData?.ResumeParserData?.DateOfBirth !== ""
                    ? moment(resumeData?.ResumeParserData?.DateOfBirth,"DD MM YYYY").format()
                    : new Date(),
                work_exp: [
                  {
                    employer: "",
                    title: "",
                    start_date: "",
                    end_date: "",
                  },
                ],

                courses: [
                  {
                    institute: "",
                    certificate: "",
                    Specialization: "",
                    start_date: "",
                    end_date: "",
                  },
                ],
                skills:
                  resumeData?.ResumeParserData?.skills?.length > 0
                    ? resumeData?.ResumeParserData?.skills.map(
                        item => item.value
                      )
                    : [],
                resume: [resumeUrl],
                
              },
              
              jobPostId,
              companyId
            );
           
            handleClose();
            setLoading(false);
            setSelectedFiles([]);
            if (res.status === 200 || res.status === 201) {
              setUpdated(pre=>!pre)
              toast.success(res?.data?.message);
            }else {
              ErrorHandlingToaster(res)
            }
          }}
        >
          Submit
        </PrimaryButton>
          </>
        )}
      </Box>
    </Modal>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  padding: "10px 0px",
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
  justifyContent: "space-between",
  flexDirection: "column",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: "8px",
  background: "white",
  bgcolor: "white",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BlackTypography,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  SecondaryFilledButton,
  PrimaryCheckbox,
  PrimaryAutoComplete,
  autocompleteProps,
  MultipleSelectInputs,
  PrimaryOutlinedButton
} from "assets/Mui/globalTheme";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import {
  useForm,
  FormProvider,
  useFieldArray,
  useFormContext,
  Controller,
} from "react-hook-form";
import GoogleLocation from "components/Common/Forms/location";
import GoogleMaterialInputLocation from "components/Common/Forms/InputLocation";
import GoogleExperienceInputLocation from "components/Common/Forms/ExperienceLocation";
import UploadModel from "components/Common/models/uploadModel";
import {
  FormControl,
  Grid,
  Stack,
  Select,
  MenuItem,
  IconButton,
  createFilterOptions,
  Checkbox,
} from "@mui/material";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {ContentState, EditorState, convertToRaw } from "draft-js";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import {
  createKeySkills,
  getAllKeySkills,
  getAllKeySkillsWithStatus,
} from "helpers/services/jobAttributes/keyskills";

import { v4 as uuid } from "uuid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  height: 500,
  overflow: "auto",
};
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import htmlToDraft from "html-to-draftjs";
import { toast } from "react-hot-toast";
import { imageURL } from "common/constants/commonURLS";

export default function AddEditWorkExperience({
  type,
  open,
  currentData,
  toggleModel,
  updateOrEditWorExperience,
}) {
  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   getValues,
  //   setValue,
  //   reset,
  //   control,
  //   formState: { errors },
  // } = useForm({
  //   defaultValues: {
  //     skill: [{ skillName: "", experienceyears: "",experiencemonths:"" }],
  //   },
  // });

  // const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
  //   {
  //     control,
  //     name: "skill",
  //   }
  // );

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      skill: [{ skillName: "", experienceyears: "" }],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "skill",
    });

    const [model, setModel] = useState(false);
    // const [companyImageUrl, setCompanyImageUrl] = useState(false);
    // const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // const [exactEdit,setExactEdit] = useState(null)
   

  // const handleEditorData = e => {
  //   setValue("description", e);
  //   setValue(
  //     "desc",
  //     draftToHtml(convertToRaw(watch("description").getCurrentContent()))
  //   );
  // };

  const [skillLoader, setSkillLoader] = useState(true);
  const [keySkills, setKeySkills] = useState([]);
  React.useEffect(()=>{
    getKeySKills()
  },[])

  async function getKeySKills() {
    const { data, status } = await getAllKeySkillsWithStatus();
    setKeySkills(
      data?.map(item => ({
        name: item?.name,
        value: item?.id,
        isActive: item?.is_active,
        id: item?.id,
      }))
    );
    if (status === 200) {
      setSkillLoader(false);
    }
  }

  const filter = createFilterOptions();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
      },
    },
  };

  const [checked, setChecked] = React.useState(false);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  const experience = [
    { name: "0", value: "0" },
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7", value: "7" },
    { name: "8", value: "8" },
    { name: "9", value: "9" },
    { name: "10+", value: "10+" },
  ];

  /* Image upload code start here */
  function imageModel() {
    setModel(!model);
    // initialFetch();
  }

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    toast.success("uploaded");
    setValue("image", imageURL+url);
    // setCompanyImageUrl(!companyImageUrl);
    setTimeout(() => {
      imageModel();
    }, 1000);
  };
  /* Image upload code End here */
  
  // function JobDescription(data) {
  //   const contentBlock = htmlToDraft(data);
  //     if (contentBlock) {
  //       const contentState = ContentState.createFromBlockArray(
  //         contentBlock.contentBlocks
  //       );
  //       const editorState = EditorState.createWithContent(contentState);
  //       return editorState
  //     }
  // }

  React.useEffect(() => {
    if (type?.includes("update")) {
      setValue("title", currentData.title);
      setValue("employer", currentData.employer);
      setValue("start_date", currentData.start_date);
      setValue("end_date", currentData.end_date);
      setValue("skill", currentData?.skill)
      setValue("location", currentData.location)
      setValue("desc", (currentData?.desc))
      setValue("image",currentData?.image)
      // setExactEdit(currentData?.desc)
      setChecked(currentData.is_currently_working || false)
      setValue("notice_period",currentData.notice_period)
    } 
  }, [type]);
// console.log(currentData,exactEdit);
  function findSelectType(type) {
    switch (type) {
      case "noticeperiod":
        return [
          { name: "Immediately", value: "Immediately" },
          { name: "10 Days", value: "10 Days" },
          { name: "20 Days", value: "20 Days" },
          { name: "30 Days", value: "30 Days" },
          { name: "40 Days", value: "40 Days" },
          { name: "50 Days", value: "50 Days" },
          { name: "60 Days", value: "60 Days" },
          { name: "70 Days", value: "70 Days" },
          { name: "80 Days", value: "80 Days" },
          { name: "90 Days", value: "90 Days" },
          { name: "100 Days", value: "100 Days" },
          { name: "110 Days", value: "110 Days" },
          { name: "120 Days", value: "120 Days" },
          { name: "120+ Days", value: "120+ Days" },
        ];

      case "experienceSection":
        return [
          { name: "0", value: "0" },
          { name: "1", value: "1" },
          { name: "2", value: "2" },
          { name: "3", value: "3" },
          { name: "4", value: "4" },
          { name: "5", value: "5" },
          { name: "6", value: "6" },
          { name: "7", value: "7" },
          { name: "8", value: "8" },
          { name: "9", value: "9" },
          { name: "10+", value: "10+" },
        ];

      case "experiencemonths":
        return [
          { name: "1", value: "1" },
          { name: "2", value: "2" },
          { name: "3", value: "3" },
          { name: "4", value: "4" },
          { name: "5", value: "5" },
          { name: "6", value: "6" },
          { name: "7", value: "7" },
          { name: "8", value: "8" },
          { name: "9", value: "9" },
          { name: "10", value: "10" },
          { name: "11", value: "11" },
        ];
      default:
        [{ name: "", value: "" }];
    }
  }

  return (
    <>
      {model && (
        <UploadModel
          isOpen={model}
          toggle={imageModel}
          handleImage={handleImageUpload}
        />
      )}
    <div>
      <Modal
        open={open}
        onClose={() => {
          toggleModel();
          if(!type?.includes("update"))
          reset();
        }}
        aria-labelledby="edit-profile-modal-title"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          <BlackTypography
            variant="h2"
            sx={{ marginBottom: "10px", position: "sticky" }}
          >
            {type === "create" ? "Create" : "Edit"} Work Experience
          </BlackTypography>
          <form onSubmit={handleSubmit((data)=>{updateOrEditWorExperience(data,checked)})}>
            <Grid
              container
              spacing={2}
              sx={{ height: "90%", overflow: "auto" }}
            >
              <Grid item md={12} lg={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-title-outlined"
                    error={errors.titile}
                  >
                    Job Title
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    fullWidth
                    placeholder="Enter your title"
                    {...register("title", { required: true })}
                    id="wor-exp-title-outlined"
                    helperText={errors?.title ? "email is required" : ""}
                    autoFocus
                    label="Job Title"
                    type={"text"}
                    name="title"
                    error={errors.title}
                    variant="outlined"
                  />
                  <PrimaryNormalFormHelperText error={errors.title}>
                    {errors.title ? "title is required Field" : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={12} lg={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-employer-outlined"
                    error={errors.titile}
                  >
                    company Name
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    fullWidth
                    placeholder="Enter your employer"
                    {...register("employer", { required: true })}
                    id="wor-exp-employer-outlined"
                    helperText={errors?.employer ? "email is required" : ""}
                    autoFocus
                    label="Job employer"
                    type={"text"}
                    name="employer"
                    error={errors.employer}
                    variant="outlined"
                  />
                  <PrimaryNormalFormHelperText error={errors.employer}>
                    {errors.employer ? "employer is required Field" : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>

              <Grid item md={12} lg={12} sm={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    {!watch("image") ? (
                      <PrimaryOutlinedButton
                        sx={{ maxWidth: "220px" }}
                        onClick={() => {
                          imageModel();
                        }}
                        startIcon={<FeatherIcon icon="edit" size="14" />}
                      >
                        Company image
                      </PrimaryOutlinedButton>
                    ) : (
                        <>
                          <PrimaryNormalInputs
                            fullWidth
                            placeholder="Enter your employer"
                            {...register("image", { required: true })}
                            id="wor-exp-employer-outlined"
                            // helperText={errors?.employer ? "email is required" : ""}
                            autoFocus
                            value={watch("image")}  
                            label="Company Image"
                            type={"text"}
                            name="employer"
                            readOnly
                            // error={errors.employer}
                            variant="outlined"
                          />
                        <PrimaryOutlinedButton
                        sx={{ maxWidth: "220px",marginBlockStart:"12px" }}
                        onClick={() => {
                          imageModel();
                        }}
                        startIcon={<FeatherIcon icon="edit" size="14" />}
                      >
                        upload image
                      </PrimaryOutlinedButton>
                        </>
                    )}
                  </FormControl>
              </Grid>

              <Grid item md={12} lg={12}>
                <FormControl sx={{ width: "100%" }}>
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-start_date-outlined"
                    error={errors.location}
                    sx={{ marginLeft: "-15px" }}
                  >
                    Location
                  </PrimaryNormalInputLabel>
                  <FormProvider
                    register={register}
                    watch={watch}
                    errors={errors}
                    formSetValue={setValue}
                  >
                    <Stack
                      direction="row"
                      spacing={"1"}
                      sx={{ marginTop: "8px" }}
                    >
                      <GoogleExperienceInputLocation updatingContent="location" />
                    </Stack>
                  </FormProvider>
                </FormControl>
              </Grid>

              <Grid item md={12} lg={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-start_date-outlined"
                    error={errors.titile}
                  >
                    Start Date
                  </PrimaryNormalInputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      disableFuture={true}
                      label="start Date"
                      value={watch("start_date")}
                      onChange={newValue => {
                        setValue("start_date", newValue);
                      }}
                      renderInput={params => (
                        <PrimaryNormalInputs
                          {...params}
                          error={errors?.start_date}
                          {...register("start_date", { required: true })}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item md={12} lg={12}>
                <Stack
                  direction="row"
                  justifyContent=""
                  alignItems="center"
                  spacing={1}
                >
                  <PrimaryCheckbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <BlackTypography>
                    I am currently working in this role
                  </BlackTypography>
                </Stack>
              </Grid>
              {!checked ? (
                <Grid item md={12} lg={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="work-exp-end_date-outlined"
                      error={errors.titile}
                    >
                      End Date
                    </PrimaryNormalInputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label="end Date"
                        value={watch("end_date")}
                        onChange={newValue => {
                          setValue("end_date", newValue);
                        }}
                        renderInput={params => (
                          <PrimaryNormalInputs
                            {...params}
                            error={errors?.end_date}
                            {...register("end_date", { required: true })}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item md={12} lg={12}>
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-employer-outlined"
                    error={errors.titile}
                  >
                    Notice Period
                  </PrimaryNormalInputLabel>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <Stack direction="row" spacing={"1"}>
                      <Select
                        // labelId={notice_period}
                        // id={update_type}
                          {...register("notice_period", { required: false })}
                          value={watch("notice_period")}
                        input={
                          <PrimaryNormalInputs
                            sx={{ width: "100%" }}
                            // label="signup-user-type"
                            // error={errors[`${noticeperiod}`]}
                            {...register("notice_period", { required: false })}
                          />
                        }
                        MenuProps={MenuProps}
                      >
                        {findSelectType("noticeperiod")?.map(item => {
                          return (
                            <MenuItem
                              key={item?.value}
                              value={item?.value} // style={getStyles(name, personName, theme)}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Stack>
                  </FormControl>
                </Grid>
              )}
              <Grid item md={12} lg={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="folloups-about-outlined"
                    error={errors.about}
                  >
                    Description
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    fullWidth
                    multiline
                    placeholder="Enter about your company"
                    {...register("desc", { required: true})}
                    id="login-about-outlined"
                    autoFocus
                    // label="about address"
                    type={"text"}
                    error={errors.desc}
                    variant="outlined"
                    rows={3}
                    value={watch("desc")}
                  />
                  {/* <PrimaryNormalFormHelperText error={errors.description}>
                    {errors?.about
                      ? "about is a required field with minimun 150 chars"
                      : "describe about your company so that we can find better profiles for you."}
                  </PrimaryNormalFormHelperText> */}
                </FormControl>
              </Grid>
              {/* <Grid item md={12} lg={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="folloups-about-outlined"
                    error={errors.about}
                  >
                    Description
                  </PrimaryNormalInputLabel>

                  <div
                    style={{
                      ...(errors?.description
                        ? {
                            border: "0.5px solid red",
                            borderRadius: "8px",
                            padding: "10px",
                            minHeight: "250px",
                          }
                        : {
                            border: "0.5px solid gray",
                            borderRadius: "8px",
                            padding: "10px",
                            minHeight: "250px",
                          }),
                      marginTop: "23px",
                    }}
                  >
                    <Editor
                      editorState={watch("description")}
                      onEditorStateChange={handleEditorData}
                    />
                  </div>
                </FormControl>
              </Grid> */}

              <Grid item xs={12} md={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="question-outlined"
                    error={errors.question}
                  >
                    Skill & Experience
                  </PrimaryNormalInputLabel>
                  <Stack spacing={2}>
                    {fields.map((field, index) => {
                      return (
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems={"center"}
                          key={field.id}
                        >
                          <Controller
                            render={props => {
                              return (
                                <PrimaryAutoComplete
                                  sx={{ width: "100%" }}
                                  {...props}
                                  // disableCloseOnSelect
                                  componentsProps={autocompleteProps}
                                  multiple={false}
                                  // loading={skillLoader}
                                  // loaderText="Fetching Skills"
                                  options={keySkills}
                                  renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                      <SecondaryFilledChip
                                        deleteIcon={
                                          <FeatherIcon icon={"x"} size="14" />
                                        }
                                        label={option.name}
                                        {...getTagProps({ index })}
                                      />
                                    ))
                                  }
                                  renderOption={(props, option, { selected }) =>
                                    option?.isActive ? (
                                      <li {...props} key={props.id}>
                                        <Checkbox
                                          icon={
                                            <FeatherIcon
                                              icon="square"
                                              size="14"
                                            />
                                          }
                                          checkedIcon={
                                            <FeatherIcon
                                              icon="check-square"
                                              size="14"
                                            />
                                          }
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option.name}
                                      </li>
                                    ) : null
                                  }
                                  getOptionLabel={option => option?.name || ""}
                                  renderInput={params => (
                                    <FormControl
                                      variant="standard"
                                      sx={{ width: "100%" }}
                                    >
                                      <MultipleSelectInputs
                                        sx={{
                                          ...(errors.skillName && {
                                            borderColor: "red",
                                          }),
                                        }}
                                        error={
                                          errors[`skill.${index}.skillName`]
                                        }
                                        variant="standard"
                                        ref={params.InputProps.ref}
                                        {...params}
                                      />
                                    </FormControl>
                                  )}
                                  // filterOptions={(options, params) => {
                                  //   const filtered = filter(options, params);
                                  //   const { inputValue } = params;
                                  //   const isExisting = options.some(
                                  //     option => inputValue === option.name
                                  //   );
                                  //   if (inputValue !== "" && !isExisting) {
                                  //     filtered.push({
                                  //       inputValue,
                                  //       isActive: true,
                                  //       id: uuid(),
                                  //       name: `${inputValue}`,
                                  //     });
                                  //   }
                                  //   return filtered;
                                  // }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                  value={getValues(`skill.${index}.skillName`)}
                                  onChange={async (_, data) => {
                                    // const lastIndex = data?.length - 1;
                                    // if (data && data?.inputValue) {
                                    //   const { data: skill } =
                                    //     await createKeySkills({
                                    //       name: data?.inputValue,
                                    //       is_active: true,
                                    //     });
                                    //   await getKeySKills();
                                    //   props.field.onChange(
                                    //     {
                                    //       name: skill.key_skill_res.name,
                                    //       value: skill.key_skill_res.id,
                                    //       isActive:skill.key_skill_res.is_active
                                    //     }
                                    //   );
                                    // } else {
                                    //   props.field.onChange(data);
                                    // }
                                    props.field.onChange(data);
                                  }}
                                />
                              );
                            }}
                            rules={{ required: true }}
                            name={`skill.${index}.skillName`}
                            control={control}
                          />


                          <FormControl
                            sx={{ width: "60%"}}
                            variant="standard"
                          >
                            <PrimaryNormalInputLabel
                              shrink
                              htmlFor="work-exp-start_date-outlined"
                              // error={errors.titile}
                            >
                              Years
                            </PrimaryNormalInputLabel>
                            <Controller
                            render={({ field }) => (        
                            <Select
                            {...field}
                              labelId="experienceyears"
                              id="experienceyears"
                              input={
                                <PrimaryNormalInputs
                                  label="signup-user-type"
                                  error={errors[`skill.${index}.experienceyears`]}
                                  {...register(`skill.${index}.experienceyears`, {
                                    required: true,
                                  })}
                                 
                                />
                              }
                              MenuProps={MenuProps}
                            >
                              {findSelectType("experienceSection")?.map(
                                item => (
                                  <MenuItem
                                    key={item?.value}
                                    value={item?.value}
                                  >
                                    {item?.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                              )}
                              name={`skill.${index}.experienceyears`}
                              control={control}
                            />

                      
                          </FormControl>
                          

                          <FormControl sx={{ width: "60%" }} variant="standard">
                            <PrimaryNormalInputLabel
                              shrink
                              htmlFor="work-exp-start_date-outlined"
                              // error={errors.titile}
                            >
                              Months
                            </PrimaryNormalInputLabel>
                            <Controller
                            render={({ field }) => (        
                            <Select
                            {...field}
                              labelId="experiencemonths"
                              id="experiencemonths"
                              input={
                                <PrimaryNormalInputs
                                  label="signup-user-type"
                                  error={errors[`skill.${index}.experiencemonths`]}
                                  {...register(`skill.${index}.experiencemonths`, {
                                    required: true,
                                  })}
                                 
                                />
                              }
                              MenuProps={MenuProps}
                            >
                              {findSelectType("experienceSection")?.map(
                                item => (
                                  <MenuItem
                                    key={item?.value}
                                    value={item?.value}
                                  >
                                    {item?.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                              )}
                              name={`skill.${index}.experiencemonths`}
                              control={control}
                            />
                          </FormControl>

                          <IconButton
                            onClick={() =>
                              append({
                                skillName: "",
                                experienceyears: "",
                                experiencemonths,
                              })
                            }
                          >
                            <FeatherIcon icon="plus" size="14" />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              if (index !== 0) remove(index);
                            }}
                          >
                            <FeatherIcon icon="minus" size="14" />
                          </IconButton>
                        </Stack>
                      );
                    })}
                  </Stack>
                </FormControl>

                {/* <FormControl sx={{ width: "100%" }}>
                  <ul>
                    {fields.map((item, index) => {
                      return (
                        <li key={item.id}>
                          <input
                            {...register(`skill.${index}.skillName`, {
                              required: true,
                            })}
                          />

                          <Controller
                          render={({ field }) => <input {...field} />}
                          name={`skill.${index}.experienceyears`}
                          control={control}
                        />

                          <Controller
                            render={({ field }) => (
                              <select {...field}>
                                {experience.map(option => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            )}
                            name={`skill.${index}.experienceyears`}
                            control={control}
                          />

                          <Controller
                            render={({ field }) => (        
                            <Select
                            {...field}
                              labelId="experienceyears"
                              id="experienceyears"
                              input={
                                <PrimaryNormalInputs
                                  label="signup-user-type"
                                  error={errors[`skill.${index}.experienceyears`]}
                                  {...register(`skill.${index}.experienceyears`, {
                                    required: true,
                                  })}
                                 
                                />
                              }
                              MenuProps={MenuProps}
                            >
                              {findSelectType("experienceSection")?.map(
                                item => (
                                  <MenuItem
                                    key={item?.value}
                                    value={item?.value}
                                  >
                                    {item?.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                              )}
                              name={`skill.${index}.experienceyears`}
                              control={control}
                            />

                        <Controller
                            render={({ field }) => (        
                            <Select
                            {...field}
                              labelId="experiencemonths"
                              id="experiencemonths"
                              input={
                                <PrimaryNormalInputs
                                  label="signup-user-type"
                                  error={errors[`skill.${index}.experiencemonths`]}
                                  {...register(`skill.${index}.experiencemonths`, {
                                    required: true,
                                  })}
                                 
                                />
                              }
                              MenuProps={MenuProps}
                            >
                              {findSelectType("experienceSection")?.map(
                                item => (
                                  <MenuItem
                                    key={item?.value}
                                    value={item?.value}
                                  >
                                    {item?.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                              )}
                              name={`skill.${index}.experiencemonths`}
                              control={control}
                            />

                        </li>
                      );
                    })}
                  </ul>
                </FormControl> */}
              </Grid>

              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Stack spacing={1} direction="row">
                  <SecondaryFilledButton
                    onClick={() => {
                      toggleModel();
                    }}
                  >
                    cancel
                  </SecondaryFilledButton>
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </Stack>{" "}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
    </>
  );
}
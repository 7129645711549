import { DELETE, POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";
import { imageURL } from "common/constants/commonURLS";
import moment from "moment";

function jobPost(data) {
  const dummyData = [
    {
      id: "557badea-9519-46d1-8a62-e70e14292fae",
      createdAt: "2024-01-02T18:34:57.230Z",
      updatedAt: "2024-01-02T18:34:57.230Z",
      info: {
        company_details: {
          id: "a41de024-7da8-40f1-9d4b-74745febb344",
          createdAt: "2023-12-15T05:04:43.265Z",
          updatedAt: "2023-12-19T10:00:42.883Z",
          company_name: "Joinee",
          company_image: null,
          tagline: "Innovate. Elevate. Your Future, Our Passion.",
          size_of_company: "1-50",
          company_type: "Startup",
          about:
            "Discover a new standard of excellence with Joinee, where we're dedicated to making a positive impact through our commitment to excellence and customer satisfaction.",
          location: "India",
          website: null,
          linkedin_url: "https://www.linkedin.com/company/tryjoinee/",
          facebook_url: null,
          twitter_url: null,
          glassdoors_url: null,
          ambition_box_url: null,
          comparably_url: null,
          careerbliss_url: null,
          payscale_url: null,
          google_reviews: null,
          perks: ["Meals", "Health Care", "Remote", "Vacation", "Wellness"],
          describe_your_culture:
            "At Joinee, we prioritize a culture of integrity and passion, where dedication to our mission is met with support, camaraderie, and a shared commitment to excellence.",
          culture_media_images: null,
          culture_media_video_url: null,
          related_to_admin: false,
          related_to_company: true,
          related_to_screening_partner: false,
          related_to_hiring_agency: false,
          is_job_post_verifier_assigned: false,
          is_active: true,
          job_post_verifier_id: null,
        },
        job_post_details: {
          id: "fe0d18c5-4f16-4492-80a2-6030e64944ea",
          is_job_post_verified: false,
          is_commission_added: false,
          is_commission_visible_to_js: false,
          time_zone: [],
          currency: "INR",
          key_skills: ["8f046750-7bd0-44f0-9380-2945e3f61593"],
          max_exp_months: "0",
          min_exp_months: "0",
          job_title: "Golang",
          job_type: "Full_Time",
          work_mode: "On_site",
          payout: "Bi_Weekly",
          hours_per_week: "less_than_30",
          project_duration: "0",
          min_sal: 1000000,
          max_sal: 3000000,
          min_exp: "1",
          max_exp: "3",
          location: "Hyderabad, Telangana, India",
          state: "Telangana",
          country: "India",
          city: "Telangana",
          job_level_id: "17229292-fde2-4cf1-9b53-9192bac2fc5c",
          no_of_vacancies: "1",
          application_deadline: "2024-01-31T17:52:00.000Z",
          job_desc:
            '<ul>\n<li style="margin-left:0px;"><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: var(--font-family,"sans-serif");">Provide Salesforce technical leadership for a team of developers, admins, and analysts inhouse as well as external partners.</span></li>\n<li style="margin-left:0px;"><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: var(--font-family,"sans-serif");">Deep and extensive knowledge, and experience of SFDC technologies including apex, lightening components, SOQL, triggers, batch apex, visual force, lightening components and lightening UI.</span></li>\n<li style="margin-left:0px;"><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: var(--font-family,"sans-serif");">Salesforce Architect level expertise and relevant experience.</span></li>\n</ul>\n',
          requirements:
            '<ul>\n<li style="margin-left:0px;"><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: var(--font-family,"sans-serif");">Provide Salesforce technical leadership for a team of developers, admins, and analysts inhouse as well as external partners.</span></li>\n<li style="margin-left:0px;"><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: var(--font-family,"sans-serif");">Deep and extensive knowledge, and experience of SFDC technologies including apex, lightening components, SOQL, triggers, batch apex, visual force, lightening components and lightening UI.</span></li>\n<li style="margin-left:0px;"><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: var(--font-family,"sans-serif");">Salesforce Architect level expertise and relevant experience.</span></li>\n</ul>\n',
          commission_rate_type: "Percentage",
          commission_rate: 14.3,
          functional_areas: ["75260a65-4cd8-443a-8002-14faed704cd7"],
          job_roles: ["5f9a7ac0-bfc1-4a96-842e-cffa68e9557b"],
          is_active: true,
          created_by_id: "2ddc154b-676d-40bf-a36d-531a674b02a7",
          company_id: "a41de024-7da8-40f1-9d4b-74745febb344",
          min_sal_by_am: 1000000,
          max_sal_by_am: 3000000,
          updatedAt: "2024-01-02T18:34:56.802Z",
          createdAt: "2024-01-02T18:34:56.802Z",
          attachment: null,
        },
      },
      notification_type: "job post",
      is_seen: false,
      is_active: true,
      sender: "2ddc154b-676d-40bf-a36d-531a674b02a7",
      receiver: "e8395ba6-7ad8-4c70-8441-0ee25563e2cb",
    },
    {
      id: "e5d752c4-1695-4b5d-87f6-ea7218912c93",
      createdAt: "2024-01-03T05:12:52.410Z",
      updatedAt: "2024-01-03T05:12:52.410Z",
      info: {
        company_details: {
          id: "a41de024-7da8-40f1-9d4b-74745febb344",
          createdAt: "2023-12-15T05:04:43.265Z",
          updatedAt: "2023-12-19T10:00:42.883Z",
          company_name: "Joinee",
          company_image: null,
          tagline: "Innovate. Elevate. Your Future, Our Passion.",
          size_of_company: "1-50",
          company_type: "Startup",
          about:
            "Discover a new standard of excellence with Joinee, where we're dedicated to making a positive impact through our commitment to excellence and customer satisfaction.",
          location: "India",
          website: null,
          linkedin_url: "https://www.linkedin.com/company/tryjoinee/",
          facebook_url: null,
          twitter_url: null,
          glassdoors_url: null,
          ambition_box_url: null,
          comparably_url: null,
          careerbliss_url: null,
          payscale_url: null,
          google_reviews: null,
          perks: ["Meals", "Health Care", "Remote", "Vacation", "Wellness"],
          describe_your_culture:
            "At Joinee, we prioritize a culture of integrity and passion, where dedication to our mission is met with support, camaraderie, and a shared commitment to excellence.",
          culture_media_images: null,
          culture_media_video_url: null,
          related_to_admin: false,
          related_to_company: true,
          related_to_screening_partner: false,
          related_to_hiring_agency: false,
          is_job_post_verifier_assigned: false,
          is_active: true,
          job_post_verifier_id: null,
        },
        job_post_details: {
          id: "52aa696a-8f59-4bf6-b278-917225e2e806",
          is_job_post_verified: false,
          is_commission_added: false,
          is_commission_visible_to_js: false,
          time_zone: [],
          currency: "INR",
          key_skills: ["8f046750-7bd0-44f0-9380-2945e3f61593"],
          max_exp_months: "0",
          min_exp_months: "0",
          job_title: "Erlang",
          job_type: "Full_Time",
          work_mode: "On_site",
          payout: "Bi_Weekly",
          hours_per_week: "less_than_30",
          project_duration: "0",
          min_sal: 1000000,
          max_sal: 3000000,
          min_exp: "1",
          max_exp: "3",
          location: "Hyderabad, Telangana, India",
          state: "Telangana",
          country: "India",
          city: "Telangana",
          job_level_id: "17229292-fde2-4cf1-9b53-9192bac2fc5c",
          no_of_vacancies: "1",
          application_deadline: "2024-01-31T17:52:00.000Z",
          job_desc:
            '<ul>\n<li style="margin-left:0px;"><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: var(--font-family,"sans-serif");">Provide Salesforce technical leadership for a team of developers, admins, and analysts inhouse as well as external partners.</span></li>\n<li style="margin-left:0px;"><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: var(--font-family,"sans-serif");">Deep and extensive knowledge, and experience of SFDC technologies including apex, lightening components, SOQL, triggers, batch apex, visual force, lightening components and lightening UI.</span></li>\n<li style="margin-left:0px;"><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: var(--font-family,"sans-serif");">Salesforce Architect level expertise and relevant experience.</span></li>\n</ul>\n',
          requirements:
            '<ul>\n<li style="margin-left:0px;"><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: var(--font-family,"sans-serif");">Provide Salesforce technical leadership for a team of developers, admins, and analysts inhouse as well as external partners.</span></li>\n<li style="margin-left:0px;"><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: var(--font-family,"sans-serif");">Deep and extensive knowledge, and experience of SFDC technologies including apex, lightening components, SOQL, triggers, batch apex, visual force, lightening components and lightening UI.</span></li>\n<li style="margin-left:0px;"><span style="color: rgb(71,77,106);background-color: rgb(255,255,255);font-size: 14px;font-family: var(--font-family,"sans-serif");">Salesforce Architect level expertise and relevant experience.</span></li>\n</ul>\n',
          commission_rate_type: "Percentage",
          commission_rate: 14.3,
          functional_areas: ["75260a65-4cd8-443a-8002-14faed704cd7"],
          job_roles: ["5f9a7ac0-bfc1-4a96-842e-cffa68e9557b"],
          is_active: true,
          created_by_id: "2ddc154b-676d-40bf-a36d-531a674b02a7",
          company_id: "a41de024-7da8-40f1-9d4b-74745febb344",
          min_sal_by_am: 1000000,
          max_sal_by_am: 3000000,
          updatedAt: "2024-01-03T05:12:51.980Z",
          createdAt: "2024-01-03T05:12:51.980Z",
          attachment: null,
        },
      },
      notification_type: "job post",
      is_seen: false,
      is_active: true,
      sender: "2ddc154b-676d-40bf-a36d-531a674b02a7",
      receiver: "e8395ba6-7ad8-4c70-8441-0ee25563e2cb",
    },
  ];

  const jobpostDetails = data?.map(each => {
    const formattedTime = moment(each?.updatedAt).format("ddd, DD YYYY");
    // if(!each?.is_job_post_verified){
    return {
      id: each?.id,
      role: each?.info?.job_post_details?.job_title,
      title: each?.info?.company_details?.company_name,
      image: `${imageURL}${each?.info?.company_details?.company_image}`,
      // time:each?.updatedAt,
      time: formattedTime,
      key: "to be approve",
      message: `${each?.info?.message ?? ""}`,
    };
    // }
  });
  return jobpostDetails;
}

export const getAllNotifications = async (page,pageSize,search) => {
  const { data, status } = await callApi(`common/notifications_list?searchParam&pageNo=${page}&pageSize=${pageSize}`);

  const finalData = {
    jobPostTweakData: jobPost(data?.data_payload),
    count: data?.data?.count,
    // count: 2,
  };
  //  const jobPostTweakData = jobPost(data?.data?)
  return finalData
};

export const getAllNotificationCount = async()=>{
  const { data, status } = await callApi(`common/unread_notifications_list?searchParam&pageNo=1&pageSize=10`);

  const finalData = {
    count: data?.overall_count,

  };

  return finalData
}

export const getAllNotificationsSettings = async () => {
  const { data, status } = await callApi(`common/notification_settings`);
  return data;
};

export const updateNotifyInAppSettings = async (data, userId) => {
 
  const response = await callApi(`common/update_all_notification_settings/${userId}`, data, PUT);
  return response
};

export const updateIndividualNotifyInAppSettings = async (data, id) => {
  await callApi(`common/notification_settings/${id}`, data, PUT);
};

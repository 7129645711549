import * as React from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { getAllReferalParterns,getRefsByCompany } from "helpers/services/referalPartner";
import { imageURL } from "common/constants/commonURLS";
import {
  GreyTypography,
  PrimaryButton,
  PrimaryButtonForTable,
  PrimaryDataGrid,
  PrimarySwitch,
  SmallPrimaryAvatar,
  SmallSizeChip,
} from "assets/Mui/globalTheme";
import TablePopper from "components/table/header/popper";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import { KeySkillOperator } from "components/table/operators/keySkillOperator";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PrimaryChip } from "assets/Mui/globalTheme";
import SkillViewModal from "helpers/Modals/SkillViewModal";
import { StyledGridOverlay } from "assets/Mui/globalTheme";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import { IconButton, Pagination, Stack } from "@mui/material";
import { getAllKeySkillsWithStatus } from "helpers/services/jobAttributes/keyskills";
import { CA, CS, ZA } from "common/constants/userModules";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import RPModel from "./addReferalModal";
import ClipBoardData from "helpers/dashboard/clipboard";
import { useLocation } from "react-router-dom";

export default function ReferalPartnerTable() {
  const [referalPartners, setReferalPartners] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openSkillModal, setOpenSkillModal] = React.useState(false);
  const [modalName, setModalName] = React.useState("");
  const [page, setPage] = React.useState(1)
  const [totalCount,setTotalCount] = React.useState("")
  const rowsPerPage = 20;
  const offset = (page - 1) * rowsPerPage;
  const loginStore = JSON.parse(localStorage.getItem("authUser"));
  const [viewAddModal, setViewAddModal] = React.useState(false)
  const [update,setUpdate] = React.useState(false)
  const [refetch,setRefetch] = React.useState(false)

  const {search} = useLocation()
  const history = useHistory();

  const dispatch = useDispatch();
  React.useEffect(() => {
    initialFetch(page,rowsPerPage);
  }, [update]);

  React.useEffect(()=>{
    const searchParams = new URLSearchParams(search)
    searchParams.set("page",page)
    history.push({
      search: searchParams.toString(),
    });
    initialFetch(page,rowsPerPage);
  },[page])



  async function initialFetch(page,rowsPerPage) {
    setLoading(true);
    try {
      if (![CA, CS].includes(loginStore.role)) {
        const { data, status } = await getAllReferalParterns("referral",page,rowsPerPage);
        setTotalCount(Math.ceil(data?.count /rowsPerPage))
        const result = data?.data?.length > 0 &&  data?.data?.map((item, index) => ({
          id: item?.id,
          phone_number: item?.phone_number,
          image: `${imageURL}${item?.image}`,
          email: item?.email,
          location: `${item?.location ? item.location : "N/A"}`,
          jobs_applied: item?.get_applied_job_post_counts?.count,
          resume: item?.resume,
          name: `${item?.first_name} ${item?.last_name}`,
          experience: `${item?.experience ? item.experience+"Yrs" : "N/A"}`,
          highest_education: "1d",
          skills: `${
            item?.skill_details?.length > 0
              ? item?.skill_details?.map(item => item?.name)?.join(",")
              : "N/A"
          }`,
          // skills: item?.skills?.map(item => item?.name)?.join(","),
          gender: item?.gender?item.gender:"N/A",
          // candidate_referred: "1 d",
          // candidate_onboarded: "1d",
          // total_earnings: "1d",
          // available_earnings: "1d",
          // bank_account_details: "2d",
        }));
        if (status === 200) {
          setLoading(false);
        }
        setReferalPartners(result);
          return
        }
        const { data:res, status } = await getRefsByCompany(loginStore.company_id, page);
        setTotalCount(Math.ceil(res?.count/rowsPerPage))
        const data =res.rows
        const result = data?.length > 0 &&  data?.map((item, index) => ({
          id: item?.candidate_details?.id,
          phone_number: item?.candidate_details?.phone_number,
          image: `${imageURL}${item?.candidate_details?.image}`,
          email: item?.candidate_details?.email,
          location: `${item?.candidate_details?.location ? item?.candidate_details?.location : "N/A"}`,
          jobs_applied: item?.candidate_applied_job_posts_count,
          resume: item?.candidate_details?.resume,
          name: `${item?.candidate_details?.first_name} ${item?.candidate_details?.last_name}`,
          experience: `${item?.candidate_details?.experience ? item?.candidate_details?.experience+"Yrs" : "N/A"}`,
          gender: item?.candidate_details?.gender || "N/A",
          skills: `${
            item?.candidate_details?.skill_details?.length > 0
              ? item?.candidate_details?.skill_details?.map(item => item?.name)?.join(",")
              : "N/A"
          }`,
          // skills: `${
          //   item?.skill_details?.length > 0
          //     ? item?.skill_details?.map(item => item?.name)?.join(",")
          //     : "N/A"
          // }`,
          // skills: item?.skills?.map(item => item?.name)?.join(","),
          // gender: item?.gender?item.gender:"N/A",
          // candidate_referred: "1 d",
          // candidate_onboarded: "1d",
          // total_earnings: "1d",
          // available_earnings: "1d",
          // bank_account_details: "2d",
        }));
        if (status === 200) {
          setLoading(false);
        }
        setReferalPartners(result);
          
    } catch (error) {
      setLoading(false) 
    }
  }


  const handleCloseModal = () => {
    setOpenSkillModal(!openSkillModal);
  };
  const handleCellClick = params => {
    const field = params.field;
    if (field === "name") {
      
    }
    if (field === "skills") {
      if (params.row.skills !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.skills.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } else if (field === "Categories") {
      if (params.row.Categories !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.Categories.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } 
  };

  return (
    <div style={{ padding: "10px", height: "91vh",display:'flex',flexDirection:'column' }}>
      {[ZA].includes(loginStore.role) ?<PrimaryButton
        sx={{width:"max-content !important",ml:"auto !important"}}
        onClick={() => {
          // dispatch({ type: "APPROVE_MODAL" });
          setViewAddModal(true)
        }}
        // startIcon={(<FeatherIcon icon={"add"} size={16} />)}
      >
        Add
      </PrimaryButton>:null}
      <PrimaryDataGrid
        // onCellDoubleClick={event => {
        //   history.push(`/common-user/${event?.row?.id}`);
        // }}
        onCellClick={handleCellClick}
        loading={loading}
        checkboxSelection={false}
        // hideFooterPagination
        disableRowSelectionOnClick
        columns={[
          {
            field: "image",
            headerName: "Image",
            filterable: false,
            disableColumnMenu:true,
            renderCell: params => {
              return (
                <SmallPrimaryAvatar
                  imgProps={{ loading: "lazy" }}
                  src={params?.formattedValue}
                >
                  {params?.row?.name?.charAt(0)?.toUpperCase()}
                </SmallPrimaryAvatar>
              );
            },
          },
          {
            field: "name", headerName: "Name", width: 200, renderCell: params => (
              <div className="d-flex gap-2 align-items-center">
                <span>{params.value}</span>
                <IconButton onClick={() =>
                ClipBoardData(`app.joinee.com/user-profile?user_id=${params.row.id}`, "Job link")
          }>
                 <FeatherIcon icon={'external-link'} size={10} />
                </IconButton>
            </div>
          ) },
          { field: "phone_number", headerName: "Phone Number", width: 300 },
          { field: "email", headerName: "Email", width: 300 },

          {
            field: "location",
            headerName: "Location",
            width: 400,
            filterOperators: LocationOperators,
          },
          { field: "experience", headerName: "Experience", width: 200 },
          {
            field: "jobs_applied",
            headerName: "Jobs Applied Count",
            width: 200,
          },
          // { field: "skills", width: 200, filterOperators: KeySkillOperator },
          {
            field: "skills",
            headerName: "Skills",
            width: 400,
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                    {params.value
                      .split(",")
                      .slice(0, 2)
                      ?.map(each => (
                        <SmallSizeChip
                          label={each}
                          style={{ margin: "1px" }}
                          size="small"
                        />
                      ))}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },
          {
            field: "gender",
            headerName: "Gender",
            width: 200,
          },
          // { field: "gender", width: 200, filterOperators: GenderOperators },
          // { field: "candidate_referred", width: 200 },
          // { field: "candidate_onboarded", width: 200 },
          // { field: "total_earnings", width: 200 },
          // { field: "available_earnings", width: 200 },
          // { field: "bank_account_details", width: 200 },
        ]}
        rows={referalPartners}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButtonForTable,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: props => (
            <Pagination
              {...props}
              // color="primary"
              // count={Math.ceil(rowsData.length / rowsPerPage)}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChange={(_, newPage) => setPage(newPage)}
              showFirstButton
              showLastButton
              sx={{
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#024430",
                    color: "#ffe7bb",
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                  },
                },
              }}
            />
          ),
        }}
        // components={{
        //   noRowsOverlay: CustomNoRowsOverlay,
        // }}
      />
      {openSkillModal && (
        <SkillViewModal
          rowdata={selectedRowData}
          closeModalFunc={handleCloseModal}
          openModal={openSkillModal}
          headerName={modalName}
        />
      )}
      {viewAddModal && (
        <RPModel isOpen={viewAddModal} updated={()=>setUpdate(pre=>!pre)} toggle={()=>setViewAddModal(false)} />
      )}
    </div>
  );
}

import { Box, Grid, IconButton, Rating, Stack,TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  BlackTypography,
  GreyTypography,
  PrimaryTypography,
  GreyVarientTypography
} from "assets/Mui/globalTheme";
import { imageURL } from "common/constants/commonURLS";
import { MODERATOR, SP, TE } from "common/constants/userModules";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
const useStyles = makeStyles({
  table:{
    display:'flex',
    flexDirection:'column',
    border:'0.5px solid rgba(0,0,0,0.5)',
    borderRadius: "8px",
    padding:'8px',
    "& tr":{
      display:'flex',
      width:'100%',
      alignItems:"center"
    },
    "& thead":{
      width:'100%',    
      position: 'sticky',
      top: 0,
      zIndex: 2,
      paddingBlock:'4px',
      borderBottom:'0.5px solid rgb(221 221 221)',
      "& th:nth-child(1),& th:nth-child(2)":{
        width:'35%',
        textAlign:'left'
      },
      "& th:nth-child(3),& th:nth-child(4)":{
        width:'15%',
        textAlign:'left'
      }
    },
    "& tbody":{
      height:"250px",
      overflowY:'scroll',
      "&::-webkit-scrollbar":{
        display:'none'
      },
      "& tr":{
        paddingBlock:'12px',
        "&:not(:last-child)":{
          borderBottom:'0.5px solid rgba(221,221,221,0.5)',
        }
      },
      "& td:nth-child(1),& td:nth-child(2)":{
        width:'35%',
        textAlign:'left'
      },
      "& td:nth-child(3),& td:nth-child(4)":{
        width:'15%',
        textAlign:'left'
      },
      "& .css-15z3oi4-MuiInputBase-root-MuiOutlinedInput-root":{
        padding:'8px'
      }
    }
  }
})

// const resumes = [
//   {
//     name: "asdsad",
//     link: "dasdsad",
//     created:"dasdasd"
//   },
//   {
//     name: "dasdsadasd",
//     link: "dasdsadsad",
//     created:"ewqeqweqweqwe"
//   },
//   {
//     name: "asdsafc",
//     link: "m,asdasf",
//     created:"qe12313"
//   },
//   {
//     name: "uyhgvf",
//     link: "vbgerg",
//     created:"werjsvrtg"
//   },
// ]

export default function ResumeTable({resumes,setViewResume,setResumeUrl}) {  
 
  const classes = useStyles()
  const user = useSelector(state => state?.Login);
  console.log(user,"used///")

  return (
    <Stack spacing={2} className="mb-4">
      <Box
      >
        <table className={classes.table}>
          <thead>
            <tr>  
              <BlackTypography variant="h6" component="th">
                {/* <td style={{width:"100%"}}></td> */}
                Name
              </BlackTypography>
              <BlackTypography variant="h6"  component="th">
                {/* <td style={{width:"100%"}}></td> */}
                CreatedBy
              </BlackTypography>
              <BlackTypography variant="h6" component="th">
                {/* <td style={{width:"100%"}}></td> */}
                link
              </BlackTypography>
              <BlackTypography variant="h6" component="th">
                {/* <td style={{width:"100%"}}></td> */}
                view
              </BlackTypography>
            </tr>
          </thead>
          <tbody>
            {resumes?.map((data,id)=>(
              <tr key={id}>
                  <td>
                    {/* <td style={{width:"100%"}}></td> */}
                  <GreyTypography variant="h6">{data}</GreyTypography>
                  {/* <FeatherIcon icon="file-text" /> */}
                  </td>
                  <td>
                    {/* <td style={{width:"100%"}}></td> */}
                  <GreyVarientTypography variant="h6">{`${user?.email}`}</GreyVarientTypography>
                </td>
                <td>
                  {/* <td style={{width:"100%"}}></td> */}
                  <IconButton
                  onClick={() => {
                    if(Boolean(data)){
                      saveAs(`${imageURL}${data}`, data);
                    }else{
                      return null
                    }
                  }}
                  >
                    <FeatherIcon size={14} icon={"download"}/>
                  </IconButton>
                  {/* <GreyTypography variant="h6">{data.link}</GreyTypography> */}
                  </td>
                  <td>
                    {/* <td style={{width:"100%"}}></td> */}
                  <IconButton onClick={() => {
                    setViewResume(data)
                    setResumeUrl(data)
                  }}>
                    <FeatherIcon size={14} icon={"external-link"}/>
                  </IconButton>
                  </td>
                  
              </tr>
            ))
            }
          </tbody>
        </table>
      </Box>
    </Stack>
  );
}

const { Row, Container, Col, CardBody, Card } = require("reactstrap");
import { updateSingleAdminStaffDetails } from "helpers/services/adminStaff/adminStaff";
import {
  FormControlLabel,
  FormGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  createIndustries,
  deleteIndustries,
  getAllIndustries,
  updateIndustries,
} from "helpers/services/jobAttributes/industries";
import { StageSearchInput, GreyTypography } from "assets/Mui/globalTheme";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import React,{useState,useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import CommonModel from "../../components/Common/models/createModel";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import { toast } from "react-hot-toast";
import CustomPagination from "components/Pagination/globalPagination";
import { useParams,useHistory } from "react-router-dom";
import SearchParamHook from "hooks/searchParams";

class Industries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      name: "",
      data: [],
      id: "",
      isLoading: "true",
      openSearch: true,
      searchRole: "",
      page:1
    };
    this.toggle = this.toggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
    this.createIndustries = createIndustries.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handlRestore = this.handlRestore.bind(this);
  }
  async componentDidMount() {
    this.setState({ isLoading: true });
    const data = await getAllIndustries();
    this.setState({ data, isLoading: false });
  }

  async componentDidUpDate(_, prevState) {
    if (prevState.data !== this.state.data) {
    }
    if (prevState.data.length !== prevState.data.length) {
      const data = await this.getAllIndustries();
      this.setState({ data: data });
    }
  }

  /*Recall the industries */
  async handleRecallIndustries() {
  }
   /*Recall the industries */
  toggle(purpose = "", name = "", active = "", id = "", index) {
    this.setState(prev => ({
      ...prev,
      purpose: purpose,
      name: name,
      index: index,
      id: id,
      active: active,
      isOpen: !this.state.isOpen,
    }));
  }

  handleDelete(index, id, name) {
    deleteIndustries(id, name);
    let result = this.state.data;

   const filterOne = result?.map((each)=>{
    if(each.id == id){
      return {
        ...each,
        is_active:!each.is_active
      }
    }else {
      return {
        ...each
      }
    }
   })

 
    // result[index].is_active = !result[index].is_active;

    // this.setState({
    //   data: result,
    // });
    this.setState({
      data:filterOne
    })
    toast.error("Disabled");
  }
  handlRestore(item, index) {
    const sample = {
      name: item.name,
      is_active: !item.is_active,
    };
    updateIndustries(sample, item.id);
    let result = this.state.data;

    const filterOne = result?.map((each)=>{
      if(each.id == item.id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

    // result[index].is_active = !result[index].is_active;
    this.setState({
      data: filterOne,
    });
    toast.success("Enabled");
  }
  handleAction(item, index) {
    item.is_active
      ? this.handleDelete(index, item.id, item.name)
      : this.handlRestore(item, index);
  }
  updateFunc(sample, type, index, id) {
    let editedId;
    if (id) {
      editedId = id;
    } else {
      editedId = this.state.id;
    }
    if (type === "Create") {
      this.createIndustries(sample);
      this.setState({
        data: [...this.state?.data, sample],
      });
    } else {
      // updateIndustries(sample, this.state.id);
      updateIndustries(sample, editedId);
      let result = this.state.data;
      // result[index] = sample;
      
      const filterOne = result?.map((each)=>{
        if(each.id == sample.id){
          return {
            ...each,
            name:sample.name,
            is_active:sample.is_active
          }
        }else {
          return {
            ...each
          }
        }
       })
     

      this.setState({
        data: filterOne,
      });
    }
  }

  /* Search toggle effect */
  handleSearchOpen = () => {
    this.setState(prevState => ({
      openSearch: !prevState.openSearch,
      searchRole: "",
    }));
  };

  handleSearch = e => {
    this.setState(prevState => ({
      searchRole: e.target.value,
    }));
  };

  searchFilterData = data => {
    const searchWord = this.state?.searchRole?.toLowerCase();
    const searchPattern = data?.filter(item => {
      const roleName = item?.name?.toLowerCase();

      if (roleName?.includes(searchWord)) {
        return item;
      }
    });
 
    return searchPattern;
  };

  /* Search toggle effect end */

  render() {
    const filteredData = this.searchFilterData(this.state?.data);
    
    return (
      <>
        <CommonModel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          headTag={"Industries"}
          purpose={this.state.purpose}
          index={this.state.index}
          id={this.state.id}
          title={this.state.name}
          active={this.state.active}
          update={this.updateFunc}
        />
        <div md={6} style={{ padding: "40px" }} className="header_jobs_sticky">
          <PrimaryButton
            sx={{ width: "200px" }}
            onClick={() => this.toggle("Create")}
          >
            Create Industries
          </PrimaryButton>
          <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
            {this.state.openSearch ? (
              <SearchIcon onClick={this.handleSearchOpen} />
            ) : (
              <StageSearchInput
                placeholder="search..."
                onChange={e => this.handleSearch(e)}
                endAdornment={
                  <IconButton edge="end" onClick={this.handleSearchOpen}>
                    <ClearIcon />
                  </IconButton>
                }
              />
            )}
          </Stack>
        </div>
        <Row style={{ marginLeft: "20px" }}>
          <div className="table_cont">
            <div className="table-responsive">
              <Card style={{ maxWidth: "80vw",}}>
                <CardBody style={{height:"calc(100vh - 220px)",overflowY:"scroll"}}>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Industriess</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {this.state.isLoading ? (
                        <BubbleLoader />
                      ) : (
                        // this.state.data.map((item, index) => (
                        filteredData?.map((item, index) => (
                          <TableRow key={item.name + index}>
                            <TableCell>{item.name}</TableCell>

                            <TableCell className="active_td">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <PrimarySwitch
                                      sx={{ m: 1 }}
                                      checked={item.is_active}
                                      onChange={async e => {
                                        this.handleAction(item, index);
                                      }}
                                    />
                                  }
                                />
                              </FormGroup>
                            </TableCell>
                            <TableCell className="col_btn">
                              <PrimaryButton
                                sx={{ width: "100px" }}
                                onClick={() =>
                                  this.toggle(
                                    "Edit",
                                    item.name,
                                    item.active,
                                    item.id,
                                    index
                                  )
                                }
                              >
                                Edit
                              </PrimaryButton>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>

          {/* <CustomPagination /> */}
        </Row>
      </>
    );
  }
}

export default Industries;



// const Industries = () => {
//   const history = useHistory();
//   const [isOpen, setIsOpen] = useState(false);
//   const [purpose, setPurpose] = useState('');
//   const [index, setIndex] = useState(-1);
//   const [id, setId] = useState('');
//   const [active, setActive] = useState(false);
//   const [name, setName] = useState('');
//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [openSearch, setOpenSearch] = useState(true);
//   const [searchRole, setSearchRole] = useState('');
//   const [page, setPage] = useState(1);
//   const searchParams = SearchParamHook()

//   const toggle = (purpose = '', name = '', active = '', id = '', index) => {
//     setPurpose(purpose);
//     setName(name);
//     setIndex(index);
//     setId(id);
//     setActive(active);
//     setIsOpen(!isOpen);
//   };

//   const handleDelete = (index, id, name) => {
//     deleteIndustries(id, name);
//     let result = [...data];
//     result[index].is_active = !result[index].is_active;
//     setData(result);
//     toast.error('Disabled');
//   };

//   const handleRestore = (item, index) => {
//     const sample = {
//       name: item.name,
//       is_active: !item.is_active,
//     };
//     updateIndustries(sample, item.id);
//     let result = [...data];
//     result[index].is_active = !result[index].is_active;
//     setData(result);
//     toast.success('Enabled');
//   };

//   const handleAction = (item, index) => {
//     item.is_active ? handleDelete(index, item.id, item.name) : handleRestore(item, index);
//   };

//   const updateFunc = (sample, type, index, id) => {
//     let editedId = id ? id : id;
//     if (type === 'Create') {
//       createIndustries(sample);
//       setData([...data, sample]);
//     } else {
//       updateIndustries(sample, editedId);
//       let result = [...data];
//       result[index] = sample;
//       setData(result);
//     }
//   };

//   const handleSearchOpen = () => {
//     setOpenSearch(!openSearch);
//     setSearchRole('');
//   };

//   const handleSearch = (e) => {
//     setSearchRole(e.target.value);
//   };

//   const searchFilterData = (data) => {
//     const searchWord = searchRole.toLowerCase();
//     const searchPattern = data.filter((item) => {
//       const roleName = item.name.toLowerCase();
//       return roleName.includes(searchWord);
//     });
//     return searchPattern;
//   };

//   const getAllIndustriesList = async (page = 1) => {
//     const data = await getAllIndustries();
//     return data;
//   };

//   /* For pagination purpose in progress */

//   useEffect(()=>{
//     getAllIndustriesList()
//   },[searchParams.get("page")])

//   /* For pagination purpose in progress */

//   const handleRecallIndustries = () => {
//     // Recall industries logic
//   };



//   useEffect(() => {
//     const fetchData = async () => {
//       setIsLoading(true);
//       const data = await getAllIndustriesList();
//       setData(data);
//       setIsLoading(false);
//     };

//     fetchData();
//   }, []);

//   const filteredData = searchFilterData(data);

//   return (
//     <>
//       <CommonModel
//         isOpen={isOpen}
//         toggle={toggle}
//         headTag={'Industries'}
//         purpose={purpose}
//         index={index}
//         id={id}
//         title={name}
//         active={active}
//         update={updateFunc}
//       />
//       <div md={6} style={{ padding: '40px' }} className="header_jobs_sticky">
//         <PrimaryButton sx={{ width: '200px' }} onClick={() => toggle('Create')}>
//           Create Industries
//         </PrimaryButton>
//         <Stack alignItems={'center'} direction={'row'} spacing={1.6}>
//           {openSearch ? (
//             <SearchIcon onClick={handleSearchOpen} />
//           ) : (
//             <StageSearchInput
//               placeholder="search..."
//               onChange={(e) => handleSearch(e)}
//               endAdornment={
//                 <IconButton edge="end" onClick={handleSearchOpen}>
//                   <ClearIcon />
//                 </IconButton>
//               }
//             />
//           )}
//         </Stack>
//       </div>
//       <Row style={{ marginLeft: '20px' }}>
//         <div className="table_cont">
//           <div className="table-responsive">
//             <Card style={{ maxWidth: '80vw' }}>
//               <CardBody style={{ height: 'calc(100vh - 220px)', overflowY: 'scroll' }}>
//                 <Table className="table mb-0">
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>Industries</TableCell>
//                       <TableCell>Status</TableCell>
//                       <TableCell>Action</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <tbody>
//                     {isLoading ? (
//                       <BubbleLoader />
//                     ) : (
//                       filteredData?.map((item, index) => (
//                         <TableRow key={item.name + index}>
//                           <TableCell>{item.name}</TableCell>

//                           <TableCell className="active_td">
//                             <FormGroup>
//                               <FormControlLabel
//                                 control={
//                                   <PrimarySwitch
//                                     sx={{ m: 1 }}
//                                     checked={item.is_active}
//                                     onChange={async (e) => {
//                                       handleAction(item, index);
//                                     }}
//                                   />
//                                 }
//                               />
//                             </FormGroup>
//                           </TableCell>
//                           <TableCell className="col_btn">
//                             <PrimaryButton
//                               sx={{ width: '100px' }}
//                               onClick={() => toggle('Edit', item.name, item.active, item.id, index)}
//                             >
//                               Edit
//                             </PrimaryButton>
//                           </TableCell>
//                         </TableRow>
//                       ))
//                     )}
//                   </tbody>
//                 </Table>
//               </CardBody>
//             </Card>
//           </div>
//         </div>

//         {/* <CustomPagination onPageChange={handlePaginationClick} currentPage={currentPage} /> */}
//         <CustomPagination />
//       </Row>
//     </>
//   )
// }

// export default Industries;
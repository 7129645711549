import { Stack } from "@mui/material";
import UserJobPostAttachment from "components/userJobPost/Pages/Attachement";
import UserJobPostChat from "components/userJobPost/Pages/Chat";
import UserJobPostEvaluations from "components/userJobPost/Pages/Evaluations";
import UserJobPostNotes from "components/userJobPost/Pages/Notes";
import UserJobPostResume from "components/userJobPost/Pages/Resume";
import ScoreCard from "components/userJobPost/Pages/scorecard";
import ProfileSummary from "components/userJobPost/Pages/summary";
import TimeLine from "components/userJobPost/Pages/timeline";
import UserAvatar from "components/userJobPost/UserAvatar";
import UserJobTabs from "components/userJobPost/userTabs";
import SearchParamHook from "hooks/searchParams";
import { useEffect, useState } from "react";
import ComingSoon from "./comingSoon";
import { TE,SP,MODERATOR,RC,RS,ACM,ZA,CA,CS,BVC,BVS} from "common/constants/userModules";

import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";

export default function UserJobPost() {
  const searchParams = SearchParamHook();
  const [navBar, setnavBar] = useState("0");
  const role = JSON.parse(localStorage.getItem("authUser")).role;
  const [loading,setLoading] = useState(true)
  useEffect(() => {
    setTimeout(()=>{setLoading(false)},1000)
    if (searchParams?.get("tab")) {
      setnavBar(searchParams.get("tab"));
    }
  }, [searchParams]);
  function findRenderPage() {
    if(![TE,SP,MODERATOR,ACM,ZA,CA,CS,BVC,BVS,RC,RS].includes(role)){
      switch (navBar) {
        case "0":
          return <ProfileSummary />;
        case "1":
          return <UserJobPostResume />;
        case "2":
            return <UserJobPostEvaluations />;    
            
        case "3":
          return <ScoreCard />;
        // return <ComingSoon />;
        case "4":
          return <UserJobPostChat />;
        // return <ComingSoon />;
        case "5":
          return <UserJobPostNotes />;
        case "6":
          return <TimeLine />;
        case "7":
          return <UserJobPostAttachment />;
      }
    }
    switch (navBar) {
      case "0":
        return <ProfileSummary />;
      case "1":
        return <UserJobPostResume />;
      case "2":
        return <UserJobPostEvaluations />;
      // return <ComingSoon />;
      case "3":
        return <ScoreCard />;
      // return <ComingSoon />;
      case "4":
        return <UserJobPostChat />;
      // return <ComingSoon />;
      case "5":
        return <UserJobPostNotes />;
      case "6":
        return <TimeLine />;
      // case "7":
      //   return <UserJobPostAttachment />;
    }
  }

  return (
    <Stack spacing={2} sx={{ padding: "15px" }}>
      <Stack
        className="header_jobs_sticky"
        sx={{ padding: "15px" }}
        spacing={2}
      >
        <UserAvatar />
        {/* all primary buttons fall into useravatar */}
        <UserJobTabs />
      </Stack>
      {loading ?     
      <>
      <div
        className="loader_container"
        style={{ height: "50vh",display:"flex",justifyContent:"center"}}
      >
        <div className="loader"></div>
      </div>
    </>: 
        <div
          style={{
            padding: "15px",
          }}
        >
          {findRenderPage()}
        </div>      
      }
    </Stack>
  );
}

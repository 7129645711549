const { Container, Col } = require("reactstrap");
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import noData from "../../assets/images/zepul/no_jobs.svg";
import { connect } from "react-redux";
import JobCardInformation from "components/jobCard/jobCardInformations";
import JobPostedCompanyAvatar from "components/jobCard/JobPostedCompanyAvatar";
import {
  GreyTypography,
  StyledCard,
  StyledCardContent,
} from "assets/Mui/globalTheme";
import { Stack } from "@mui/material";
import ApprovedCardsButton from "components/jobCard/ApproveCardButtons";
import SocketConnection from "hooks/sockets";
import CustomPagination from "components/Pagination/globalPagination";
import SearchParamHook from "hooks/searchParams";
import { ACM, CA, CS, ZA } from "common/constants/userModules";
import {
  // getAllJobs,
  getAllJobsBasedOnCompany,
  getSingleJobDetails,
  getAllActiveJobs
} from "helpers/services/jobs/jobs";

// function JobList({ role, jobsData, loader, setUpdates, count, pages, setPages }) {
  function JobList({fetchCompanyJobs,filterCompanyJobs}) {
    const activePathDetails = fetchCompanyJobs?.activePath
    const companyId = fetchCompanyJobs?.companyId

  let history = useHistory();
  const [tableData, setTableData] = useState([]);
  const login = {
    ...JSON.parse(localStorage.getItem("authUser")),
  };
  // const [viewDetails, setViewDetails] = useState(tableData[0]);

  const handleClose = confirm => {};

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const searchItems = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const results = tableData?.filter(item =>
      item.job_title.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  }, [searchTerm]);



  // useEffect(() => {
  //   setViewDetails(tableData[0]);
  // }, [tableData]);
  function randcolor() {
    function c() {
      return Math.floor(Math.random() * 220 + 36).toString(16);
    }
    return "#" + c() + c() + c();
  }



    /*Individual Api with pagination start here */

    const [jobs, setJobs] = useState([]);
    const [load, setLoad] = useState(false);
    const [filterSearch, setFilterSearch] = useState("");
    const [update, setUpdate] = useState(false);
    const [listLoad,setListLoad] = useState(false)
    const searchParams = SearchParamHook();
  
    const [page, setPage] = React.useState(1)
    const [totalCount,setTotalCount] = React.useState(0)
    const rowsPerPage = 10;
  
    const {search} = useLocation()
    useEffect(()=>{
      dataCaller()
    },[searchParams.get("page")])
  
    useEffect(() => {
      dataCaller();
     // setUpdate((pre)=>!pre);
   }, [update]);
   useEffect(() => {
    dataCaller();
  }, []);

  useEffect(()=>{
    if(filterCompanyJobs || !filterCompanyJobs){
      dataCaller();
    }

  },[filterCompanyJobs])
  
  // const tableData = jobs?.filter(
  //   data => data.is_job_post_verified !== true && data.is_active === true
  // );
  
  
    async function dataCaller() {
      setLoad(true)
      try {
        const { data, status } = [CS].includes(login.role) || fetchCompanyJobs?.companyId ? 
        // await getAllJobsBasedOnCompany(login.company_id, companyId,activePathDetails,page,rowsPerPage)
        await getAllJobsBasedOnCompany(companyId,activePathDetails,page,rowsPerPage)
        :  await getAllActiveJobs(page,rowsPerPage);
    
        if (status === 200) {
          const sortedJobsList = data?.job_post_details?.sort((a,b)=>new Date(b.updatedAt) - new Date(a.updatedAt))
       
          setJobs(sortedJobsList);
          setLoad(false)
  
          setTotalCount(Math.ceil((data?.overall_jobs_count)/rowsPerPage))
  
  
     
        }
        ErrorHandlingToaster({ data, status });
      } catch (error) {
        setLoad(false)
      }
    }
    /*Individual Api with pagination start here */

    useEffect(() => {
      const activeData = jobs?.filter(
        data => data.is_job_post_verified === true && data.is_active === true
      );
      const sortedJobsList = activeData?.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      // setTableData(sortedJobsList);
      setTableData(activeData);
    }, [jobs]);

    // useEffect(()=>{
    //   setJobs(jobsData)
    //   setLoad(loader)
    //   setPage(pages)
    // },[loader,jobsData,pages])



  return (
    <>
      {!load ? (
        <div>
          <Container
            fluid
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              height: "75vh",
            }}
          >
            <div className="row row-gap-4" style={{height:"90%",overflowY:"scroll"}}>
              {tableData?.length > 0 ? (
                tableData.map(item => (
                  <Col xs={12} md={6} lg={4} className="mb-4" key={item.id}>
                    <StyledCard>
                      <StyledCardContent>
                        <Stack direction="column" spacing={1}>
                          <JobPostedCompanyAvatar item={item} />
                          <JobCardInformation
                            tableData={tableData}
                            setUpdate={setUpdate}
                            setTableData={setTableData}
                            item={item}
                          />
                          <ApprovedCardsButton
                            tableData={tableData}
                            item={item}
                            setUpdate={setUpdate}
                            setTableData={setTableData}
                            // handleViewMore={handleViewMore}
                          />
                        </Stack>
                      </StyledCardContent>
                    </StyledCard>
                  </Col>
                ))
              ) : (
                <div className="no_data_show">
                  <div>
                    <img src={noData} className="lg_img"></img>
                    <GreyTypography variant="h6">
                      {/* Approved Bucket is Empty */}
                      No jobs found
                    </GreyTypography>
                  </div>
                </div>
              )}
            </div>

            {tableData?.length > 0 ? (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  // marginBottom: "50px",
                }}
              >
                <CustomPagination count={totalCount} page={page} setPage={setPage} />
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
      ) : (
        <div className="loader_container" style={{ height: "80vh" }}>
          <div className="loader"></div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = state => ({
  role: state.Login.role,
});
export default connect(mapStateToProps, null)(JobList);

import SearchParamHook from "hooks/searchParams";
import UserJobPostEvaluationTabs from "../Evaluations/tabs";
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import ScreeningEvaluation from "../Evaluations/screening";
import ScoreCardEvaluationByUser from "./ScoreCardEvaluatonByUser";
import { deleteScreenEvaluationQuestions, deleteScreenEvaluationSkill, getScreenEvaluationQuestions, getScreenEvaluationSkills, postScreenEvaluationQuestions, postScreenEvaluationSkills, updateScreenEvaluationQuestions, updateScreenEvaluationSkills } from "helpers/services/jobAttributes/screenEvaluators";
import { TE,SP,MODERATOR } from "common/constants/userModules";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";


function UserJobPostScoreEvalutions(){
    const searchParams = SearchParamHook();
    const [evaluation, setEvaluation] = useState("screening");
    const [skills,setSkills] = useState([])
    const [questions,setQuestions] = useState([])
    const [loading, setLoading] = useState(false);
    const role = JSON.parse(localStorage.getItem("authUser")).role;
    const evaluatorId = JSON.parse(localStorage.getItem("authUser")).id;
    const userJobPostId = searchParams.get('user_job_post_id');
    const jobPostId = searchParams.get('job_id')
    const {candidate} = useSelector(state => state.Candidates);
     const moderatorEvalId = role !== MODERATOR ? candidate.moderator_evalution_details: evaluatorId
    const screeningEvalId = role !== SP ? candidate.screening_evalution_details: evaluatorId
    const technicalEvalId = role !== TE ? candidate.technical_evalution_details: evaluatorId
    const [evaluId,setEvaluId] = useState('')
    const [evaluType,setEvaluType] = useState('')
    const [display,setDisplay] = useState('Scrrened page carousal here')
    useEffect(() => {
      let evalId = evaluatorId
      let evalType
      const evaluation_type = searchParams.get("evaluation_type");
      if (evaluation_type) {
        setEvaluation(evaluation_type);
        switch(evaluation_type){
          case "screening":
            evalType = SP;
            evalId = (screeningEvalId);
            break
          case "technical":
            evalType = TE;
            evalId = (technicalEvalId);
            break
          default:
            evalType = MODERATOR;
            evalId = (moderatorEvalId);
        }   
      }
      if(evalId !== ''){
        setEvaluId(evalId)
        setEvaluType(evalType)
        // fetchScreenEvaluationSkills(evalId,evalType)
        // fetchScreenEvaluationQuestions(evalId,evalType)
      }else{
        setQuestions([])
        setSkills([])
      }
    }, [searchParams]);
  
   

    async function fetchScreenEvaluationSkills(evalId,evalType){
        setLoading(true)
        try{
          const {rows} =  await getScreenEvaluationSkills(userJobPostId,evalId,evalType)
          const updatedList = rows?.map((item)=>({
           evaluateId:item.id,
           skillInfo:item?.skill_details?.length > 0 ? item.skill_details[0] : [],
           comment:item.sp_comment,
           rating:item.sp_rating
          }))
          setSkills(updatedList)
          setLoading(false)
        }catch(err){
          console.error(err);
          // setSkills([])
          setLoading(false)
        }
      }
    
      async function fetchScreenEvaluationQuestions(evalId,evalType){
        setLoading(true)    
      try {
        const res = await getScreenEvaluationQuestions(userJobPostId,jobPostId,evalId,evalType)
        const tweakData = res?.data?.map((item)=>({
          candidateJobId:item.user_job_post_id,
          evaluateId:item.id,
          answer:item.answer,
          comment:item.sp_comment,
          rating:item.sp_rating,
          view:item.is_active,
          questionName:item?.job_post_question || ""
        }))
        setQuestions(tweakData)
        console.log(tweakData,'child veda');
        setTimeout(() => {
          setLoading(false)
          
        }, 1000);
    
      } catch (error) {
        setLoading(false)
        // setSkills([])
      }
      // console.log(res);
      }


    function FindEvaluationPage() {
      switch (evaluation) {
        case "screening":
          return (
            <ScoreCardEvaluationByUser
              readOnly={role !== SP}
              loading={loading}
              questionData={questions}
              skillData={skills}
              display={display}
            />
          );
        case "technical":
          return (
            <ScoreCardEvaluationByUser
              readOnly={role !== TE}
              loading={loading}
              questionData={questions}
              skillData={skills}
            
            />
          );
        case "moderate":
          return (
            <ScoreCardEvaluationByUser
              readOnly={role !== MODERATOR}
              loading={loading}
              questionData={questions}
              skillData={skills}
            />
          );
      }
    }
    return (
      <Stack spacing={2}>
        {/* <UserJobPostEvaluationTabs setEvaluId={setEvaluId} setEvaluType={setEvaluType} fetchSkills={fetchScreenEvaluationSkills} fetchQuestions={fetchScreenEvaluationQuestions} /> */}
        <FindEvaluationPage/>
      </Stack>
    );
}

export default UserJobPostScoreEvalutions;
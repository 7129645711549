import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BlackTypography,
  PrimaryAutoComplete,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryTypography,
  SecondaryFilledButton,
  autocompleteProps,
} from "assets/Mui/globalTheme";
import { useForm } from "react-hook-form";
import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createAdminStaff,
  getSingleAdminStaffDetails,
  updateAdminStaff,
} from "helpers/services/adminStaff/adminStaff";
import {
  createCompanyStaff,
  getSingleCompanyStaffDetails,
  updateCompanyStaff,
} from "helpers/services/companies/company";
import {
  createBackgroundVerificationCompanyStaff,
  getSingleBackgroundVerificationStaffDetails,
  updateBackgroundVerificationCompanyStaff,
} from "helpers/services/BackgroundVerifications/backgroundVerifications";
import {
  createRecrutementCompanyStaff,
  getSingleRecrutementStaffDetails,
} from "helpers/services/RecrutementCompany/recrutement";
import { toast } from "react-hot-toast";
import PhoneInput, {
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import { sendOtp } from "helpers/services/otpVerifiy/verify";
import StaffOtp from "./Settings/StaffModel/staffOtp";
import PartnersOtp from "./partnersOtp";
import { createReferalPartner } from "helpers/services/referalPartner";
import { getAllFunctionalArea } from "helpers/services/jobAttributes/functionalArea";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import { debounce } from "@mui/material/utils";
import { createSP, createScreenPartner } from "helpers/services/screeningPartner/screeningPartner";
import parse from "autosuggest-highlight/parse";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

// import GoogleLocation from "components/Common/Forms/location";
const GOOGLE_MAPS_API_KEY = "AIzaSyDk52HPcdIZWt2glyeWGUyn9hiqAsGMJvk";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height:500,
  overflowY:"scroll",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 2,
};

const gender = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
  { name: "prefer Not To say", value: "prefer_not_to_say" },
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: "10px",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    },
  },
};

function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}
const autocompleteService = { current: null };

export default function AddSPModel({
  isOpen,
  toggle,
  updated,
 
}) {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  const [viewOtp, setViewOtp] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [tempNum, setTempNum] = React.useState("");
  const [functionalAreas, setFunctionalAreas] = React.useState([]);
  const [industries, setIndustries] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const [value, setValues] = React.useState(null);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }
    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  React.useEffect(() => {
    fetchIndustries();
    getFunctionalAreas();
  }, []);
  // console.log(backendData,id,"v");
  async function fetchIndustries() {
    const data = await getAllIndustries();
    setIndustries(
      data
        ?.filter(item => item.is_active)
        ?.map(item => ({ name: item.name, value: item.id }))
    );
  }
  async function getFunctionalAreas() {
    const data = await getAllFunctionalArea();
    setFunctionalAreas(
      data?.map(item => ({ name: item.name, value: item.id }))
    );
  }
  function handleIndustryChange(e) {
    alert(e.target.value);
    setValue(
      "industry",
      typeof e.target.value === "string" ? value?.split(",") : value
    );
  }
  function handlefunctionalAreaChange(e) {
    setValue(
      "functional_area",
      typeof e.target.value === "string" ? value?.split(",") : value
    );
  }
  const onSubmission = async data => {
    console.log(data);
    if (!isPossiblePhoneNumber(data.mobile)) {
      setError("mobile", { type: "custom", message: "invalid number" });
      return;
    }
    try {
      setLoad(true);
    // data.industry = [data.industry]
    // data.functional_area = [data.functional_area]
      const obj = {
        ...data,
        phone_number: data.mobile,
        userType: "SCREENING_PARTNER",
      };
      delete obj.mobile;
      const resData = await createSP(obj);
      // console.log(resData);
      setLoad(false);
      if (resData.status === 201) {
        // const res =  await sendOtp("mobile",data.mobile)
        // const { status } = res
        // console.log(res);
        setViewOtp(true);
        // if (status === 200) {
        // toggle()
        // updated()
        // }
      }
    } catch (error) {
      setLoad(false);
    }
  };

  const userData = {
    first_name: watch("first_name"),
    last_name: watch("last_name"),
    email: watch("email"),
    mobile: watch("mobile"),
    user_password: watch("user_password"),
  };
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => {
          toggle();
          reset();
          setLoad(false);
        }}
        aria-labelledby="edit-profile-modal-first_name"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          {!load ? (
            <>
              <BlackTypography variant="h2" sx={{ marginBottom: "10px" }}>
                Create Partner
              </BlackTypography>
              <form autoComplete={"off"} onSubmit={handleSubmit(onSubmission)}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="work-exp-first-name-outlined"
                        error={errors.first_name}
                      >
                        First name
                      </PrimaryNormalInputLabel>
                      <PrimaryNormalInputs
                        fullWidth
                        placeholder="Enter your First name"
                        {...register("first_name", { required: true })}
                        id="wor-exp-first-name-outlined"
                        helperText={
                          errors?.first_name ? "first  name is required" : ""
                        }
                        autoFocus
                        label="Job first_name"
                        type={"text"}
                        name="first_name"
                        error={errors.first_name}
                        variant="outlined"
                      />
                      <PrimaryNormalFormHelperText error={errors.first_name}>
                        {errors.first_name
                          ? "first name is required Field"
                          : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="work-exp-last-name-outlined"
                        error={errors.last_name}
                      >
                        Last name
                      </PrimaryNormalInputLabel>
                      <PrimaryNormalInputs
                        fullWidth
                        placeholder="Enter your Last name"
                        {...register("last_name", { required: true })}
                        id="wor-exp-last-name-outlined"
                        helperText={
                          errors?.last_name ? "last  name is required" : ""
                        }
                        autoFocus
                        label=" last name"
                        type={"text"}
                        name="last_name"
                        error={errors.last_name}
                        variant="outlined"
                      />
                      <PrimaryNormalFormHelperText error={errors.last_name}>
                        {errors.last_name ? "last name is required Field" : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="work-exp-email-outlined"
                        error={errors.email}
                      >
                        Email
                      </PrimaryNormalInputLabel>
                      <PrimaryNormalInputs
                        fullWidth
                        placeholder="Enter your email"
                        {...register("email", { required: true })}
                        id="wor-exp-email-outlined"
                        helperText={errors?.email ? "email is required" : ""}
                        autoFocus
                        label=" email"
                        type={"email"}
                        name="email"
                        error={errors.email}
                        variant="outlined"
                      />
                      <PrimaryNormalFormHelperText error={errors.email}>
                        {errors.email ? "email is required Field" : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryTypography
                        sx={{ fontWeight: "500 !important" }}
                        variant="h5"
                      >
                        Number
                      </PrimaryTypography>
                      <PhoneInput
                        id="signup-phone-number"
                        labelId="signup-phone-number"
                        international={true}
                        useNationalFormatForDefaultCountryValue={true}
                        withCountryCallingCode={true}
                        {...register("mobile", {
                          required: {
                            value: true,
                            message: "mobile number is required Field",
                          },
                        })}
                        className={`input_phone ${
                          errors?.mobile ? "error_phone" : ""
                        } `}
                        style={{ marginTop: "20px !important" }}
                        name="phone Number"
                        onChange={phone => {
                          setValue("mobile", phone);
                        }}
                        label="signup-phone-number"
                        value={watch("mobile")}
                        defaultCountry="IN"
                        initialValueFormat="national"
                        placeholder="Enter phone number"
                      />
                      {errors.mobile ? (
                        <PrimaryNormalFormHelperText error={errors.mobile}>
                          {errors.mobile?.message || ""}
                        </PrimaryNormalFormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>

                  <Grid item md={6}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="user-password-outlined"
                        error={errors.user_password}
                      >
                        password
                      </PrimaryNormalInputLabel>
                      <PrimaryNormalInputs
                        fullWidth
                        placeholder="Enter your password"
                        {...register("user_password", {
                          required: {
                            value: true,
                            message: "password is required Field*",
                          },
                        })}
                        id="user-password-outlined"
                        helperText={
                          errors?.user_password ? "password is required" : ""
                        }
                        autoFocus
                        label=" user_password"
                        type={"password"}
                        name="user_password"
                        error={errors.user_password}
                        variant="outlined"
                      />
                      <PrimaryNormalFormHelperText error={errors.user_password}>
                        {errors.user_password?.message || ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}                    
                    >
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor="folloups-gender-outlined"
                        >
                          gender
                        </PrimaryNormalInputLabel>
                        <Select
                          labelId="folloups-gender-outlined"
                          id="folloups-gender-outlined"
                          {...register("gender", { required: true })}
                          value={watch("gender")}
                          displayEmpty
                          input={
                            <PrimaryNormalInputs
                              label="folloups-gender-outlined"
                              error={errors?.gender}
                              {...register("gender", { required: true })}
                            />
                          }
                          MenuProps={MenuProps}
                        >
                          {gender?.map(item => {
                            return (
                              <MenuItem
                                key={item?.value}
                                value={item?.value} // style={getStyles(name, personName, theme)}
                              >
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <PrimaryNormalFormHelperText error={errors.gender}>
                          {errors?.gender
                            ? "gender is a required field"
                            : "Select your gender."}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                  </Grid>
                  {/* <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor="folloups-functional-area-outlined"
                          error={errors.functional_area}
                        >
                          Categories
                        </PrimaryNormalInputLabel>
                        <Select
                          // multiple
                          displayEmpty
                          onChange={handlefunctionalAreaChange}
                          labelId="folloups-functional-area-outlined"
                          id="folloups-functional-area-outlined"
                          value={watch("functional_area")}
                          {...register("functional_area", { required: true })}
                          input={
                            <PrimaryNormalInputs
                              label="folloups-functional-area-outlined"
                              error={errors?.functional_area}
                              {...register("functional_area", {
                                required: true,
                              })}
                            />
                          }
                          MenuProps={MenuProps}
                        >
                          {functionalAreas?.map(item => {
                            return (
                              <MenuItem
                                key={item?.value}
                                value={item?.value} // style={getStyles(name, personName, theme)}
                              >
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <PrimaryNormalFormHelperText
                          error={errors.functional_area}
                        >
                          {errors?.functional_area
                            ? "functional Area is a required field"
                            : "Select your functional Area."}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                  </Grid> */}
                  <Grid item xs={4} md={12} lg={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                        <Stack spacing={2}>
                          <PrimaryNormalInputLabel
                            shrink
                            htmlFor="folloups-location-outlined"
                          >
                            Location
                          </PrimaryNormalInputLabel>
                          <PrimaryAutoComplete
                            componentsProps={autocompleteProps}
                            id="google-map-demo"
                            placeholder="Add a location"
                            getOptionLabel={option =>
                              typeof option === "string" ? option : option.description
                            }
                            filterOptions={x => x}
                            options={options}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={value}
                            noOptionsText="No locations"
                            onChange={(_, newValue) => {
                              setOptions(newValue ? [newValue, ...options] : options);
                              setValues(newValue);
                              // updateJob({ location: newValue.description });
                            }}
                            onInputChange={(_, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            renderInput={params => (
                              <PrimaryNormalInputs
                                ref={params.InputProps.ref}
                                placeholder="add a location"
                                sx={{ marginTop: "8px" }}
                                {...params}
                                // error={errors[updatingContent] ? true : false}
                                // value={value}
                                // onChange={event => {
                                // setValue(event.target.value);
                                // }}
                                label="Add a location"
                                fullWidth
                              />
                            )}
                            renderOption={(props, option) => {
                              const matches =
                                option?.structured_formatting?.main_text_matched_substrings ||
                                [];
                              const parts = parse(
                                option?.structured_formatting?.main_text,
                                matches?.map(match => [
                                  match.offset,
                                  match.offset + match.length,
                                ])
                              );
                              return (
                                <li {...props}>
                                  <Grid container alignItems="center">
                                    <Grid item sx={{ display: "flex", width: 44 }}>
                                      <FeatherIcon icon="map-pin" size="16" />
                                    </Grid>
                                    <Grid
                                      item
                                      sx={{
                                        width: "calc(100% - 44px)",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {parts.map((part, index) => (
                                        <Box
                                          key={index}
                                          component="span"
                                          sx={{
                                            fontWeight: part.highlight ? "bold" : "regular",
                                          }}
                                        >
                                          {part.text}
                                        </Box>
                                      ))}

                                      <BlackTypography variant="h5">
                                        {option.structured_formatting?.secondary_text}
                                      </BlackTypography>
                                    </Grid>
                                  </Grid>
                                </li>
                              );
                            }}/>
                        </Stack>
                      </FormControl>
                  </Grid>
                  {/* <Grid item xs={4}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor="folloups-industry-outlined"
                        >
                          industry
                        </PrimaryNormalInputLabel>
                        <Select
                          // multiple
                          displayEmpty
                          labelId="folloups-industry-outlined"
                          id="folloups-industry-outlined"
                          value={watch("industry")}
                          onChange={handleIndustryChange}
                          {...register("industry", { required: true })}
                          input={
                            <PrimaryNormalInputs
                              label="folloups-industry-outlined"
                              error={errors?.industry}
                              {...register("industry", { required: true })}
                            />
                          }
                          MenuProps={MenuProps}
                        >
                          {industries?.map(item => {
                            return (
                              <MenuItem
                                key={item?.value}
                                value={item?.value} // style={getStyles(name, personName, theme)}
                              >
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <PrimaryNormalFormHelperText error={errors.industry}>
                          {errors?.industry
                            ? "industry is a required field"
                            : "Select your Industry."}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                  </Grid>                    */}

                  <Grid item xs={12}>
                    
                  <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="folloups-about-outlined"
                        error={errors.about}
                      >
                        About
                      </PrimaryNormalInputLabel>
                      <PrimaryNormalInputs
                        fullWidth
                        multiline
                        placeholder="Description"
                        {...register("about", { required: true })}
                        id="login-about-outlined"
                        autoFocus
                        label="about address"
                        type={"text"}
                        error={errors.about}
                        variant="outlined"
                        rows={4}
                      />
                      <PrimaryNormalFormHelperText error={errors.about}>
                        {errors?.about
                          ? "Description is a required field"
                          : "describe about yourself so that we can find better profiles for you."}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid alignItems={"flex-end"} item xs={7}>
                    <Stack spacing={1} direction="row">
                      <SecondaryFilledButton
                        onClick={() => {
                          toggle();
                          reset();
                        }}
                      >
                        cancel
                      </SecondaryFilledButton>
                      <PrimaryButton type="submit">Submit</PrimaryButton>
                    </Stack>{" "}
                  </Grid>
                </Grid>
              </form>
            </>
          ) : (
            <div style={{ marginLeft: "45%" }}>
              <CircularProgress size={40} />
            </div>
          )}
        </Box>
      </Modal>
      {viewOtp ? (
        <PartnersOtp
          isOpen={viewOtp}
          toggle={() => setViewOtp(false)}
          userData={userData}
          handleClose={toggle}
          clearField={reset}
          reUpdate={updated}
        
        />
      ) : null}
    </div>
  );
}

import { Card, CardContent, Chip, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryChip,
  PrimaryDivider,
  PrimaryTypography,
  deleteIconContainer,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import AddEditKeySkills from "./addKeySkills";
import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
import SearchParamHook from "hooks/searchParams";

export default function CandidateSkills({ candidateDetails,readOnly }) {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const searchParams = SearchParamHook();
  useEffect(() => {
    if (candidateDetails?.skill_details) {
      const result = candidateDetails?.skill_details?.map(
        item => ({
          value: item?.id,
          name: item?.name,
        })
      );
      setSelectedSkills(result);
    }
  }, [candidateDetails]);
  async function handleDelete(skillIndex) {
    let queryParamsForUserJobPostId = searchParams.get("user_id");
    let skill = selectedSkills;
    let updatedSkills = skill?.filter((each)=>{
      if(each.value !== skillIndex){
        return each.value
      }
    })
    const extract = updatedSkills?.map((each)=>each?.value)
   const deleteSkills =  await updateUserJobPost(queryParamsForUserJobPostId, {
      key_skills: [...extract],
    });
    setSelectedSkills([...updatedSkills]);
  }
  const [skillModel, setSkillModel] = useState(false);
  function toggleModel() {
    setSkillModel(!skillModel);
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
        <PrimaryTypography component="h4" variant="h4">
          Skills
        </PrimaryTypography>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        style={{
          gap: "10px",
        }}
        useFlexGap
        flexWrap="wrap"
      >
        {selectedSkills?.length > 0 && selectedSkills?.map((skill, skillIndex) => (
          <PrimaryChip
            color="primary"
            variant="filled"
            label={skill.name}
            readOnly={readOnly}
            // onDelete={() => {
            //   handleDelete(skill.value);
            // }}
            onDelete = {()=>{
              if(!readOnly){
                handleDelete(skill?.value);
              }
            }}
            deleteIcon={
              <div style={deleteIconContainer} disabled={readOnly}>
                <FeatherIcon icon="x" size="14" />
              </div>
            }
          />
        ))}
        <PrimaryChip
          color="primary"
          variant="filled"
          label={`Add ${selectedSkills?.length? "/ Remove":""} Skills`}
          disabled={readOnly}
          onClick={() => {
            toggleModel();
            // AddEditWorkExperience
          }}
          //   onDelete={handleDelete}
          icon={
            <div style={deleteIconContainer}>
              <FeatherIcon icon="plus" size="14" />
            </div>
          }
        />
      </Stack>
      {skillModel ?<AddEditKeySkills
        toggleModel={toggleModel}
        open={skillModel}
        onSubmit={setSelectedSkills}
        currentData={selectedSkills}
 
      />: null}
    </Stack>
  );
}

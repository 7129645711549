import * as React from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { imageURL } from "common/constants/commonURLS";
import {
  MenuProps,
  PrimaryButton,
  PrimaryButtonForTable,
  PrimaryDataGrid,
  PrimaryNormalInputs,
  PrimarySwitch,
  SmallPrimaryAvatar,
  SmallSizeChip,
} from "assets/Mui/globalTheme";
import TablePopper from "components/table/header/popper";
import { getAllScreeningPartnerWithStatus } from "helpers/services/screeningPartner/screeningPartner";
import { getAllRecruitmentUsersList } from "helpers/services/RecrutementCompany/recrutement";
import { Box, FormControl, MenuItem, Select,Pagination } from "@mui/material";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import IndustryInput from "components/table/header/industrySelect";
import { IndustryOperators } from "components/table/operators/industryOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { WorkModeOperators } from "components/table/operators/worModeOperators";
import { useHistory,useLocation } from "react-router-dom";
import { PrimaryChip, HoldChip } from "assets/Mui/globalTheme";
import SkillViewModal from "helpers/Modals/SkillViewModal";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { StyledGridOverlay } from "assets/Mui/globalTheme";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import { CA, CS, ZA } from "common/constants/userModules";
import CompanyTypeModel from "./addCompanyType";

export default function RecrutingCompanyTable() {
  const [screeningPartners, setScreeningPartners] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openSkillModal, setOpenSkillModal] = React.useState(false);
  const [modalName, setModalName] = React.useState("");
  const loginStore = JSON.parse(localStorage.getItem("authUser"));
  const role = loginStore?.role
  const [viewAddModal, setViewAddModal] = React.useState(false)
  const [update,setUpdate] = React.useState(false)
  const [refetch,setRefetch] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const [totalCount,setTotalCount] = React.useState("")
  const rowsPerPage = 20;
  const offset = (page - 1) * rowsPerPage;
  const {search} = useLocation()
  const history = useHistory();

  React.useEffect(() => {
    initialFetch(role,page,rowsPerPage);
  }, [update]);

  React.useEffect(()=>{
    const searchParams = new URLSearchParams(search)
    searchParams.set("page",page)
    history.push({
      search: searchParams.toString(),
    });
    initialFetch(role,page,rowsPerPage);
  },[page])

  

  async function initialFetch(role,page,rowsPerPage) {
    setLoading(true);
    const { data, status } = await getAllRecruitmentUsersList(role,page,rowsPerPage);
    setTotalCount(Math.ceil((data?.count)/rowsPerPage))
    const result = data?.data?.length > 0 && data?.data?.map((item, index) => ({
      id: item?.id,
      email: `${item?.email}`,
      image: `${imageURL}${item?.image}`,
      phone_number: `${item?.phone_number}`,
      gender: `${item?.gender?item.gender:"N/A"}`,
      location: `${item?.location?item.location:"N/A"}`,
      industry: `${
        item?.industry_details?.length > 0
          ? item?.industry_details?.map(item => item?.name)?.join(",")
          : "N/A"
      }`,

      Categories: `${
        item?.functional_area_details?.length > 0
          ? item?.functional_area_details?.map(item => item?.name)?.join(",")
          : "N/A"
        }`,
      companies_assigned:item?.assigned_company_details?.length > 0
        ? item?.assigned_company_details?.map(item => item?.company_name)?.join(",")
        : "N/A" ,
      skills: `${
          item?.skill_details?.length > 0
            ? item?.skill_details?.map(item => item?.name)?.join(",")
            : "N/A"
        }`,    
    //   experience: `${item?.experience}`,
    //   work_mode: `${item?.work_mode}`,
    //   candidate_screened: `${item?.candidate_screened}`,
    //   candidate_onboarded: `${item?.candidate_onboarded}`,
    //   total_earnings: `${item?.total_earnings}`,
    //   available_earnings: `${item?.available_earnings}`,
    //   pending_earnings: `${item?.pending_earnings}`,
    //   highest_education: `${item?.highest_education}`,
    //   jobs_screened: item?.get_screened_job_post_counts?.count,
      name: `${item?.first_name} ${item?.last_name}`,
      Status: `${item.user_approval}`,
    }));
    if (status === 200) {
      setLoading(false);
      // setTotalCount(data?.count)
    }
    setScreeningPartners(result);
  }

  const handleCloseModal = () => {
    setOpenSkillModal(!openSkillModal);
  };
  
  const handleCellClick = params => {
    const field = params.field;

    if (field === "skills") {
      if (params.row.skills !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.skills.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } else if (field === "Categories") {
      if (params.row.Categories !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.Categories.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    }else if (field === "companies_assigned") {
      if (params.row.companies_assigned !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.companies_assigned.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    }
    else {
      if(!["skills","Categories","companies_assigned"].includes(field) && ![CA,CS].includes(loginStore.role))
        history.push(`/common-user/${params.row.id}`);

      // history.push(`/common-user/${params.row.id}`);
    }
  };

  return (
    <div style={{ padding: "10px", height: "91vh",display:'flex',flexDirection:'column'  }}>
      {[ZA].includes(loginStore.role) ?<PrimaryButton
        sx={{width:"max-content !important",ml:"auto !important"}}
        onClick={() => {
          // dispatch({ type: "APPROVE_MODAL" });
          setViewAddModal(true)
        }}
        // startIcon={(<FeatherIcon icon={"add"} size={16} />)}
      >
        Add
      </PrimaryButton>:null}
      <PrimaryDataGrid
        // onCellClick={event => {
        //   history.push(`/common-user/${event?.row?.id}`);
        // }}
        loading={loading}
        onCellClick={handleCellClick}
        // checkboxSelection={true}
        columns={[
          {
            field: "image",
            headerName: "Image",
            filterable: false,
            disableColumnMenu:true,
            renderCell: params => {
              return (
                <SmallPrimaryAvatar
                  imgProps={{ loading: "lazy" }}
                  src={params?.formattedValue}
                >
                  {params?.row?.name?.charAt(0)?.toUpperCase()}
                </SmallPrimaryAvatar>
              );
            },
          },
          { field: "name", headerName: "Name",width: 200 },
          { field: "phone_number", headerName: "Phone Number",width: 300 },
          { field: "email",headerName: "Email", width: 300 },
          {
            field: "Status",
            headerName: "Status",
            width: 200,
            renderCell: params => {
              if (params.formattedValue !== "Approve") {
                return <HoldChip label={params?.formattedValue} size="small"/>;
              }
              return <PrimaryChip label={params?.formattedValue} size="small"/>;
            },
          },
          { field: "gender",headerName: "Gender", filterOperators: GenderOperators },
          { field: "location",headerName: "Location", filterOperators: LocationOperators, width: 400 },
          { field: "industry",headerName: "Industry", filterOperators: IndustryOperators, width: 400 },
          {
            field: "Categories",
            headerName: "Categories",
            width: 400,
            renderCell: paramss => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {paramss.value && paramss.value !== "N/A" ? (
                  <>
                    {/* <span>
                      {paramss.value.split(",").slice(0, 2).join(",")}
                    </span>
                    {paramss.value.split(",").length > 1 && (
                      <InfoOutlinedIcon
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          opacity: "50%",
                        }}
                      />
                    )} */}
                       {paramss.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },
          ["ZA"].includes(role) ? (
          {
            field: "companies_assigned",
            width: 400,
            headerName:"Companies Assigned",
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center",gap:"8px" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                       {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          }
          ) : "",

          {
            field: "skills",
            headerName: "Skills",
            width: 400,
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                    {/* <span>{params.value.split(",").slice(0, 2).join(",")}</span>
                    {params.value.split(",").length > 1 && (
                      <InfoOutlinedIcon
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          opacity: "50%",
                        }}
                      />
                    )} */}
                     {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}} size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },          
        //   { field: "Categories", width: 400 },
        //   { field: "experience" },
        //   {
        //     field: "work_mode",
        //     filterOperators: WorkModeOperators,
        //     width: 200,
        //   },
        //   { field: "candidate_screened", width: 200 },
        //   { field: "candidate_onboarded", width: 200 },
        //   { field: "total_earnings", width: 200 },
        //   { field: "available_earnings", width: 200 },
        //   { field: "pending_earnings", width: 200 },
        //   { field: "highest_education", width: 200 },
        //   { field: "Bank_account_details" },
        ]}
        rows={screeningPartners}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButton,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: props => (
            <Pagination
              {...props}
              // color="primary"
              // count={Math.ceil(rowsData.length / rowsPerPage)}
              count={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onChange={(_, newPage) => setPage(newPage)}
              showFirstButton
              showLastButton
              sx={{
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: "#cde2e7",
                    color: "#066a4c",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#024430",
                    color: "#ffe7bb",
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                  },
                },
              }}
            />
          ),
        }}
        slotProps={{
          baseButton: { style: { width: "100px" } },
          panel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          filterPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          toolbar: { style: {} },
          preferencesPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
        }}
      />
        {openSkillModal && (
        <SkillViewModal
          rowdata={selectedRowData}
          closeModalFunc={handleCloseModal}
          openModal={openSkillModal}
          headerName={modalName}
        />
      )}
      {viewAddModal && (
        <CompanyTypeModel isOpen={viewAddModal} updated={()=>setUpdate(pre=>!pre)} toggle={()=>setViewAddModal(false)} />
      )}
    </div>
  );
}

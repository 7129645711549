const { Container, Row, Col } = require("reactstrap");
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import noData from "../../assets/images/zepul/no_jobs.svg";
import { CloseJobModel } from "pages/Job/JobList/CloseJobModel";
import { deleteSingleJob } from "helpers/services/jobs/jobs";
import { connect } from "react-redux";
import {
  GreyTypography,
  PrimaryOutlinedOpacitedButton,
  StyledCard,
  StyledCardContent,
} from "assets/Mui/globalTheme";
import { Stack } from "@mui/material";
import JobPostedCompanyAvatar from "components/jobCard/JobPostedCompanyAvatar";
import JobCardInformation from "components/jobCard/jobCardInformations";
import ViewMoreButton from "components/jobCard/ViewMoreButtons";
import ReplyIcon from "@mui/icons-material/Reply";
// import { ACM } from "common/constants/userModules";
import { ACM, CA, CS, ZA } from "common/constants/userModules";
import {
  getAllClosedJobs,
  getAllClosedJobsBasedOnCompany,
  getAllJobsBasedOnCompany,
  getSingleJobDetails,
} from "helpers/services/jobs/jobs";
import ErrorHandlingToaster from "helpers/Toaster/error";
import SearchParamHook from "hooks/searchParams";
import CustomPagination from "components/Pagination/globalPagination";
import { useLocation } from "react-router-dom";

// function ClosedJobs({ jobs, load, setUpdate }) {
function ClosedJobs({fetchCompanyJobs,filterCompanyJobs}) {
  const activePathDetails = fetchCompanyJobs?.activePath
  const companyId = fetchCompanyJobs?.companyId
  let history = useHistory();
  const role = JSON.parse(localStorage.getItem("authUser")).role;
  const login = {
    ...JSON.parse(localStorage.getItem("authUser")),
  };
  const [tableData, setTableData] = useState([]);
  // const [viewDetails, setViewDetails] = useState(tableData[0]);
  const [modelData, setModelData] = useState({
    modal: false,
    details: {},
  });

  const handleClose = confirm => {};

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const searchItems = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const results = tableData.filter(item =>
      item.job_title.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  }, [searchTerm]);

  // useEffect(() => {
  //   setViewDetails(tableData[0]);
  // }, [tableData]);
  function randcolor() {
    function c() {
      return Math.floor(Math.random() * 220 + 36).toString(16);
    }
    return "#" + c() + c() + c();
  }

  /*Individual Api with pagination start here */

  const [jobs, setJobs] = useState([]);
  const [load, setLoad] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");
  const [update, setUpdate] = useState(false);
  const [listLoad, setListLoad] = useState(false);
  const searchParams = SearchParamHook();

  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const rowsPerPage = 10;

  const { search } = useLocation();
  useEffect(() => {
    dataCaller();
  }, [searchParams.get("page")]);

  useEffect(() => {
    dataCaller();
    // setUpdate((pre)=>!pre);
  }, [update]);
  useEffect(() => {
    dataCaller();
  }, []);

  useEffect(()=>{
    if(filterCompanyJobs || !filterCompanyJobs){
      dataCaller();
    }
  
  },[filterCompanyJobs])


  useEffect(() => {
    const activeData = jobs.filter(data => data.is_active === false);
    setTableData(activeData);
  }, [jobs]);

  async function dataCaller() {
    setLoad(true);
    try {
      const { data, status } = [CS].includes(login.role) || fetchCompanyJobs?.companyId 
        ?
        //  await getAllClosedJobsBasedOnCompany(login.company_id, page,rowsPerPage)
        await getAllJobsBasedOnCompany(companyId,activePathDetails,page,rowsPerPage)
        : await getAllClosedJobs(page, rowsPerPage);

      if (status === 200) {
        const sortedJobsList = data?.job_post_details?.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        setJobs(sortedJobsList);
        setLoad(false);

      
        setTotalCount(Math.ceil((data?.overall_jobs_count)/rowsPerPage))
      }
      ErrorHandlingToaster({ data, status });
    } catch (error) {
      setLoad(false);
    }
  }
  /*Individual Api with pagination start here */

  return (
    <div className="loader_container">
      {!load ? (
        <Container fluid style={{ paddingLeft: "15px", paddingRight: "15px", height: "75vh", }}>
          <Row style={{height:"90%",overflowY:"scroll"}}>
            {tableData?.length > 0 ? (
              tableData.map(item => (
                <Col xs={12} md={6} lg={4} className="mb-4" key={item.id}>
                  <StyledCard>
                    <StyledCardContent>
                      <Stack direction="column" spacing={1}>
                        <JobPostedCompanyAvatar item={item} />

                        <JobCardInformation
                          tableData={tableData}
                          setUpdate={setUpdate}
                          setTableData={setTableData}
                          item={item}
                        />
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent={"flex-end"}
                        >
                          {[ACM].includes(role) ? (
                            <PrimaryOutlinedOpacitedButton
                              sx={{ width: "130px" }}
                              endIcon={
                                <ReplyIcon
                                  sx={{
                                    transform:
                                      "rotate3d(0,1,0,180deg) !important",
                                  }}
                                />
                              }
                            >
                              Transfer
                            </PrimaryOutlinedOpacitedButton>
                          ) : null}
                          <ViewMoreButton id={item?.id} />
                        </Stack>
                      </Stack>
                    </StyledCardContent>
                  </StyledCard>
                </Col>
              ))
            ) : (
              <div className="no_data_show">
                <div>
                  <img src={noData} className="lg_img"></img>
                  <GreyTypography variant="h6">
                    Closed Bucket is Empty
                  </GreyTypography>
                </div>
              </div>
            )}
          </Row>

          {modelData ? (
            <CloseJobModel
              setModelData={doc => setModelData(doc)}
              show={modelData.modal}
              onHide={() => {
                setModelData({ modal: false, details: {} });
              }}
              params={modelData}
              onSubmit={async () => {
                const { status } = await deleteSingleJob(modelData.details.id);
                const index = this.state.tableData.findIndex(
                  ({ id }) => id === modelData.details.id
                );
                const data = [...this.state.tableData];

                data.splice(0, index);
                this.setState({ tableData: data });
                handleClose();
              }}
            />
          ) : null}

          {tableData?.length > 0 ? (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                // marginBottom: "50px",
              }}
            >
              <CustomPagination
                count={totalCount}
                page={page}
                setPage={setPage}
              />
            </div>
          ) : (
            ""
          )}
        </Container>
      ) : (
        <div className="loader"></div>
      )}
    </div>
  );
}
const mapStateToProps = state => ({
  role: state.Login.role,
});
export default connect(mapStateToProps, null)(ClosedJobs);

import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryOpacitedButton,
  PrimaryTypography,
  GrayDivider,
  BlackTypography,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import InputButtonField from "./inputButtonField";
import { useEffect, useState } from "react";
import moment from "moment";
import MaskedInput from "string-mask";
import { roleRestrictionsForHash } from "common/constants/roleRestrictions";
import { useSelector } from "react-redux";
import SearchParamHook from "hooks/searchParams";

export default function CandidateInformation({ candidateDetails,readOnly }) {
  const [candidate, setCandidate] = useState({});
  const role = useSelector(state => state.Login.role);
  const searchParams = SearchParamHook();
  const order = searchParams.get("stage_order")

  useEffect(() => {
    setCandidate(candidateDetails);
  }, [candidateDetails]);
  
  const dateOfBirth = candidate?.dob


    const ageObj = candidate?.dob? `${moment(candidate?.dob).format("DD/MM/YYYY")}-(${candidate?.age_of_candidate}Yrs)`:"null"
    // const ageObj = `${candidate.dob}-(${candidate?.age_of_candidate}Yrs)`
    const age = ageObj.toString()
    

  /* Age calculation code start here */
  // function calculateAge(selectedDate) {
  //   const differenceInMilliseconds = new Date() - new Date(selectedDate);
  //   const yearsDifference =
  //     differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365);
  //   const age = Math.floor(yearsDifference);
  //   return age;
  // }

  // // Calculate age whenever selectedDate changes
  // const selectedDate = watch(update_type);
  // const age = calculateAge(selectedDate);

  // // Concatenate the selected date and age
  // const newValueWithAge = `${selectedDate} ${age} yrs`;
  /* Age calculation code start here */

   /* Mobile num hashing */
   const mask = new MaskedInput('AA*****AA', { A: /[A-Za-z]/ });
   const hashedNames = (data)=>{
     const stageHashCheck = roleRestrictionsForHash[role]?.includes(parseInt(order))
   
     if(stageHashCheck){
       return data;
     }else {
    
       const hashedOne = mask.apply(data)
       return hashedOne;
     }
   }
   /* Mobile num hashing */


  console.log(candidate,"candidate")
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
        <PrimaryTypography component="h4" variant="h4">
          Candidate Details
        </PrimaryTypography>
      </Stack>
      <BorderCard>
        <CardContent>
          <Stack spacing={1}>
            <InputButtonField
              text_field={"First Name"}
              input_value={candidate?.first_name}
              type="input"
              update_type={"first_name"}
              readOnly={readOnly}
            />

            <InputButtonField
              text_field={"Last Name"}
              input_value={candidate?.last_name}
              type="input"
              update_type={"last_name"}
              readOnly={readOnly}
            />

            <InputButtonField
              text_field={"Gender"}
              input_value={candidate?.gender}
              type="select"
              update_type={"gender"}
              readOnly={readOnly}
            />
            <InputButtonField
              text_field={"Current Company"}
              input_value={candidate?.current_company}
              type="input"
              update_type={"current_company"}
              readOnly={readOnly}
            />

            <InputButtonField
              text_field={"Current Designation"}
              input_value={candidate?.current_postition}
              type="input"
              update_type={"current_postition"}
              readOnly={readOnly}
            />
            <InputButtonField
              text_field={"Current Department"}
              input_value={candidate?.current_department}
              type="input"
              update_type={"current_department"}
              readOnly={readOnly}
            />
            <InputButtonField
              text_field={"Current Industry"}
              input_value={candidate?.current_industry}
              type="input"
              update_type={"current_industry"}
              readOnly={readOnly}
            />
            <InputButtonField
              text_field={"Work Location"}
              input_value={candidate?.work_location?.length ?candidate?.work_location[0]:"N/A"}
              type="location"
              update_type={"location"}
              readOnly={readOnly}
            />
            <InputButtonField
              text_field={"Birth Date"}
              // input_value={candidate?.dob}
              input_value={age}
              type="date"
              update_type={"dob"}
              readOnly={readOnly}
            />
            <InputButtonField
              text_field={"Current Address"}
              input_value={candidate?.current_address}
              type="multiline"
              update_type={"current_address"}
              readOnly={readOnly}
            />
            {/* <InputButtonField
              text_field={"Email"}
              input_value={candidate?.email}
              type="input"
              update_type={"candidate_email"}
              readOnly={readOnly}
            /> */}
            <InputButtonField
              text_field={"Other Contact"}
              input_value={hashedNames(candidate?.other_contact)}
              input_type="number"
              type="input"
              update_type={"other_contact"}
              readOnly={readOnly}
            />
          </Stack>
        </CardContent>
      </BorderCard>
    </Stack>
  );
}

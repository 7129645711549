import { POST } from "common/constants/methods";
import callApi from "helpers/callApi";

export async function viewSingleRefaralPartner(id) {
  return await callApi(`referral_partner_authorise/${id}`);
}
export const viewSingleUserDetailsInKanbanBoard = async (uid, job_post_id) => {
  return await callApi(
    `referral_partner_authorise/get_job_post_details?user_id=${uid}&job_post_id=${job_post_id}`
  );
};
export const getAllReferalParterns = async (path,page,rowsPerPage) => {
  return await callApi(`referral_partner_authorise?type=${path}&&pageSize=${rowsPerPage}&&pageNo=${page}`);
  // return await callApi(`referral_partner_authorise?type=${path}`);
};

export async function getRefsByCompany(compId,pageNo,pageSize=20) {
  return await callApi(`company_authorise/getCandidatesBasedOnCompany?company_id=${compId}&pageNo=${pageNo}&pageSize=${pageSize}`)
}

export async function createReferalPartner(data) {
  return await callApi("referral_partner_authorise/register",data,POST)
}

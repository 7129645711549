import React, { useEffect, useState } from "react";

import { Col, Row, Container, Label } from "reactstrap";
import { Cascader } from "antd";
import "antd/dist/antd.css";
import "../JobType.css";
import {
  getAllEducationalQualifications,
  getAllEducationalSpecialization,
  getEducationalSpecializationWithQualification,
} from "helpers/services/jobAttributes/educationalQualifications";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import "./Education.css";
import { connect } from "react-redux";
import { updateJob } from "store/jobs/actions";
import { makeStyles } from "@mui/styles";
import { SecondaryFilledChip } from "assets/Mui/globalTheme";
const useStyles = makeStyles({
  error:{
    "& .ant-select-selector":{
      borderColor:"red !important",
      borderRadius:"8px !important"
    }
  }
})
const EducationQualification = ({ updateJob, jobs }) => {
  const { SHOW_CHILD } = Cascader;
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  // const [specs,setSpecs] = useState([]);
  const classes = useStyles()
  useEffect(() => {
    const data = jobs.educational_qualifications.map(each=>[each.education_qualification_id,each.education_qualification_spec_id])
    setSelected(data)
  }, [jobs.educational_qualifications]);
  useEffect(() => {
    dataCaller()
    // getQualifySpecs()
    // return () => {
    //   dataCaller()
    // };
  }, []);
  // console.log(selected,"v");
  // async function getQualifySpecs(){
  //   const x =  await getAllEducationalSpecialization();
  //   setSpecs(x)
  // }
  async function dataCaller() {
    const data = await getAllEducationalQualifications();
    const items = await data.filter(vals=>vals.is_active).map(iterator => {
      return {
        value: iterator.id,
        label: iterator.name,
        children: [...iterator.specs].filter(vals=>vals.is_active).map((e) => ({
          label:e.name,
          value:e.id,
        })),
        isLeaf: false,
      };
    });
    setOptions(items)
  }

  // async function onClickRenderChildren(selectedOption){
  //   const item = selectedOption[selectedOption.length-1]
  //   item.loading = true
  //   const fetchChildList = await getEducationalSpecializationWithQualification(item.value)
  //   const childItems = fetchChildList.filter(vals=>vals.is_active).map(each=>({
  //     value:each.id,
  //     label:each.name
  //   }))
  //   item.loading = false
  //   item.children = childItems
  //   setOptions([...options])
  //   // setLoadingOptions([...options]);
  // }
  
  function handleOnchange(value,s){
    const tweakValue = value.map(item=>{
      const [education_qualification_id,education_qualification_spec_id] = item
      return({
        education_qualification_id,
        education_qualification_spec_id
      })
    })
    setSelected(value)
    // console.log(value,s,tweakValue,"v");
    updateJob({ educational_qualifications: tweakValue });
  }
  // console.log(specs,"v");
  const isError = !Boolean(selected.length)
  return (
    <div className="px-0" id="job-type-main-cont">
      <Container className="px-0" fluid>
        <Row>
          <Col xs={12} style={{ margin: "auto" }}>
            <h5 style={{}}>
              Please choose Educational Qualification?{" "}
              <span className="text_sm_gray">(Select all that applied)</span>
            </h5>
          </Col>
          <Col xs={12}>
            <Label>
              Enter your Qualifications <span className="red">*</span>
            </Label>
            <div className="mt-3">
              <div id="education_qual">
                <Cascader
                  style={{
                    width: "100%",
                  }}
                  className={isError ? classes.error :""}
                  options={options}
                  value={selected}
                  showCheckedStrategy={SHOW_CHILD}
                  // loadData={onClickRenderChildren}
                  onChange={handleOnchange}
                  multiple
                  allowClear
                  tagRender={(label,_,value)=>
                    <SecondaryFilledChip style={{marginRight:"4px"}} onDelete={label.onClose}  label={label.label} deleteIcon={<FeatherIcon icon={"x"} size="14" />} />
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
// const EducationQualification = ({ updateJob, jobs }) => {
//   const { SHOW_CHILD } = Cascader;
//   const [selected, setSelected] = useState([]);
//   const [options, setOptions] = useState([]);
//   // useEffect(() => {
//   //   fetchDetails();
//   // }, [jobs.educational_qualifications]);
//   useEffect(() => {
//     // let mounted = true;
//     async function dataCaller() {
//       const data = await getAllEducationalQualifications();
//       const items = await data.filter(vals=>vals.is_active).map(iterator => {
//         return {
//           value: iterator.id,
//           label: iterator.name,
//           isLeaf: false,
//         };
//       });

//       setOptions(items);
//     }
//     // mounted ? dataCaller() : null;
//     return () => {
//       mounted = false;
//     };
//   }, []);
//   // async function fetchDetails() {
//   //   let data = [];
//   //   const selectedQualifications = [...options];
//   //   await jobs.educational_qualifications.map(async item => {
//   //     data.push(
//   //       item?.education_qualification_id,
//   //       item?.education_qualification_spec_id
//   //     );
//   //     const selectedItems = options.find(
//   //       opt => opt.value === item.education_qualification_id
//   //     );
//   //     let spec_data = [];
//   //     spec_data = await getEducationalSpecializationWithQualification(
//   //       selectedItems?.value
//   //     );
//   //     const children = await spec_data?.map(spec => ({
//   //       value: spec.id,
//   //       label: spec.name,
//   //     }));
//   //     selectedItems["children"] = children;
//   //     selectedItems["loading"] = false;
//   //     selectedItems["isLeaf"] = true;

//   //     selectedQualifications.push(selectedItems);
//   //   });
//   //   setOptions([...selectedQualifications]);
//   //   setSelected(data);
//   // }
//   const onChange = async (value, selectedOptions) => {
//     console.log(value,selectedOptions,"v parent");
//     let totalData = [];
//     totalData = jobs.educational_qualifications;
//     setSelected(value);
//     await value.map(async item => {
//       if (item.length === 1) {
//         const data = item[0];
//         const items = await getEducationalSpecializationWithQualification(data);
//         let result = [];
//         result = await items?.map(item => {
//           const idx = totalData.findIndex(
//             ({ education_qualification_spec_id }) =>
//               education_qualification_spec_id === item.id
//           );
//           if (idx === -1)
//             return {
//               education_qualification_id: item.educational_qualification_id,
//               education_qualification_spec_id: item.id,
//             };
//           else
//             return {
//               education_qualification_id: undefined,
//               education_qualification_spec_id: undefined,
//             };
//         });
//         const fitered = result.filter(
//           item => item.education_qualification_id !== undefined
//         );
//         totalData.push(...fitered);
//       } else {
//         const data = [];
//         data.push({
//           education_qualification_id: item[0],
//           education_qualification_spec_id: item[1],
//         });
//         totalData.push(...data);
//       }

//       updateJob({ educational_qualifications: totalData });
//     });
//   };
//   const loadData = async selectedOptions => {
//     console.log(selectedOptions,"v child");
//     const targetOption = selectedOptions[selectedOptions.length - 1];
//     targetOption.loading = true;
//     const data = await getEducationalSpecializationWithQualification(
//       selectedOptions[0].value
//     );
//     const children = await data.map(item => {
//       return {
//         value: item.id,
//         label: `${targetOption.label}/ ${item.name}`,
//       };
//     });
//     targetOption.loading = false;
//     targetOption.isLeaf = false;
//     targetOption.children = [...children];
//     setOptions([...options]);
//   };

//   return (
//     <div className="px-0" id="job-type-main-cont">
//       <Container className="px-0" fluid>
//         <Row>
//           <Col xs={12} style={{ margin: "auto" }}>
//             <h5 style={{}}>
//               Please choose Educational Qualification?{" "}
//               <span className="text_sm_gray">(Select all that applied)</span>
//             </h5>
//           </Col>
//           <Col xs={12}>
//             <Label>
//               Enter your Qualifications <span className="red">*</span>
//             </Label>
//             <div className="mt-3">
//               <div id="education_qual">
//                 <Cascader
//                   style={{
//                     width: "100%",
//                   }}
//                   options={options}
//                   value={selected}
//                   showCheckedStrategy={SHOW_CHILD}
//                   loadData={loadData}
//                   onChange={onChange}
//                   multiple
//                   changeOnSelect
//                 />
//               </div>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

const mapStateToProps = ({ Jobs }) => ({
  jobs: Jobs,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EducationQualification);

import React, { useState, useEffect } from "react";
import {
  ScoreCardBox,
  ScoreCardHeader,
  BlackTypography,
  DownloadMenuButton,
  StyledCard,
  StyledEvalCard,
} from "assets/Mui/globalTheme";
import { Stack, Grid, InputLabel, Select, Box } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Avatar from "@mui/material/Avatar";
import EvaluationValue from "./EvaluationValue";
import EvaluationSlider from "./EvaluationSlider";
import { useSelector } from "react-redux";
import { useRef } from "react";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function EvaluationCards({
  scoreData,
  screeningSkills,
  evaluatorSkills,
  moderatorSkills,
  jobPostInfo,
  setDetails,
  setSkillsType,
  skillsType
}) {
  const [currentValue, setCurrentValue] = useState(skillsType);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(()=>{
    setCurrentValue(skillsType)
  },[skillsType])

  /*Old Integration */
  const { candidate } = useSelector(state => state.Candidates);
  const jobTitle =
    candidate?.user_job_post_details?.job_post_in_user_job_post?.job_title ||
    "";
  let finalData = scoreData;
  const screeningPartnerDetails = finalData?.screening_partner_evalutation;
  const technicalEvalDetails = finalData?.technical_evalutation;
  const moderatorDetails = finalData?.moderator_evalutation;
  const btnRef = useRef(null);
  let finalScore =
    (screeningPartnerDetails?.average_rating || 0) +
    (technicalEvalDetails?.average_rating || 0) +
    (moderatorDetails?.average_rating || 0);
  // const avg = Math.round(overAllScore / 3);
  const avg = finalScore / 3;
  const roundScore = avg > 1 ? avg.toFixed(1) : avg === 0 ? 0 : avg.toFixed(1);
  /*Old Integration */

  return (
    <>
      <ScoreCardBox>
        <ScoreCardHeader>
          <Grid container alignItems="center">
            <Grid xs={8} lg={10} sx={{ padding: "8px" }}>
              <BlackTypography varient="h4">Evaluation Report</BlackTypography>
            </Grid>
            <Grid xs={8} lg={2} sx={{ padding: "8px" }}>
              <Stack
                direction={"row"}
                justifyContent={"end"}
                alignItems={"end"}
              >
                <Select
                  labelId="demo-simple-select-label"
                  //   input={<DownloadMenuButton />}
                  sx={{
                    backgroundColor: "#E6F0ED",
                    color: "#000000",
                    borderRadius: "8px",
                    height: "36px",
                    width: 100,
                    fontSize: "13px",
                  }}
                  value={currentValue}
                  // defaultValue={currentValue}
                  onChange={e => {
                    const {
                      target: { value },
                    } = e;
                    setSkillsType(e.target.value)
                    // setCurrentValue(value);
                    setCurrentValue(
                      typeof value === "string" ? value.split(",") : value
                    );
                  }}
                >
                  {["skill", "question"].map(each => {
                    return (
                      <MenuItem
                      key={each} value={each}
                        sx={{ height: "36px", fontSize: "13px" }}
                      >
                        {each}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Grid>
          </Grid>
        </ScoreCardHeader>

        <Box sx={{ padding: "16px" }}>
          <Grid container spacing={1} sx={{ paddingTop: "12px" }}>
            <Grid lg={4}>
              <StyledEvalCard>
                <CardHeader
                  avatar={
                    <Avatar
                      sx={{
                        bgcolor: "#E6F0ED",
                        color: "#000000",
                        fontSize: "13px",
                        fontFamily: "Inter",
                      }}
                      aria-label="recipe"
                    >
                      SP
                    </Avatar>
                  }
                  title={`${
                    screeningPartnerDetails?.sp_details?.first_name
                      ? screeningPartnerDetails?.sp_details?.first_name +
                        screeningPartnerDetails?.sp_details?.last_name
                      : "N/A"
                  }`}
                  subheader="Screening Evaluation"
                />
                <EvaluationSlider
                  evalution_name={"screening"}
                  color="#455A64"
                  thumb__hover_color={" rgba(58, 133, 137, 0.16)"}
                  score={screeningPartnerDetails?.average_rating || 0}
                />
              </StyledEvalCard>
            </Grid>

            <Grid lg={4}>
              <StyledEvalCard>
                <CardHeader
                  avatar={
                    <Avatar
                      sx={{
                        bgcolor: "#E6F0ED",
                        color: "#000000",
                        fontSize: "13px",
                        fontFamily: "Inter",
                      }}
                      aria-label="recipe"
                    >
                      M
                    </Avatar>
                  }
                  title={`${
                    moderatorDetails?.moderator_details?.first_name
                      ? moderatorDetails?.moderator_details?.first_name +
                        moderatorDetails?.moderator_details?.last_name
                      : "N/A"
                  }`}
                  subheader="Moderator Evaluation"
                />
                <EvaluationSlider
                  evalution_name={"Moderator"}
                  color="#7986CB"
                  thumb__hover_color={" rgba(65, 58, 137, 0.16)"}
                  score={moderatorDetails?.average_rating || 0}
                />
              </StyledEvalCard>
            </Grid>

            <Grid lg={4}>
              <StyledEvalCard>
                <CardHeader
                  avatar={
                    <Avatar
                      sx={{
                        bgcolor: "#E6F0ED",
                        color: "#000000",
                        fontSize: "13px",
                        fontFamily: "Inter",
                      }}
                      aria-label="recipe"
                    >
                      TE
                    </Avatar>
                  }
                  title={`${
                    technicalEvalDetails?.evaluator_details?.first_name
                      ? technicalEvalDetails?.evaluator_details?.first_name +
                        technicalEvalDetails?.evaluator_details?.last_name
                      : "N/A"
                  }`}
                  subheader="Technical Evaluation"
                />
                <EvaluationSlider
                  evalution_name={"Technical"}
                  color="#4FC3F7"
                  thumb__hover_color={" rgba(59, 119, 137, 0.16)"}
                  score={technicalEvalDetails?.average_rating || 0}
                />
              </StyledEvalCard>
            </Grid>
          </Grid>
        </Box>
      </ScoreCardBox>
    </>
  );
}

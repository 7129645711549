import React from 'react';
import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryTypography,
  GrayDivider,
  GreyTypography,
  BlackTypography,
  PrimaryOpacitedButton,
  PrimaryButton,
  MiddleDivider,
  PrimaryAvatar,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import AddEditWorkExperience from "./AddEditWorkExperience";
import { updateJobPost } from "helpers/services/jobs/jobs";
import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
import SearchParamHook from "hooks/searchParams";
import WholeEditWorkExperience from "./WholeEditWorkExperience";
import Badge from "@mui/material/Badge";

export default function CandidateExperience({ candidateDetails,readOnly }) {
  const [workExpModel, setWorkExpModel] = useState(false);
  const [editWorkExpModel, setEditWorkExpModel] = useState(false);
  const [updateType, setUpdateType] = useState("create");
  const [updateIndex, setUpdateIndex] = useState(0);
  const [workExp, setWorkExp] = useState([]);
  useEffect(() => {
    if (candidateDetails?.work_exp) {
      setWorkExp(candidateDetails?.work_exp);
    }
  }, [candidateDetails]);
  const [candidateId, setCandidateId] = useState(null);
  const searchParams = SearchParamHook();
  useEffect(() => {
    let queryParamsForUserId = searchParams.get("user_id");
    if (queryParamsForUserId)
      setCandidateId(queryParamsForUserId);
  }, [searchParams]);
  function toggleModel() {
    setWorkExpModel(!workExpModel);
  }
  function editToggleModel() {
    setEditWorkExpModel(!editWorkExpModel);
  }
  async function updateOrEditWorExperience(work_exp_data,checkMark) {
    // console.log(work_exp_data,"work_exp_data")
    toggleModel();
    // console.log(work_exp_data,editor,"v");
    if (updateType?.includes("update")) {
      const obj = { ...work_exp_data,is_currently_working:checkMark }
      // if (!work_exp_data.desc) {
      //   obj.desc=e
      // }
      const data = [...workExp];
      data[updateIndex] = {
        ...obj,
      };
      await updateUserJobPost(candidateId, {
        work_exp: data,
      });
      setWorkExp(data);
    } else {
      const obj = { ...work_exp_data,is_currently_working:checkMark }
      setWorkExp([...workExp, obj]);
      await updateUserJobPost(candidateId, {
        work_exp: [...workExp, obj],
      });
    }
  }

  async function deleteWorkExperience(index) {
    const filterExp = workExp.filter((_, id) => id !== index)
    await updateUserJobPost(candidateId, {
      work_exp: filterExp,
    });
    setWorkExp(filterExp);
    // console.log(index,filterIndex);
  }

  return (
    <Stack spacing={2} className="mt-3">
      <AddEditWorkExperience
        toggleModel={toggleModel}
        open={workExpModel}
        type={updateType}
        currentData={workExp[updateIndex]}
        updateOrEditWorExperience={updateOrEditWorExperience}
      />
      <WholeEditWorkExperience
        editToggleModel={editToggleModel}
        toggleModel={toggleModel}
        open={editWorkExpModel}
        type={updateType}
        data={workExp}
        setUpdateType={setUpdateType}
        setUpdateIndex={setUpdateIndex}
        deleteWorkExperience={deleteWorkExperience}
      />
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
        <Stack
          spacing={1}
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <PrimaryTypography component="h4" variant="h4">
            Experience Section
          </PrimaryTypography>
          {/* <PrimaryOpacitedButton
            onClick={() => {
              toggleModel();
              setUpdateType("create");
            }}
            startIcon={<FeatherIcon icon="plus" size="14" />}
          >
            Add
          </PrimaryOpacitedButton> */}
          {!readOnly?
          <FeatherIcon
            icon="plus"
            size="18"
            onClick={() => {
              toggleModel();
              setUpdateType("create");
            }}
          />:""}
          {!readOnly?
          <FeatherIcon
            icon="edit"
            size="16"
            onClick={() => {
              editToggleModel();
              setUpdateType("wholeEdit");
            }}
          />:""}
        </Stack>
      </Stack>
      {/* <BorderCard>
        <CardContent>
          <Stack spacing={3}>
            {workExp?.map(
              ({ title, employer, start_date, end_date }, workExpIndex) => {
                return (
                  <>
                    <Stack spacing={1}>
                      <BlackTypography variant="h5" component="h5">
                        {title}
                      </BlackTypography>
                      <BlackTypography variant="h6" component="h6">
                        {employer}
                      </BlackTypography>
                      <GreyTypography variant="h6" component="h6">
                        {start_date} - {end_date}
                      </GreyTypography>
                      <PrimaryButton
                        sx={{ width: "100px" }}
                        onClick={() => {
                          toggleModel();
                          setUpdateType(`update${workExpIndex}`);
                          setUpdateIndex(workExpIndex);
                        }}
                      >
                        Edit
                      </PrimaryButton>
                    </Stack>
                  </>
                );
              }
            )}
          </Stack>
        </CardContent>
      </BorderCard> */}

      <BorderCard>
        <CardContent>
          <Stack spacing={3}>
            {workExp.length > 0 && workExp?.map(
              ({ title, employer, start_date, end_date,desc,skill,image }, workExpIndex) => {
                const isLastItem = workExpIndex === workExp.length - 1;
                return (
                  <>
                    <Stack spacing={1} direction="row">
                    <PrimaryAvatar
                              variant="square"
                              sx={{ border: "none" }}
                              //   src={`${syoft}`}
                              src={`${image}`}
                              alt={`${employer?.charAt(0, 2)}`}
                            />
                      <Stack
                        style={{ whiteSpace: "normal", width: "90%" }}
                        spacing={0.8}
                      >
                        <BlackTypography variant="h4" component="h4">
                          {title}
                        </BlackTypography>
                        <BlackTypography variant="h5" component="h5">
                          {employer} - Fulltime
                        </BlackTypography>
                        <GreyTypography variant="h6" component="h6">
                          {start_date} - {end_date}
                        </GreyTypography>
                        <BlackTypography>Skills:</BlackTypography>
                        {/* <GreyTypography
                          variant="h6"
                          component="h6"
                          style={{ width: "100%", wordWrap: "break-word" }}
                        >
                          ReactJs,NodeJs,Python,Angular,ExpressJs,Devops,Jenkins,Microservices
                        </GreyTypography> */}

                        <GreyTypography
                          variant="h6"
                          component="h6"
                          style={{ width: "100%", wordWrap: "break-word" }}
                        >
                          {skill?.length > 0 && skill?.map((each, index, array) => (
                            <React.Fragment key={each}>
                              {each?.skillName?.inputValue?each.skillName.inputValue:each.skillName.name}{" -"}{each?.experienceyears}{"."}{each?.experiencemonths}{"Yrs"}
                              {index !== array.length - 1 && ", "}
                            </React.Fragment>
                          ))}
                        </GreyTypography>

                      <BlackTypography>Description:</BlackTypography>    
                      <GreyTypography contentEditable='false' dangerouslySetInnerHTML={{ __html: desc }}></GreyTypography>

                        {!isLastItem && <MiddleDivider />}
                      </Stack>
                      {/* <PrimaryButton
                        sx={{ width: "100px" }}
                        onClick={() => {
                          toggleModel();
                          setUpdateType(`update${workExpIndex}`);
                          setUpdateIndex(workExpIndex);
                        }}
                      >
                        Edit
                      </PrimaryButton> */}
                      {/* <Stack
                        justifyContent="flex-start"
                        marginLeft="auto !important"
                        alignItems="end"
                      >
                        <FeatherIcon
                          icon="edit"
                          size="16"
                          onClick={() => {
                            toggleModel();
                            setUpdateType(`update${workExpIndex}`);
                            setUpdateIndex(workExpIndex);
                          }}
                        />
                      </Stack> */}
                    </Stack>
                  </>
                );
              }
            )}
          </Stack>
        </CardContent>
      </BorderCard>
    </Stack>
  );
}

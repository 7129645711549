import { takeEvery, put, call } from "redux-saga/effects";
import { LOGIN_USER, LOGOUT_USER, SIGNUP_PARTNER } from "./actionTypes";
import { loginSuccess, apiError, isAdmin } from "./actions";
import { convertRoleForApi, roleFinder } from "../../../helpers/roleFinder";
import { CA, ZA } from "common/constants/userModules";
import callApi from "helpers/callApi";
import { POST } from "common/constants/methods";
import { toast } from "react-hot-toast";
import ErrorHandlingToaster from "helpers/Toaster/error";

async function localstore(data) {
  if (data.role === "REFERAL_PARTNER") return;
  const role = roleFinder(data.role);
  await localStorage.setItem(
    "authUser",
    JSON.stringify({ ...data, role, token: data.token })
  );
}
async function apiRoute(role) {
  switch (role) {
    case ZA:
      return "admin_authorise/login";
    default:
      return "admin_authorise/login";
  }
}

function* loginUser({ payload: { user, history, location,loadCB } }) {
  const apiRequest = yield call(apiRoute, user.role);
  const response = yield call(
    callApi,
    apiRequest,
    {
      email: user.email,
      password: user.password,
      login_type: "normal",
    },
    POST
  );
  // console.log(response?.data?.company_details?.company_name,"response...")
  // localStorage.setItem("companyName",response?.data?.company_details?.company_name)
  if (!response) {
    loadCB(false)
    return
  }
  if ([200, 201].includes(response?.status)) {
    toast.success("Successfully Logged In!")
  }
  if (response?.status !== 200) {
    ErrorHandlingToaster(response);
    yield put(apiError(response?.data?.message));
    return;
  }
  if (response.data.usertype === "SCREENING_PARTNER") {
    yield call(localstore, {
      role: response?.data?.usertype,
      screeningPartner_id: response?.data?.id,
      id: response?.data?.id,
      token: response?.data?.access,
    });
  } else if (response.data.usertype === "RECRUITMENT_COMPANY") {
    yield call(localstore, {
      role: "RECRUITMENT_COMPANY",
      recrutement_company_id: response?.data?.company_id,
      id: response?.data?.id,
      token: response?.data?.access,
    });
  } else if (response.data.usertype === "BACKGROUND_VERIFICATION_STAFF") {
    yield call(localstore, {
      role: "BACKGROUND_VERIFICATION_STAFF",
      recrutement_company_id: response?.data?.company_id,
      user_id: response?.data?.id,
      id: response?.data?.id,
      token: response?.data?.access,
    });
  } else if (response.data.usertype === "RECRUTEMENT_COMPANY_STAFF") {
    yield call(localstore, {
      role: "RECRUTEMENT_COMPANY_STAFF",
      recrutement_company_id: response?.data?.company_id,
      user_id: response?.data?.id,
      id: response?.data?.id,
      token: response?.data?.access,
    });
  } else
    yield call(localstore, {
      role: response?.data?.usertype,
      company_id: response?.data?.company_id,
      id: response?.data.id,
      token: response?.data?.access,
      company_name:response?.data?.company_details?.company_name
    });
  yield put(loginSuccess(response.data));
  if (response?.data?.usertype === "ADMIN") yield put(isAdmin(true));
  else yield put(isAdmin(false));
  if (!location?.state?.from?.pathname) history.push("/dashboard");
  else
    history.push(
      location?.state?.from?.pathname + location?.state?.from?.search
    );
}

function* logoutUser({ payload: { history } }) {
  try {
    toast.success("Successfully Logged Out")
    localStorage.removeItem("authUser");
    history.replace("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}
async function signupApiRoute(role) {
  switch (role) {
    case CA:
      return "company_authorise/register";
    default:
      return "admin_authorise/register";
  }
}
function* signupPartner({ payload: { user, history } }) {
  const usertype = convertRoleForApi(user.usertype);
  const route = yield call(signupApiRoute, user.usertype);

  const response = {
    ...user,
    usertype: usertype,
  };
  const dataItem = yield call(callApi, route, response, POST);
  if (!dataItem?.data) return;

  yield put(loginSuccess(dataItem.data));
  yield call(localstore, {
    role: response?.data?.usertype,
    token: response?.data?.access,
  });
  if (user.usertype === "BVC") {
    history.push("/");
    return;
  }
  if (user.usertype === "CA") {
    //TODO Fix this
    history.push("/company-create");
    return;
  }
  if (user.usertype === "SP") {
    history.push("/");
  }
  if (user.usertype === "ACM") {
    history.push("/login");
  }
  if (user.usertype === "ZM") {
    history.push("/login");
  }
}
function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(SIGNUP_PARTNER, signupPartner);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;

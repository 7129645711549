import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "reactstrap";
import {
  FormControl,
  Grid,
  Stack,
  Select,
  MenuItem,
  IconButton,
  createFilterOptions,
  Checkbox,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import {
  BlackTypography,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  SecondaryFilledButton,
  PrimaryCheckbox,
  PrimaryAutoComplete,
  autocompleteProps,
  MultipleSelectInputs,
} from "assets/Mui/globalTheme";

import "./JobLocation.css";
import "antd/dist/antd.css";
import "./JobType.css";
import { updateJob } from "store/jobs/actions";
import { connect } from "react-redux";
import { getAllJobLevelsBasedonFunctionalArea } from "helpers/services/jobAttributes/jobLevels";
import { JobFunctionalAreaOtherModel } from "./FunctionalArea/JobFunctionalAreaOtherModel";

const JobTypes = ({ updateJob, job_roles, functional_areas }) => {
  const [tagsData, setTagsData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [model, setModel] = useState(false);
  useEffect(() => {
    getAllJobRoles();
  }, [functional_areas]);
  
  const options = tagsData.map(item => ({
    label: item.name,
    value: item.id,
  }));
  const defaultOption = options.find(option => option.value === filter[0]);

  async function getAllJobRoles() {
    if (functional_areas[0].length > 0) {
      const items = await getAllJobLevelsBasedonFunctionalArea(
        functional_areas[0]
      );
      setTagsData(items);
    }
  }
  useEffect(() => {
    const data = job_roles;
    setFilter(data);
  }, [job_roles]);
  // console.log(tagsData,job_roles,"v");
  function handleFilterClick(e) {
    // if (!filter.find(({ id }) => id === e)) {
    //   setFilter([...filter, { id: e }]);
    // }
    setFilter([e]);
    updateJob({ job_roles: [e] });
  }

  return tagsData?.length > 0 ? (
    <div className="px-0">
      <Container className="px-0" fluid>
        <Row>
          <Col xs={12} className="mt-4">
            <h5>
              How do you classify your Job Roles?{" "}
              <span className="text_sm_gray">(Select all that applied)</span>
              <span className="red p-2">*</span>{" "}
            </h5>
          </Col>
          {/* <Col xs={12}>
            <Row>
              <div className="mt-3">
                <div className="flex_wrapper">
                  {tagsData?.length > 0 ? (
                    tagsData?.map((item, index) => (
                      item?.is_active ?
                      <div key={index} className="filter_max_width">
                        <div
                          className={`${
                            filter[0] === item.id
                              ? "selected_time_zones"
                              : "time-zones"
                          } normal_filter_class`}
                          onClick={e => handleFilterClick(item.id)}
                        >
                          <span className="icon_filters">
                            <span>{item.name}</span>
                          </span>
                        </div>
                      </div> : null
                    ))
                  ) : (
                    <p style={{ color: "gray" }}>
                      Please Select A functional Area
                    </p>
                  )}
                <div
                  className="selected_time_zones"
                  style={{background:"#cde2e7",color:"#024430"}}
                  onClick={() => {
                    setModel(true);
                  }}
                >
                  Add +
                </div>
                </div>
              </div>
            </Row>
          </Col> */}

          <Grid item xs={12} md={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              getOptionLabel={option => option.label}
              defaultValue={defaultOption}
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                if (newValue) {
                 let id = newValue.value
                  setFilter([id]);
                  updateJob({ job_roles: [id] });

                }
              }}
              renderInput={params => (
                <TextField {...params} label="Job Roles" />
              )}
            />
          </Grid>
          <Grid lg={3} md={3} sx={{width:"auto",marginTop:"8px"}}>
          <div
              className="selected_time_zones"
              style={{ background: "#cde2e7", color: "#024430",}}
              onClick={() => {
                setModel(true);
              }}
            >
              Add +
            </div>
            </Grid>
        </Row>
      </Container>
      <div>
        {model ? (
          <JobFunctionalAreaOtherModel
            // setModelData={doc => setModelData(doc)}
            show={model}
            data={functional_areas[0]}
            onHide={() => {
              setModel(false);
            }}
            // params={modelData}
            onSubmit={() => {
              setModel(true);
            }}
            setFilter={setFilter}
            fetchCb={getAllJobRoles}
            updateRole={updateJob}
          />
        ) : null}
      </div>
    </div>
  ) : (
    <></>
  );
};
const mapStateToProps = ({ Jobs }) => ({
  job_roles: Jobs.job_roles,
  functional_areas: Jobs.functional_areas,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobTypes);

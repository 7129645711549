import React, { useState, useEffect } from "react";
import {
  Avatar,
  FormControl,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Select,
  Drawer,
  Stack,
  Typography,
  Chip,
  Box,
} from "@mui/material";
import {
  ErrorSolidButton,
  PrimaryOutlinedButton,
  PrimaryPopUp,
  StyledListItemButton,
  PrimaryAvatar,
  StyledProfilePopText,
  BlackTypography,
  SuccessSolidButton,
  GreyTypography,
  PrimaryChip,
  SmallPrimaryAvatar,
  ExtraSmallTypography,
} from "assets/Mui/globalTheme";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatNumberWithCommas } from "helpers/functions/formatAmount";
import { getSingleJobDetails } from "helpers/services/jobs/jobs";
import { makeStyles } from "@mui/styles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";

const useStyles = makeStyles({
  anchor: {
    "& a": {
      color: "#024430 !important",
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 0.8fr",
    gap: 8,
    padding: 8,
    "& >div:first-of-type": {
      border: "1px solid #ddd",
      borderRadius: 6,
      "& >div": {
        margin: 8,
        paddingRight: 8,
        borderRadius: 6,
        "& h2": {
          // display:'none'
        },
        "& p": {
          marginTop: 8,
          // display:'none',
        },
      },
    },
    "& >div:last-of-type": {
      "& >div": {
        border: "1px solid #ddd",
        borderRadius: 6,
        marginBottom: 12,
      },
    },
  },
  gridTable: {
    marginBlock: "12px",
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    paddingInline: "10px",
    gap: "6px",
    // alignItems:'center'
  },
});

export default function JobDescDrawer({
  viewJobInfo,
  setViewJobInfo,
  jobPostInfo,
}) {
  const { candidate } = useSelector(state => state.Candidates);
  const classes = useStyles();

  function handleDrawer() {
    setViewJobInfo(false);
  }

  return (
    <>
      <Drawer open={viewJobInfo} anchor="right" onClose={handleDrawer}>
        <Box sx={{ width: "45vw", background: "#fff", height: "100%" }}>
          <Stack
            className={classes.anchor}
            boxShadow={2}
            p={1.25}
            direction={"row"}
            alignItems={"center"}
            sx={{ background: "#cde2e7", color: "#024430" }}
            justifyContent={"space-between"}
          >
            {/* <Link to={`/joblist/jobs/${jobPostId}`}> */}
            <Typography style={{ color: "#024430 !important" }}>
              {
                candidate?.user_job_post_details?.job_post_in_user_job_post
                  ?.job_title
              }
            </Typography>
            {/* </Link> */}
            <IconButton onClick={handleDrawer}>
              <FeatherIcon fill={"#fff"} icon="x" size={18} />
            </IconButton>
          </Stack>
          <div className={classes.gridContainer}>
            <div>
              <div style={{ height: "350px", overflowY: "scroll" }}>
                <Typography
                  gutterBottom
                  boxShadow={2}
                  sx={{
                    background: "#cde2e7",
                    color: "#024430",
                    padding: "8px",
                  }}
                >
                  Job Description
                </Typography>
                <div
                  dangerouslySetInnerHTML={{ __html: jobPostInfo?.job_desc }}
                ></div>
              </div>
              <hr style={{ marginInline: "8px" }} />
              <div
                style={{
                  height: "200px",
                  overflowY: jobPostInfo?.requirements && "scroll",
                }}
              >
                <Typography
                  gutterBottom
                  boxShadow={2}
                  sx={{
                    background: "#cde2e7",
                    color: "#024430",
                    padding: "8px",
                  }}
                >
                  Job Requirements
                </Typography>
                {jobPostInfo?.requirements && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: jobPostInfo?.requirements,
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div>
              <div>
                <Typography
                  gutterBottom
                  boxShadow={2}
                  sx={{
                    background: "#cde2e7",
                    color: "#024430",
                    padding: "8px",
                  }}
                >
                  Job Insights
                </Typography>
                <div className={classes.gridTable}>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Job Level
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.new_job_level?.name}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Job Type
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.job_type}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Experience
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.min_exp}-{jobPostInfo?.max_exp}yrs
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Min Salary
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {getSymbolFromCurrency(jobPostInfo?.currency) +
                      " " +
                      formatNumberWithCommas(jobPostInfo?.min_sal)}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Max Salary
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {getSymbolFromCurrency(jobPostInfo?.currency) +
                      " " +
                      formatNumberWithCommas(jobPostInfo?.max_sal)}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Vacancies
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.no_of_vacancies}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Work Mode
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.work_mode}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Location
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.location}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Applied
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {jobPostInfo?.applicants_count}
                  </GreyTypography>
                  <BlackTypography
                    sx={{ fontWeight: "500 !important" }}
                    variant="h6"
                  >
                    Application Deadline
                  </BlackTypography>
                  <GreyTypography variant="h6">
                    {moment(jobPostInfo?.application_deadline).format("ll")}
                  </GreyTypography>
                </div>
              </div>
              <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
                <Typography
                  gutterBottom
                  boxShadow={2}
                  sx={{
                    background: "#cde2e7",
                    color: "#024430",
                    padding: "8px",
                  }}
                >
                  Skills
                </Typography>
                <Stack
                  direction={"row"}
                  flexWrap={"wrap"}
                  p={2}
                  gap={2}
                  alignItems={"flex-start"}
                >
                  {jobPostInfo?.key_skills_details?.map(skill => (
                    <PrimaryChip
                      size="small"
                      key={skill.id}
                      label={skill.name}
                    />
                  ))}
                </Stack>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  );
}

const { Row, Container, Col } = require("reactstrap");
import {
  createKeySkills,
  deleteKeySkill,
  getAllKeySkills,
  updateKeySkills,
} from "helpers/services/jobAttributes/keyskills";
import React from "react";
import { toast } from "react-hot-toast";
import CommonModel from "../../components/Common/models/createModel";
import {
  FormControlLabel,
  FormGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import {
  PrimaryButton,
  PrimarySwitch,
} from "assets/Mui/globalTheme";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import {
  StageSearchInput,
  GreyTypography,
} from "assets/Mui/globalTheme";

class KeySkills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      name: "",
      // id: 0,
      id:"",
      data: [],
      isLoading: "true",
      openSearch:true,
      searchRole:""
    };
    this.toggle = this.toggle.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const items = await getAllKeySkills();

    this.setState({ data: items, isLoading: false });
  }
  // createToggle() {
  //   this.setState({
  //     purpose: "create",
  //     isOpen: !this.state.isOpen,
  //   });
  // }
  toggle(purpose = "", name = "", id = "", active = "", index) {
    this.setState({
      purpose: purpose,
      name: name,
      index,
      id: id,
      active: active,
      isOpen: !this.state.isOpen,
    });
  }
  handleAction(item, index) {
    updateKeySkills({ name: item.name, is_active: !item.is_active }, item.id);
    let result = this.state.data;
    // result[index].is_active = !result[index].is_active;

    const filterOne = result?.map((each)=>{
      if(each.id == item.id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

     const statusCheck = result?.filter((each)=>{
      if(each?.id == item.id){
        return {
          ...each,
          is_active:!item.is_active
        }
      }
     })

    this.setState({
      data: filterOne,
    });

    if(statusCheck && statusCheck[0]?.is_active){
      toast.error("disabled")
   
    }else {
      toast.success("enabled")
    }
    
    // if(!result[index].is_active){
    //   toast.error("Disabled")
    // }else {
    //   toast.success("Enabled")
    // }
  }
  handleDelete(index, id) {
    deleteKeySkill(id);
    let result = this.state.data;
    result.splice(index, 1);
    this.setState({
      data: result,
    });
    toast.error("Disabled");
  }

  async updateFunc(sample, type, index,id) {

    let editedId;
    if (id) {
      editedId = id;
    } else {
      editedId = this.state.id;
    }

    if (type === "Create") {
      await createKeySkills(sample);
      this.setState({
        data: [...this.state?.data, sample],
      });
    } else {
      // await updateKeySkills(sample, this.state.id);
      await updateKeySkills(sample, editedId);
      let result = this.state.data;
      // result[index] = sample;

      const filterOne = result?.map((each)=>{
        if(each.id == sample.id){
          return {
            ...each,
            name:sample.name,
            is_active:sample.is_active
          }
        }else {
          return {
            ...each
          }
        }
       })

      this.setState({
        data: filterOne,
      });
    }
  }

   /* Search toggle effect */
   handleSearchOpen = () => {
    this.setState((prevState) => ({
      openSearch: !prevState.openSearch,
      searchRole:""
    }));
  }

  handleSearch=(e)=>{
    this.setState((prevState)=>({
      searchRole:e.target.value
    }))
  }

  searchFilterData = (data)=>{
    const searchWord = this.state?.searchRole?.toLowerCase()
    const searchPattern = data?.filter((item)=>{
      const roleName = item?.name?.toLowerCase()
    
      if(roleName?.includes(searchWord)){
        return item
      }
    })
    return searchPattern
  }
  
  /* Search toggle effect end */

  render() {
    const filteredData = this.searchFilterData(this.state?.data)
    return (
      <Container fluid>
        <CommonModel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          headTag={"Key Skills"}
          purpose={this.state.purpose}
          index={this.state.index}
          title={this.state.name}
          // defaultValue={this.state.default}
          active={this.state.active}
          update={this.updateFunc}
          id={this.state.id}
        />
        <div className="header_jobs_sticky" md={6} style={{ padding: "40px" }}>
          <PrimaryButton
            sx={{ width: "200px" }}
            onClick={() => this.toggle("Create")}
          >
            Create Key Skills
          </PrimaryButton>
          <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
                  {this.state.openSearch ? (
                    <SearchIcon
                      onClick={this.handleSearchOpen}
                    
                    />
                  ) : (
                    <StageSearchInput
                      placeholder="search..."
                      onChange={(e)=>this.handleSearch(e)}
                      endAdornment={
                        <IconButton edge="end" onClick={this.handleSearchOpen}>
                          <ClearIcon />
                        </IconButton>
                      }
                    />
                  )}
                
                </Stack>
        </div>
        <Row style={{ marginTop: "", marginLeft: "20px" }}>
          <div className="table_cont">
            <div className="table-responsive">
              <Table className="table mb-0">
                <TableHead>
                  <TableRow>
                    <th>Key Skills</th>
                    <th>status</th>
                    <th>Action</th>
                  </TableRow>
                </TableHead>
                <tbody>
                  {this.state.isLoading ? (
                  <BubbleLoader />
                  ) : (
                    this.state.data.map((item, index) => (
                      // filteredData?.map((item, index) => (
                      <TableRow key={item.name + index}>
                        <TableCell>{item.name}</TableCell>

                        <TableCell>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <PrimarySwitch
                                  sx={{ m: 1 }}
                                  checked={item?.is_active}
                                  onChange={async e => {
                                    this.handleAction(item, index);
                                  }}
                                />
                              }
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell className="col_btn">
                          <PrimaryButton
                            sx={{ width: "100px" }}
                            onClick={() =>
                              this.toggle(
                                "Edit",
                                item.name,
                                item.id,
                                item.is_active,
                                index
                              )
                            }
                          >
                            Edit
                          </PrimaryButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}

export default KeySkills;

import {
  CardContent,
  Grid,
  ImageList,
  ImageListItem,
  Stack,
} from "@mui/material";
import {
  BlackTypography,
  BorderCard,
  GreyTypography,
  PrimaryButton,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import HeaderTextForCompany from "./headerText";
import { perks } from "common/constants/perks";
import { useEffect, useState } from "react";
import { imageURL } from "common/constants/commonURLS";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  imageGrid:{
    // height:600,
    display:'grid',
    // overflowY:'scroll',
    // cursor:'pointer',
    // gridAutoColumns: '100px',
    // gridAutoFlow:'column',
    gap:16,
    padding:8,
    gridTemplateColumns:'repeat(auto-fill,minmax(100px,1fr))',
    borderRadius:'10px',
    border:'0.5px solid rgba(3,23,83,0.2)',
    // gridAutoRows:'100px',
    "& img":{
      width:"100%",
      height:"100%"
    }

  }
})
export default function CompanyOverViewIndex({ company,industry }) {
  const classes = useStyles()

  const [selectedPerks, setSelectedPerks] = useState([]);
  useEffect(() => {
    const data = perks?.filter(item => {
      return company?.perks?.includes(item?.name);
    });
    setSelectedPerks(data);
  }, [company?.perks]);
  return (
    <Stack spacing={3} style={{ marginTop: "10px" }}>
      <Stack spacing={1}>
        <HeaderTextForCompany>Tagline</HeaderTextForCompany>
        <GreyTypography
          additionalProps={{ textTransform: "lowercase !important" }}
          variant="h6"
          component="h6"
        >
          {company?.tagline}
        </GreyTypography>
      </Stack>

      <Stack spacing={1}>
        <HeaderTextForCompany>about</HeaderTextForCompany>
        <GreyTypography
          additionalProps={{ textTransform: "lowercase !important" }}
          variant="h6"
          component="h6"
        >
          {company?.about}
        </GreyTypography>
      </Stack>

      <Stack spacing={1}>
        <HeaderTextForCompany>culture description</HeaderTextForCompany>
        <GreyTypography
          additionalProps={{ textTransform: "lowercase !important" }}
          variant="h6"
          component="h6"
        >
          {company?.describe_your_culture}
        </GreyTypography>
      </Stack>
      <Stack spacing={1}>
        <HeaderTextForCompany>Basic Information</HeaderTextForCompany>

        <BorderCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  Comapany Size
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {company?.size_of_company}
                </BlackTypography>
              </Grid>

              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  company type
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {company?.company_type}
                </BlackTypography>
              </Grid>
              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  industry
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {industry?.length ? industry[0].name || "":""}
                </BlackTypography>
              </Grid>
              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  location
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {company?.location}
                </BlackTypography>
              </Grid>
            </Grid>
          </CardContent>
        </BorderCard>
      </Stack>
      <Stack spacing={1}>
        <HeaderTextForCompany>
          Benefits Reported by Employees
        </HeaderTextForCompany>
        <BorderCard>
          <CardContent>
            <Grid alignItems={"center"} container spacing={2}>
              {selectedPerks.map(({ image, name, selected }, index) => {
                return (
                  <Grid item xs={12} md={2} key={image}>
                    <div
                      style={{
                        transition: "0.4s ease-in-out",
                      }}
                      className={`perks_selection selected_perk
                  }`}
                    >
                      <img
                        className="small_img"
                          src={image}
                          // style={{objectFit:'contain',width:"100%",height:"100%"}}
                        />
                      <p>{name}</p>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </BorderCard>
      </Stack>
      <Stack spacing={1}>
        <HeaderTextForCompany>External Links</HeaderTextForCompany>

        <BorderCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  Linkedin
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {company?.linkedin_url}
                </BlackTypography>
              </Grid>

              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  facebook
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {company?.facebook_url}
                </BlackTypography>
              </Grid>
              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  Twitter
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {company?.twitter_url}
                </BlackTypography>
              </Grid>

              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  Glassdoor
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {company?.glassdoors_url}
                </BlackTypography>
              </Grid>
              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  AmbitionBox
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {company?.ambition_box_url}
                </BlackTypography>
              </Grid>
              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  Comparably
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  linkedin.com/url
                  {/* {company?.comparably_url} */}
                </BlackTypography>
              </Grid>

              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  Careerbliss
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {company?.careerbliss_url}
                </BlackTypography>
              </Grid>

              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  payscale
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {company?.payscale_url}
                </BlackTypography>
              </Grid>

              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  Google Reviews
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {company?.google_reviews}
                </BlackTypography>
              </Grid>
            </Grid>
          </CardContent>
        </BorderCard>
      </Stack>
      <Stack spacing={1}>
        <HeaderTextForCompany>Cultural Media Images</HeaderTextForCompany>
        <div className={classes.imageGrid}>
        {company?.culture_media_images?.map((item, index) => (
                <>
                  <LazyLoadImage
                    key={index}
                    alt={"alternative"}
                    loading="lazy"
                    effect="blur"
                    src={imageURL + item}
                  />
                  {/* <img
                    loading="lazy"
                    {...srcset(`${imageURL}${item}`, 40, 2, 2)}
                  /> */}
                </>
              ))}
        </div>
        {/* <BorderCard>
          <CardContent>
            <ImageList variant="masonry" cols={3} gap={8}>
              {company?.culture_media_images?.map((item, index) => (
                <>
                  <LazyLoadImage
                    key={index}
                    alt={"alternative"}
                    loading="lazy"
                    effect="blur"
                    src={imageURL + item}
                  />
                  <img
                    loading="lazy"
                    {...srcset(`${imageURL}${item}`, 40, 2, 2)}
                  />
                </>
              ))}
            </ImageList>
          </CardContent>
        </BorderCard> */}
      </Stack>
    </Stack>
  );
}
function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

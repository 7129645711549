import { Box, Modal, Stack, Typography } from "@mui/material";
import {
  PrimaryButton,
  PrimaryTypography,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { getAllAdminStaffList } from "helpers/services/adminStaff/adminStaff";
import { assignAccountMangerToCompany } from "helpers/services/zepulAdmin/AccountMangers";
import SearchParamHook from "hooks/searchParams";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ReactSelect from "react-select";

export default function AssignAccountManagers({
  isOpen,
  toggle,
  company,
  currentAcm,
  setUpdate
}) {
  const { id } = useParams();
  const style = {
    position: "absolute",
    top: "50%",
    borderRadius: "8px",
    pading: "20px",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    p: 4,
  };
  // useEffect(() => {
  //   setAccountManager(currentAcm);
  // }, [currentAcm]);
  const [accountManager, setAccountManager] = useState("");
  const [accountManagerOptions, setAccountMangerOptions] = useState([]);
  useEffect(() => {
    findAllAccountMangers();
  }, []);
  async function handleSaveAccountManager() {
    const data = await assignAccountMangerToCompany(
      company?.id,
      accountManager?.value
    );
    if (data.status === 200) {
      toast.success("assigned successfully")
      setUpdate(pre=>!pre)
      toggle();
    }
    ErrorHandlingToaster(data);
  }
  async function findAllAccountMangers() {
    let data = await getAllAdminStaffList();
    const account_results = await data.filter(({ usertype }) => {
      if (usertype === "ACCOUNT_MANAGER") {
        return true;
      }
      return false;
    });
    const options = account_results.map(item => {
      return {
        value: item.id,
        label: item.first_name + " " + item.last_name + " " + item.email,
      };
    });
    const activeACM = account_results.find((i)=>i.id===company.job_post_verifier_id)
    setAccountMangerOptions(options);
    console.log(activeACM);
    setAccountManager({
      value: activeACM?.id,
      label:activeACM?.email
    })
  }
  function handleSelectAccountManager(data) {
    setAccountManager(data);
  }
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      sx={{
        pading: "20px",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={1}>
          <PrimaryTypography variant="h2">
            Assign Account Manager
          </PrimaryTypography>
          <Typography variant="body1" sx={{ my: 2, color: "gray" }}>
            Assigning An account Manager and you can change it whenever you want
            to change!
            <br />
          </Typography>
          <ReactSelect
            style={{ zIndex: "999" }}
            value={accountManager}
            isMulti={false}
            onChange={handleSelectAccountManager}
            options={accountManagerOptions}
            classNamePrefix="select2-selection"
          />
          <Stack direction="row" justifyContent={"space-between"}>
            <SecondaryFilledButton sx={{ width: "140px" }} onClick={toggle}>
              Cancel
            </SecondaryFilledButton>
            <PrimaryButton
              sx={{ width: "140px" }}
              onClick={handleSaveAccountManager}
            >
              Save Changes
            </PrimaryButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

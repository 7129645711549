import {
  GreyTypography,
  PrimaryOutlinedButton,
  SuccessCircularProgress,
  PrimaryTypography,
  SecondaryCircularProgress,
  BorderCard,
  GrayLiniearDivider,
} from "assets/Mui/globalTheme";
import EvaluationValue from "./EvaluationValue";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import html2canvas from "html2canvas";
import { Stack, Grid, Box, Typography, CircularProgress } from"@mui/material";
import { jsPDF } from "jspdf";
import SkillScore from "./SkillsScoreCarousal";
import { makeStyles } from "@mui/styles";
import { useRef } from "react";
import PdfScore from "./pdfScore";
import PdfScoreCard from "./pdfScoreCard";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
const useStyles = makeStyles({
  grid:{
    display:'grid',
    gridTemplateColumns:'repeat(3,1fr)',
    gap:'12px'
  },
  print:{
    "@media print":{
      "&":{
        display:'none !important',
      }
    },
  },
  btn:{
    color:"#081753",
    "&:hover":{
        color:'#fff'
    }
  }
})

const trackColor = {
  success: "#2DA13F",
  average: "#FFECAF",
  bad:'#B70404'
}

function getScoreColor(score) {
  console.log(score,"v");
  if (score >= 4) {
    return trackColor.success
  } else if (score >= 3) {
    return trackColor.average
  } else {
    return trackColor.bad
  }
}

export default function EvaluationReport({ scoreData,screeningSkills,evaluatorSkills,moderatorSkills,jobPostInfo }) {
  const {candidate} = useSelector((state)=>state.Candidates)
  const jobTitle = candidate?.user_job_post_details?.job_post_in_user_job_post?.job_title || ""
  let finalData = scoreData;
  const screeningPartnerDetails = finalData?.screening_partner_evalutation;
  const technicalEvalDetails = finalData?.technical_evalutation;
  const moderatorDetails = finalData?.moderator_evalutation;
  const btnRef = useRef(null)
  const classes = useStyles()
  let finalScore =
    (screeningPartnerDetails?.average_rating || 0) +
    (technicalEvalDetails?.average_rating || 0) +
    (moderatorDetails?.average_rating || 0);
  // const avg = Math.round(overAllScore / 3);
  const avg = (finalScore / 3);
  const roundScore = avg > 1 ? avg.toFixed(1) : avg === 0? 0 : avg.toFixed(1);
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} lg={4} spacing={2}>
          <PrimaryTypography className="mb-4" variant="h2">
            Evaluation Report
          </PrimaryTypography>
          <Stack spacing={4}>
            <Stack direction="row" spacing={2}>
              <GreyTypography variant="h6">Screening Evalution:</GreyTypography>
              <PrimaryTypography variant="h4">
                {screeningPartnerDetails?.sp_details?.first_name
                  ? screeningPartnerDetails?.sp_details?.first_name +
                    screeningPartnerDetails?.sp_details?.last_name
                  : "N/A"}
              </PrimaryTypography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <GreyTypography variant="h6">Moderator Evalution:</GreyTypography>
              <PrimaryTypography variant="h4">
                {moderatorDetails?.moderator_details?.first_name
                  ? moderatorDetails?.moderator_details?.first_name +
                    moderatorDetails?.moderator_details?.last_name
                  : "N/A"}
              </PrimaryTypography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <GreyTypography variant="h6">Technical Evalution:</GreyTypography>
              <PrimaryTypography variant="h4">
                {technicalEvalDetails?.evaluator_details?.first_name
                  ? technicalEvalDetails?.evaluator_details?.first_name +
                    technicalEvalDetails?.evaluator_details?.last_name
                  : "N/A"}
              </PrimaryTypography>
            </Stack>{" "}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Stack spacing={2}>
            <EvaluationValue
              evalution_name={"screening"}
              color="#455A64"
              thumb__hover_color={" rgba(58, 133, 137, 0.16)"}
              score={screeningPartnerDetails?.average_rating || 0}
            ></EvaluationValue>
            <EvaluationValue
              evalution_name={"Moderator"}
              color="#7986CB"
              thumb__hover_color={" rgba(65, 58, 137, 0.16)"}
              score={moderatorDetails?.average_rating || 0}
            ></EvaluationValue>
            <EvaluationValue
              evalution_name={"Technical"}
              color="#4FC3F7"
              thumb__hover_color={" rgba(59, 119, 137, 0.16)"}
              score={technicalEvalDetails?.average_rating || 0}
            ></EvaluationValue>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Stack spacing={2} alignItems={"center"}>
            <PDFDownloadLink
              className={classes.btn}
              document={
                <PdfScoreCard
                  jobTitle={jobTitle}
                  score={roundScore}
                  screening={screeningSkills}
                  moderator={moderatorSkills}
                  evaluator={evaluatorSkills}
                  jobPostInfo={jobPostInfo}
                />
              }
              fileName="report.pdf"
            >
              {({ blob, url, loading, error }) => {
                // console.log(blob, url, loading, error, "v");
                if (error) {
                  toast.error("wrong");
                }
                return loading ? (
                  <CircularProgress />
                ) : (
                  <PrimaryOutlinedButton
                    ref={btnRef} //convertToCanvas
                    // onClick={()=>openPrintView()}
                    // onClick={handleGeneratePDF}
                    sx={{ width: "250px" }}
                    className={classes.print}
                    startIcon={<FeatherIcon icon="file-text" size="14" />}
                  >
                    Download
                  </PrimaryOutlinedButton>
                );
              }}
            </PDFDownloadLink>
            <Box
              sx={{
                borderRadius: "10px",
                padding: "22px",
                border: "0.5px solid rgba(3,23,83,0.2)",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <SecondaryCircularProgress
                  sx={{
                    position: "absolute",
                    left: "0",
                  }}
                  variant="determinate"
                  value={50}
                />
                <SuccessCircularProgress
                  variant="determinate"
                  value={roundScore * 10}
                  // strokeColor={getScoreColor(roundScore)}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    height: "150px",
                    right: 0,
                    left: 0,
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <Stack alignItems="center">
                    <Typography
                      sx={{
                        color: "#2DA13F",
                        fontWeight: "900",
                        fontSize: "24px",
                      }}
                      variant="h2"
                      component="h2"
                    >
                      {roundScore}/5
                    </Typography>
                    <GreyTypography variant="h6">Overall Score</GreyTypography>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <GrayLiniearDivider
        sx={{ marginTop: "15px", marginBottom: "20px" }}
      ></GrayLiniearDivider>
    </>
  );
}


// export default function EvaluationReport({scoreData}){
//   const classes = useStyles()
//   let finalData = scoreData;
//   const screeningPartnerDetails = finalData?.screening_partner_evalutation;
//   const technicalEvalDetails = finalData?.technical_evalutation;
//   const moderatorDetails = finalData?.moderator_evalutation;
//     let finalScore =
//     (screeningPartnerDetails?.average_rating || 0)  +
//     (technicalEvalDetails?.average_rating || 0) +
//     (moderatorDetails?.average_rating || 0);
//   const avg = Math.round(finalScore / 3);
//   return (
//     <>
//       <PrimaryTypography className="mb-4" variant="h2">
//         Evaluation Report
//       </PrimaryTypography>
//       <div className={classes.grid}>
//         <SkillScore type={"screening"} color={"#455A64"} thumbColor={" rgba(58, 133, 137, 0.16)"} value={screeningPartnerDetails?.average_rating||0} data={screeningPartnerDetails?.sp_details} />
//         <SkillScore type={"Technical"} color={"#4FC3F7"} thumbColor={" rgba(59, 119, 137, 0.16)"} value={technicalEvalDetails?.average_rating || 0} data={technicalEvalDetails?.evaluator_details} />
//         <SkillScore type={"Moderator"} color={"#7986CB"} thumbColor={" rgba(65, 58, 137, 0.16)"} value={moderatorDetails?.average_rating || 0} data={moderatorDetails?.moderator_details} />
//       </div>
//     </>
//   );
// }
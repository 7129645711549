export const perks = [
  {
    name: "Meals",
    selected: false,
    image: "https://img.icons8.com/fluency/48/000000/buffet-breakfast.png",
  },
  {
    name: "Health Care",
    selected: false,
    image: "https://img.icons8.com/doodle/48/000000/nature-care.png",
  },
  {
    name: "Retirement",
    selected: false,
    image:
      "https://img.icons8.com/external-smashingstocks-thin-outline-color-smashing-stocks/67/000000/external-benefits-finance-smashingstocks-thin-outline-color-smashing-stocks.png",
  },
  {
    name: "Parental Leave",
    selected: false,
    image:
      "https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-parental-parenthood-flaticons-flat-flat-icons.png",
  },
  {
    name: "Equity Benefits",
    selected: false,
    image:
      "https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-equity-accounting-flaticons-flat-flat-icons.png",
  },
  {
    name: "Remote",
    selected: false,
    image: "https://img.icons8.com/doodle/48/000000/home-office.png",
  },
  {
    name: "Vacation",
    selected: false,
    image:
      "https://img.icons8.com/external-gradient-design-circle/66/000000/external-vacation-hotel-and-services-gradient-design-circle.png",
  },
  {
    name: "Wellness",
    selected: false,
    image:
      "https://img.icons8.com/external-bearicons-detailed-outline-bearicons/64/000000/external-Wellness-human-mind-bearicons-detailed-outline-bearicons.png",
  },
  {
    name: "Pet Friendly",
    selected: false,
    image: "https://img.icons8.com/officel/80/000000/dog-park.png",
  },
  {
    name: "Others",
    selected: false,
    image: "https://img.icons8.com/ios/50/000000/gift--v1.png",
  },
];

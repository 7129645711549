import { Stack } from "@mui/material";
import {
  BorderCard,
  BorderedCardActionArea,
  PrimaryTextButton,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import AddEditNotes from "./addNotes";
// import EmailEditNotes from "./emailNotes";
import { useEffect, useState } from "react";
import { createNotes } from "helpers/services/notes/create";
import SearchParamHook from "hooks/searchParams";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getNotes } from "helpers/services/notes/getNotes";
import { toast } from "react-hot-toast";
import { v4 as uuid } from "uuid"

export default function UserJobPostNotesHeader({setCheckNotes,editorText,setEditorText}) {
  const [notesModel, setNotesModel] = useState(false);
  const [userJobPostId, setUserJobPostId] = useState(null);
  const [jobPostId, setJobPostId] = useState(null);
  const searchParams = SearchParamHook();
  const dispatch = useDispatch();
  useEffect(() => {
    const userJobPostId = searchParams.get("user_job_post_id");
    if (userJobPostId) {
      setUserJobPostId(userJobPostId);
    }
    const jobPostId = searchParams.get("job_id");
    if (jobPostId) {
      setJobPostId(jobPostId);
    }
  }, [searchParams]);
  function toggleModel() {
    setNotesModel(!notesModel);
  }
  async function updateNotes(data) {
   const result = await createNotes({
      user_job_post_id: userJobPostId,
      job_post_id: jobPostId,
      ...data,
    });
    if(result.status === 201){
      toast.success("Successfully created!")
    }
    const { data: notes, status } = await getNotes(userJobPostId);
    if (status === 200) {
      dispatch({
        type: "ADD_NOTES",
        payload: notes?.data,
      });
      toggleModel();
    }
  }
  function updateCheckNotes(data) {
    delete data.text_note;
    console.log(data,"v",editorText);
    // if (editorText.length > 150) {
    //   setError("text_note", { type: "custom", message: "less than 150 characters" },{shouldFocus:true})
    //   return;
    // }
    const tweak = {
      subject: data.title,
      id: uuid(),
      notes: data.note,
      is_active: "",
      createdAt: "",
      updatedAt: new Date(),
      created_by_id: "",
      updated_by_id: "",
      user_job_post_id: "",
      job_post_id: "",
      notes_updtby: {
        email: "d@syoft.com",
        first_name: "admin.",
        last_name: "shifyt",
        user_name: "admin Zepul",
        phone_number: null,
        image: "img_1692505186632.png"
      },
    };
    toggleModel();
    setCheckNotes((pre)=>[tweak,...pre])
  }
  return (
    <BorderCard sx={{ marginBottom: "10px" }}>
      <Stack>
        <PrimaryTextButton
          sx={{ width: "180px" }}
          onClick={toggleModel}
          endIcon={<FeatherIcon icon="plus-circle" size="14" />}
        >
          Add Notes
        </PrimaryTextButton>
      </Stack>
      {/* {notesModel ?
        <EmailEditNotes //AddEditNotes
          open={notesModel}
          editorText={editorText}
        type="create"
        toggleModel={toggleModel}
        // updateOrEditNotes={updateNotes}
          updateOrEditNotes={updateCheckNotes}
          setEditorText={setEditorText}
          
      />:null} */}
      {notesModel ?
        <AddEditNotes
          open={notesModel}
          editorText={editorText}
        type="create"
        toggleModel={toggleModel}
        updateOrEditNotes={updateNotes}
          // updateOrEditNotes={updateCheckNotes}
          setEditorText={setEditorText}
          
      />:null}
    </BorderCard>
  );
}

import React, { useState,useEffect } from "react";
import {
  ScoreCardBox,
  ScoreCardHeader,
  BlackTypography,
  DownloadMenuButton,
  StyledCard,
  StyledEvalCard,
  MiddleDivider,
  PrimaryTypography,
  RatingButton,
  GreyTypography,
} from "assets/Mui/globalTheme";
import { MODERATOR, SP, TE } from "common/constants/userModules";
import { Stack, Grid, InputLabel, Select, Box } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Avatar from "@mui/material/Avatar";
import SendIcon from "@mui/icons-material/Send";
import StarIcon from "@mui/icons-material/Star";
import { yellow } from "@mui/material/colors";
import noData from '../../../../assets/images/comment.svg';

export default function ModeratorSkillCards({
  heading,
  skills,
  evType,
  jobPostInfo,
  setDetails,
  setModSkillsType,
  modSkillsType
}) {
  const [currentValue, setCurrentValue] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{
    setCurrentValue(modSkillsType)
  },[modSkillsType])

  let comment;
  let rating;
  if (evType === SP) {
    comment = "sp_comment";
    rating = "sp_rating";
  } else if (evType === MODERATOR) {
    comment = "moderator_comment";
    rating = "moderator_rating";
  } else {
    comment = "evaluator_comment";
    rating = "evaluator_rating";
  }

  const jobSkills = jobPostInfo?.key_skills_details?.length
    ? jobPostInfo?.key_skills_details
    : [];

  return (
    <>
      <ScoreCardBox>
        <ScoreCardHeader>
          <Grid container alignItems="center">
            <Grid xs={8} lg={10} sx={{ padding: "8px" }}>
              <BlackTypography varient="h4">
                {heading} Evalution
              </BlackTypography>
            </Grid>
            <Grid xs={8} lg={2} sx={{ padding: "8px" }}>
              <Stack
                direction={"row"}
                justifyContent={"end"}
                alignItems={"end"}
              >
                <Select
                  labelId="demo-simple-select-label"
                  //   input={<DownloadMenuButton />}
                  sx={{
                    backgroundColor: "#E6F0ED",
                    color: "#000000",
                    borderRadius: "8px",
                    height: "36px",
                    width: 100,
                    fontSize: "13px",
                  }}
                  value={currentValue}
                  defaultValue={currentValue}
                  onChange={e => {
                    const {
                      target: { value },
                    } = e;

                    // setDetails({ type: e.target.value, userType: heading });
                    setModSkillsType(e.target.value)
                    setCurrentValue(
                      // On autofill we get a
                      // stringified value.
                      typeof value === "string" ? value.split(",") : value
                    );
                  }}
                >
                  {["skill", "question"].map(each => {
                    return (
                      <MenuItem
                        value={each}
                        sx={{ height: "36px", fontSize: "13px" }}
                      >
                        {each}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Grid>
          </Grid>
        </ScoreCardHeader>

        <Box sx={{ padding: "16px" }}>
          <Grid
            container
            spacing={1}
            sx={{ paddingTop: "12px", height: "200px", overflowY: "scroll", "&::-webkit-scrollbar": {
              display: "none", // Hide the scrollbar for WebKit browsers (e.g., Chrome, Safari)
            },
            scrollbarWidth: "none", }}
          >
            {skills?.length
              ? skills?.map((each, id) => (
                  <Grid lg={4} key={id} spacing={1}>
                    <StyledEvalCard>
                      <Stack
                        direction="column"
                        spacing={1}
                        sx={{ padding: "8px" }}
                      >
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <PrimaryTypography
                            variant="h5"
                            sx={{ color: "#044B36" }}
                          >
                            {/* {each?.skill_details[0]?.name} */}
                            {each?.skill_details?each.skill_details[0]?.name:each?.job_post_question?each?.job_post_question[0]:"N/a"}
                          </PrimaryTypography>
                          {/* {each?.[rating] && (
                            <RatingButton
                              endIcon={<StarIcon sx={{ color: yellow[700] }} />}
                            >
                              {each?.[rating]}
                            </RatingButton>
                          )} */}
                          <RatingButton
                            endIcon={<StarIcon sx={{ color: yellow[700] }} />}
                          >
                            {each?.[rating]? each?.[rating]:0}
                          </RatingButton>
                        </Stack>
                        <MiddleDivider />
                        <Stack
                          direction={"column"}
                          sx={{ overflowY: "scroll", height: "100px", "&::-webkit-scrollbar": {
                            display: "none", // Hide the scrollbar for WebKit browsers (e.g., Chrome, Safari)
                          },
                          scrollbarWidth: "none", }}
                        >
                          <BlackTypography
                            variant="h5"
                            sx={{ fontSize: "15px" }}
                          >
                            #Comments
                          </BlackTypography>
                          {each?.[comment] ? (
                            <GreyTypography
                              variant="h6"
                              sx={{
                                color: "#000000",
                                opacity: "60%",
                                fontSize: "13px",
                              }}
                            >
                              {each?.[comment]}
                            </GreyTypography>
                          ) : (
                            <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:80,width:80}}>
                            <img src={noData}  style={{height:"100%",width:"100%"}}/></Box>
                          )}
                          {/* <GreyTypography
                        //     variant="h6"
                        //     sx={{
                        //       color: "#000000",
                        //       opacity: "60%",
                        //       fontSize: "13px",
                        //     }}
                        //   >
                        //     {each?.[comment]}
                        //   </GreyTypography> */}
                        </Stack>
                      </Stack>
                    </StyledEvalCard>
                  </Grid>
                ))
              : jobSkills?.map((each, id) => (
                  <Grid key={id} spacing={1}>
                    <StyledEvalCard>
                      <Stack
                        direction="column"
                        spacing={1}
                        sx={{ padding: "8px" }}
                      >
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <PrimaryTypography
                            variant="h5"
                            sx={{ color: "#044B36" }}
                          >
                            {each?.name}
                          </PrimaryTypography>
                          <RatingButton
                            endIcon={<StarIcon sx={{ color: yellow[700] }} />}
                          >
                            0
                          </RatingButton>
                        </Stack>
                        <MiddleDivider />
                        <Stack
                          direction={"column"}
                          sx={{ overflowY: "scroll", height: "100px",  "&::-webkit-scrollbar": {
                            display: "none", // Hide the scrollbar for WebKit browsers (e.g., Chrome, Safari)
                          },
                          scrollbarWidth: "none", }}
                        >
                          <BlackTypography
                            variant="h5"
                            sx={{ fontSize: "15px" }}
                          >
                            #Comments
                          </BlackTypography>
                          {/* <GreyTypography
                            variant="h6"
                            sx={{
                              color: "#000000",
                              opacity: "60%",
                              fontSize: "13px",
                            }}
                          >
                            No comments
                          </GreyTypography> */}
                            <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:80,width:80}}>
                            <img src={noData}  style={{height:"100%",width:"100%"}}/></Box>
                        </Stack>
                      </Stack>
                    </StyledEvalCard>
                  </Grid>
                ))}

            {/* <Grid lg={4}>
              <StyledEvalCard>
                <Stack direction="column" spacing={1} sx={{ padding: "8px" }}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <PrimaryTypography>
                      Leadership capabilities
                    </PrimaryTypography>
                    <RatingButton endIcon={<SendIcon />}>3.5</RatingButton>
                  </Stack>
                  <MiddleDivider />
                  <Stack direction={"column"} sx={{overflowY:"scroll"}}>
                  <BlackTypography>#Comments</BlackTypography>
                  <GreyTypography varient="h4">
                    Set goals for yourself. What leadership capabilities do you
                    want to develop? What do you want to achieve as a leader?
                    Once you have set goals for yourself, you can start to
                    develop a plan to achieve them.
                  </GreyTypography>
                  </Stack>
                </Stack>
              </StyledEvalCard>
            </Grid> */}

            {/* <Grid lg={4}>
              <StyledEvalCard>
                <Stack direction="column" spacing={1} sx={{ padding: "8px" }}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <PrimaryTypography>
                      Leadership capabilities
                    </PrimaryTypography>
                    <RatingButton endIcon={<SendIcon />}>3.5</RatingButton>
                  </Stack>
                  <MiddleDivider />
                  <Stack direction={"column"} sx={{overflowY:"scroll"}}>
                  <BlackTypography>#Comments</BlackTypography>
                  <GreyTypography varient="h4">
                    Set goals for yourself. What leadership capabilities do you
                    want to develop? What do you want to achieve as a leader?
                    Once you have set goals for yourself, you can start to
                    develop a plan to achieve them.
                  </GreyTypography>
                  </Stack>
                </Stack>
              </StyledEvalCard>
            </Grid> */}
          </Grid>
        </Box>
      </ScoreCardBox>
    </>
  );
}

import { FormControl, Stack } from "@mui/material";
import { GreyTypography, PrimaryButton, PrimaryNormalFormHelperText, PrimaryNormalInputLabel, PrimaryOutlinedOpacitedButton, PrimaryTypography } from "assets/Mui/globalTheme";
import PhoneInput from "react-phone-number-input";
import "../../assets/css/auth/signup.css"
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import BasicModal from "components/Common/models/otpModel";
import { useState } from "react";
import MobileOtp from "./mobileOtp";
import { sendOtp } from "helpers/services/otpVerifiy/verify";
import { toast } from "react-hot-toast";
const useStyles = makeStyles({
  inputField: {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    marginBlock:"16px"
  },
  error: {
    color:"red"
  }
})
export default function MobileNumber({reUpdate,currentNumber}) {
  const classes = useStyles()
  const [viewOtp,setViewOtp] = useState(false)
  const { register, setValue,setError, watch, handleSubmit, formState: { errors } } = useForm()
  async function handleForm(data) {
    const { cur_mobile, new_mobile, confirm_mobile } = data
    if (cur_mobile !== currentNumber) {
      setError("cur_mobile", { type: 'custom', message: "must match with existing one" })
      // toast.error("your current number must have to be match with existing one")
      return;
    }
    if (new_mobile !== confirm_mobile) {
      setError("confirm_mobile", { type: 'custom', message: "new number and confirm number must have to be same" })
      return
    }
    try {
      const res =  await sendOtp("mobile",confirm_mobile)
      const { data, status } = res
      console.log(res);
      if (status === 200) {
        setViewOtp(true)
        // reUpdate()
        // toast.success("updated Successfully")        
      }
    } catch (error) {
      
    }
  }
  console.log(currentNumber,"v");
  return (
    <div style={{width:"60%"}}>
    <GreyTypography my={2} variant="h6">Please enter your current number to change your number</GreyTypography>
    <form style={{width:"100%"}} onSubmit={handleSubmit(handleForm)}>
      <div className={classes.inputField}>
            <PrimaryTypography variant="h5"
            >
              Current Number
          </PrimaryTypography>
          <div>
            <PhoneInput
              id="signup-phone-number"
              labelId="signup-phone-number"
              international
            // readOnly
              {...register("cur_mobile", {
                required: true,
                minLength: 10,
              })}
              className={`input_phone ${
                errors?.cur_mobile ? "error_phone" : ""
                } `}
              style={{marginTop:"20px !important"}}
              name="phone Number"
              error={errors.cur_mobile}
              onChange={phone => {
                setValue("cur_mobile", phone);
              }}
              label="signup-phone-number"
              value={watch("cur_mobile")}
              // disabled
              defaultCountry="IN"
              initialValueFormat="national"
              placeholder="Enter phone number"
            />
           {errors?.cur_mobile ?  <span className={errors?.cur_mobile && classes.error}>
              {errors?.cur_mobile.message || "mobile number is required"}
            </span> : null}

          </div>
          </div>
      <div className={classes.inputField}>
            <PrimaryTypography variant="h5"
            >
              New Number
          </PrimaryTypography>
          <div>
            <PhoneInput
              id="signup-phone-number"
              labelId="signup-phone-number"
              international
              {...register("new_mobile", {
                required: true,
                minLength: 10,
              })}
              className={`input_phone ${
                errors?.new_mobile ? "error_phone" : ""
                } `}
              style={{marginTop:"20px !important"}}
              name="phone Number"
              error={errors.new_mobile}
              onChange={phone => {
                setValue("new_mobile", phone);
              }}
              label="signup-phone-number"
              value={watch("new_mobile")}
              defaultCountry="IN"
              initialValueFormat="national"
              placeholder="Enter phone number"
            />
            <span className={errors.new_mobile && classes.error}>
              {errors?.new_mobile ? "mobile number is required" : ""}
            </span>

          </div>
          </div>
      <div className={classes.inputField}>
            <PrimaryTypography variant="h5"
            >
              Confirm Number
          </PrimaryTypography>
          <div>
            <PhoneInput
              id="signup-phone-number"
              labelId="signup-phone-number"
              international
              {...register("confirm_mobile", {
                required: true,
                minLength: 10,
              })}
              className={`input_phone ${
                errors?.confirm_mobile ? "error_phone" : ""
                } `}
              style={{marginTop:"20px !important"}}
              name="phone Number"
              error={errors.confirm_mobile}
              onChange={phone => {
                setValue("confirm_mobile", phone);
              }}
              label="signup-phone-number"
              value={watch("confirm_mobile")}
              defaultCountry="IN"
              initialValueFormat="national"
              placeholder="Enter phone number"
            />
           {errors?.confirm_mobile ? <span className={errors.confirm_mobile && classes.error}>
              { errors?.confirm_mobile.message || "mobile number is required"}
            </span> :null}

          </div>
          </div>
        <PrimaryButton sx={{width:"auto"}} type="submit">Save</PrimaryButton>
        {/* <PrimaryOutlinedOpacitedButton>Clear</PrimaryOutlinedOpacitedButton> */}

      </form>
      {viewOtp ? 
      <MobileOtp reUpdate={reUpdate} isOpen={viewOtp} phone_number={watch("confirm_mobile")} toggle={()=>setViewOtp(false)} />  : null
    }
    </div>
  )
}
import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryTypography,
  GrayDivider,
  GreyTypography,
  BlackTypography,
  PrimaryButton,
  PrimaryOpacitedButton,
  MiddleDivider,
  PrimaryAvatar,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
import SearchParamHook from "hooks/searchParams";
import { useEffect, useState } from "react";
import AddEditEducation from "./AddEditEducation";
import WholeEditEducation from "./WholeEditEducation";


export default function EducationInformation({ candidateDetails,readOnly }) {
  const [educationModel, setEducationModel] = useState(false);
  const [editEducationModel, setEditEducationModel] = useState(false);
  const [updateType, setUpdateType] = useState("create");
  const [updateIndex, setUpdateIndex] = useState(0);
  const [educationalDetails, setEducationalDetails] = useState([]);
  useEffect(() => {
    if (candidateDetails?.courses) {
      setEducationalDetails(candidateDetails?.courses);
    }
  }, [candidateDetails]);
  const [userJobPostId, setUserJobPostId] = useState(null);
  const searchParams = SearchParamHook();
  useEffect(() => {
    let queryParamsForUserJobPostId = searchParams.get("user_id");
    if (queryParamsForUserJobPostId)
      setUserJobPostId(queryParamsForUserJobPostId);
  }, [searchParams]);
  function toggleModel() {
    setEducationModel(!educationModel);
  }
  function editToggleModel() {
    setEditEducationModel(!editEducationModel);
  }

  async function updateOrEditEducationalDetails(ed_data,checkMark) {
    toggleModel();
    if (updateType?.includes("update")) {
      const obj = { ...ed_data,is_currently_pursuing:checkMark }
      // if (!ed_data.desc) {
      //   obj.desc=editor
      // }
      const data = [...educationalDetails];
      data[updateIndex] = {
        ...obj,
      };
     await updateUserJobPost(userJobPostId, {
        courses: data,
      });
      setEducationalDetails(data);
    } else {
      const obj = { ...ed_data,is_currently_pursuing:checkMark }
      setEducationalDetails([...educationalDetails, obj]);
      await updateUserJobPost(userJobPostId, {
        courses: [...educationalDetails, obj],
      });
    }
  }
  console.log(candidateDetails);
  async function deleteEduExperience(index) {
    const filterEdu = educationalDetails.filter((_, id) => id !== index)
    await updateUserJobPost(userJobPostId, {
      courses: filterEdu,
    });
    setEducationalDetails(filterEdu);
    // console.log(index,filterIndex);
  }
  return (
    <Stack spacing={2} className="mt-3">
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <AddEditEducation
          toggleModel={toggleModel}
          open={educationModel}
          type={updateType}
          currentData={educationalDetails[updateIndex]}
          updateOrEditWorExperience={updateOrEditEducationalDetails}
        />
        <WholeEditEducation
          editToggleModel={editToggleModel}
          toggleModel={toggleModel}
          open={editEducationModel}
          type={updateType}
          data={educationalDetails}
          setUpdateType={setUpdateType}
          setUpdateIndex={setUpdateIndex}
          deleteEduExperience={deleteEduExperience}
        />
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
        <PrimaryTypography component="h4" variant="h4">
          Education Section
        </PrimaryTypography>
        {/* <PrimaryOpacitedButton
          onClick={() => {
            toggleModel();
            setUpdateType("create");
          }}
          startIcon={<FeatherIcon icon="plus" size="14" />}
        >
          Add
        </PrimaryOpacitedButton> */}
        {!readOnly?
        <FeatherIcon
          icon="plus"
          size="18"
          onClick={() => {
            toggleModel();
            setUpdateType("create");
          }}
        />:""}
        {!readOnly?
        <FeatherIcon
          icon="edit"
          size="16"
          onClick={() => {
            editToggleModel();
            setUpdateType("wholeeditEducation");
          }}
        />:""}
      </Stack>
      <BorderCard>
        <CardContent>
          {educationalDetails?.length > 0 && educationalDetails?.map(
            ({ certificate, institute, start_date,end_date,desc,specializaion,institute_image }, EdIndex) => {
              const isLastItem = EdIndex === educationalDetails.length - 1;
              return (
                <>
                  {/* <Stack spacing={3}>
                    <Stack spacing={1}>
                      <BlackTypography
                        sx={{ maxWidth: "600px" }}
                        variant="h5"
                        component="h5"
                      >
                        {certificate}
                      </BlackTypography>
                      <BlackTypography variant="h6" component="h6">
                        {institute}{" "}
                      </BlackTypography>
                      <GreyTypography variant="h6" component="h6">
                        {start_date}-{end_date}
                      </GreyTypography>
                      <PrimaryButton
                        sx={{ width: "100px" }}
                        onClick={() => {
                          toggleModel();
                          setUpdateType(`update${EdIndex}`);
                          setUpdateIndex(EdIndex);
                        }}
                      >
                        Edit
                      </PrimaryButton>
                    </Stack>
                  </Stack> */}

                  <Stack spacing={2} direction="row">
                  <PrimaryAvatar
                            variant="square"
                            sx={{ border: "none" }}
                            src={`${institute_image}`}
                            alt={`${institute?.charAt(0, 2)}`}
                          />
                    <Stack
                      style={{
                        whiteSpace: "normal",
                        width: "90%",
                        marginBottom: "15px",
                      }}
                      spacing={0.8}
                    >
                      <BlackTypography variant="h4" component="h4">
                        {certificate}
                      </BlackTypography>
                      <BlackTypography variant="h5" component="h5">
                        {specializaion}{" "}
                      </BlackTypography>
                      <BlackTypography variant="h5" component="h5">
                        {institute}{" "}
                      </BlackTypography>
                      <GreyTypography variant="h6" component="h6">
                        {start_date}-{end_date}
                      </GreyTypography>
                      <BlackTypography>Description:</BlackTypography>
                    
                      <GreyTypography>{desc}</GreyTypography>

                      {!isLastItem && <MiddleDivider />}
                    </Stack>
                  </Stack>
                </>
              );
            }
          )}
        </CardContent>
      </BorderCard>
    </Stack>
  );
}

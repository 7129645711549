import {
  Divider,
  Grid,
  InputLabel,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { InputAdornment, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useState } from "react";
import { useSelector } from "react-redux";
import { updateCompanies } from "helpers/services/companies/company";
import {
  BlackTypography,
  GrayDivider,
  GrayLiniearDivider,
  GreyTypography,
  PrimaryButton,
  PrimaryDivider,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputs,
  PrimaryTypography,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { updateCommonUser } from "helpers/services/common";
import { toast } from "react-hot-toast";

export default function PasswordChange() {
  // const user = useSelector(state => state.Login);
  // const [showPassword, setShowPassword] = useState(false);
  // const [cShowPasswrod, setCShowPassword] = useState(false);
  const [samePasswordError, setSamePasswordError] = useState(false);
  const [cpassworError, setCpasswordError] = useState(false);
  // const [showcurrentPassword, setShowCurrentPassword] = useState(false);
  // const {
  //   register,
  //   handleSubmit,
  //   getValues,
  //   watch,
  //   reset,
  //   unregister,
  //   formState: { errors },
  //   setValue,
  // } = useForm();

  // function handleShowCPassword() {
  //   setCShowPassword(!cShowPasswrod);
  // }
  // function handleShowPassword() {
  //   setShowPassword(!showPassword);
  // }
  // function handleShowCurrentPassword() {
  //   setShowCurrentPassword(!showcurrentPassword);
  // }
  // function handleChangePassword(data) {
  //   if (data.password === data.current_password) {
  //     setSamePasswordError(true);
  //   } else {
  //     setSamePasswordError(false);
  //     updateCommonUser({
  //       password: data?.password,
  //     });
  //     reset();
  //     toast.success("Password Changed Successfully");
  //   }
  // }


  const user = useSelector((state) => state.Login);
  const [showPassword, setShowPassword] = useState(false);
  const [cShowPassword, setCShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  function handleShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleShowCurrentPassword() {
    setShowCurrentPassword(!showCurrentPassword);
  }

  function handleShowCPassword() {
    setCShowPassword(!cShowPassword);
  }

  const handleChangePassword = (data) => {
    if (data.password !== data.cpassword) {
      toast.error('Passwords do not match');
      return;
    }

    // Add additional validation or checks if needed

    // If validation passes, update the password
    updateCommonUser({
      password: data.password,
    });

    // Reset the form and show success message
    setValue('current_password', ''); // Reset current_password field
    setValue('password', ''); // Reset password field
    setValue('cpassword', ''); // Reset cpassword field
    toast.success('Password changed successfully');
  };

  return (
    <Paper elevation={"0"} sx={{ padding: "20px" }}>
      <BlackTypography className="mb-2" variant="h2">
        Password
      </BlackTypography>
      <GreyTypography variant="h6" className="mb-4">
        Please Enter Your Current Password to Change Your Password
      </GreyTypography>
      {/* <GrayLiniearDivider className="mb-2" /> */}
      <Form onSubmit={handleSubmit(handleChangePassword)}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InputLabel>Current Password</InputLabel>
          </Grid>
          <Grid item xs={6}>
            {/* <PrimaryNormalInputs
              placeholder="password"
              sx={{ width: "100%" }}
              {...register("current_password", { required: true })}
              id="current-password-outlined"
              autoFocus
              label="current Password"
              type={showcurrentPassword ? "text" : "password"}
              error={errors.current_password}
              helperText={
                errors?.current_password?.type === "required" &&
                "Current Password is required"
              }
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowCurrentPassword}
                >
                  {showcurrentPassword ? (
                    <FeatherIcon icon="eye" size={"14"} />
                  ) : (
                    <FeatherIcon icon="eye-off" size={"14"} />
                  )}{" "}
                </InputAdornment>
              }
              variant="outlined"
            /> */}

            <PrimaryNormalInputs
              placeholder="Current Password"
              {...register('current_password', {
                required: 'Current Password is required',
              })}
              type={showCurrentPassword ? 'text' : 'password'}
              error={Boolean(errors.current_password)}
              helperText={errors.current_password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={handleShowCurrentPassword}>
                    <FeatherIcon icon={showCurrentPassword ? 'eye' : 'eye-off'} size="14" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
            <PrimaryNormalFormHelperText>
              Enter your current password
            </PrimaryNormalFormHelperText>
          </Grid>
          {/* <Grid item xs={12}>
            <GrayLiniearDivider className="mb-2" />
          </Grid>{" "} */}
          <Grid item xs={6}>
            <InputLabel>New Password</InputLabel>
          </Grid>
          <Grid item xs={6}>
            {/* <PrimaryNormalInputs
              placeholder="password"
              sx={{ width: "100%" }}
              {...register("password", {
                required: true,
                pattern: {
                  value:
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,10}$/,
                  message:
                    "Password must contain at least one letter, one number, and one special character",
                },
              })}
              id="password-outlined"
              label="Password"
              type={showPassword ? "text" : "password"}
              error={errors.password || samePasswordError}
              // helperText={
              //   (errors?.password?.type === "required" &&
              //     "Password is required",
              //   samePasswordError && "Password is same as current password")
              // }
              onChange={e => {
                if (e.target.value === getValues("current_password")) {
                  setSamePasswordError(true);
                } else {
                  setSamePasswordError(false);
                }
              }}
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowPassword}
                >
                  {showPassword ? (
                    <FeatherIcon icon="eye" size={"14"} />
                  ) : (
                    <FeatherIcon icon="eye-off" size={"14"} />
                  )}{" "}
                </InputAdornment>
              }
              variant="outlined"
            /> */}


            <PrimaryNormalInputs
              placeholder="New Password"
              {...register('password', {
                required: 'New Password is required',
                pattern: {
                  value:
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,10}$/,
                  message:
                    'Password must contain at least one letter, one number, and one special character',
                },
              })}
              type={showPassword ? 'text' : 'password'}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowPassword}
                >
                  {showPassword ? (
                    <FeatherIcon icon="eye" size={"14"} />
                  ) : (
                    <FeatherIcon icon="eye-off" size={"14"} />
                  )}{" "}
                </InputAdornment>
              }
              variant="outlined"
              fullWidth
            />
           
            
            <PrimaryNormalFormHelperText error={errors.password}>
              {errors.password && errors.password.message}
              {/* Password required */}
            </PrimaryNormalFormHelperText>

          </Grid>{" "}
          {/* <Grid item xs={12}>
            <GrayLiniearDivider className="mb-2" />
          </Grid>{" "} */}
          <Grid item xs={6}>
            <InputLabel>Confirm New Password</InputLabel>
          </Grid>
          <Grid item xs={6}>
            {/* <PrimaryNormalInputs
              placeholder="password"
              sx={{ width: "100%" }}
              {...register("cpassword", { required: true,
                validate: (value) => value === getValues("password") || "Passwords do not match",
               })}
              id="cpassword-outlined"
              label="confirm password"
              helperText={
                (errors?.cpassword?.type === "required" &&
                  "confirm new password is required",
                cpassworError && "password is not matching")
              }
              type={cShowPassword ? "text" : "password"}
              error={errors.cpassword || cpassworError}
              onChange={e => {
                if (e.target.value !== getValues("password")) {
                  setCpasswordError(true);
                } else {
                  setCpasswordError(false);
                }
              }}
              variant="outlined"
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowCPassword}
                >
                  {cShowPassword ? (
                    <FeatherIcon icon="eye" size={"14"} />
                  ) : (
                    <FeatherIcon icon="eye-off" size={"14"} />
                  )}
                </InputAdornment>
              }
            /> */}

             <PrimaryNormalInputs
              placeholder="Confirm New Password"
              {...register('cpassword', {
                required: 'Confirm New Password is required',
              })}
              type={cShowPassword ? 'text' : 'password'}
              error={Boolean(errors.cpassword)}
              helperText={errors.cpassword?.message}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end" onClick={handleShowCPassword}>
              //       <FeatherIcon icon={cShowPassword ? 'eye' : 'eye-off'} size="14" />
              //     </InputAdornment>
              //   ),
              // }}

              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowCPassword}
                >
                  {cShowPassword ? (
                    <FeatherIcon icon="eye" size={"14"} />
                  ) : (
                    <FeatherIcon icon="eye-off" size={"14"} />
                  )}
                </InputAdornment>
              }
              
              variant="outlined"
              fullWidth
            />

            {/* <PrimaryNormalFormHelperText error={errors.cpassword || cpassworError}>
              Confirm new Password
              {errors.cpassword || cpassworError?"Password not matched":""}
            </PrimaryNormalFormHelperText> */}

          </Grid>
          <Stack
            sx={{ width: "100%" }}
            alignItems="center"
            direction="row"
            justifyContent={"flex-end"}
            spacing={2}
          >
            <SecondaryFilledButton sx={{ width: "100px" }}>
              Cancel
            </SecondaryFilledButton>
            <PrimaryButton type="submit" sx={{ width: "200px" }}>
              Save Changes
            </PrimaryButton>
          </Stack>
        </Grid>
      </Form>
    </Paper>
  );
}








// export default function PasswordChange() {
//   const user = useSelector((state) => state.Login);
//   const [showPassword, setShowPassword] = useState(false);
//   const [cShowPassword, setCShowPassword] = useState(false);
//   const [showCurrentPassword, setShowCurrentPassword] = useState(false);

//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     getValues,
//     setValue,
//   } = useForm();

//   function handleShowPassword() {
//     setShowPassword(!showPassword);
//   }

//   function handleShowCurrentPassword() {
//     setShowCurrentPassword(!showCurrentPassword);
//   }

//   function handleShowCPassword() {
//     setCShowPassword(!cShowPassword);
//   }

//   const handleChangePassword = (data) => {
//     if (data.password !== data.cpassword) {
//       toast.error('Passwords do not match');
//       return;
//     }

//     // Add additional validation or checks if needed

//     // If validation passes, update the password
//     updateCommonUser({
//       password: data.password,
//     });

//     // Reset the form and show success message
//     setValue('current_password', ''); // Reset current_password field
//     setValue('password', ''); // Reset password field
//     setValue('cpassword', ''); // Reset cpassword field
//     toast.success('Password changed successfully');
//   };

//   return (
//     <Paper elevation={'0'} sx={{ padding: '20px' }}>
//       <BlackTypography className="mb-2" variant="h2">
//          Password
//        </BlackTypography>
//        <GreyTypography variant="h6" className="mb-4">
//          Please Enter Your Current Password to Change Your Password
//        </GreyTypography>
//       <form onSubmit={handleSubmit(handleChangePassword)}>
//         <Grid container spacing={2}>
//           {/* Current Password */}
//           <Grid item xs={6}>
//             <InputLabel>Current Password</InputLabel>
//           </Grid>
//             <Grid item xs={6}>
//             <TextField
//               placeholder="Current Password"
//               {...register('current_password', {
//                 required: 'Current Password is required',
//               })}
//               type={showCurrentPassword ? 'text' : 'password'}
//               error={Boolean(errors.current_password)}
//               helperText={errors.current_password?.message}
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end" onClick={handleShowCurrentPassword}>
//                     <FeatherIcon icon={showCurrentPassword ? 'eye' : 'eye-off'} size="14" />
//                   </InputAdornment>
//                 ),
//               }}
//               variant="outlined"
//               fullWidth
//             />
//           </Grid>


//           {/* New Password */}
//           <Grid item xs={12}>
//             <InputLabel>New Password</InputLabel>
//             <TextField
//               placeholder="New Password"
//               {...register('password', {
//                 required: 'New Password is required',
//                 pattern: {
//                   value:
//                     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,10}$/,
//                   message:
//                     'Password must contain at least one letter, one number, and one special character',
//                 },
//               })}
//               type={showPassword ? 'text' : 'password'}
//               error={Boolean(errors.password)}
//               helperText={errors.password?.message}
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end" onClick={handleShowPassword}>
//                     <FeatherIcon icon={showPassword ? 'eye' : 'eye-off'} size="14" />
//                   </InputAdornment>
//                 ),
//               }}
//               variant="outlined"
//               fullWidth
//             />
//           </Grid>

//           {/* Confirm New Password */}
//           <Grid item xs={12}>
//             <InputLabel>Confirm New Password</InputLabel>
//             <TextField
//               placeholder="Confirm New Password"
//               {...register('cpassword', {
//                 required: 'Confirm New Password is required',
//               })}
//               type={cShowPassword ? 'text' : 'password'}
//               error={Boolean(errors.cpassword)}
//               helperText={errors.cpassword?.message}
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end" onClick={handleShowCPassword}>
//                     <FeatherIcon icon={cShowPassword ? 'eye' : 'eye-off'} size="14" />
//                   </InputAdornment>
//                 ),
//               }}
//               variant="outlined"
//               fullWidth
//             />
//           </Grid>

//           {/* Buttons */}
//           <Grid item xs={12}>
//             <Stack direction="row" spacing={2} justifyContent="flex-end">
//               <Button variant="contained" onClick={() => setValue('current_password', '')}>
//                 Cancel
//               </Button>
//               <Button type="submit" variant="contained">
//                 Save Changes
//               </Button>
//             </Stack>
//           </Grid>
//         </Grid>
//       </form>
//     </Paper>
//   );
// }


import {
  ACCOUNT_MANAGER,
  BVC,
  CA,
  MODERATOR,
  ZA,
} from "common/constants/userModules";
import {
  deleteSingleAdminStaff,
  getAllAdminStaffList,
  updateAdminStaff,
} from "helpers/services/adminStaff/adminStaff";
import {
  getAllCompanyStaffList,
  updateCompanyStaff,
} from "helpers/services/companies/company";
import empty from "../../assets/images/zepul/shiyftEmpty.png";
import findUserType from "helpers/functions/findUserType";
import React from "react";
import { Alert, Button, Col, Row } from "reactstrap";
import {
  Card,
  FormControlLabel,
  FormGroup,
  CardContent,
  Stack,
  Typography,
  Skeleton,
  IconButton,
} from "@mui/material";
import "../../assets/css/settings.css";
import StaffModel from "./StaffModel/StaffModel";
import { IOSSwitch } from "components/Common/iosswitch";
import {
  createRecrutementCompanyStaff,
  getAllRecrutementCompanyStaffList,
  updateRecrutementCompanyStaff,
} from "helpers/services/RecrutementCompany/recrutement";
import {
  getAllBackgroundVerificationCompanyStaffList,
  updateBackgroundVerificationCompanyStaff,
} from "helpers/services/BackgroundVerifications/backgroundVerifications";
import {
  GreyTypography,
  PrimaryAvatar,
  PrimaryButton,
  PrimaryDivider,
  PrimarySwitch,
  PrimaryTypography,
  StageSearchInput,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { toast } from "react-hot-toast";
class Staff extends React.Component {
  moderatorValue="moderatorSearchValue"
  acmValue="acmSearchValue"
  csValue="csSearchValue"
  rcValue = "rcSearchValue"
  bvcValue = "bvcSearchValue"
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      headTag: "",
      RStaff: [],
      backendData: "",
      purpose: "",
      id: "",
      accountManager: [],
      moderator: [],
      update: false,
      BVCStaff: [],
      role: "SP",
      staff: [],
      loading: true,
      isApprove: "",
      [this.moderatorValue]:"",
      [this.acmValue]:"",
      [this.csValue]:"",
      [this.rcValue]: "",
      [this.bvcValue]:""
    };

    this.toggle = this.toggle.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    const user = await findUserType();
    this.setState({ role: user });
    if (user === ZA) {
      const data = await getAllAdminStaffList();
      const sortDate = [...data].sort((a,b)=>new Date(b.updatedAt)-new Date(a.updatedAt))
      sortDate.map(item => {
        if (item.usertype === "MODERATOR") {
          this.setState(prevState => ({
            moderator: [...prevState.moderator, item],
          }));
        }
        if (item.usertype === "ACCOUNT_MANAGER") {
          this.setState(prevState => ({
            accountManager: [...prevState.accountManager, item],
          }));
        }
      });
    } else if (user === CA) {
      const data = await getAllCompanyStaffList();
      const sortData = [...data].sort((a,b)=>new Date(b.updatedAt)-new Date(a.updatedAt))
      sortData.map(item => {
        this.setState(prevState => ({
          staff: [...prevState.staff, item],
        }));
      });
    } else if (user === BVC) {
      const { data } = await getAllBackgroundVerificationCompanyStaffList();
      const sortData = [...data].sort((a,b)=>new Date(b.updatedAt)-new Date(a.updatedAt))
      sortData.map(item => {
        this.setState(prevState => ({
          BVCStaff: [...prevState.BVCStaff, item],
        }));
      });
    } else {
      const { data } = await getAllRecrutementCompanyStaffList();
      const sortData = [...data].sort((a,b)=>new Date(b.updatedAt)-new Date(a.updatedAt))
      sortData?.map(item => {
        this.setState(prevState => ({
          RStaff: [...prevState.RStaff, item],
        }));
      });
    }
    this.setState({ loading: false });
  }
  async componentDidUpdate(_, prevState) {
    if (prevState.update !== this.state.update) {
      if (this.state.role === ZA) {
        const data = await getAllAdminStaffList();
        const sortDate = [...data].sort((a,b)=>new Date(b.updatedAt)-new Date(a.updatedAt))
        this.setState({ accountManager: [], moderator: [] });
        sortDate.map(item => {
          if (item.usertype === "MODERATOR") {
            this.setState(prevState => ({
              moderator: [...prevState.moderator, item],
            }));
          }
          if (item.usertype === "ACCOUNT_MANAGER") {
            this.setState(prevState => ({
              accountManager: [...prevState.accountManager, item],
            }));
          }
        });
      } else if (this.state.role === CA) {
        const data = await getAllCompanyStaffList();
        const sortData = [...data].sort((a,b)=>new Date(b.updatedAt)-new Date(a.updatedAt))
        this.setState(() => ({
          staff: sortData,
        }));
      } else if (this.state.role === BVC) {
        const { data } = await getAllBackgroundVerificationCompanyStaffList();
        const sortData = [...data].sort((a,b)=>new Date(b.updatedAt)-new Date(a.updatedAt))
        this.setState(() => ({
          BVCStaff: sortData,
        }));
      } else {
        const { data } = await getAllRecrutementCompanyStaffList();
        const sortData = [...data].sort((a,b)=>new Date(b.updatedAt)-new Date(a.updatedAt))
        this.setState(() => ({
          RStaff: sortData,
        }));
      }
    }
  }

  toggle(headTag = "", backendData = "", purpose = "", id = "",isApprove="Approve") {
    this.setState({
      headTag: headTag,
      backendData: backendData,
      isOpen: !this.state.isOpen,
      purpose,
      id,
      isApprove
    });
  }
  update = () => {
    this.setState({ update: !this.state.update });
  };

  handleSearchValue=(type,e)=>{
    this.setState({
      [type]:e.target.value
    })
  }

  clearSearchValue = (type) => {
    this.setState({
      [type]:""
    })
  }

  render() {
    const sortedModerator = this.state.moderator.filter((staff) => {
      const email = staff.email.toLowerCase()
      const user = staff.user_name.toLowerCase()
      return user.includes(this.state.moderatorSearchValue.toLowerCase().trim()) || email.includes(this.state.moderatorSearchValue.toLowerCase().trim())
    })
    const sortedACM = this.state.accountManager.filter((staff) => {
      const email = staff.email.toLowerCase()
      const user = staff.user_name.toLowerCase()
      return user.includes(this.state.acmSearchValue.toLowerCase().trim()) || email.includes(this.state.acmSearchValue.toLowerCase().trim())
    })
    const sortedCStaff = this.state.staff.filter((staff) => {
      const email = staff.email.toLowerCase()
      const user = staff.user_name.toLowerCase()
      return user.includes(this.state.csSearchValue.toLowerCase().trim()) || email.includes(this.state.csSearchValue.toLowerCase().trim())
    })
    const sortedRCStaff = this.state.RStaff.filter((staff) => {
      const email = staff.email.toLowerCase()
      const user = staff.user_name.toLowerCase()
      return user.includes(this.state.rcSearchValue.toLowerCase().trim()) || email.includes(this.state.rcSearchValue.toLowerCase().trim())
    })
    const sortedBGVStaff = this.state.BVCStaff.filter((staff) => {
      const email = staff.email.toLowerCase()
      const user = staff.user_name.toLowerCase()
      return user.includes(this.state.rcSearchValue.toLowerCase().trim()) || email.includes(this.state.rcSearchValue.toLowerCase().trim())
    })

    // console.log(sortedModerator,"v");
    return (
      <>
        {this.state.loading ? (
          <>
            <Typography component="div" variant={"h3"}>
              <Skeleton />
            </Typography>
            <Typography component="div" variant={"h3"}>
              <Skeleton />
            </Typography>{" "}
            <Typography component="div" variant={"h3"}>
              <Skeleton />
            </Typography>
          </>
        ) : (
          <React.Fragment>
            <Row>
             {this.state.isOpen ? <StaffModel
                isOpen={this.state.isOpen}
                toggle={this.toggle}
                headTag={this.state.headTag}
                backendData={this.state.backendData}
                purpose={this.state.purpose}
                id={this.state.id}
                isApprove={this.state.isApprove}
                updated={this.update}
                role={this.state.role}
              />:null}

              <Row>
                {this.state.role === ZA ? (
                  <>
                    <Col xs={12} md={6}>
                      <div
                        className="email_brand_div"
                        style={{
                          marginBottom: "10px",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      >
                        <div className="company_grid">
                          <div className="alert_small">
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems={"center"}
                            >
                              <PrimaryDivider
                                sx={{
                                  borderTopRightRadius: `5px`,
                                  borderBottomRightRadius: `5px`,
                                  height: "30px",
                                  paddingLeft: "4px",
                                }}
                                orientation="vertical"
                                flexItem
                              />
                              <PrimaryTypography component="h4" variant="h4">
                                Moderator
                              </PrimaryTypography>
                            </Stack>
                          </div>
                          {/* <PrimaryButton
                            sx={{ width: "100px" }}
                            color="primary"
                            onClick={() =>
                              this.toggle(
                                "Add Moderator",
                                "MODERATOR",
                                "CREATE"
                              )
                            }
                            startIcon={<FeatherIcon icon="plus" size="14" />}
                          >
                            Add
                          </PrimaryButton> */}
                        </div>
                      </div>                      
                        <StageSearchInput
                          placeholder="search..."
                          fullWidth
                          // sx={{ display: newItem?.isDisabled ? "none" : "flex" }}
                          onChange={this.handleSearchValue.bind(null,this.moderatorValue)}
                          value={this.state.moderatorSearchValue}
                          endAdornment={
                            <IconButton
                              edge="end"
                              className={this.state.moderatorSearchValue ? "d-inline":"d-none"}
                              onClick={this.clearSearchValue.bind(null,this.moderatorValue)}
                            >
                              <FeatherIcon icon={"x"} size={18} />
                            </IconButton>
                          }
                        />
                       <div style={{height:"calc(100vh - 300px)",marginTop:"10px", overflowY:"scroll"}}> 
                        {sortedModerator.length > 0 ? (
                          sortedModerator?.map((item, index) => (
                            <Card key={item?.id} style={{ marginBottom: "10px" }}>
                              <Row
                                className="email_brands email_brand_div"
                                key={index}
                              >
                                <Col xs={6} className="brand_wrapper">
                                  <PrimaryAvatar>
                                    <span>{item.email.charAt(0)}</span>
                                  </PrimaryAvatar>
                                  <div>
                                    <h5>{item.user_name}</h5>
                                    <h6 style={{ color: "gray" }}>
                                      {item.email}
                                    </h6>
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <PrimarySwitch
                                          sx={{ m: 1 }}
                                          defaultChecked={item.is_active}
                                          onChange={async (_,val) => {
                                            if (!val){
                                              await deleteSingleAdminStaff(
                                                item.id
                                              );
                                              toast.error("disabled")
                                            }
                                            else {
                                              const data =
                                                await updateAdminStaff(
                                                  {
                                                    ...item,
                                                    is_active: true,
                                                    usertype: MODERATOR,
                                                  },
                                                  item.id
                                                );
                                              toast.success("enabled")
                                            }
                                          }}
                                        />
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={3} className="edit_del_btn">
                                  <Button
                                    color="success"
                                    onClick={() => {
                                      this.toggle(
                                        "Edit Moderator",
                                        "MODERATOR",
                                        "EDIT",
                                        item.id
                                      );
                                    }}
                                  >
                                    <i className="bx bx-edit"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </Card>
                          ))
                        ) : (
                          <div className="no_data_staff">
                            <img className="lg_img" src={empty} />
                            <GreyTypography variant="h6">
                              No Moderators
                            </GreyTypography>{" "}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div
                        className="email_brand_div"
                        style={{
                          marginBottom: "10px",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      >
                        <div className="company_grid">
                          <div className="alert_small">
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems={"center"}
                            >
                              <PrimaryDivider
                                sx={{
                                  borderTopRightRadius: `5px`,
                                  borderBottomRightRadius: `5px`,
                                  height: "30px",
                                  paddingLeft: "4px",
                                }}
                                orientation="vertical"
                                flexItem
                              />
                              <PrimaryTypography component="h4" variant="h4">
                                Account Manger
                              </PrimaryTypography>
                            </Stack>
                          </div>{" "}
                          {/* <PrimaryButton
                            sx={{ width: "100px" }}
                            onClick={() =>
                              this.toggle(
                                "Add Account Manager",
                                "ACCOUNT_MANAGER",
                                "CREATE"
                              )
                            }
                            startIcon={<FeatherIcon icon="plus" size="14" />}
                          >
                            Add
                          </PrimaryButton> */}
                        </div>
                        </div>
                        <StageSearchInput
                          placeholder="search..."
                          fullWidth
                          // sx={{ display: newItem?.isDisabled ? "none" : "flex" }}
                          onChange={this.handleSearchValue.bind(null,this.acmValue)}
                          value={this.state.acmSearchValue}
                          endAdornment={
                            <IconButton
                              edge="end"
                              className={this.state.acmSearchValue ? "d-inline":"d-none"}
                              onClick={this.clearSearchValue.bind(null,this.acmValue)}
                            >
                              <FeatherIcon icon={"x"} size={18} />
                            </IconButton>
                          }
                        />
                      <div style={{height:"calc(100vh - 300px)",marginTop:"10px", overflowY:"scroll"}}>
                        {sortedACM.length > 0 ? (
                          sortedACM?.map((item, index) => (
                            <Card key={item?.id} style={{ marginBottom: "10px" }}>
                              <Row
                                className="email_brands email_brand_div"
                                key={index}
                              >
                                <Col xs={6} className="brand_wrapper">
                                  <PrimaryAvatar>
                                    <span>{item.email.charAt(0)}</span>
                                  </PrimaryAvatar>
                                  <div>
                                    <h5>{item.user_name}</h5>
                                    <h6 style={{ color: "gray" }}>
                                      {item.email}
                                    </h6>
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <PrimarySwitch
                                          sx={{ m: 1 }}
                                          defaultChecked={item.is_active}
                                          onChange={async (e,val) => {
                                            // console.log(item.is_active,val,"v");
                                            if (!val) {
                                              await deleteSingleAdminStaff(
                                                item.id
                                              );
                                              toast.error("disabled")
                                            }
                                            else {
                                              const data =
                                                await updateAdminStaff(
                                                  {
                                                    ...item,
                                                    is_active: true,
                                                    usertype: ACCOUNT_MANAGER,
                                                  },
                                                  item.id
                                                );
                                              toast.success("enabled")
                                            }
                                          }}
                                        />
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={3} className="edit_del_btn">
                                  <Button
                                    color="success"
                                    onClick={() => {
                                      this.toggle(
                                        "Edit Account Manger",
                                        "ACCOUNT_MANAGER",
                                        "EDIT",
                                        item.id
                                      );
                                    }}
                                  >
                                    <i className="bx bx-edit"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </Card>
                          ))
                        ) : (
                          <div className="no_data_staff">
                            <img className="lg_img" src={empty} />
                            <GreyTypography variant="h6">
                              No Account Managers
                            </GreyTypography>{" "}
                          </div>
                        )}
                      </div>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                {this.state.role === CA ? (
                  <Col xs={12} md={12}>
                    <div
                      className="email_brand_div"
                      style={{
                        marginBottom: "10px",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      <div className="company_grid">
                        <div className="alert_small">
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems={"center"}
                          >
                            <PrimaryDivider
                              sx={{
                                borderTopRightRadius: `5px`,
                                borderBottomRightRadius: `5px`,
                                height: "30px",
                                paddingLeft: "4px",
                              }}
                              orientation="vertical"
                              flexItem
                            />
                            <PrimaryTypography component="h4" variant="h4">
                              Staff
                            </PrimaryTypography>
                          </Stack>
                        </div>{" "}
                        <PrimaryButton
                          sx={{ width: "100px" }}
                          onClick={() =>
                            this.toggle("Add Staff", "COMPANY_STAFF", "CREATE")
                          }
                          startIcon={<FeatherIcon icon="plus" size="14" />}
                        >
                          Add
                        </PrimaryButton>
                      </div>
                      </div>
                      <StageSearchInput
                          placeholder="search..."
                          fullWidth
                          // sx={{ display: newItem?.isDisabled ? "none" : "flex" }}
                          onChange={this.handleSearchValue.bind(null,this.csValue)}
                          value={this.state.csSearchValue}
                          endAdornment={
                            <IconButton
                              edge="end"
                              className={this.state.csSearchValue ? "d-inline":"d-none"}
                              onClick={this.clearSearchValue.bind(null,this.csValue)}
                            >
                              <FeatherIcon icon={"x"} size={18} />
                            </IconButton>
                          }
                        />
                    <div style={{height:"calc(100vh - 300px)",marginTop:"10px", overflowY:"scroll"}}>
                      {sortedCStaff.length > 0 ? (
                        sortedCStaff?.map((item, index) => (
                          <Card key={item?.id} style={{ marginBottom: "10px" }}>
                            <Row
                              className="email_brands email_brand_div"
                              key={index}
                            >
                              <Col xs={6} className="brand_wrapper">
                                <PrimaryAvatar>
                                  {item.email.charAt(0)}
                                </PrimaryAvatar>
                                <div>
                                  <h5>
                                    {item.first_name} {item.last_name}
                                  </h5>
                                  <h6 style={{ color: "gray" }}>
                                    {item.email}
                                  </h6>
                                </div>
                              </Col>
                              <Col xs={3}>
                                {" "}
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <PrimarySwitch
                                        sx={{ m: 1 }}
                                        defaultChecked={item.is_active}
                                        onChange={async (e,value) => {
                                          await updateCompanyStaff(
                                            {
                                              email: item.email,
                                              first_name: item.first_name,
                                              last_name: item.last_name,
                                              is_active: value,
                                            },
                                            item.id
                                          );
                                          if (value)
                                            toast.success('enabled')
                                          else
                                            toast.error('disabled')
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={3} className="edit_del_btn">
                                <Button
                                  color="success"
                                  onClick={() => {
                                    this.toggle(
                                      "Edit Staff",
                                      "COMPANY_STAFF",
                                      "EDIT",
                                      item.id
                                    );
                                  }}
                                >
                                  <i className="bx bx-edit"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Card>
                        ))
                      ) : (
                        <div className="no_data_staff">
                          <img className="lg_img" src={empty} />
                          <GreyTypography variant="h6">
                            No Company Staff
                          </GreyTypography>{" "}
                        </div>
                      )}{" "}
                    </div>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.role === "RC" ? (
                  <Col xs={12} md={12}>
                    <div
                      className="email_brand_div"
                      style={{
                        marginBottom: "10px",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      <div className="company_grid">
                        <div className="alert_small">
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems={"center"}
                          >
                            <PrimaryDivider
                              sx={{
                                borderTopRightRadius: `5px`,
                                borderBottomRightRadius: `5px`,
                                height: "30px",
                                paddingLeft: "4px",
                              }}
                              orientation="vertical"
                              flexItem
                            />
                            <PrimaryTypography component="h4" variant="h4">
                              Staff
                            </PrimaryTypography>
                          </Stack>
                        </div>{" "}
                        <PrimaryButton
                          sx={{ width: "100px" }}
                          onClick={() =>
                            this.toggle("Add Staff", "COMPANY_STAFF", "CREATE")
                          }
                          startIcon={<FeatherIcon icon="plus" size="14" />}
                        >
                          Add
                        </PrimaryButton>
                      </div>
                      </div>
                      <StageSearchInput
                          placeholder="search..."
                          fullWidth
                          // sx={{ display: newItem?.isDisabled ? "none" : "flex" }}
                          onChange={this.handleSearchValue.bind(null,this.rcValue)}
                          value={this.state.rcSearchValue}
                          endAdornment={
                            <IconButton
                              edge="end"
                              className={this.state.rcSearchValue ? "d-inline":"d-none"}
                              onClick={this.clearSearchValue.bind(null,this.rcValue)}
                            >
                              <FeatherIcon icon={"x"} size={18} />
                            </IconButton>
                          }
                        />
                    <div style={{height:"calc(100vh - 300px)",marginTop:"10px", overflowY:"scroll"}}>
                      {sortedRCStaff.length > 0 ? (
                        sortedRCStaff?.map((item, index) => (
                          <Card style={{ marginBottom: "10px" }}>
                            <Row
                              className="email_brands email_brand_div"
                              key={index}
                            >
                              <Col xs={6} className="brand_wrapper">
                                <PrimaryAvatar>
                                  {item.email.charAt(0)}
                                </PrimaryAvatar>
                                <div>
                                  <h5>{item.user_name}</h5>
                                  <h6 style={{ color: "gray" }}>
                                    {item.email}
                                  </h6>
                                </div>
                              </Col>
                              <Col xs={3}>
                                {" "}
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <PrimarySwitch
                                        sx={{ m: 1 }}
                                        defaultChecked={item.is_active}
                                        onChange={async (e,value) => {
                                          await updateRecrutementCompanyStaff(
                                            {
                                              email: item.email,
                                              first_name: item.first_name,
                                              last_name: item.last_name,
                                              is_active: value,
                                            },
                                            item.id
                                          );
                                          if (value)
                                          toast.success('enabled')
                                        else
                                          toast.error('disabled')
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={3} className="edit_del_btn">
                                <Button
                                  color="success"
                                  onClick={() => {
                                    this.toggle(
                                      "Edit Staff",
                                      "RECRUTEMENT_STAFF",
                                      "EDIT",
                                      item.id
                                    );
                                  }}
                                >
                                  <i className="bx bx-edit"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Card>
                        ))
                      ) : (
                        <div className="no_data_staff">
                          <img className="lg_img" src={empty} />
                          <GreyTypography variant="h6">
                            No Company Staff
                          </GreyTypography>{" "}
                        </div>
                      )}{" "}
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                {this.state.role === "BVC" ? (
                  <Col xs={12} md={12}>
                    <div
                      className="email_brand_div"
                      style={{
                        marginBottom: "10px",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      <div className="company_grid">
                        <div className="alert_small">
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems={"center"}
                          >
                            <PrimaryDivider
                              sx={{
                                borderTopRightRadius: `5px`,
                                borderBottomRightRadius: `5px`,
                                height: "30px",
                                paddingLeft: "4px",
                              }}
                              orientation="vertical"
                              flexItem
                            />
                            <PrimaryTypography component="h4" variant="h4">
                              Staff
                            </PrimaryTypography>
                          </Stack>
                        </div>{" "}
                        <PrimaryButton
                          sx={{ width: "100px" }}
                          onClick={() =>
                            this.toggle("Add Staff", "COMPANY_STAFF", "CREATE")
                          }
                          startIcon={<FeatherIcon icon="plus" size="14" />}
                        >
                          Add
                        </PrimaryButton>
                      </div>
                      </div>
                      <StageSearchInput
                          placeholder="search..."
                          fullWidth
                          // sx={{ display: newItem?.isDisabled ? "none" : "flex" }}
                          onChange={this.handleSearchValue.bind(null,this.bvcValue)}
                          value={this.state.bvcSearchValue}
                          endAdornment={
                            <IconButton
                              edge="end"
                              className={this.state.bvcSearchValue ? "d-inline":"d-none"}
                              onClick={this.clearSearchValue.bind(null,this.bvcValue)}
                            >
                              <FeatherIcon icon={"x"} size={18} />
                            </IconButton>
                          }
                        />
                    <div style={{height:"calc(100vh - 300px)",marginTop:"10px", overflowY:"scroll"}}>
                      {sortedBGVStaff.length > 0 ? (
                        sortedBGVStaff?.map((item, index) => (
                          <Card style={{ marginBottom: "10px" }}>
                            <Row
                              className="email_brands email_brand_div"
                              key={index}
                            >
                              <Col xs={6} className="brand_wrapper">
                                <div className="pofile_banner">
                                  <span style={{ textTransform: "uppercase" }}>
                                    {item.email.charAt(0)}
                                  </span>
                                </div>
                                <div>
                                  <h5>{item.user_name}</h5>
                                  <h6 style={{ color: "gray" }}>
                                    {item.email}
                                  </h6>
                                </div>
                              </Col>
                              <Col xs={3}>
                                {" "}
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <PrimarySwitch
                                        sx={{ m: 1 }}
                                        defaultChecked={item.is_active}
                                        onChange={async (_,value) => {
                                          await updateBackgroundVerificationCompanyStaff(
                                            {
                                              email: item.email,
                                              first_name: item.first_name,
                                              last_name: item.last_name,
                                              is_active: value,
                                            },
                                            item.id
                                          );
                                          if (value)
                                            toast.success('enabled')
                                          else
                                            toast.error('disabled')
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={3} className="edit_del_btn">
                                <Button
                                  color="success"
                                  onClick={() => {
                                    this.toggle(
                                      "Edit Staff",
                                      "BACKGROUND_VERIFICATION_STAFF",
                                      "EDIT",
                                      item.id
                                    );
                                  }}
                                >
                                  <i className="bx bx-edit"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Card>
                        ))
                      ) : (
                        <div className="no_data_staff">
                          <img className="lg_img" src={empty} />
                          <GreyTypography variant="h6">
                            No Company Staff
                          </GreyTypography>{" "}
                        </div>
                      )}{" "}
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Row>
          </React.Fragment>
        )}
      </>
    );
  }
}

export default Staff;

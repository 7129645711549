import * as React from "react";
import {
  PrimaryAvatar,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryTypography,
  SecondaryFilledButton
} from "assets/Mui/globalTheme";
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Grid,
  Stack
} from "@mui/material";
import { Col, Row } from "reactstrap";
import { grey, indigo } from "@mui/material/colors";
import { imageURL } from "common/constants/commonURLS";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import UploadModel from "components/Common/models/uploadModel";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { useForm, Controller } from "react-hook-form";
import { updateCompanies } from "helpers/services/companies/company";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { useSelector,useDispatch } from "react-redux";
import { updateTechnicalEvaluator } from "helpers/services/TechincalEvator/technicalEvaluator";
import { updateCommonUser } from "helpers/services/common";
import { toast } from "react-hot-toast";
import { loginSuccess } from "store/actions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function CompanyForm({ user, toggleModel }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm();
  const userDetails = useSelector(state => state.Login);
  const dispatch = useDispatch()

  const onSubmit = handleSubmit(async data => {
    delete data.industry_name;
    data.image = data.profile_image || "";
    delete data.profile_image;
    delete data.email;
    // data.industry = [data.industry];
    // delete data.industry;
    const result = await updateCommonUser(data);
    if (result.status === 200) {
      dispatch(loginSuccess(result))
      toggleModel();
      toast.success("Successfully Updated");
    }
    ErrorHandlingToaster(result);
  });

  // const [industries, setIndustries] = React.useState([]);
  const [avatarHover, setAvatarHover] = React.useState(false);
  const [modelOpen, setModelOpen] = React.useState(false);
  // React.useEffect(() => {
  //   getIndustry();
  // }, []);
  React.useEffect(() => {
    const industry_details = user?.user_industry_details?.find(
      (item, index) => index === 0
    );
    setValue("first_name", user?.first_name);
    setValue("last_name", user?.last_name);
    setValue("email", user?.email);
    // setValue("industry", industry_details?.id);
    // setValue("industry_name", industry_details?.name);
    setValue("profile_image", user?.image);
  }, [user]);
  // async function getIndustry() {
  //   const industries = await getAllIndustries();
  //   const indus = industries.map(item => {
  //     return {
  //       id: item.id,
  //       name: item.name,
  //     };
  //   });
  //   setIndustries(indus);
  // }
  function handleAvatar() {
    setAvatarHover(!avatarHover);
  }
  function handleUpload() {
    setModelOpen(!modelOpen);
  }
  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setValue("profile_image", url);
    handleUpload();
  };
  return (
    <form onSubmit={onSubmit}>
      <UploadModel
        isOpen={modelOpen}
        toggle={handleUpload}
        handleImage={handleImageUpload}
      />
      <UploadModel
        isOpen={modelOpen}
        toggle={handleUpload}
        handleImage={handleImageUpload}
      />
      <PrimaryTypography variant="h2">Edit Profile</PrimaryTypography>
      <Row className="mt-4">
        <Col xs={12} className="mb-3">
          <input
            type="text"
            {...register("profile_image", { required: true })}
            style={{ display: "none" }}
          />
          <PrimaryAvatar
            variant="circular"
            onClick={handleUpload}
            sx={{
              height: "94px !important",
              width: "94px !important",
              ...(errors?.profile_image && {
                border: "1px solid red",
              }),
            }}
            onMouseOver={handleAvatar}
            onMouseOut={handleAvatar}
            src={`${imageURL}${getValues("profile_image")}`}
          >
            {avatarHover ? (
              <FeatherIcon icon="edit" size="14" />
            ) : (
              user?.first_name?.charAt(0)
            )}
          </PrimaryAvatar>
        </Col>
        <Col xs={6} className="mb-3 ">
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="profile-edit-first-name"
              error={errors.tagline}
            >
              First Name
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="John"
              id="profile-edit-first-name"
              label="First name"
              error={errors?.first_name}
              {...register("first_name", { required: true })}
              type="text"
            />
            <PrimaryNormalFormHelperText error={errors.first_name}>
              {errors?.first_name ? "first name is required" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Col>
        <Col xs={6} className="mb-3">
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="profile-edit-last-name"
              error={errors.last_name}
            >
              Last Name
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="doe"
              id="profile-edit-last-name"
              label="Last name"
              error={errors?.last_name}
              {...register("last_name", { required: true })}
              type="text"
            />
            <PrimaryNormalFormHelperText error={errors.last_name}>
              {errors?.last_name ? "last name is required" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Col>
        {/* <Col xs={12} className="mb-3">
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="profile-edit-email"
              error={errors.last_name}
            >
              Email
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="johndoe@zepul.com"
              id="profile-edit-email"
              label="email"
              error={errors?.email}
              {...register("email", { required: true })}
              type="email"
            />
            <PrimaryNormalFormHelperText error={errors.email}>
              {errors?.email ? "email is required" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Col> */}
        {/* {userDetails?.role === "CA" ? (
          <Col xs={12} className="mb-3">
            <Controller
              name="industry_name"
              render={({ field }) => (
                <FormControl sx={{ width: "100%" }}>
                  <PrimaryNormalInputLabel
                    error={errors?.industry}
                    shrink
                    sx={{
                      left: "-12px",
                      top: "10px",
                    }}
                    id="edit-profile-industry"
                  >
                    Industry
                  </PrimaryNormalInputLabel>
                  <Select
                    {...field}
                    MenuProps={MenuProps}
                    error={errors?.industry_name}
                    renderValue={selected => {
                      if (
                        selected?.length === 0 ||
                        selected === undefined ||
                        selected === null
                      ) {
                        return watch("industry_name");
                      }
                      return selected;
                    }}
                    //TODO CHECK HERE FOR INDUSTRY NO BUGS WORKING FINE
                    displayEmpty={true}
                    labelId="selct-user-type"
                    id="demo-multiple-name"
                    value={watch("industry_name") || ""}
                    input={
                      <PrimaryNormalInputs
                        label="edit-profile-industry"
                        // {...register("industry_name", { required: true })}
                        // value={watch("industry_name")|| ""}
                      />
                    }
                  >
                    {industries?.map(item => {
                      return (
                        <MenuItem
                          key={item?.id}
                          value={item.name || ""}
                          onClick={e => {
                            setValue("industry", item?.id);
                          }}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              control={control}
            />
          </Col>
        ) : null} */}
        <Col xs={6}></Col>
        <Col xs={6}>
          <Stack spacing={1} direction="row">
            <SecondaryFilledButton
              onClick={() => {
                toggleModel();
              }}
            >
              close
            </SecondaryFilledButton>
            <PrimaryButton type="submit">Save</PrimaryButton>
          </Stack>{" "}
        </Col>
      </Row>
    </form>
  );
}

const { Row, Container, Col, Card, CardBody } = require("reactstrap");
import {
  createEducationalQualifications,
  getAllEducationalQualifications,
  updateEducationalQualification,
} from "helpers/services/jobAttributes/educationalQualifications";
import React from "react";
import { FormControlLabel, FormGroup } from "@mui/material";
import "../../../assets/css/jobattr.css";
import { IOSSwitch } from "components/Common/iosswitch";
import CreateModel from "components/Common/models/createModel";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import { toast } from "react-hot-toast";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Stack } from "@mui/material";
import {
  StageSearchInput,
  GreyTypography,
} from "assets/Mui/globalTheme";
import SearchIcon from "@mui/icons-material/Search";

class Qualifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      id:"",
      name: "",
      navSelected: "0",
      data: [],
      openSearch:true,
      searchRole:""
    };
    this.toggle = this.toggle.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }
  async componentDidMount() {
    const data = await getAllEducationalQualifications();
    this.setState({ data });
  }

  toggle(purpose = "", name = "", active = "", id = "", index) {
    this.setState({
      purpose: purpose,
      name: name,
      index,
      id,
      active: active,
      isOpen: !this.state.isOpen,
    });
  }

  handleAction(item, index) {
    let result = this.state.data;
    updateEducationalQualification(item.id, {
      is_active: !item.is_active,
      name: item.name,
    });
    // result[index].is_active = !result[index].is_active;

    const filterOne = result?.map((each)=>{
      if(each.id == item.id){
        return {
          ...each,
          is_active:!each.is_active
        }
      }else {
        return {
          ...each
        }
      }
     })

     const statusCheck = result?.filter((each)=>{
      if(each?.id == item.id){
        return {
          ...each,
          is_active:!item.is_active
        }
      }
     })


     if(statusCheck && statusCheck[0]?.is_active){
      toast.error("disabled")
   
    }else {
      toast.success("enabled")
    }


    this.setState({
      data: filterOne,
    });
    
    // if (result[index].is_active) {
    //   toast.success("enabled")
    // } else {
    //   toast.error("disabled")
    // }
  }
  async updateFunc(sample, type, index,id) {

    let editedId;
    if (id) {
      editedId = id;
    } else {
      editedId = this.state.id;
    }

    if (type === "Create") {
      await createEducationalQualifications(sample);
      this.setState({
        data: [...this.state?.data, sample],
      });
    } else {
      // await updateEducationalQualification(this.state.id, sample);
      const changeData = {
        name: sample?.name,
        is_active: sample?.is_Active,
      }

      await updateEducationalQualification(editedId, changeData);
      let result = this.state.data;

      const filterOne = result?.map((each)=>{
        if(each.id == editedId){
          return {
            ...each,
            name:sample.name,
            is_active:sample.is_active
          }
        }else {
          return {
            ...each
          }
        }
       })

      // result[index] = sample;
      this.setState({
        data: filterOne,
      });
    }
  }


    /* Search toggle effect */
    handleSearchOpen = () => {
      this.setState((prevState) => ({
        openSearch: !prevState.openSearch,
        searchRole:""
      }));
    }

    handleSearch=(e)=>{
      this.setState((prevState)=>({
        searchRole:e.target.value
      }))
    }

    searchFilterData = (data)=>{
      const searchWord = this.state?.searchRole?.toLowerCase()
      const searchPattern = data?.filter((item)=>{
        const roleName = item?.name?.toLowerCase()
        const categoryName = item?.job_attribute_functional_area?.name?.toLowerCase()
    
        if(roleName?.includes(searchWord) || categoryName?.includes(searchWord)){
          return item
        }
      })
      return searchPattern
    }
    
    /* Search toggle effect end */


  render() {
    const filteredData = this.searchFilterData(this.state?.data)
    return (
      <Container fluid>
        <CreateModel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          headTag={"Educational Qualification"}
          purpose={this.state.purpose}
          index={this.state.index}
          title={this.state.name}
          active={this.state.active}
          update={this.updateFunc}
          id={this.state.id}
        />
        <Row>
          <Row className="align-items-center ">
            <Col md={12} lg={12} style={{ padding: "40px" }} className="header_jobs_sticky">
              <PrimaryButton
                sx={{ width: "244px" }}
                onClick={() => this.toggle("Create")}
              >
                Create Educational Qualifications
              </PrimaryButton>

              <Stack alignItems={"center"} direction={"row"} spacing={1.6}>
                  {this.state.openSearch ? (
                    <SearchIcon
                      onClick={this.handleSearchOpen}
                    
                    />
                  ) : (
                    <StageSearchInput
                      placeholder="search..."
                      onChange={(e)=>this.handleSearch(e)}
                      endAdornment={
                        <IconButton edge="end" onClick={this.handleSearchOpen}>
                          <ClearIcon />
                         </IconButton>
                      }
                    />
                  )}
                
                </Stack>

            </Col>
          </Row>
          <div className="table_cont">
            <div className="table-responsive">
              <Card style={{ maxWidth: "80vw" }}>
                <CardBody>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Qualification</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {/* {this.state.data.map((item, index) => ( */}
                      {filteredData?.map((item, index) => (
                        <TableRow key={item.name + index}>
                          <TableCell>{item.name}</TableCell>

                          <TableCell className="active_td">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <PrimarySwitch
                                    sx={{ m: 1 }}
                                    checked={item.is_active}
                                    onChange={async e => {
                                      this.handleAction(item, index);
                                    }}
                                  />
                                }
                              />
                            </FormGroup>
                          </TableCell>
                          <TableCell className="col_btn">
                            <PrimaryButton
                              sx={{ width: "100px" }}
                              onClick={() =>
                                this.toggle(
                                  "Edit",
                                  item.name,
                                  item.active,
                                  item.id,
                                  index
                                )
                              }
                            >
                              Edit
                            </PrimaryButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}

export default Qualifications;

import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BlackTypography,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryTypography,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { useForm } from "react-hook-form";
import { CircularProgress, FormControl, Grid, Stack } from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createAdminStaff,
  getSingleAdminStaffDetails,
  updateAdminStaff,
} from "helpers/services/adminStaff/adminStaff";
import {
  createCompanyStaff,
  getSingleCompanyStaffDetails,
  updateCompanyStaff,
} from "helpers/services/companies/company";
import {
  createBackgroundVerificationCompanyStaff,
  getSingleBackgroundVerificationStaffDetails,
  updateBackgroundVerificationCompanyStaff,
} from "helpers/services/BackgroundVerifications/backgroundVerifications";
import {
  createRecrutementCompanyStaff,
  getSingleRecrutementStaffDetails,
} from "helpers/services/RecrutementCompany/recrutement";
import { toast } from "react-hot-toast";
import PhoneInput,{isValidPhoneNumber,formatPhoneNumberIntl} from "react-phone-number-input";
import { sendOtp } from "helpers/services/otpVerifiy/verify";
import StaffOtp from "./staffOtp";

export default function StaffModel({
  purpose,
  role,
  backendData,
  id,
  headTag,
  isOpen,
  toggle,
  updated,
  isApprove
}) {
  const [viewOtp, setViewOtp] = React.useState(false)
  const [load, setLoad] = React.useState(false)
  const [tempNum,setTempNum] = React.useState("")
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  React.useEffect(() => {
    if (purpose === "EDIT") fillEditFieldValues();
  }, [purpose]);
  // console.log(backendData,id,"v");
  async function fillEditFieldValues() {
    if (role === "RC") {
      const { data: currentData } = await getSingleRecrutementStaffDetails(id);
      setValue("first_name", currentData.first_name);
      setValue("last_name", currentData.last_name);
      setValue("email", currentData.email);
    } else if (role === "ZA") {
      const currentData = await getSingleAdminStaffDetails(id);
      // const num = formatPhoneNumberIntl(currentData?.phone_number)
      setValue("first_name", currentData.first_name);
      setValue("last_name", currentData.last_name);
      setValue("email", currentData.email);
      setValue("mobile",currentData?.phone_number)
      setTempNum(currentData?.phone_number)
    } else if (role === "CA") {
      const currentData = await getSingleCompanyStaffDetails(id);
      // const num = formatPhoneNumberIntl(currentData?.phone_number)
      setValue("first_name", currentData.first_name);
      setValue("last_name", currentData.last_name);
      setValue("email", currentData.email);
      setValue("mobile",currentData?.phone_number)
      setTempNum(currentData?.phone_number)
    } else {
      const { data: currentData } =
        await getSingleBackgroundVerificationStaffDetails(id);
      setValue("first_name", currentData.first_name);
      setValue("last_name", currentData.last_name);
      setValue("email", currentData.email);
      setValue("mobile",currentData?.phone_number)
      setTempNum(currentData?.phone_number)
    }
  }

  async function SendOtpHandle(number) {
    try {
      setLoad(true)
      const res =  await sendOtp("mobile",number)
      const { status } = res
      console.log(res);
      if (status === 200 && viewOtp) {
        setViewOtp(true)     
        setLoad(false)
      }        
    } catch (error) {        
      setLoad(false)
    }
  }

  const updateOrEditStaff = async data => {
    if (!isValidPhoneNumber(data.mobile)) {
      setError("mobile",{type:"custom",message:"invalid number"})
      return;
    }
    if (purpose === "EDIT") {
      console.log("enter to efit");
      try {
        if (data.mobile === tempNum) {
          console.log("previous");
          delete data.user_password;
          switch (role) {
            case "ZA":
              await updateAdminStaff({ ...data,isApprove }, id);
              break;
            case "CA":
              await updateCompanyStaff({ ...data }, id);
              break;
    
            case "BVC":
              await updateBackgroundVerificationCompanyStaff({ ...data }, id);
              break;
            default:
              return null;
          }
                updated();
                toggle();
        } else {
        console.log("enter to otp");
  
          // SendOtpHandle(data.mobile)
            setLoad(true)
            const res =  await sendOtp("mobile",data.mobile)
            const { status } = res
            console.log(res);
            if (status === 200) {
              setViewOtp(true)     
              setLoad(false)
            }                
          }
        } catch (error) {
        setLoad(false)        
      }

    } else {
      // SendOtpHandle(data.mobile)
      try {
        setLoad(true)
        const res =  await sendOtp("mobile",data.mobile)
        const { status } = res
        console.log(res);
        if (status === 200) {
          setViewOtp(true)     
          setLoad(false)
        }        
      } catch (error) {        
        setLoad(false)
      }
    }
    // toast.success("Successfully Created!")

  };
  const userData = {
    first_name: watch("first_name"),
    last_name: watch("last_name"),
    email: watch("email"),
    mobile: watch("mobile"),
    user_password:watch("user_password")
  }
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => {
          toggle();
          reset();
          setLoad(false);
        }}
        aria-labelledby="edit-profile-modal-first_name"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          {!load ?
            <>
          <BlackTypography variant="h2" sx={{ marginBottom: "10px" }}>
            {headTag}{" "}
          </BlackTypography>
          <form autoComplete={"off"} onSubmit={handleSubmit(updateOrEditStaff)}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-first-name-outlined"
                    error={errors.first_name}
                  >
                    First name
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    fullWidth
                    placeholder="Enter your first_name"
                    {...register("first_name", { required: true })}
                    id="wor-exp-first-name-outlined"
                    helperText={
                      errors?.first_name ? "first  name is required" : ""
                    }
                    autoFocus
                    label="Job first_name"
                    type={"text"}
                    name="first_name"
                    error={errors.first_name}
                    variant="outlined"
                  />
                  <PrimaryNormalFormHelperText error={errors.first_name}>
                    {errors.first_name ? "first name is required Field" : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-last-name-outlined"
                    error={errors.last_name}
                  >
                    Last name
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    fullWidth
                    placeholder="Enter your Last Name"
                    {...register("last_name", { required: true })}
                    id="wor-exp-last-name-outlined"
                    helperText={
                      errors?.last_name ? "last  name is required" : ""
                    }
                    autoFocus
                    label=" last name"
                    type={"text"}
                    name="last_name"
                    error={errors.last_name}
                    variant="outlined"
                  />
                  <PrimaryNormalFormHelperText error={errors.last_name}>
                    {errors.last_name ? "last name is required Field" : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-email-outlined"
                    error={errors.email}
                  >
                    Email
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    fullWidth
                    placeholder="Enter your email"
                    {...register("email", { required: true })}
                    id="wor-exp-email-outlined"
                    helperText={errors?.email ? "email is required" : ""}
                    autoFocus
                    label=" email"
                    type={"email"}
                    name="email"
                    error={errors.email}
                    variant="outlined"
                  />
                  <PrimaryNormalFormHelperText error={errors.email}>
                    {errors.email ? "email is required Field" : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryTypography sx={{fontWeight:"500 !important"}} variant="h5"
            >
              Number
          </PrimaryTypography>
                  <PhoneInput
              id="signup-phone-number"
              labelId="signup-phone-number"
                        international={true}
                        useNationalFormatForDefaultCountryValue={true}
              withCountryCallingCode={true}
              {...register("mobile", {
                required: true,
                minLength: 10,
              })}
              className={`input_phone ${
                errors?.confirm_mobile ? "error_phone" : ""
                } `}
              style={{marginTop:"20px !important"}}
              name="phone Number"
              error={errors.confirm_mobile}
              onChange={phone => {
                setValue("mobile", phone);
              }}
              label="signup-phone-number"
              value={watch("mobile")}
              defaultCountry="IN"
              initialValueFormat="national"
              placeholder="Enter phone number"
            />
                  {errors.mobile ?<PrimaryNormalFormHelperText error={errors.mobile}>
                    {errors.mobile.message || "mobile number is required Field"}
                  </PrimaryNormalFormHelperText> : null}
                </FormControl>
              </Grid>
              {purpose !== "EDIT" && (
                <Grid item md={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="user-password-outlined"
                      error={errors.user_password}
                    >
                      password
                    </PrimaryNormalInputLabel>
                    <PrimaryNormalInputs
                      fullWidth
                      placeholder="Enter your password"
                      {...register("user_password", {
                        required: false,
                      })}
                      id="user-password-outlined"
                      helperText={
                        errors?.user_password ? "password is required" : ""
                      }
                      autoFocus
                      label=" user_password"
                      type={"password"}
                      name="user_password"
                      error={errors.user_password}
                      variant="outlined"
                    />
                    <PrimaryNormalFormHelperText error={errors.user_password}>
                      {errors.user_password ? "password is required Field" : ""}
                    </PrimaryNormalFormHelperText>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Stack spacing={1} direction="row">
                  <SecondaryFilledButton
                    onClick={() => {
                      toggle();
                      reset();
                    }}
                  >
                    cancel
                  </SecondaryFilledButton>
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </Stack>{" "}
              </Grid>
            </Grid>
          </form>            
            </>
            :
            <div style={{marginLeft:"45%"}}>
              <CircularProgress size={40} />
            </div>
          }
        </Box>
      </Modal>
      {viewOtp ? <StaffOtp id={id} purpose={purpose} isOpen={viewOtp} toggle={() => setViewOtp(false)} userData={userData} backendData={backendData} isApprove={isApprove} handleClose={toggle} reUpdate={updated} clearField={ reset} /> : null}
    </div>
  );
}

import  React,{useState} from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useFormContext } from "react-hook-form";
import { useForm, Controller } from "react-hook-form";
import { handleApproveJob } from "helpers/services/adminStaff/AccountManager.js/ApproveJobs";
import getSymbolFromCurrency from "currency-symbol-map";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { toast } from "react-hot-toast";
import {
  PrimaryNormalInputs,
  PrimaryTypography,
  BlackTypography,
  MiddleDivider,
  CommissionTypography,
  GreyTypography,
  PrimaryCommissionInputs,
  PrimaryNormalInputLabel,
  MenuProps,
  PrimaryNormalFormHelperText,
  PrimaryButton,
  PrimarySwitch,
} from "assets/Mui/globalTheme";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  TextField,
  makeStyles,
  Card,
  CardContent,
  Paper,
} from "@mui/material";
import { updateCommissions } from "helpers/services/CommissionsApi/commissionUpdate";
import { getCommissionById } from "helpers/services/CommissionsApi/commissionUpdate";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CommissionModal({ isOpen, title, toggle, tableData ,jobpostId,reCall}) {
  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  console.log(tableData,"jobdata")
  // console.log(jobpostId,"jobpostId")

  const [showCommission, setShowCommission] = useState(true);

  const payouts = [
    { name: "Per Month", value: "per_month"},
    { name: "Per Year", value: "per_year" },
    { name: "Per Quarterly", value: "per_quarterly" },
    { name: "One Time", value: "one_time" },
  ];

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    toggle();
  };

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const fetchCommissionById = async id => {
    if(id){
      const { status, data } = await getCommissionById(id);
      if (data?.data) {
        // const payoutValue = data?.data?.refer_self_commission?.[0]?.payout || '';
        setValue("selfReferPayoutType", data?.data?.refer_self_commission?.[0]?.payout?data?.data?.refer_self_commission?.[0]?.payout:"");
        // setValue("selfReferPayoutType", payoutValue.trim());
  
        setValue(
          "selfReferralFlatRate",
          data?.data?.refer_self_commission?.[0]?.flat_rate
        );
        setValue(
          "referredPayoutType",
          data?.data?.refer_now_commission?.[0]?.flat_rate
        );
        setValue("referredFlatRate", data?.data?.refer_now_commission?.[0]?.flat_rate);
        setValue(
          "screeningPerProfile",
          data?.data?.sp_commission?.length > 0
        );
      
        setValue(
          "screeningOnboard",
          data?.data?.sp_commission?.length > 0 
        );
        setValue(
          "technicalPerProfile",
          data?.data?.te_commission?.length > 0 
        );
        setValue(
          "technicalOnboard",
          data?.data?.te_commission?.length > 0 
        );
        setValue(
          "moderatorPerProfile",
          data?.data?.me_commission?.length > 0
        );
        setValue(
          "moderatorOnboard",
          data?.data?.me_commission?.length > 0
        );
        setValue(
          "recruitmentCompanyPayoutType",
          data?.data?.rc_commission?.[0]?.payout
        );
        setValue(
          "recruitmentCompanyFlatRate",
          data?.data?.rc_commission?.[0]?.flat_rate
        );
        setValue(
          "screeningFlatRatePerProfile",
          data?.data?.sp_commission?.[0]?.flat_rate
        );
        setValue("screeningFlatRateOnboard", data?.data?.sp_commission?.[1]?.flat_rate);
        setValue(
          "technicalFlatRatePerProfile",
          data?.data?.te_commission?.[0]?.flat_rate
        );
        setValue("technicalFlatRateOnboard", data?.data?.te_commission?.[1]?.flat_rate);
        setValue(
          "moderatorFlatRatePerProfile",
          data?.data?.me_commission?.[0]?.flat_rate
        );
        setValue("moderatorFlatRateOnboard", data?.data?.me_commission?.[1]?.flat_rate);
        setValue("bgvFlatRate", data?.data?.bgv_commission?.[0]?.flat_rate);
  
        setValue("minSalary",data?.data?.min_sal_by_am);
        setValue("maxSalary",data?.data?.max_sal_by_am);
        setValue("minSalary",data?.data?.min_sal_by_am);
      }
    }
   
  };

  React.useEffect(() => {
    setValue("minSalary", tableData?.min_sal);
    setValue("maxSalary", tableData?.max_sal);
  }, [tableData]);
  React.useEffect(() => {
    fetchCommissionById(jobpostId);
  }, [jobpostId !== undefined]);

  const onSubmit = async data => {
    console.log(data, "nnn"); // Handle form submission logic here

    /*commissions payload */

    const commissionDataFormat = {

      min_sal_by_am:data?.minSalary !== undefined?data?.minSalary:0,
    max_sal_by_am:data?.maxSalary !== undefined?data?.maxSalary:0,
    is_commission_visible_to_js:showCommission,

      refer_self_commission: [
        {
          payout: data?.selfReferPayoutType ?? "",
          flat_rate: data?.selfReferralFlatRate ?? "",
          commission_type: "flat",
        },
      ],
      refer_now_commission: [
        {
          payout: data?.referredPayoutType ?? "",
          flat_rate: data?.referredFlatRate ?? "",
          commission_type: "flat",
        },
      ],
      sp_commission: [
        {
          payout: "per_profile",
          flat_rate: data?.screeningFlatRatePerProfile ?? "",
          commission_type: "flat",
        },
        {
          payout: "on_board",
          flat_rate: data?.screeningFlatRateOnboard ?? "",
          commission_type: "flat",
        },
      ],
      te_commission: [
        {
          payout: "per_profile",
          flat_rate: data?.technicalFlatRatePerProfile ?? "",
          commission_type: "flat",
        },
        {
          payout: "on_board",
          flat_rate: data?.technicalFlatRateOnboard ?? "",
          commission_type: "flat",
        },
      ],
      me_commission: [
        {
          payout: "per_profile",
          flat_rate: data?.moderatorFlatRatePerProfile ?? "",
          commission_type: "flat",
        },
        {
          payout: "on_board",
          flat_rate: data?.moderatorFlatRateOnboard ?? "",
          commission_type: "flat",
        },
      ],
      bgv_commission: [
        {
          payout: "one_time",
          flat_rate: data?.bgvFlatRate ?? "",
          commission_type: "flat",
        },
      ],
      rc_commission: [
        {
          payout: data?.recruitmentCompanyPayoutType ?? "",
          flat_rate: data?.recruitmentCompanyFlatRate ?? "",
          commission_type: "flat",
        },
      ],
    };

    console.log(commissionDataFormat,"commissionDataFormat")
    const updateCommissionsData = await updateCommissions(commissionDataFormat,tableData?.id)
    const {status} = updateCommissionsData
    if(status === 200){
      toast.success("Submitted successfully")
    }else {
      toast.error("Something went wrong")
    }
    if(!jobpostId){
      reCall()
    }
   
    handleClose();

    /*Approve job logic */
    // const { status, data: approvedData } = await handleApproveJob(
    //   tableData?.id
    // );
    // if ([200,201].includes(status)) {
    //   setUpdate(pre=>!pre);
    //   toast.success("Successfully Approved!")
    // } else {
    //   ErrorHandlingToaster({ status, data: approvedData });
    // }
    /* Approve logic end here */
  };

  const jobtype = tableData?.job_type?.replace("_", "");

  return (
    <React.Fragment>
      <BootstrapDialog
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ marginLeft: "100px" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* Job Description Section */}
          <CommissionTypography variant="h3">
            Job Description
          </CommissionTypography>
          <BlackTypography variant="body1">
            Job Title: {tableData?.job_title}
            <br />
            Company: {tableData?.company_details?.company_name}
            <br />
            Min Salary: {getSymbolFromCurrency(tableData?.currency)}{" "}{tableData?.min_sal}
            <br />
            Max Salary: {getSymbolFromCurrency(tableData?.currency)}{" "}{tableData?.max_sal}
            <br />
            Commission: {tableData?.commission_rate}%<br />
            Flat Rate:
            {getSymbolFromCurrency(tableData?.currency)}{" "}{Math.ceil(
              tableData?.max_sal * (tableData?.commission_rate / 100)
            )}{" "}
            <br />
            Jobtype:{tableData?.job_type?.replace("_", "")}
          </BlackTypography>
          <MiddleDivider />

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              {/*Salary section */}

              {jobtype === "Contract" ? (
                <>
                  <Grid item xs={12} sx={{ marginTop: "10px" }}>
                    <CommissionTypography variant="h1" sx={{ color: "black" }}>
                      Salary
                    </CommissionTypography>
                  </Grid>

                  <Grid item xs={12} lg={6} md={12}>
                    <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                      Joinee Min Salary
                    </PrimaryNormalInputLabel>
                    <PrimaryCommissionInputs
                      sx={{ marginTop: "-20px" }}
                      fullWidth
                      label="Min Salary"
                      {...register("minSalary", { required: false })}
                      // error={!!errors.minSalary}
                      // helperText={
                      //   errors.minSalary && "Flat Rate is required"
                      // }
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={12}>
                    <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                      Joinee Max Salary
                    </PrimaryNormalInputLabel>
                    <PrimaryCommissionInputs
                      sx={{ marginTop: "-20px" }}
                      fullWidth
                      label="Min Salary"
                      {...register("maxSalary", { required: false })}
                      // error={!!errors.minSalary}
                      // helperText={
                      //   errors.minSalary && "Flat Rate is required"
                      // }
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}

              {/* Job  seeker Section */}
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <CommissionTypography variant="h1" sx={{ color: "black" }}>
                  Refer Self{" "}
                  <PrimarySwitch
                    checked={showCommission || getValues("referredFlatRate") !== null}
                    onChange={() => setShowCommission(!showCommission)}
                  />
                </CommissionTypography>
              </Grid>

              {showCommission && (
                <>
                  {" "}
                  <Grid item xs={12} lg={6} md={12}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="folloups-job-type"
                        error={errors.job_type}
                      >
                        Payout
                      </PrimaryNormalInputLabel>

                      <Select
                        labelId="folloups-job-type"
                        id="folloups-job-type"
                        value={getValues("selfReferPayoutType")}
                        {...register("selfReferPayoutType", { required: true })}
                        input={
                          <PrimaryNormalInputs
                            placeholder="Payout"
                            label="folloups-job-type"
                            error={errors?.selfReferPayoutType}
                            {...register("selfReferPayoutType", {
                              required: true,
                            })}
                          />
                        }
                        MenuProps={MenuProps}
                      >
                        {payouts?.map(item => {
                          return (
                            <MenuItem
                              key={item?.value}
                              value={item?.value.toLowerCase()} // style={getStyles(name, personName, theme)}
                            >
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <PrimaryNormalFormHelperText
                        error={errors.selfReferPayoutType}
                      >
                        {errors?.selfReferPayoutType
                          ? "Payout type is a required field"
                          : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6} md={12}>
                    <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                      Flat rate
                    </PrimaryNormalInputLabel>
                    <PrimaryCommissionInputs
                      sx={{ marginTop: "-20px" }}
                      fullWidth
                      label="Flat Rate"
                      {...register("selfReferralFlatRate", { required: true })}
                      error={!!errors.selfReferralFlatRate}
                      helperText={
                        errors.selfReferralFlatRate && "Flat Rate is required"
                      }
                    />
                  </Grid>
                </>
              )}

              {/* Referby candidate Section */}
              <Grid item xs={12}>
                <CommissionTypography variant="h1">
                  Refer Now
                </CommissionTypography>
              </Grid>

              <Grid item xs={12} lg={6} md={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="folloups-job-type"
                    error={errors.job_type}
                  >
                    Payout
                  </PrimaryNormalInputLabel>
                  <Select
                    labelId="folloups-job-type"
                    id="folloups-job-type"
                    value={getValues("referredPayoutType")}
                    {...register("referredPayoutType", { required: true })}
                    input={
                      <PrimaryNormalInputs
                        placeholder="Select A Job Type"
                        label="folloups-job-type"
                        error={errors?.referredPayoutType}
                        {...register("referredPayoutType", { required: true })}
                      />
                    }
                    MenuProps={MenuProps}
                  >
                    {payouts?.map(item => {
                      return (
                        <MenuItem
                          key={item?.value}
                          value={item?.value} // style={getStyles(name, personName, theme)}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <PrimaryNormalFormHelperText
                    error={errors.referredPayoutType}
                  >
                    {errors?.referredPayoutType
                      ? "Payout type is a required field"
                      : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={6} md={12}>
                <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                  Flat rate
                </PrimaryNormalInputLabel>
                <PrimaryCommissionInputs
                  sx={{ marginTop: "22px" }}
                  fullWidth
                  label="Flat Rate"
                  {...register("referredFlatRate", { required: true })}
                  error={!!errors.referredFlatRate}
                  helperText={
                    errors.referredFlatRate && "Flat Rate is required"
                  }
                />
              </Grid>

              {/* Screening Partner Section */}
              <Grid item xs={12}>
                <CommissionTypography variant="h1">
                  Screening Partner
                </CommissionTypography>
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={getValues("screeningPerProfile")}
                      {...register("screeningPerProfile", { required: false })}
                      color="primary"
                    />
                  }
                  label="Per Profile"
                  sx={{
                    color: errors.screeningPerProfile ? "red" : "inherit",
                  }}
                />
                {errors.screeningPerProfile && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={getValues("screeningOnboard")}
                      {...register("screeningOnboard", { required: false })}
                      color="primary"
                    />
                  }
                  label="Onboard"
                  sx={{
                    color: errors.screeningOnboard ? "red" : "inherit",
                  }}
                />
                {errors.screeningOnboard && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              {watch("screeningPerProfile") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    label="Flat Rate (Per Profile)"
                    {...register("screeningFlatRatePerProfile", {
                      required: false,
                    })}
                    error={!!errors.screeningFlatRatePerProfile}
                    helperText={
                      errors.screeningFlatRatePerProfile &&
                      "Flat Rate is required"
                    }
                  />
                </Grid>
              )}

              {watch("screeningOnboard") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    label="Flat Rate (Onboard)"
                    {...register("screeningFlatRateOnboard", {
                      required: false,
                    })}
                    error={!!errors.screeningFlatRateOnboard}
                    helperText={
                      errors.screeningFlatRateOnboard && "Flat Rate is required"
                    }
                  />
                </Grid>
              )}

              {/* Technical Evaluator Section */}
              <Grid item xs={12}>
                <CommissionTypography variant="h1">
                  Technical Evaluator
                </CommissionTypography>
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={getValues("technicalPerProfile")}
                      {...register("technicalPerProfile", { required: false })}
                      color="primary"
                    />
                  }
                  label="Per Profile"
                  sx={{
                    color: errors.technicalPerProfile ? "red" : "inherit",
                  }}
                />
                {errors.technicalPerProfile && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={getValues("technicalOnboard")}
                      {...register("technicalOnboard", { required: false })}
                      color="primary"
                    />
                  }
                  label="Onboard"
                  sx={{
                    color: errors.technicalOnboard ? "red" : "inherit",
                  }}
                />
                {errors.technicalOnboard && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              {watch("technicalPerProfile") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    label="Flat Rate (Per Profile)"
                    {...register("technicalFlatRatePerProfile", {
                      required: false,
                    })}
                    error={!!errors.technicalFlatRatePerProfile}
                    helperText={
                      errors.technicalFlatRatePerProfile &&
                      "Flat Rate is required"
                    }
                  />
                </Grid>
              )}

              {watch("technicalOnboard") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    label="Flat Rate (Onboard)"
                    {...register("technicalFlatRateOnboard", {
                      required: false,
                    })}
                    error={!!errors.technicalFlatRateOnboard}
                    helperText={
                      errors.technicalFlatRateOnboard && "Flat Rate is required"
                    }
                  />
                </Grid>
              )}

              {/*Moderator section */}

              <Grid item xs={12}>
                <CommissionTypography variant="h1">
                  Moderator
                </CommissionTypography>
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={getValues("moderatorPerProfile")}
                      {...register("moderatorPerProfile", { required: false })}
                      color="primary"
                    />
                  }
                  label="Per Profile"
                  sx={{
                    color: errors.moderatorPerProfile ? "red" : "inherit",
                  }}
                />
                {errors.moderatorPerProfile && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={getValues("moderatorOnboard")}
                      {...register("moderatorOnboard", { required: false })}
                      color="primary"
                    />
                  }
                  label="Onboard"
                  sx={{
                    color: errors.moderatorOnboard ? "red" : "inherit",
                  }}
                />
                {errors.moderatorOnboard && (
                  <Typography variant="body2" color="red">
                    This field is required.
                  </Typography>
                )}
              </Grid>

              {watch("moderatorPerProfile") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    label="Flat Rate (Per Profile)"
                    {...register("moderatorFlatRatePerProfile", {
                      required: false,
                    })}
                    error={!!errors.moderatorFlatRatePerProfile}
                    helperText={
                      errors.moderatorFlatRatePerProfile &&
                      "Flat Rate is required"
                    }
                  />
                </Grid>
              )}

              {watch("moderatorOnboard") && (
                <Grid item xs={12} lg={6}>
                  <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                    Flat rate
                  </PrimaryNormalInputLabel>
                  <PrimaryCommissionInputs
                    fullWidth
                    label="Flat Rate (Onboard)"
                    {...register("moderatorFlatRateOnboard", {
                      required: false,
                    })}
                    error={!!errors.moderatorFlatRateOnboard}
                    helperText={
                      errors.moderatorFlatRateOnboard && "Flat Rate is required"
                    }
                  />
                </Grid>
              )}

              {/* Recrutment company Section */}
              <Grid item xs={12}>
                <CommissionTypography variant="h1">
                  Recruitment Company
                </CommissionTypography>
              </Grid>

              <Grid item xs={12} lg={6} md={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="folloups-job-type"
                    error={errors.job_type}
                  >
                    Payout
                  </PrimaryNormalInputLabel>
                  <Select
                    labelId="folloups-job-type"
                    id="folloups-job-type"
                    value={getValues("recruitmentCompanyPayoutType")}
                    {...register("recruitmentCompanyPayoutType", {
                      required: true,
                    })}
                    input={
                      <PrimaryNormalInputs
                        placeholder="Select A Job Type"
                        label="folloups-job-type"
                        error={errors?.recruitmentCompanyPayoutType}
                        {...register("recruitmentCompanyPayoutType", {
                          required: true,
                        })}
                      />
                    }
                    MenuProps={MenuProps}
                  >
                    {payouts?.map(item => {
                      return (
                        <MenuItem
                          key={item?.value}
                          value={item?.value} // style={getStyles(name, personName, theme)}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <PrimaryNormalFormHelperText
                    error={errors.recruitmentCompanyPayoutType}
                  >
                    {errors?.recruitmentCompanyPayoutType
                      ? "Payout type is a required field"
                      : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={6} md={12}>
                <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                  Flat rate
                </PrimaryNormalInputLabel>
                <PrimaryCommissionInputs
                  fullWidth
                  label="Flat Rate"
                  {...register("recruitmentCompanyFlatRate", {
                    required: true,
                  })}
                  error={!!errors.recruitmentCompanyFlatRate}
                  helperText={
                    errors.recruitmentCompanyFlatRate && "Flat Rate is required"
                  }
                />
              </Grid>

              {/* Background company Section */}
              <Grid item xs={12}>
                <CommissionTypography variant="h1">
                  Background Verification
                </CommissionTypography>
              </Grid>

              <Grid item xs={12}>
                {/* <TextField
                  fullWidth
                  label="Flat Rate"
                  {...register("bgvFlatRate", { required: true })}
                  error={!!errors.bgvFlatRate}
                  helperText={errors.bgvFlatRate && "Flat Rate is required"}
                /> */}

                <PrimaryNormalInputLabel shrink htmlFor="folloups-job-type">
                  Flat rate
                </PrimaryNormalInputLabel>
                <PrimaryCommissionInputs
                  sx={{ marginTop: "22px" }}
                  fullWidth
                  label="Flat Rate"
                  {...register("bgvFlatRate", { required: true })}
                  error={!!errors.bgvFlatRate}
                  helperText={errors.bgvFlatRate && "Flat Rate is required"}
                />
              </Grid>

              {/* Similar sections for other partner types */}
            </Grid>

            <PrimaryButton
              type="submit"
              sx={{ marginTop: "8px", width: "100px" }}
            >
              Submit
            </PrimaryButton>
          </form>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Save changes
          </Button> */}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

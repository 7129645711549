import { Button } from "@mui/material";
import {
  PrimaryButton,
  PrimaryOutlinedButton,
  PrimaryOutlinedOpacitedButton,
  SecondaryButton,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import ErrorHandlingToaster from "helpers/Toaster/error";
import { handleApproveJob } from "helpers/services/adminStaff/AccountManager.js/ApproveJobs";
import SocketConnection from "hooks/sockets";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CloseJobModel from "./closeJobModel";
import { deleteSingleJob } from "helpers/services/jobs/jobs";
import ViewMoreButton from "./ViewMoreButtons";
import { toast } from "react-hot-toast";

export default function ApprovedCardsButton({
  tableData,
  setTableData,
  item,
  //   handleViewMore,
  setUpdate,
}) {
  // const { sendMessage } = SocketConnection();
  const role = useSelector(state => state?.Login?.role);
  const [open, setOpen] = useState(false);
  const [closeId, setCloseId] = useState("");
  const onSubmit = async () => {
    const { status, data } = await deleteSingleJob(closeId);
    ErrorHandlingToaster({ status, data });
    if (status === 200) {
      setUpdate(true);
      toast.success("Successfully Closed")
      const index = tableData.findIndex(({ id }) => id === closeId);
      const data = [...tableData];
      const items = data.splice(index, 1);
      setTableData(data);
    }
    handleClose();
  };
  const handleClose = React.useCallback(() => {
    setOpen(!open);
    setCloseId(item?.id);
  }, [open]);
  return (
    <div className="d-flex align-items-center justify-content-end gap-2 mt-2">
      {role === "ZA" || role === "ACM" ? (
        <PrimaryOutlinedOpacitedButton
          sx={{ width: "100px" }}
          onClick={handleClose}
        >
          Close
        </PrimaryOutlinedOpacitedButton>
      ) : null}
      <ViewMoreButton id={item?.id} />
      <CloseJobModel
        open={open}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />
    </div>
  );
}
